import React from 'react'
import MobileHeader from '../../common/MobileHeader'
import { makeStyles } from '@fluentui/react-components';
import { FASQsData } from '../../common/FaqsData';
import FaqCategoryList from './FaqCategoryList';
import FaqForm from './FaqForm';
import { isValidEmail } from '../../../../utils/validators';
import { LeadRequest, ZohoService } from '../../../../services/openapi';
import useUTMParams from '../../../../hooks/useUTMParams';

function MobileFaqs() {
    const classes = useStyles();
    const [data, setData] = React.useState({ question: '', email: '', name: '' });
    const [isValidMail, setIsValidMail] = React.useState(false);
    const [isformSubmitted, setisFormSubmitted] = React.useState<boolean>(false);
    const utmParams = useUTMParams();

    const onSubmit = (formValues: any) => {
        const hasValidEmail = isValidEmail(data.email)
        setIsValidMail(!hasValidEmail);
        if (hasValidEmail) {
            setisFormSubmitted(true);
            setData({ ...data, question: "", email: "", name: "" });
            createLead(formValues)
        }
    }
    const createLead = (formValues: any) => {
        const data: LeadRequest = {
            data: [
                {
                    "Lead_Source": "FlyghtCloud Form",
                    "Last_Name": formValues.name,
                    "Email": formValues.email,
                    Description: formValues.question,
                    "Form_Page_Url": "https://flyghtcloud.ideaforgetech.com/faqs",
                    "Form_Name": "FAQ - Flyght Cloud",
                    ...utmParams,
                }
            ]
        }

        ZohoService.addLeadToZohoCrm(data).then(() => {
            console.log("Lead created successfully")
        }).catch(err => console.error("Error creating lead", err))
    }

    return <>
        <div style={{ zIndex: 9999, background: "#ffffff", position: "sticky", top: "0" }}>
            <MobileHeader />
        </div>
        <div className={classes['mob-container']}>
            <h2 className={classes.primaryHeading}>Frequently Asked Questions</h2>
            <div>
                {FASQsData.map((data, index) => (
                    <div key={index} >
                        <FaqCategoryList key={index} faqsData={data} />
                    </div>
                ))}
            </div>
        </div>
        <div className={classes['mob-container']}>
            <h3 className={classes.cardHeading}>Can't find what you're looking for?</h3>
            <p style={{ margin: ".5em 0" }}>Don't worry, you can submit your question and we'll get back to  you with an answer.</p>
            <FaqForm
                data={data}
                isValidMail={isValidMail}
                onSubmit={onSubmit}
                setData={setData}
                setisFormSubmitted={setisFormSubmitted}
                setIsValidMail={setIsValidMail}
            />
            {
                isformSubmitted ? <div> <h3 className={classes.cardHeading} style={{ marginTop: "1em" }}>Thank you for reaching out!</h3>
                    <p style={{ margin: ".5em 0" }}>We have received your question and will contact you at the provided email address with a response.</p>
                </div> : null
            }
        </div>
        <div className={classes['mob-container']} style={{ fontSize: ".7rem", textAlign: "center" }}>
            <p style={{ marginTop: "1em", color: "#586A84" }}>Copyright © 2024 ideaForge Technology Ltd. All rights reserved.</p>
        </div>
    </>
}

export default MobileFaqs;

const useStyles = makeStyles({
    headerNavBar: {
        position: "sticky",
        top: 0,
        width: "100%",
        backgroundColor: "white",
        zIndex: 999

    },
    "mob-container": {
        width: "93%",
        margin: "0 auto 1em auto"
    },
    faqContainer: {
        maxWidth: "92%",
    },
    primaryHeading: {
        fontSize: "1.5rem",
        fontWeight: 600,
        padding: ".5em 0"
    },
    cardHeading: {
        fontSize: "1.2rem",
        fontWeight: 600,
    },
})