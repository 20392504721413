import * as React from 'react';
import { AuthContext } from "../AuthContext";
import { Button, Toolbar, tokens } from "@fluentui/react-components";
import { UserInfoResponse, UsersService } from "../services/openapi";
import { Link } from 'react-router-dom';
import { Header } from './Header';

export const ManageUsers = () => {
    const { me: user } = React.useContext(AuthContext);

    const [users, setUsers] = React.useState<UserInfoResponse[] | null>();
    const [error, setError] = React.useState('');
    const [search, setSearch] = React.useState('');

    React.useEffect(() => {
        UsersService.listUsers()
            .then((users) => setUsers(users.users))
            .catch((err) => setError(`Could not fetch users: ${err}`));
    }, [])

    if (!user?.admin) {
        return <div>You are not authorized to use this page.</div>
    }

    return <div style={{
        height: '96%',
        boxShadow: '5px 1px 20px 0px rgba(0, 0, 0, 0.05)'
    }}>
        <Header ButtonLink='/' ButtonText='Dashboard' />

        <div style={{
            display: 'flex',
            paddingRight: '3em',
            flexDirection: 'column',
            height: "95%",
            margin: '1em',
            backgroundColor: tokens.colorNeutralBackground1,
            borderRadius: '0.5em',
        }}>
            <div style={{
                fontWeight: 600, fontSize: '2.5em',
                margin: '1em'
            }}>
                Users
            </div>

            {error && <div>{error}</div>}

            <Toolbar>
                <input
                    type="input"
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Search user by email/Id"
                    style={{
                        width: '80%',
                        borderRadius: '1.5em',
                        padding: '1em 2em',
                        fontSize: '1.1em',
                        fontWeight: 'bold',
                        border: '0.01em solid black',
                        marginLeft: '0.5em',
                    }}
                />
                <Button style={{
                    width: "17%",
                    borderRadius: '1.5em',
                    fontSize: '1.1em',
                    paddingTop: '1em',
                    paddingBottom: '1em',
                    fontWeight: 'bold',
                    border: '0.01em solid black',
                    marginLeft: "1em",
                }}><Link to={'/admin/manageUsers/addUser'}>Add User</Link></Button>
            </Toolbar>

            <br />
            <div className='scrollablelist' style={{
                overflowY: 'scroll',
                height: '100%',
            }}>
                {users && users.filter((u) =>
                    u.email.toLowerCase().includes(search) || u.id.includes(search)
                ).map((u) => {
                    return <div
                        key={u.id}
                        style={{
                            padding: '0.8em',
                            margin: '0.8em',
                            backgroundColor: tokens.colorNeutralBackground1Hover,
                            borderRadius: '1em',
                            fontSize: '1.2em',
                        }}
                    >
                        <Link style={{
                            textDecoration: 'none',
                            color: 'black',
                        }}
                            to={`/admin/manageUsers/${u.id}`} state={{ userInfo: u }}>
                            <div>
                                <b>Email: </b> {u.email}<br />
                                <b>admin: </b>{u.admin.toString()}<br />
                                <b>ID: </b>{u.id}<br />
                                <b>Joined: </b>{u.joined}<br />
                                <b>Active: </b>{u.active.toString()}<br />
                                <b>plugin_Client: </b>{u.pluginClient.toString()}<br />
                            </div>
                        </Link>
                    </div>
                })}
            </div>
        </div >
    </div>
};