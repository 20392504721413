import React from 'react';
import { Button, Field, Input, Textarea, makeStyles } from '@fluentui/react-components';

const useStyles = makeStyles({
    quesText: {
        marginTop: "2rem",
        marginBottom: ".5rem",
        fontSize: "1rem !important",
        fontWeight: 600,
    },
    mailText: {
        marginBottom: ".5rem",
        fontSize: "1rem !important",
        fontWeight: 600,
    }
});

interface FaqFormProps {
    data: { question: string; email: string; name: string };
    isValidMail: boolean;
    onSubmit: (formValues: any) => void;
    setData: React.Dispatch<React.SetStateAction<{ question: string; email: string; name: string }>>;
    setisFormSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
    setIsValidMail: React.Dispatch<React.SetStateAction<boolean>>;
}

const FaqForm: React.FC<FaqFormProps> = ({ data, isValidMail, onSubmit, setData, setisFormSubmitted, setIsValidMail }) => {
    const classes = useStyles();

    return (
        <form
            style={{
                flexGrow: 1,
                margin: 'auto',
            }}
            onSubmit={(e) => { e.preventDefault(); onSubmit(data); }}
        >
            <div style={{ paddingBottom: '1em' }}>
                <h3 className={classes.quesText}>Your question *</h3>
                <Textarea
                    style={{ width: '100%', border: "1px solid #D1DBE8" }}
                    appearance="outline"
                    size="large"
                    placeholder="Ask your question here..."
                    value={data.question}
                    onChange={(e) => {
                        setisFormSubmitted(false);
                        setData({ ...data, question: e.target.value });
                    }}
                />
            </div>
            <div style={{ paddingBottom: '1em' }}>
                <h3 className={classes.mailText}>Your name *</h3>
                <Input
                    style={{ width: '100%', border: "1px solid #D1DBE8" }}
                    appearance="outline"
                    size="large"
                    placeholder="Type your name here"
                    value={data.name}
                    onChange={(e) => {
                        setisFormSubmitted(false);
                        setData({ ...data, name: e.target.value });
                    }}
                />
            </div>
            <div>
                <h3 className={classes.mailText}>Your email address *</h3>
                <Field validationMessageIcon={null} validationMessage={isValidMail ? "Incorrect email address" : null}>
                    <Input
                        style={{
                            width: '100%',
                            border: isValidMail ? "1px solid red" : "1px solid #D1DBE8"
                        }}
                        appearance="outline"
                        size="large"
                        placeholder="Type your email here"
                        value={data.email}
                        onChange={(e) => {
                            setIsValidMail(false);
                            setisFormSubmitted(false);
                            setData({ ...data, email: e.target.value });
                        }}
                    />
                </Field>
            </div>
            <div style={{ marginTop: "1rem" }}>
                <Button
                    style={{ width: '100%', background: "#5E5CE6", color: "white", padding: "8px 16px", borderRadius: "8px" }}
                    appearance="primary"
                    disabled={!data.question || !data.email || !data.name}
                    type="submit"
                >
                    Submit
                </Button>
            </div>
        </form>
    );
};

export default FaqForm;
