import { makeStyles, shorthands } from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";
import PageNotFoundImage from "../assets/icons/page_not_found.svg";
import { PrimaryButton2 } from "./common/PrimaryButton2";

interface PageNotFoundProps {
    headerText?: string;
    descriptionText?: string;
    actionButton?: boolean;
}

export function PageNotFound(props: PageNotFoundProps) {
    const { headerText = "Page Not Found", descriptionText = "Sorry, we can't find the page that you are looking for.", actionButton = true } = props;
    const classes = useStyles();

    const navigate = useNavigate();
    return <div className={classes.container}>
        <img src={PageNotFoundImage} alt="page-not-found" />
        <h2 className={classes.header}>{headerText}</h2>
        <p className={classes.content}>{descriptionText}</p>
        {actionButton && <PrimaryButton2 label="Back to Dashboard" onClick={() => {
            navigate("/dashboard")
        }} />}
    </div>
}

const useStyles = makeStyles({
    container: {
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        // justifyContent: "center",
        paddingTop: "40px",
        flexDirection: "column",
        ...shorthands.gap("16px"),
    },
    header: {
        color: "#536D86",
        textAlign: "center",
        fontSize: "28px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "36px"
    },
    content: {
        color: "#536D86",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "28px"
    }
})