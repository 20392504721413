import { Card, makeStyles, shorthands} from '@fluentui/react-components';

interface featuresDataProps {
    id: number,
    title: string,
    icon: string
}
function FeaturesCard({ featuresData }: { featuresData: featuresDataProps }) {
    const classes = useStyles();
    return <Card className={classes.card} style={{ padding: "0" }}>
        <div style={{ display: "flex", height: "10vh", padding: ".3em", alignItems: "center", justifyContent: "space-between" }}>
            <img className={classes.gridImg} src={featuresData.icon} alt={featuresData.title} />
            <p style={{ fontWeight: "700" }}>{featuresData.title}</p>
        </div>
    </Card >
}

const useStyles = makeStyles({
    card: {
        width: "100%",
        maxWidth: "100%",
        height: 'calc(100% - 0rem)',
        boxShadow: "none",
        ...shorthands.border("1px", "solid", "#DBE0E5")
    },
    gridImg: {
        width: "20px",
        marginLeft: ".5em",
        marginRight: ".5em"
    },
})

export default FeaturesCard