import { Card, makeStyles, shorthands, CardHeader, Text, Caption1, TagGroup, Tag } from "@fluentui/react-components"
import { useNavigate } from "react-router-dom"
import { truncateText } from "../../common/textUtils"

type WorkFlowCardProps = {
    workFlowCardData: {
        id: string,
        icon: string,
        title: string,
        info: string
        tagNames: string[]
    },
    selectedTabValue: string | null
}
const WorkFlowCard = ({ workFlowCardData, selectedTabValue }: WorkFlowCardProps) => {
    const styles = useStyles();
    const navigate = useNavigate();

    const handleClickCard = () => {
        if (selectedTabValue === "Partner Plugins") {
            navigate(`/workFlow-library/my-workFlowLibrary`)
        } else {
            navigate(`/workFlow-library/ideaForge-plugins/${workFlowCardData.id}`)
        }
    }
    return <Card className={styles.card} onClick={handleClickCard}>
        <div>
            <div className={styles.imgConatiner} style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{
                    width: "40%",
                    minHeight: "calc(0rem + 15vh)",
                }}>
                    <img className={styles.headerImage}
                        style={{ boxShadow: "-1px 1px 10px 0px #0000001A" }}
                        src={workFlowCardData.icon}
                        alt={workFlowCardData.title} />
                </div>
                <div style={{ marginLeft: ".5em", width: "60%" }}>
                    <CardHeader
                        header={<Text className={styles.cardHeading}>{truncateText(workFlowCardData.title, 6)}</Text>}
                        description={
                            <Caption1 className={styles.caption}>{truncateText(workFlowCardData.info, 15)}</Caption1>
                        }
                    />
                </div>
            </div>
        </div>
        <div>
            <TagGroup role="list" className={styles.tagContainer} >
                {
                    workFlowCardData.tagNames.map((item, index) => <Tag key={index} role="listitem" className={styles.tagBar} style={{ height: "3.5vh", borderRadius: "4px" }}>{item}</Tag>
                    )
                }
            </TagGroup>
        </div>
    </Card >
}
const useStyles = makeStyles({
    card: {
        ...shorthands.border('1px', 'solid', '#E3E9F2'),
        ...shorthands.borderRadius("16px"),
        ...shorthands.padding('15px'),
        cursor: "pointer",
        backgroundColor: "#FBFBFB",
        boxShadow: "none",
        width: "100%",
        maxWidth: "100%",
        height: "calc(0rem + 100%)",
    },
    cardHeading: {
        fontSize: ".8em",
        color: "#272727",
        fontWeight: 500,
        lineHeight: "1.5rem",
        ...shorthands.margin(0),
    },
    headerImage: {
        ...shorthands.borderRadius("4px"),
        width: "100%",
        height: "100%",
        objectFit: "cover"
    },
    caption: {
        color: '#272727',
        marginTop: "1em",
        fontWeight: 400,
        marginBottom: "1em",
    },
    tagBar: {
        backgroundColor: "#E3E6EB",
        color: "#272727",
        fontSize: ".5rem !important",
        ...shorthands.padding("4px", "10px"),
        display: "flex", justifyContent: "center", alignItems: "center",
        '& .f1g03r3y': {
            fontSize: '.7rem',
        },
    },
    imgConatiner: {
        height: 'calc(85% - 4rem)',
    },
    tagContainer: {
        display: "flex",
        flexWrap: "wrap",
        marginTop: ".5em",
        ...shorthands.gap(".2em")
    }
})


export default WorkFlowCard