import * as React from "react";
import { Button, Field, Input, makeStyles } from "@fluentui/react-components";
import { Eye16Regular, EyeOff16Regular } from "@fluentui/react-icons";
import useIsMobile from "../hooks/useIsMobile";
interface ForgotPasswordVerificationForm {
    otp: string,
    password: string,
    confirmPassword: string
}

interface SignProps {
    onSubmit: (f: ForgotPasswordVerificationForm) => void;
    onChangeAuthForm: (f: string) => void;
    formData: ForgotPasswordVerificationForm;
    initialState: ForgotPasswordVerificationForm;
    buttonState: boolean;
    setForgotPasswordVerificationValues: React.Dispatch<React.SetStateAction<ForgotPasswordVerificationForm>>;
}

const useStyles = makeStyles({
    primaryHeading: {
        fontSize: "1.2rem",
        fontWeight: 600,
        paddingTop: "1em",
        paddingBottom: "1em",
        textAlign: "center"
    },
    spanColor: {
        color: "#1570EF",
        marginTop: "1em",
        cursor: "pointer"
    },
    inputContainer: {
        paddingBottom: "1.5em",
        '& .f13qh94s': {
            display: "block"
        }
    }
});

export const ForgotPasswordVerification = (props: SignProps) => {
    const classes = useStyles();
    const isMobile = useIsMobile();

    const [passwordVisible, setPasswordVisible] = React.useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = React.useState(false);
    const [isPasswordMatch, setIsPasswordMatch] = React.useState(false);
    const [passwordError, setPasswordError] = React.useState<string | null>(null);

    const handleBackLogin = () => {
        props.setForgotPasswordVerificationValues(props.initialState);
        props.onChangeAuthForm("forgotPassword");
    }

    const handleSubmit = () => {
        const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{10,}$/;
        const isPasswordValid = passwordRegex.test(props.formData.password);

        if (isPasswordValid && props.formData.password === props.formData.confirmPassword) {
            props.onSubmit(props.formData);
            props.setForgotPasswordVerificationValues(props.initialState);
        }
        else if (!isPasswordValid) {
            setPasswordError("Incorrect password. Password must contain 10 characters,1 Uppercase, 1 Lowercase, 1 number  and 1 special character.");
            setIsPasswordMatch(false);
        } else if (props.formData.password !== props.formData.confirmPassword) {
            setIsPasswordMatch(true);
            setPasswordError(null);
        }
        else {
            setPasswordError("Passwords do not match. Please try again.");
            setIsPasswordMatch(true);
        }
    };


    return <div className="signUpContainer">
        <h3 className={classes.primaryHeading}>Reset Password</h3>
        <p>Enter your new password and confirm it.</p>
        <form
            onSubmit={(e) => { e.preventDefault() }}
            style={{ marginTop: "1.5em", }}
        >
            <div className={classes.inputContainer}>
                <Input

                    style={{
                        width: '100%',
                        border: isMobile ? "1px solid #E5E5E5" : "1px solid #E5E5E5",
                        borderRadius: "8px"
                    }}
                    autoComplete="off"
                    appearance="outline"
                    name="otp"
                    size="large"
                    placeholder="OTP"
                    maxLength={4}
                    value={props.formData.otp}
                    onChange={(e) => {
                        props.setForgotPasswordVerificationValues({ ...props.formData, otp: e.target.value })
                    }}

                />
            </div>
            <div className={classes.inputContainer}>
                <Field validationMessageIcon={null} validationMessage={passwordError !== null ? passwordError : null}>
                    <Input
                        style={{
                            width: '100%',
                            borderRadius: "8px",
                            border: isMobile ? passwordError !== null ? "1px solid red" : "1px solid #E5E5E5" : passwordError !== null ? "1px solid red" : "1px solid #E5E5E5"
                        }}
                        appearance="outline"
                        autoComplete="new-password"
                        name="password"
                        size="large"
                        placeholder="Password"
                        type={passwordVisible ? "text" : "password"}
                        contentAfter={passwordVisible ?
                            <EyeOff16Regular onClick={() => setPasswordVisible(false)} />
                            : <Eye16Regular onClick={() => setPasswordVisible(true)} />}
                        value={props.formData.password}
                        onChange={(e) => {
                            setPasswordError(null)
                            setIsPasswordMatch(false)
                            props.setForgotPasswordVerificationValues({ ...props.formData, password: e.target.value })
                        }}
                    />
                </Field>
            </div>
            <div className={classes.inputContainer} style={{ marginTop: "0" }}>
                <Field validationMessageIcon={null} validationMessage={isPasswordMatch ? "Passwords do not match. Please try again." : null}>
                    <Input
                        style={{
                            width: '100%',
                            borderRadius: "8px",
                            border: isMobile ? isPasswordMatch ? "1px solid red" : "1px solid #E5E5E5" : isPasswordMatch ? "1px solid red" : "1px solid #E5E5E5"
                        }}
                        appearance="outline"
                        size="large"
                        placeholder="Confirm Password"
                        type={confirmPasswordVisible ? "text" : "password"}
                        contentAfter={confirmPasswordVisible ?
                            <EyeOff16Regular onClick={() => setConfirmPasswordVisible(false)} />
                            : <Eye16Regular onClick={() => setConfirmPasswordVisible(true)} />}
                        value={props.formData.confirmPassword}
                        onChange={(e) => {
                            setIsPasswordMatch(false)
                            props.setForgotPasswordVerificationValues({ ...props.formData, confirmPassword: e.target.value })
                        }}
                    />
                </Field>
            </div>
            <p style={{ fontSize: "0.8rem" }}>
                Password must be at least 10 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character.
            </p>
            <div style={{ marginTop: "1rem" }}>
                <Button
                    shape="circular"
                    style={{
                        width: '100%', background: "#0A84FF", color: "white", padding: "11px",
                    }}

                    onClick={handleSubmit}
                    disabled={!props.formData.otp || !props.formData.password || !props.formData.confirmPassword || props.buttonState}
                    type="submit"
                >
                    Submit
                </Button>
            </div>
            <div style={{ marginTop: "1rem" }}>
                <Button
                    shape="circular"
                    style={{
                        width: '100%', background: "none", border: "1px solid #5C74E6", color: "#5E5CE6", padding: "11px",
                    }}
                    onClick={handleBackLogin}
                >
                    Cancel
                </Button>
            </div>
        </form>
    </div >

};
