import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Field, Radio, RadioGroup, Tooltip, makeStyles, shorthands } from '@fluentui/react-components';
import { Dismiss20Regular } from '@fluentui/react-icons';
import { Feature, Map, View } from 'ol';
import { defaults } from "ol/control";
import { Coordinate } from 'ol/coordinate';
import { getCenter } from "ol/extent";
import { Point } from "ol/geom";
import ImageLayer from "ol/layer/Image";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import { Projection, fromLonLat } from "ol/proj";
import Static from "ol/source/ImageStatic";
import OSM from "ol/source/OSM";
import VectorSource from "ol/source/Vector";
import { Stroke, Style } from "ol/style";
import { useEffect, useRef, useState } from "react";
import GCPFlagIcon from "../../assets/icons/gcp_flag.svg";
import { API_BASE } from "../../constants";
import { ControlPointProjection, ControlPointResponse, ControlPointsService, FlightsService, ImageResponse } from "../../services/openapi";
import { getFlagStyle, imageNormalStyle, imageSelectedStyle, imageSuccessStyle, } from './mapview_assets';


interface MapViewProps {
    flightID: string,
    setShowImageView: React.Dispatch<React.SetStateAction<"true" | "false" | "pending">>,
    showImageView: "true" | "false" | "pending",
    mapClickable: boolean,
    controlPoints: ControlPointResponse[] | null,
    selectedMarker?: ControlPointResponse,
    setSelectedMarker: React.Dispatch<React.SetStateAction<ControlPointResponse | undefined>>,
    flaggedImages: ControlPointProjection[],
    getControlPointProjections: () => void
}

let offsetX: number = 0;
let offsetY: number = 0;


export function GCPMapView(props: MapViewProps) {
    const { flightID, setShowImageView, showImageView, mapClickable, controlPoints, selectedMarker, setSelectedMarker, flaggedImages, getControlPointProjections } = props;
    const classes = useStyles();
    const [flightImages, setFlightImages] = useState<ImageResponse[]>([]);
    const mapRef = useRef<HTMLDivElement>(null);
    const imageref = useRef<HTMLDivElement>(null);
    const [currentImage, setCurrentImage] = useState<ImageResponse | null>(null);
    const [imageEl, setImageEl] = useState<HTMLImageElement | null>(null);
    const flagDragabbleRef = useRef<HTMLImageElement>(null)
    const [showImageActionButtons, setShowImageActionButtons] = useState<{
        visible: boolean,
        saveButtonVisible: boolean,
        imagePos?: { imageX: number, imageY: number }
    }>({ visible: false, saveButtonVisible: true });
    const [controlPoint, setControlPoint] = useState<ControlPointResponse>();
    const [showMarkersConfirmation, setShowMarkerConfirmation] = useState(false);
    const [resetDraggedFlag, setResetDraggedFlag] = useState(false);

    const getFlightImages = () => {
        FlightsService.getImages(flightID)
            .then(res => {
                const filteredImages = res.filter(image => image.latitude !== null && image.longitude !== null);
                setFlightImages(filteredImages);
            })
            .catch(err => console.error(err));
    }

    useEffect(getFlightImages, []);

    useEffect(() => {
        if (!mapRef.current || !flightImages || !flightImages.length) return;

        let icons = (flightImages || []).map((i) => {
            const p = fromLonLat([i.longitude || 0, i.latitude || 0]);
            const iconFeature = new Feature({
                geometry: new Point(p),
                image: i,
            });

            let iconStyle;
            const flaggedImageIDs = flaggedImages.map(obj => obj.imageId);
            if (flaggedImageIDs.includes(i.id)) {
                iconStyle = imageSuccessStyle;
            } else if (currentImage?.id === i.id) {
                iconStyle = imageSelectedStyle;
            } else {
                iconStyle = imageNormalStyle;
            }

            iconFeature.setStyle(iconStyle);

            return iconFeature;
        });

        const vectorSource = new VectorSource({
            features: icons,
        });

        const vectorLayer = new VectorLayer({
            source: vectorSource,
            opacity: 1,
            zIndex: 10,
        });

        const markerIcons = (controlPoints || []).map(m => {
            const p = fromLonLat([m.x, m.y]);

            const iconFeature = new Feature({
                geometry: new Point(p),
            });

            iconFeature.setProperties({
                marker: m.name,
                markerID: m.id
            });

            iconFeature.setStyle(getFlagStyle(m.name, "normal"));

            return iconFeature;
        });

        const markerSource = new VectorSource({
            features: markerIcons,
        });

        const markerLayer = new VectorLayer({
            source: markerSource,
            opacity: 1,
            zIndex: 12,
        });

        const extent = vectorLayer.getSource()?.getExtent() || [0, 0, 0, 0];

        const map = new Map({
            controls: defaults({
                zoom: false,
                rotate: false,
                attribution: false,
            }),
            target: mapRef.current,
            layers: [
                new TileLayer({
                    visible: true,
                    source: new OSM(),
                }),
                vectorLayer,
                markerLayer
            ],
            view: new View({
                center: getCenter(extent),
                zoom: 12,
            }),
            keyboardEventTarget: document,
        });

        map.getView().fit(extent);

        if (mapClickable) {
            if (selectedMarker) {
                map.once('postrender', function () {
                    const p = fromLonLat([selectedMarker.x, selectedMarker.y])
                    const pixel = map.getPixelFromCoordinate(p)
                    map.getView().setCenter(p)

                    const feature = map.forEachFeatureAtPixel(pixel, (f) => {
                        return f;
                    });
                    if (feature) {
                        if (!currentImage) {
                            setShowImageView("pending")
                        };
                        const marker = feature.getProperties().marker;
                        if (marker) {
                            let realFeature: any;
                            markerLayer.getSource()?.forEachFeature(f => {
                                if (f.getProperties().markerID === feature.getProperties().markerID) {
                                    realFeature = f;

                                };
                                f.setStyle(getFlagStyle(f.getProperties().marker, "normal"));
                            });

                            if (realFeature) {
                                realFeature.setStyle(getFlagStyle(marker, "selected"));
                            };
                        };
                    };
                });
            };
            map.on('click', (e) => {
                let marker
                const feature = map.forEachFeatureAtPixel(e.pixel, (f) => {
                    return f;
                });
                if (feature) {
                    setShowImageView("true")
                    const image = feature.get('image');
                    marker = feature.getProperties().marker
                    if (image) {
                        vectorLayer.getSource()?.forEachFeature(f => {
                            if (f.getProperties().image.id === image.id) {
                                f.setStyle(imageSelectedStyle);
                            } else {
                                if (flaggedImages.find(item => item.imageId === f.getProperties().image.id)) {
                                    f.setStyle(imageSuccessStyle);
                                } else {
                                    f.setStyle(imageNormalStyle);
                                };
                            };
                        });
                        selectImage(image);
                    };
                    if (marker) {
                        let realFeature: any;
                        markerLayer.getSource()?.forEachFeature(f => {
                            if (f.getProperties().marker === feature.getProperties().marker) {
                                realFeature = f;
                                const m = controlPoints?.find(item => item.id === f.getProperties().markerID)
                                if (m) {
                                    setSelectedMarker(m);
                                };
                            };
                            f.setStyle(getFlagStyle(f.getProperties().marker, "normal"));
                        });

                        if (realFeature) {
                            realFeature.setStyle(getFlagStyle(marker, "selected"));
                        };

                    };
                };
            });
            map.on("pointermove", function (e) {
                const hit = map.hasFeatureAtPixel(e.pixel);
                map.getTargetElement().style.cursor = hit ? 'pointer' : '';
            });
        }

        return () => {
            map.setTarget(undefined);
        };
    }, [flightImages, flaggedImages, mapClickable, selectedMarker, controlPoints]);

    useEffect(() => {
        if (!imageref.current || !currentImage || !imageEl) return;

        const extent = [0, 0, imageEl.width, imageEl.height];
        const projection = new Projection({
            code: 'static-image',
            units: 'pixels',
            extent,
        });
        const source = new Static({
            url: `${API_BASE}/api/images/${currentImage.id}/content`,
            projection,
            imageExtent: extent,
        });

        const flagStyles = new Style({
            stroke: new Stroke({
                color: "white",
                width: 0.5,
            })
        });

        const p = fromLonLat([0, 0]);
        const iconFeature = new Feature({
            geometry: new Point(p),
        });
        iconFeature.setStyle(flagStyles)

        const flagSource = new VectorSource({
            features: [iconFeature]
        });

        const vector = new VectorLayer({
            source: flagSource,
            opacity: 1,
            zIndex: 10,
        });
        const map = new Map({
            controls: defaults({
                zoom: false,
                rotate: false,
                attribution: false,
            }),
            target: imageref.current,
            layers: [
                new ImageLayer({
                    source,
                }),
                vector
            ],
            view: new View({
                projection: projection,
                center: getCenter(extent),
                zoom: 0,
                maxZoom: 6,
            }),
            keyboardEventTarget: document,
        });
        map.getView().fit(extent);
        const moveFlag = (s: Style, p: Coordinate, callback?: () => void) => {
            const vectorLayer = map.getAllLayers()[1] as VectorLayer<VectorSource<Point>>

            if (vectorLayer) {
                vectorLayer.getSource()?.forEachFeature(f => {
                    f.setGeometry(new Point(p))
                    f.setStyle(s)
                    return;
                })
                if (callback) {
                    callback()
                };
            };
        };


        const im = flaggedImages.find(item => item.imageId === currentImage.id)
        const controlPoint = controlPoints?.find(item => item.id === im?.controlPointId)
        if (im && controlPoint) {
            moveFlag(getFlagStyle(controlPoint.name, "normal"), [im.imageX, imageEl.height - im.imageY], () => {
                setShowImageActionButtons({ visible: true, saveButtonVisible: false })
            })
        } else {
            setShowImageActionButtons({ visible: false, saveButtonVisible: false })
            const vectorLayer = map.getAllLayers()[1] as VectorLayer<VectorSource<Point>>
            if (vectorLayer) {
                vectorLayer.getSource()?.forEachFeature(f => {
                    const p = fromLonLat([0, 0]);
                    f.setGeometry(new Point(p))
                    f.setStyle(flagStyles)
                    return;
                })
            };
            setResetDraggedFlag(false)
            if (flagDragabbleRef && flagDragabbleRef.current) {
                flagDragabbleRef.current.addEventListener('dragstart', function (event: DragEvent) {

                    const rect = (event.target as HTMLElement).getBoundingClientRect();


                    const flagHandleEndX = rect.left + (rect.width / 2) - 1;
                    const flagHandleEndY = rect.top + (rect.height / 2);

                    const mouseX = event.clientX;
                    const mouseY = event.clientY;

                    const offsetX = mouseX - flagHandleEndX;
                    const offsetY = mouseY - flagHandleEndY;
                    event.dataTransfer?.setData('application/json', JSON.stringify({ offsetX, offsetY }));
                    event.dataTransfer?.setData('text', 'marker');
                });
            };

            map.getViewport().addEventListener('dragover', function (event) {
                event.preventDefault();
            });

            map.getViewport().addEventListener('drop', function (event: any) {
                event.preventDefault();
                const offsetData = event.dataTransfer?.getData('application/json');
                const { offsetX, offsetY } = JSON.parse(offsetData || '{}');
                var data = event.dataTransfer.getData('text');
                if (data === 'marker') {
                    var pixel = map.getEventPixel({ clientX: event.clientX, clientY: event.clientY });
                    pixel[0] -= offsetX;
                    pixel[1] -= offsetY;

                    const [x, y] = map.getCoordinateFromPixel(pixel);

                    vector.getSource()?.forEachFeature(f => {
                        f.setGeometry(new Point([x, y]))
                        f.setStyle(getFlagStyle("", "normal"))
                        return;
                    })

                    const realY = imageEl.height - y;
                    if (x >= 0 && x < imageEl.width && realY >= 0 && realY < imageEl.height) {
                        setShowImageActionButtons({ visible: true, imagePos: { imageX: x, imageY: realY }, saveButtonVisible: true })
                    };
                };
            });
        };

        return () => {
            map.setTarget(undefined);
        };
    }, [imageEl, currentImage, flaggedImages, resetDraggedFlag])

    useEffect(() => {
        getControlPointProjections()
    }, []);


    const selectImage = (i: ImageResponse) => {
        setCurrentImage(i);
        const im = new Image();
        im.src = `${API_BASE}/api/images/${i.id}/content`;
        im.onload = () => {
            setImageEl(im)
        };
    };

    const saveControlPointProjection = (data: { imageId: string, imageX: number, imageY: number }) => {
        ControlPointsService.createControlPointProjection(controlPoint?.id ?? selectedMarker?.id ?? "", data).then(() => { getControlPointProjections(); setControlPoint(undefined) });
    };

    const resetControlPointProjection = (projectionID: string) => {
        ControlPointsService.deleteControlPointProjection(projectionID).then(() => { getControlPointProjections() });
    };

    const showFlag = !showImageActionButtons.visible && currentImage
    return <div className={classes.mapViewContainer} style={{ width: showImageView === "false" ? "50%" : "100%" }}>
        <div ref={mapRef} style={{ width: '100%', height: '100%', borderTopRightRadius: "8px", borderBottomRightRadius: "8px", overflow: "hidden" }}></div>
        {showImageView === "true" && <div style={{ width: '100%', height: '100%', position: "relative" }}>
            <div ref={imageref} style={{ width: '100%', height: '100%' }}></div>
            {showFlag && <div className={classes.controls}>
                <Tooltip content="Marker" relationship="label" hideDelay={0} appearance="inverted" positioning={"before-top"} showDelay={0} >
                    <img src={GCPFlagIcon} alt="zoom in" className={classes.icon} onClick={() => null} ref={flagDragabbleRef} />
                </Tooltip>
            </div>}

            {showImageActionButtons.visible && <div className={classes.imageActionButtons}>
                <Button appearance="secondary" className={classes.secondaryButton}
                    onClick={() => {
                        const image = flaggedImages.find(item => item.imageId === currentImage?.id)
                        if (image) {
                            resetControlPointProjection(image.id)
                        } else {
                            setResetDraggedFlag(true)
                        }
                        setShowImageActionButtons({ visible: false, imagePos: undefined, saveButtonVisible: false })
                    }}
                >Reset</Button>

                {showImageActionButtons.saveButtonVisible && <Button appearance="primary" className={classes.primaryButton} onClick={() => {
                    setShowMarkerConfirmation(true)

                }}>Save</Button>
                }
            </div>}
        </div>}

        {showImageView === "pending" && <div style={{ width: '100%', height: '100%', display: "flex", alignItems: "center", justifyContent: "center" }}>
            <p className={classes.pendingImageText}>
                Click on any image capture point to tag GCP on that image
            </p>
        </div>}

        {showImageView !== "false" && <div className={classes.actionsContainer}>
            <Button appearance="primary" className={classes.cancelActionButton} onClick={() => { setCurrentImage(null); setSelectedMarker(undefined); setShowImageView("false") }}>
                Cancel
            </Button>
            <Button appearance="primary" className={classes.primaryButton} onClick={() => { setCurrentImage(null); setSelectedMarker(undefined); getControlPointProjections(); setShowImageView("false") }}>Done</Button>
        </div>}

        <Dialog
            open={showMarkersConfirmation}
            onOpenChange={(_, d) => { setShowMarkerConfirmation(d.open) }}
            modalType='non-modal'
        >
            <DialogSurface >
                <DialogBody style={{ gap: "16px" }}>
                    <DialogTitle
                        className={classes.markerConfirmationHeader}
                        action={
                            <DialogTrigger action="close">
                                <Button
                                    appearance="subtle"
                                    aria-label="close"
                                    icon={<Dismiss20Regular />}
                                />
                            </DialogTrigger>
                        }>Confirm GCP</DialogTitle>
                    <DialogContent >
                        <Field>
                            <RadioGroup onChange={(e, data) => {
                                if (data.value) {
                                    const cp = controlPoints?.find(item => item.name === data.value)
                                    if (cp) {
                                        setControlPoint(cp)
                                    }
                                }
                            }}>
                                {controlPoints?.map(item => <Radio value={item.name} label={item.name} defaultChecked={item.id === selectedMarker?.id} key={item.id} />)}
                            </RadioGroup>
                        </Field>
                    </DialogContent>

                    <DialogActions className={classes.createMarkerActionsContainer}>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary" className={classes.createMarkerCloseActionButton}>Close</Button>
                        </DialogTrigger>
                        <Button appearance="primary" className={classes.createMarkerSuccessActionButton} onClick={() => {
                            saveControlPointProjection({ imageId: currentImage?.id ?? "", imageX: showImageActionButtons.imagePos?.imageX ?? 0, imageY: showImageActionButtons.imagePos?.imageY ?? 0 })
                            setShowMarkerConfirmation(false)
                        }}>
                            Done
                        </Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog >
    </div >
}

const useStyles = makeStyles({
    mapViewContainer: {
        height: "100%",
        borderTopRightRadius: "1rem",
        borderBottomRightRadius: "1rem",
        display: "flex",
        position: "relative",
        ...shorthands.borderRadius("8px"),
    },
    controls: {
        position: 'absolute',
        bottom: '124px',
        right: '20px',
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "40px",
        height: "40px",
        ...shorthands.borderRadius("8px"),
        boxShadow: "-2px 2px 10px 0px rgba(0, 0, 0, 0.25)",
        backgroundColor: "white"
    },
    icon: {
        ...shorthands.padding("8px"),
        cursor: "pointer"
    },
    imageActionButtons: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: "92px",
        display: "flex",
        ...shorthands.gap("24px"),
        justifyContent: "center",
        alignItems: "center",
    },
    secondaryButton: {
        display: "flex",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "24px",
        paddingRight: "24px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        ...shorthands.borderRadius("8px"),
        ...shorthands.border("1px", "solid", "#79747E"),
        color: "#79747E",
        textAlign: "center",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0.1px"
    },
    pendingImageText: {
        color: "#586A84",
        textAlign: "center",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "24px"
    },
    markerConfirmationHeader: {
        color: "#586A84",
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "28px"
    },
    createMarkerActionsContainer: {
        display: "flex",
        ...shorthands.gap("16px"),
        marginTop: "1rem",
    },
    createMarkerCloseActionButton: {
        display: "flex",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "24px",
        paddingRight: "24px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        ...shorthands.borderRadius("8px"),
        ...shorthands.border("1px", "solid", "#79747E"),
        color: "#79747E",
        textAlign: "center",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0.1px"

    },
    createMarkerSuccessActionButton: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        ...shorthands.borderRadius("8px"),
        backgroundColor: "#5E5CE6",
        color: "#FFF",
        textAlign: "center",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0.1px",
        paddingLeft: "24px",
        paddingRight: "24px",
    },
    actionsContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "14px",
        paddingBottom: "14px",
        ...shorthands.gap("24px"),
        ...shorthands.border("1px", "solid", "#E3E9F2"),
        ...shorthands.borderRadius("0", "0", "8px", "8px"),
        position: "absolute",
        bottom: "0",
        left: "0",
        right: "0",
        backgroundColor: "#FFF",

    },
    cancelActionButton: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        ...shorthands.borderRadius("8px"),
        ...shorthands.border("1px", "solid", "rgba(121, 116, 126, 1)"),
        backgroundColor: "#FFF",
        color: "#79747E",
        textAlign: "center",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0.1px",
        paddingLeft: "24px",
        paddingRight: "24px",
        paddingTop: "10px",
        paddingBottom: "10px",
    },
    primaryButton: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        ...shorthands.borderRadius("8px"),
        backgroundColor: "#5E5CE6",
        color: "#FFF",
        textAlign: "center",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0.1px",
        paddingLeft: "24px",
        paddingRight: "24px",
        paddingTop: "10px",
        paddingBottom: "10px",
        ...shorthands.border("none"),
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#7E7CFF",
            color: "#FFF",
        },
        "&:active": {
            color: "#FFF",
            backgroundColor: "#3C3AC7",
        }
    },
})