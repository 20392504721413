import { Button, Dialog, Switch, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, Tooltip, makeStyles, mergeClasses, shorthands } from "@fluentui/react-components";
import React, { useRef, useState } from "react";
import CSVReader from 'react-csv-reader';
import { Link, useParams } from "react-router-dom";
import DeleteIcon from "../../assets/icons/delete.svg";
import projectHeaderIcon from "../../assets/icons/project_header.svg";
import { ControlPointProjection, ControlPointResponse, ControlPointsService, ProjectionsResponse, WorkflowDetailsResponse, WorkflowsService } from "../../services/openapi";
import { Loading } from "../Loading";
import { GCPMapView } from "./MapView";
import { GCPCompleteModal } from "./modals/GCPCompleteModal";
import SampleCSVModal from "./modals/SampleCSVModal";

enum Steps {
    browseGCPData = 'browseGCPData',
    viewMarkers = 'viewMarkers',
}

export function GCPTaggingPage() {
    const classes = useStyles()
    const { workflowId = "" } = useParams();

    const csvRef = useRef<HTMLInputElement>(null);
    const [wf, setWf] = useState<WorkflowDetailsResponse | null>(null);
    const [currentStep, setCurrentStep] = useState<Steps>(Steps.browseGCPData);
    const [epsgCode, setEpsgCode] = useState("");
    const [showDoneModal, setShowDoneModal] = useState(false);
    const [controlPoints, setControlPoints] = useState<ControlPointResponse[] | null>(null);
    const [showImageView, setShowImageView] = useState<"true" | "false" | "pending">("false");
    const [currentSelectedMarker, setCurrentSelectedMarker] = useState<ControlPointResponse>();
    const [csvUploadError, setCSVUploadError] = useState<string | null>(null)
    const [showSampleCSV, setShowSampleCSV] = useState(false)
    const [flaggedImages, setFlaggedImages] = useState<ControlPointProjection[]>([]);

    React.useEffect(() => {
        if (workflowId) {
            load();
        }
    }, [workflowId]);

    React.useEffect(() => {
        if (wf?.flightId) {
            getControlPoints();
            getControlPointProjections()
        }
    }, [wf?.flightId]);

    const load = () => {
        WorkflowsService.getWorkflowDetails(workflowId).then((wf) => setWf(wf));
    };

    const toggleControlPointCheckpoint = (data: ControlPointResponse, isChecked: boolean) => {
        ControlPointsService.editControlPoint(data.id, { checkpoint: isChecked }).then(() => getControlPoints()).catch(err => console.error(err));
    }

    const deleteControlPoint = (id: string) => {
        ControlPointsService.deleteControlPoint(id).then(() => getControlPoints()).catch(err => console.error(err));
    }

    const getControlPoints = () => {
        ControlPointsService.getControlPoints(wf?.flightId ?? "")
            .then(value => {
                setControlPoints(value.controlPoints);
                if (value.controlPoints.length > 0) {
                    setCurrentStep(Steps.viewMarkers);
                    setCSVUploadError(null)
                } else {
                    setCurrentStep(Steps.browseGCPData);
                }
            });
    };

    const uploadControlPoints = async (
        file: File | undefined,
    ) => {
        if (!file) return;
        const csvContents = await file.text();
        await ControlPointsService.uploadControlPoints(
            wf?.flightId ?? "",
            {
                epsgCode: `EPSG:${epsgCode}`,
                data: csvContents,
            }
        ).catch(err => {
            console.table(err);
            if (err.body) {
                const regex = /missing field `(.+?)`/;
                const match = err.body.match(regex);
                if (match) {
                    const missingField = match[1];
                    setCSVUploadError(`Invalid CSV: Missing field "${missingField}"`);
                } else {
                    setCSVUploadError("Please upload a valid file");
                }
            } else {
                setCSVUploadError("Please upload a valid file");
            }
        });
        getControlPoints();
    };


    const getControlPointProjections = async () => {
        ControlPointsService.getAllProjectionsForFlight(wf?.flightId || "").then((d: ProjectionsResponse) => {
            setFlaggedImages(d.projections.map(item => item))
        }).catch(err => console.error(err))
    };

    const getNoOfProjections = (cid: string) => {
        return flaggedImages.reduce(((value, projection) => projection.controlPointId === cid ? ++value : value), 0);
    }

    if (!wf) {
        return <Loading />;
    };

    const invalidEPSG = !epsgCode || isNaN(Number(epsgCode))

    return <Dialog open={true}>
        <div className={classes.projectHeaderText}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <Link style={{ textDecoration: "none" }} className={classes.flexContent} to="/projects">
                    <img src={projectHeaderIcon} />
                    Projects
                </Link>
            </div>
            <p style={{ margin: "0 .2em " }}>{'>'}</p>
            <Link style={{ textDecoration: "none" }} className={classes.flexContent} to={`/projects/${wf.projectId}`}>{wf.projectName}</Link><p style={{ margin: "0 .2em " }}>{'>'}</p>
            {wf.flightId && <Link to={`/projects/${wf.projectId}/flights/${wf.flightId}`} style={{ textDecoration: "none" }} className={classes.flexContent} >{wf.flightName}</Link>}
            <p style={{ margin: "0 .2em " }}>{'>'}</p> <Link style={{ textDecoration: "none" }} className={classes.flexContent} to={`/workflows/${wf.id}`}>{wf.name}</Link>
            <p style={{ margin: "0 .2em " }}>{'>'}</p> GCP Tagging
        </div>

        <div className={classes.gcpParentContainer}>
            <div className={classes.gcpContainer}>
                {showImageView === "false" && <>
                    {currentStep === Steps.browseGCPData && <div className={classes.gcpDataContainer} >
                        <h2 className={classes.gcpContainerHeader}>GCP Tagging</h2>

                        <div className={mergeClasses(classes.epsgCodeSelectionContainer)}>
                            <p style={{ marginRight: "8px" }}>Enter EPSG Code</p>
                            EPSG:<div style={{ position: "relative" }}><input value={epsgCode} onChange={(v) => setEpsgCode(v.target.value)} className={classes.epsgInput} ref={input => input && input.focus()} />
                                {epsgCode && isNaN(Number(epsgCode)) && <p style={{ position: "absolute", bottom: "-24px", fontSize: "12px", color: "red" }}>Enter valid EPSG code</p>}
                            </div>
                        </div>

                        <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "space-between", gap: "8px", marginTop: "48px" }}>
                            <div className={classes.gcpDataBrowserContainer}>
                                <div className={mergeClasses(classes.gcpDataBrowserItem, classes.browseGCPContainer)}>
                                    <p className={classes.text}>Drag and drop or browse your GCP file</p>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                                        {/* TODO: hide the tooltip if epsg is valid */}
                                        <Tooltip content="Please enter an EPSG code" relationship="label" positioning="above">
                                            <Button
                                                appearance="primary"
                                                className={invalidEPSG ? classes.primaryDisabledButton : classes.primaryButton}
                                                onClick={() => { if (csvRef.current) csvRef.current.click() }}
                                                disabled={invalidEPSG}>
                                                Browse GCP File
                                            </Button>
                                        </Tooltip>

                                        {csvUploadError && <p style={{ fontSize: "12px", color: "red", marginTop: "2px" }}>{csvUploadError}</p>}
                                    </div>

                                    <CSVReader
                                        onFileLoaded={async (data, fileInfo, originalFile) => {
                                            await uploadControlPoints(originalFile); // TODO: client side validation
                                        }}
                                        parserOptions={{
                                            header: true,
                                            dynamicTyping: true,
                                            skipEmptyLines: true,
                                        }}
                                        cssClass={classes.displayNone}
                                        label=""
                                        ref={csvRef}
                                    />

                                    <div>
                                        <p className={classes.subText}>( Supported file format: .csv )</p>

                                        <p className={classes.subTextLink} onClick={() => { setShowSampleCSV(true) }}>Click to view a sample file</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}

                    {currentStep === Steps.viewMarkers && <div className={classes.viewMarkersContainer}>
                        <h2 className={classes.gcpContainerHeader}>GCP Tagging</h2>

                        <div className={classes.gcpDataTableContainer}>
                            <Table arial-label="Default table" as="div" noNativeElements >
                                <TableHeader style={{ marginBottom: "14px" }}>
                                    <TableRow className={classes.gcpDataTableHeader}>
                                        {["#", "Marker", "Latitude", "Longitude", "Elevations", "Checkpoint", "Projections", " "].map((column) => (
                                            <TableHeaderCell key={column} >
                                                <Tooltip content={column} relationship="label" hideDelay={0} positioning={"above"} showDelay={0} withArrow>
                                                    <p style={{ width: "100%", textOverflow: "ellipsis", overflow: "hidden" }}>{column}</p>
                                                </Tooltip>
                                            </TableHeaderCell>
                                        ))}
                                    </TableRow>
                                </TableHeader>
                                <TableBody style={{ overflowY: "auto", paddingBottom: "80px" }} as="div">
                                    {controlPoints && controlPoints.map((item, index) => {
                                        return <TableRow key={item.name} style={{ border: "none" }} className={classes.gcpDataTableRow}>
                                            <TableCell style={{ maxWidth: "52px" }}>
                                                {index + 1}
                                            </TableCell>
                                            <TableCell style={{ textDecorationLine: "underline" }}>
                                                <div style={{ cursor: "pointer" }} onClick={() => {
                                                    setCurrentSelectedMarker(item)
                                                }}>
                                                    {item.name}
                                                </div>
                                            </TableCell>
                                            <TableCell style={{ paddingRight: 0 }}>
                                                <Tooltip content={item.x} relationship="label" hideDelay={0} positioning={"above"} showDelay={0} withArrow>
                                                    <p style={{ width: "100%", textOverflow: "ellipsis", overflow: "hidden" }}> {item.x}</p>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell style={{ paddingRight: 0 }}>

                                                <Tooltip content={item.y} relationship="label" hideDelay={0} positioning={"above"} showDelay={0} withArrow>
                                                    <p style={{ width: "100%", textOverflow: "ellipsis", overflow: "hidden" }}> {item.y}</p>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell style={{ paddingRight: 0 }}>
                                                <Tooltip content={item.z} relationship="label" hideDelay={0} positioning={"above"} showDelay={0} withArrow>
                                                    <p style={{ width: "100%", textOverflow: "ellipsis", overflow: "hidden" }}> {item.z}</p>
                                                </Tooltip>
                                            </TableCell>

                                            <TableCell>
                                                <Switch onChange={(e, data) => { toggleControlPointCheckpoint(item, data.checked) }} checked={item.isCheckpoint} />
                                            </TableCell>
                                            <TableCell style={{ paddingRight: 0 }}>
                                                {getNoOfProjections(item.id)}
                                            </TableCell>
                                            <TableCell>
                                                <img src={DeleteIcon} alt="delete" style={{ width: "24px", height: "24px", cursor: "pointer" }} onClick={() => { deleteControlPoint(item.id) }} />
                                            </TableCell>
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table>
                        </div>

                        <div className={classes.viewMarkersActionContainer}>
                            <Button appearance="primary" className={classes.cancelActionButton} onClick={() => {
                                if (controlPoints) {
                                    controlPoints.forEach(item => {
                                        deleteControlPoint(item.id)
                                    })
                                }
                            }}>
                                <Link to={`/workflows/${workflowId}`}>Cancel</Link>
                            </Button>
                            <Button appearance="primary" className={classes.primaryButton} onClick={() => {
                                setShowDoneModal(true)
                            }}>Done</Button>
                        </div>
                    </div>}
                </>}

                <GCPMapView
                    flightID={wf.flightId ?? ""}
                    setShowImageView={setShowImageView}
                    showImageView={showImageView}
                    mapClickable={currentStep !== Steps.browseGCPData}
                    controlPoints={controlPoints}
                    selectedMarker={currentSelectedMarker}
                    setSelectedMarker={setCurrentSelectedMarker}
                    flaggedImages={flaggedImages}
                    getControlPointProjections={getControlPointProjections}
                />

                <GCPCompleteModal wf={wf} workflowID={workflowId} visible={showDoneModal} setIsVisible={setShowDoneModal} />

                <SampleCSVModal visible={showSampleCSV} setIsVisible={setShowSampleCSV} />
            </div>
        </div>
    </Dialog >
}

const useStyles = makeStyles({
    projectHeaderText: {
        height: "3rem",
        paddingLeft: "1.25rem",
        display: "flex",
        alignItems: "center",
        color: "#2B2B2B",
        ...shorthands.borderBottom('1px', 'solid', '#E3E9F2'),
    },
    flexContent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "black", fontWeight: "normal"
    },
    gcpParentContainer: {
        height: "calc(100vh - 96px)",
        width: "100%",
        ...shorthands.padding("1rem"),
    },
    gcpContainer: {
        height: "100%",
        width: "100%",
        ...shorthands.borderRadius("1rem"),
        ...shorthands.border('1px', 'solid', '#E3E9F2'),
        display: "flex",
    },
    gcpContainerHeader: {
        color: "#586A84",
        fontSize: "28px",
        fontWeight: 600,
        lineHeight: "36px",
        marginBottom: "42px",
    },
    gcpDataContainer: {
        height: "100%",
        width: "50%",
        paddingTop: "24px",
        paddingBottom: "38px",
        paddingLeft: "32px",
        paddingRight: "32px",
        display: "flex",
        flexDirection: "column",
    },
    gcpDataBrowserContainer: {
        display: "flex",
        flexDirection: "column",
        ...shorthands.gap("21px"),
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
    },
    orText: {
        color: "#586A84",
        textAlign: "center",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "24px",
        whiteSpace: "nowrap",
    },
    gcpDataBrowserItem: {
        height: "100%",
        ...shorthands.borderRadius("8px"),
        ...shorthands.border('1px', 'solid', '#E3E9F2'),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        ...shorthands.gap("24px"),
        ...shorthands.flex("1"),
        paddingTop: "24px",
        paddingBottom: "24px",
        paddingLeft: "14px",
        paddingRight: "14px",
        width: "100%",
    },
    browseGCPContainer: {
        backgroundColor: "#F7F7FF",
    },
    createGCPContainer: {
        backgroundColor: "#FFF",
    },
    primaryButton: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        ...shorthands.borderRadius("8px"),
        backgroundColor: "#5E5CE6",
        color: "#FFF",
        textAlign: "center",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0.1px",
        paddingLeft: "24px",
        paddingRight: "24px",
        paddingTop: "10px",
        paddingBottom: "10px",
        ...shorthands.border("none"),
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#7E7CFF",
            color: "#FFF",
        },
        "&:active": {
            color: "#FFF",
            backgroundColor: "#3C3AC7",
        }
    },
    secondaryButton: {
        display: "flex",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "24px",
        paddingRight: "24px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        ...shorthands.borderRadius("8px"),
        ...shorthands.border("1px", "solid", "#79747E"),
        color: "#79747E",
        textAlign: "center",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0.1px"
    },
    subText: {
        color: "#586A84",
        textAlign: "center",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "24px"
    },
    subTextLink: {
        color: "#2E90FA",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "24px",
        textDecorationLine: "underline",
        cursor: "pointer"
    },
    epsgCodeSelectionContainer: {
        display: "flex",
        alignItems: "center",
        ...shorthands.gap("8px"),
        color: "#586A84",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "24px",
        position: "relative"
    },
    text: {
        color: "#617191",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "24px",
    },
    removeText: {
        color: "#2E90FA",
        fontSize: "14px",
        lineHeight: "20px",
        marginLeft: "1rem",
        cursor: "pointer",
    },
    gcpStepsContainer: {
        display: "flex",
        flexDirection: "column",
        ...shorthands.gap("32px"),
        height: "100%",
    },
    menuTriggerButton: {
        minWidth: "180px",
        ...shorthands.padding("4px", "11px"),
        color: "rgba(0, 0, 0, 0.90)",
        fontSize: "14px",
        lineHeight: "20px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "rgba(255, 255, 255, 0.70)",
        ...shorthands.border("1px", "solid", "rgba(0, 0, 0, 0.06)"),
        ...shorthands.borderRadius("3px"),
    },
    stepTag: {
        color: "#586A84",
        textAlign: "center",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        ...shorthands.padding("4px", "16px"),
        backgroundColor: "#FFF",
        position: "absolute",
        left: "0",
        top: "0",
        borderTopLeftRadius: "8px",
        borderBottomRightRadius: "8px",
    },
    primaryDisabledButton: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        ...shorthands.borderRadius("8px"),
        backgroundColor: "rgba(29, 27, 32, 0.12)",
        color: "rgba(29, 27, 32, 0.38)",
        textAlign: "center",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0.1px",
        paddingLeft: "24px",
        paddingRight: "24px",
        paddingTop: "10px",
        paddingBottom: "10px",
        ...shorthands.border("none"),
    },
    viewMarkersContainer: {
        height: "100%",
        width: "50%",
        paddingTop: "24px",
        paddingLeft: "32px",
        paddingRight: "32px",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        position: "relative",
        overflowX: "hidden",
    },
    viewMarkersActionContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "14px",
        paddingBottom: "14px",
        paddingLeft: "-14px",
        ...shorthands.gap("24px"),
        ...shorthands.borderTop("1px", "solid", "#E3E9F2"),
        marginLeft: "-32px",
        marginRight: "-32px",
        position: "fixed",
        bottom: "0",
        left: "17.5rem",
        right: "3rem",
        marginBottom: "16px",
        borderBottomLeftRadius: "8px",
        borderBottomRightRadius: "8px",
        backgroundColor: "#FFF",
        ...shorthands.border("1px", "solid", "rgb(227, 233, 242)"),
        zIndex: "200"

    },
    cancelActionButton: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        ...shorthands.borderRadius("8px"),
        ...shorthands.border("1px", "solid", "rgba(121, 116, 126, 1)"),
        backgroundColor: "#FFF",
        color: "#79747E",
        textAlign: "center",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0.1px",
        paddingLeft: "24px",
        paddingRight: "24px",
        paddingTop: "10px",
        paddingBottom: "10px",
    },
    displayNone: {
        display: "none"
    },
    gcpDataTableHeader: {
        ...shorthands.padding("4px"),
        color: "#7D8FA9",
        fontSize: "18px",
        lineHeight: "24px",
        textTransform: "uppercase",
        backgroundColor: "#F2F6FA",
        ...shorthands.borderRadius("8px"),
        maxHeight: "32px",
        height: "32px",
        ...shorthands.border("none"),
        marginBottom: "14px",
        display: 'flex',
        "&>*:first-child": {
            flexGrow: 0,
            minWidth: "48px",
        }
    },
    gcpDataTableRow: {
        color: "#586A84",
        fontSize: "18px",
        lineHeight: "24px",
    },
    gcpDataTableContainer: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        marginBottom: "60px",
        ...shorthands.gap("1rem"),
    },
    createMarkerContainer: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        ...shorthands.gap("1rem"),
        color: "#586A84",
        fontSize: "18px",
        lineHeight: "24px",
        '> img': {
            paddingTop: '1px'
        }
    },
    createMarkerHeader: {
        color: "#586A84",
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "28px"
    },
    epsgInput: {
        ...shorthands.borderRadius("8px"),
        backgroundColor: "#F2F6FA",
        ...shorthands.padding("8px", "16px"),
        ...shorthands.border("none"),
        ...shorthands.outline("none "),
        color: "#7D8FA9",
        fontSize: "18px",
        lineHeight: "24px",
        height: "48px",
    },
})