import odm from './ODM.svg';
import agisoft from './Metashape.svg';
import odm_coloured from './ODM_coloured.png';
import metashape_coloured from './MetashapeColoured.png';
import drive_folder_upload from './drive_folder_upload.svg';

import {
  Database24Regular,
  DocumentFolder24Regular,
  DocumentSave24Regular,
  Layer24Regular,
  Map24Filled,
  Map24Regular,
  Wallpaper24Regular,
} from '@fluentui/react-icons';
import { Tooltip } from '@fluentui/react-components';

interface Props {
  plginId: string;
  coloured: boolean;
}

interface Plugin {
  icon: React.ReactNode;
  coloredIcon?: React.ReactNode;
  name: string;
}

interface PluginsData {
  [key: string]: Plugin;
}

const pluginsData: PluginsData = {
  ODM: {
    icon: (
      <img
        src={odm}
        width={30}
        alt="odm"
        style={{ width: "24px", height: "24px" }}
      />
    ),
    coloredIcon: (
      <img
        src={odm_coloured}
        width={60}
        alt="odm"
        style={{ width: "48px", height: "48px" }}
      />
    ),
    name: "ODM",
  },
  METASHAPE: {
    icon: (
      <img
        src={agisoft}
        width={30}
        alt="odm"
        style={{ width: "24px", height: "24px" }}
      />
    ),
    coloredIcon: (
      <img
        src={metashape_coloured}
        width={60}
        alt="metashape"
        style={{ width: "48px", height: "48px" }}
      />
    ),
    name: "Metashape",
  },
  STOCKPILE_REPORT_GENERATOR: {
    icon: <Wallpaper24Regular />,
    name: "Stockpile Report Generator",
  },
  PROJECT_FILE_PICKER: {
    icon: <DocumentFolder24Regular />,
    name: "Project File Picker",
  },
  PREPARE_DATASET: {
    icon: <Database24Regular />,
    name: "Prepare Dataset",
  },
  TILE_1C: {
    icon: <Layer24Regular />,
    name: "Tile 1C",
  },
  TILE_4C_RGB: {
    icon: <Map24Regular />,
    name: "Tile 4c RGB",
  },
  TILE_GEOJSON: {
    icon: <Map24Filled />,
    name: "Tile GeoJSON",
  },
  GEOJSON_EDITOR: {
    icon: <Map24Filled />,
    name: "GeoJSON Editor",
  },
  WORKFLOW_FILE_UPLOADER: {
    icon: <DocumentSave24Regular />,
    coloredIcon: (
      <img
        src={drive_folder_upload}
        width={60}
        alt="drive_folder_uploader"
        style={{ width: "48px", height: "48px" }}
      />
    ),
    name: "Workflow File Uploader",
  },
};

export const PluginIcon = ({ plginId, coloured }: Props) => {
  const plugin: Plugin | undefined = pluginsData[plginId];

  if (!plugin) {
    return <></>;
  }

  const icon: React.ReactNode = coloured
    ? pluginsData[plginId].coloredIcon ?? <></>
    : pluginsData[plginId].icon;

  if (coloured) {
    return <>{icon}</>;
  }

  return (
    <Tooltip
      content={plugin.name}
      relationship="label"
      withArrow
      positioning="below"
    >
      <div>{icon}</div>
    </Tooltip>
  );
};
