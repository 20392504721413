import * as React from 'react';
import { AuthService, PluginResponse, PluginTokenResponse, PluginsService } from '../services/openapi';
import { Loading } from './Loading';
import { Button } from '@fluentui/react-components';

export const PluginTokensList = () => {
    const [tokens, setTokens] = React.useState(null as PluginTokenResponse[] | null);
    const [plugins, setPlugins] = React.useState(null as PluginResponse[] | null);
    const [selectedPlugin, setSelectedPlugin] = React.useState('');
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        load();
    }, [])

    const load = () => {
        setLoading(true);
        Promise.all([AuthService.getPluginTokens(), PluginsService.getOwnPlugins()])
            .then(([ts, ps]) => {
                setTokens(ts.tokens);
                setPlugins(ps.plugins);
                setLoading(false);
            })
            .catch((e) => {
                console.error(e);
                // TODO: show error
                setLoading(false);
            });
    }

    const createToken = () => {
        if (selectedPlugin) {
            setLoading(true);
            AuthService.createPluginToken({ pluginId: selectedPlugin })
                .then(() => {
                    load();
                    setSelectedPlugin('');
                })
                .catch((e) => console.error(e));
        }
    }

    const revokeToken = (token: string) => {
        setLoading(true);
        AuthService.revokePluginToken(token)
            .then(() => {
                load();
            })
            .catch((e) => console.error(e));
    }

    if (loading) {
        return <Loading />;
    }

    return <div>
        <h1>Plugin Tokens</h1>

        <div>
            <h2>Create New Plugin Token</h2>
            <div>
                <select value={selectedPlugin} onChange={(e) => setSelectedPlugin(e.target.value)}>
                    <option value=''>-- Select One --</option>
                    {plugins?.map((p) => {
                        return <option value={p.id}>{p.name}</option>
                    })}
                </select>
            </div>
            <div>
                <Button
                    appearance='primary'
                    disabled={!selectedPlugin}
                    onClick={() => createToken()}
                >Create Token</Button>
            </div>
        </div>
        <div>
            <h2>Available Tokens</h2>
            <table border={1} style={{ width: '100%' }}>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Plugin Id</th>
                        <th>Expires At (UTC)</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {tokens?.map((t) => {
                        return <tr>
                            <td>{t.token}</td>
                            <td>{t.pluginId}</td>
                            <td>{t.expiresAtUtc}</td>
                            <td>
                                <Button
                                    appearance='primary'
                                    onClick={() => revokeToken(t.token)}
                                >Revoke</Button>
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    </div>;
};
