/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PluginEventLogRequest } from '../models/PluginEventLogRequest';
import type { PluginEventResponse } from '../models/PluginEventResponse';
import type { QueueItemResponse } from '../models/QueueItemResponse';
import type { TaskFileResponse } from '../models/TaskFileResponse';
import type { TaskFilesResponse } from '../models/TaskFilesResponse';
import type { TaskInfoResponse } from '../models/TaskInfoResponse';
import type { TaskPropertiesRequest } from '../models/TaskPropertiesRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TaskQueueService {

    /**
     * @param topic
     * @param tag
     * @returns QueueItemResponse Assign a new task
     * @throws ApiError
     */
    public static assign(
        topic: string,
        tag?: string | null,
    ): CancelablePromise<QueueItemResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/task_queue/assign',
            query: {
                'topic': topic,
                'tag': tag,
            },
        });
    }

    /**
     * @param topic
     * @param tag
     * @returns QueueItemResponse Get currently assigned items
     * @throws ApiError
     */
    public static getAssigned(
        topic: string,
        tag?: string | null,
    ): CancelablePromise<Array<QueueItemResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/task_queue/items',
            query: {
                'topic': topic,
                'tag': tag,
            },
        });
    }

    /**
     * @param taskId
     * @returns QueueItemResponse Get current status of already assigned task by id
     * @throws ApiError
     */
    public static getTask(
        taskId: string,
    ): CancelablePromise<QueueItemResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/task_queue/items/{task_id}',
            path: {
                'task_id': taskId,
            },
        });
    }

    /**
     * @param taskId
     * @param requestBody
     * @returns QueueItemResponse [Admin only] Update properties of already assigned task
     * @throws ApiError
     */
    public static updateTaskProperties(
        taskId: string,
        requestBody: TaskPropertiesRequest,
    ): CancelablePromise<QueueItemResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/task_queue/items/{task_id}',
            path: {
                'task_id': taskId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param taskId
     * @returns any Ack a task
     * @throws ApiError
     */
    public static ack(
        taskId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/task_queue/items/{task_id}/ack',
            path: {
                'task_id': taskId,
            },
        });
    }

    /**
     * @param taskId
     * @returns any Nack a task
     * @throws ApiError
     */
    public static nack(
        taskId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/task_queue/items/{task_id}/nack',
            path: {
                'task_id': taskId,
            },
        });
    }

    /**
     * @param parentTaskId
     * @param requestBody
     * @returns any Log plugin events
     * @throws ApiError
     */
    public static logTaskEvent(
        parentTaskId: string,
        requestBody: PluginEventLogRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tasks/{parent_task_id}/event',
            path: {
                'parent_task_id': parentTaskId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param parentTaskId
     * @param page
     * @param perPage
     * @returns PluginEventResponse events
     * @throws ApiError
     */
    public static getTaskEvents(
        parentTaskId: string,
        page?: number | null,
        perPage?: number | null,
    ): CancelablePromise<Array<PluginEventResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tasks/{parent_task_id}/events',
            path: {
                'parent_task_id': parentTaskId,
            },
            query: {
                'page': page,
                'per_page': perPage,
            },
        });
    }

    /**
     * @param parentTaskId
     * @returns TaskFilesResponse Get associated input/output files
     * @throws ApiError
     */
    public static getFiles(
        parentTaskId: string,
    ): CancelablePromise<TaskFilesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tasks/{parent_task_id}/files',
            path: {
                'parent_task_id': parentTaskId,
            },
        });
    }

    /**
     * @param parentTaskId
     * @param fileId
     * @returns string Get associated input/output files
     * @throws ApiError
     */
    public static getFilePresignedUrl(
        parentTaskId: string,
        fileId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tasks/{parent_task_id}/files/{file_id}/presigned_url',
            path: {
                'parent_task_id': parentTaskId,
                'file_id': fileId,
            },
        });
    }

    /**
     * @param parentTaskId
     * @param fileId
     * @returns TaskFileResponse Set file upload statsu and attributes
     * @throws ApiError
     */
    public static syncFile(
        parentTaskId: string,
        fileId: string,
    ): CancelablePromise<TaskFileResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tasks/{parent_task_id}/files/{file_id}/sync',
            path: {
                'parent_task_id': parentTaskId,
                'file_id': fileId,
            },
        });
    }

    /**
     * @param parentTaskId
     * @returns TaskInfoResponse Get associated input/output files
     * @throws ApiError
     */
    public static getTaskInfo(
        parentTaskId: string,
    ): CancelablePromise<TaskInfoResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tasks/{parent_task_id}/info',
            path: {
                'parent_task_id': parentTaskId,
            },
        });
    }

}
