/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreatePluginTokenRequest } from '../models/CreatePluginTokenRequest';
import type { LoginRequest } from '../models/LoginRequest';
import type { OtpRequest } from '../models/OtpRequest';
import type { PluginTokenResponse } from '../models/PluginTokenResponse';
import type { PluginTokensResponse } from '../models/PluginTokensResponse';
import type { ResetPasswordRequest } from '../models/ResetPasswordRequest';
import type { SignUpRequest } from '../models/SignUpRequest';
import type { UserResponse } from '../models/UserResponse';
import type { VerifyResetOTPRequest } from '../models/VerifyResetOTPRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthService {

    /**
     * @param requestBody
     * @returns UserResponse Login
     * @throws ApiError
     */
    public static login(
        requestBody: LoginRequest,
    ): CancelablePromise<UserResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/login',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any No content
     * @throws ApiError
     */
    public static logout(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/logout',
        });
    }

    /**
     * @returns PluginTokensResponse New token
     * @throws ApiError
     */
    public static getPluginTokens(): CancelablePromise<PluginTokensResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/auth/plugin_tokens',
        });
    }

    /**
     * @param requestBody
     * @returns PluginTokenResponse New token
     * @throws ApiError
     */
    public static createPluginToken(
        requestBody: CreatePluginTokenRequest,
    ): CancelablePromise<PluginTokenResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/plugin_tokens',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param tokenId
     * @returns any No content
     * @throws ApiError
     */
    public static revokePluginToken(
        tokenId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/auth/plugin_tokens/{token_id}',
            path: {
                'token_id': tokenId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any Reset Password
     * @throws ApiError
     */
    public static resetPassword(
        requestBody: ResetPasswordRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/reset_password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any Verify Reset Password OTP
     * @throws ApiError
     */
    public static verifyResetPasswordOtp(
        requestBody: VerifyResetOTPRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/auth/reset_password/verify_otp',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any SignUp
     * @throws ApiError
     */
    public static signUp(
        requestBody: SignUpRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/signup',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any Verify OTP
     * @throws ApiError
     */
    public static verifyOtp(
        requestBody: OtpRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/auth/signup/verify_otp',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns UserResponse Get current user
     * @throws ApiError
     */
    public static whoami(): CancelablePromise<UserResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/auth/whoami',
        });
    }

}
