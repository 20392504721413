import { Body1, Card, shorthands, CardHeader, CardPreview, makeStyles } from '@fluentui/react-components'

interface worksDataProps {
    id: number,
    title: string,
    icon: string,
    info: string
}
function HowWorks({ worksData }: { worksData: worksDataProps }) {
    const classes = useStyles()
    return <Card className={classes.card}>
        <CardPreview>
            <img className={classes.gridImg}
                src={worksData.icon}
                alt="work logo"
            />
        </CardPreview>
        <CardHeader
            header={
                <Body1>
                    <h3 className={classes.cardHeading}>{worksData.title}</h3>
                    <p className={classes.cardInfo}>{worksData.info}</p>
                </Body1>
            }
        />
    </Card >
}

const useStyles = makeStyles({
    card: {
        width: "100%",
        maxWidth: "100%",
        height: "calc(1rem + 100%)",
        boxShadow: "none",
        fontSize: "1.2rem",
        ...shorthands.border('none'),

    },
    gridImg: {
        width: "100%",
    },
    cardHeading: {
        fontSize: "1.2rem",
        fontWeight: 600,
        marginBottom: ".5em",
    },
    cardInfo: {
        marginTop: "0",
        color: "#000000",
    }
})

export default HowWorks;