import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { API_BASE } from './constants';
import { OpenAPI } from './services/openapi';
import { lightTheme } from './theme';

import { FluentProvider } from '@fluentui/react-components';
import AppWrapper from './AppWrapper';

OpenAPI.BASE = API_BASE;
OpenAPI.WITH_CREDENTIALS = true;
OpenAPI.CREDENTIALS = 'include';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <FluentProvider theme={lightTheme} style={{ height: '100%' }}>
      <AppWrapper />
    </FluentProvider>
  </React.StrictMode>
);
