import * as React from 'react'
import { Card, makeStyles, shorthands, mergeClasses } from '@fluentui/react-components';

interface offerCardProps {
    id: number,
    title: string,
    info: string,
    icon: string,
}
function OfferCard({ offerData }: { offerData: offerCardProps }) {
    const styles = useStyles();
    return <Card
        className={mergeClasses(styles.card)}
    >
        <div style={{ height: "80%" }}>
            <div>
                <img className={styles.cardImg} src={offerData.icon} alt="offerData.title" />
                <h3 className={styles.cardHeading}>{offerData.title}</h3>
            </div>
            <div style={{ height: "20%" }}>
                <p className={styles.cardInfo}>{offerData.info}</p>
            </div>
        </div>
    </Card >
}

const useStyles = makeStyles({
    card: {
        width: "98%",
        maxWidth: "100%",
        height: 'calc(100% - 0rem)',
        ...shorthands.border("1px", "solid", "#CFDEE8"),
        ...shorthands.borderRadius("4px"),
        backgroundColor: "#F7FAFC",
        boxShadow: "none"
    },
    cardImg: {
        width: "20px"
    },
    cardHeading: {
        fontSize: "1rem",
        fontWeight: 500,
        ...shorthands.margin(0),
    },
    cardInfo: {
        color: "#3D404A",
        fontSize: ".8rem",
        fontWeight: 400,
        lineHeight: "1rem",
        marginTop: "1em"
    },
})

export default OfferCard