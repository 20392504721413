import * as React from 'react'
import { Accordion, AccordionHeader, AccordionItem, AccordionToggleEventHandler, AccordionPanel, makeStyles } from '@fluentui/react-components';
import useIsMobile from '../../../hooks/useIsMobile';

interface Faq {
    question: string;
    answer: string;
}
interface FaqCategory {
    id: number;
    type: string;
    faqs: Faq[];
}
function FaqCategoryList({ faqsData }: { faqsData: FaqCategory }) {
    const classes = useStyles();
    const isMobile = useIsMobile();

    const [openItems, setOpenItems] = React.useState<string[]>(['0']);

    const handleToggle: AccordionToggleEventHandler<string> = (event, data) => {
        setOpenItems(data.openItems);
    };
    return <>
        <h3 className={classes.bgSubHeader} style={{ margin: !isMobile ? "2rem 0" : "1.2em 0" }}>{faqsData.type}</h3>
        <div>
            {faqsData.faqs.map((data, index) => (
                <Accordion
                    openItems={openItems}
                    onToggle={handleToggle}
                    multiple
                    collapsible
                >
                    <AccordionItem style={{ marginTop: "1em", background: "#F8FAFD" }} value={index.toString()}>
                        <AccordionHeader className={classes.accordionList}>{data.question}</AccordionHeader>
                        <AccordionPanel style={{ width: "100%", margin: 0, padding: ".5em" }}>
                            <div>{data.answer}</div>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            ))}
        </div>

    </>
}
const useStyles = makeStyles({
    bgSubHeader: {
        fontSize: "1.2rem",
        maxWidth: "70%",
        fontWeight: 600,
        paddingLeft: ".5em",
    },
    accordionList: {
        '& .f1ewtqcl': {
            display: "flex",
            justifyContent: 'space-between',
            flexDirection: 'row-reverse',
            fontSize: "1rem !important",
            fontWeight: 600,

        },

    },

})

export default FaqCategoryList