import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, makeStyles, shorthands } from "@fluentui/react-components";
import { Dismiss20Regular } from "@fluentui/react-icons";
import { useNavigate } from "react-router-dom";
import { WorkflowDetailsResponse, WorkflowsService } from "../../../services/openapi";

interface GCPCompleteModalProps {
    visible: boolean;
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
    workflowID: string;
    wf: WorkflowDetailsResponse | null;
}

export function GCPCompleteModal(props: GCPCompleteModalProps) {
    const { visible, setIsVisible, wf, workflowID } = props;
    const classes = useStyles();
    const history = useNavigate();

    const finishTask = () => {
        if (!wf) return;
        const step = wf.steps.find(step => step.id === "gcp_tagging");
        if (step && step?.taskId) {
            WorkflowsService.updateTaskStatus(workflowID, step.taskId, { status: "completed" }).then(() => {
                history(-1);
            }).catch((err) => console.error(err));
        };
    };

    return <Dialog
        open={visible}
        onOpenChange={(_, d) => { setIsVisible(d.open); }}
        modalType='non-modal'
    >
        <DialogSurface >
            <DialogBody style={{ gap: "16px" }}>
                <DialogTitle
                    className={classes.header}
                    action={
                        <DialogTrigger action="close">
                            <Button
                                appearance="subtle"
                                aria-label="close"
                                icon={<Dismiss20Regular />}
                            />
                        </DialogTrigger>
                    }>GCP Tagging Completed?</DialogTitle>
                <DialogContent >
                    <p className={classes.doneText}>
                        This action is irreversible. Once GCP tagging is finalized, the workflow will proceed to the next stage.
                    </p>
                </DialogContent>

                <DialogActions className={classes.actionsContainer}>
                    <DialogTrigger disableButtonEnhancement>
                        <Button appearance="secondary" className={classes.closeButton}>Close</Button>
                    </DialogTrigger>
                    <Button appearance="primary" className={classes.successButton} onClick={finishTask}>
                        Done
                    </Button>
                </DialogActions>
            </DialogBody>
        </DialogSurface>
    </Dialog >
};


const useStyles = makeStyles({
    header: {
        color: "#586A84",
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "28px"
    },
    doneText: {
        color: "#586A84",
        fontSize: "18px",
        lineHeight: "24px"
    },
    actionsContainer: {
        display: "flex",
        ...shorthands.gap("16px"),
        marginTop: "1rem",
    },
    closeButton: {
        display: "flex",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "24px",
        paddingRight: "24px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        ...shorthands.borderRadius("8px"),
        ...shorthands.border("1px", "solid", "#79747E"),
        color: "#79747E",
        textAlign: "center",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0.1px"
    },
    successButton: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        ...shorthands.borderRadius("8px"),
        backgroundColor: "#5E5CE6",
        color: "#FFF",
        textAlign: "center",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0.1px",
        paddingLeft: "24px",
        paddingRight: "24px",
    },
})