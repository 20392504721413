import { tokens } from '@fluentui/react-components';
import * as React from 'react';
import { Link, useParams } from "react-router-dom";
import projectHeaderIcon from "../assets/icons/project_header.svg";
import { MapLayerResponse, ProjectResponse, ProjectsService, ViewsService } from '../services/openapi';
import { Header } from './Header';
import { Loading } from './Loading';
import { MapModal } from './MapModal';


export const ProjectViews = () => {
    const { projectId } = useParams();
    const [project, setProject] = React.useState<ProjectResponse | null>();
    const [layers, setLayers] = React.useState<MapLayerResponse[] | null>();

    React.useEffect(() => {
        setLayers(null);
        if (!projectId) return;
        ProjectsService.getProjectSummary(projectId)
            .then((ps) => setProject(ps))
            .catch((err) => console.error(err));
        ViewsService.getProjectViews(projectId)
            .then((res) => setLayers(res.layers))
            .catch((err) => console.error(err));
    }, [projectId]);

    if (!layers || !project) {
        return <Loading />
    }

    return <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%" }}>
        <Header>
            <div
                style={{
                    height: '100%',
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <div><Link style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textDecoration: "none", color: "black"
                    }} to="/projects"><img alt="Project" src={projectHeaderIcon} /> Projects</Link></div> <p style={{ margin: "0 .2em  " }}>{'>'}</p>
                    <Link to={`/projects/${project.id}`} style={{ textDecoration: "none", color: "black" }} id="map-breadcrumb">{project.name}</Link>
                    <span style={{ margin: "0 .2em  " }}>{">"}</span>
                    Views
                </div>
            </div>
        </Header>
        <div
            style={{
                width: '100%',
                height: '100%',
                flex: 1,
                background: tokens.colorNeutralBackground1,
                boxShadow: '5px 1px 20px 0px rgba(0, 0, 0, 0.05)',
                padding: ".5rem"
            }}
        >
            {/* <OpenLayers layers={layers} showFullScreenIcon={false} /> */}
            <MapModal layers={layers} type='non-modal' projectID={projectId} />
        </div>
    </div>
};
