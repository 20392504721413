import { Input, RadioGroup, Radio, Toolbar, tokens } from '@fluentui/react-components';
import * as React from 'react';
import { UserInfoResponse, UsersService } from '../services/openapi';
import { useParams } from 'react-router-dom';
import { PasswordRegular, Eye16Regular, EyeOff16Regular } from '@fluentui/react-icons';
import { Header } from './Header';


export const User = () => {
    const { userId } = useParams();

    const [error, setError] = React.useState('');
    const [uInfo, setUserInfo] = React.useState<UserInfoResponse>({ id: '', admin: true, active: true, email: '', joined: '', pluginClient: false });
    const [status, setStatus] = React.useState({ active: true });
    const [message, setMessage] = React.useState("");

    React.useEffect(() => {
        UsersService.getUser(userId!)
            .then((uInfo) => {
                setUserInfo(uInfo);
                setStatus({ active: uInfo!.active });
                setMessage('');
                setError('');
            })
            .catch((error) => setError(`Could not fetch user ${error}`))
    }, [userId])

    const [passwordVisible, setPasswordVisible] = React.useState(false);
    const [passwordRequest, setPassword] = React.useState({ password: '' });
    const submitPassword = () => {
        if (!userId) {
            setError("userId not found");
            return;
        }
        UsersService.setPassword(userId, passwordRequest)
            .then((uInfo) => { setUserInfo(uInfo); setMessage("password updated"); })
            .catch((error) => setError(`Could not update password ${error}`))
    };

    const submitStatus = () => {
        if (!userId) {
            setError("userId not found");
            return;
        }
        UsersService.setStatus(userId, status)
            .then((uInfo) => { setUserInfo(uInfo); setMessage("status updated"); })
            .catch((error) => setError(`Couldn't update user status ${error}`))
    };

    return <div style={{height:"95%"}}>
        <Header ButtonLink='/admin/manageUsers' ButtonText='ManageUsers'/>

        <div style={{
            margin: '5%',
            height: '60%',
            minHeight: '35em',
            display: 'flex',
            flexDirection: 'row',
            boxShadow: '5px 1px 20px 0px rgba(0, 0, 0, 0.05)',
        }}>
            <div className="operations" style={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: tokens.colorNeutralBackground1,
                borderRadius: '2em', marginRight: '1em', width: '50%', padding: '3em',
                justifyContent: 'space-between'
            }}>
                <div style={{ fontWeight: 600, fontSize: '1.5em', marginTop: '1em' }}>
                    Reset Password
                </div>
                <Toolbar style={{ borderRadius: '1em', padding: '0.2em' }}>
                    <Input value={passwordRequest.password} onChange={(e) => {
                        setPassword({ ...passwordRequest, password: e.target.value });
                    }}
                        placeholder="New Password"

                        type={passwordVisible ? "text" : "password"}
                        contentBefore={<PasswordRegular />}
                        contentAfter={passwordVisible ?
                            <EyeOff16Regular onClick={() => setPasswordVisible(false)} />
                            : <Eye16Regular onClick={() => setPasswordVisible(true)} />}
                    />
                    <button type="submit" onClick={() => submitPassword()} style={{ marginLeft: '1em' }}>Submit</button>
                </Toolbar>


                <div style={{ fontWeight: 600, fontSize: '1.5em', marginTop: '5%' }}>
                    Set Status
                </div>
                <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} onChange={(_, e) => { setStatus({ ...status, active: !!e.value }); }}>
                    <Radio value='1' label='Active' checked={status.active} />
                    <Radio value='' label='Inactive' checked={!status.active} />
                </RadioGroup>
                <button type="submit" onClick={() => submitStatus()} style={{ maxWidth: "4em" }}>Submit</button>

                <div style={{ fontWeight: 600, fontSize: '1.5em', marginTop: '5%' }}>
                    Set Credits
                </div>
            </div>


            <div style={{
                backgroundColor: tokens.colorNeutralBackground1,
                padding: '2em', borderRadius: '2em', width: '50%'
            }}>
                <div style={{ fontWeight: 600, fontSize: '1.5em', margin: '1em' }}>
                    User
                </div>
                <div className='UserDetailCurrent' style={{
                    borderRadius: '0.5em',
                    borderColor: 'black',
                    borderWidth: '1em',
                    backgroundColor: tokens.colorNeutralBackground3,
                    padding: '2em',
                    justifyContent: 'space-between',
                }}>
                    {uInfo && <div style={{
                        fontSize: '1.1em'
                    }}>
                        <b>Email: </b> {uInfo.email}<br />
                        <b>admin: </b>{uInfo.admin.toString()}<br />
                        <b>ID: </b>{uInfo.id}<br />
                        <b>Joined: </b>{uInfo.joined}<br />
                        <b>Active: </b>{uInfo.active.toString()}<br />
                        <b>plugin_Client: </b>{uInfo!.pluginClient.toString()}<br />
                    </div>
                    }
                </div>

                <div style={{ color: 'red', fontWeight: 600, fontSize: '1em', marginLeft: '2em', marginTop: '2em' }}>
                    {!error ? message : error}
                </div>
            </div>
        </div >
    </div>
}
