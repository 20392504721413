import { Card, makeStyles, shorthands, mergeClasses } from '@fluentui/react-components';
interface flyghtCloudDataProps {
  id: number,
  title: string,
  icon: string,
  info: string
}
function FlyghtCloudCard({ flyghtData }: { flyghtData: flyghtCloudDataProps }) {
  const styles = useStyles();
  return <Card
    className={mergeClasses(styles.card)}
  >
    <div style={{ display: "flex", alignItems: "center" }}>
      <img alt='card logo' style={{ width: "12%", height: "100%" }} src={flyghtData.icon} />
      <div style={{ paddingLeft: "2em", color: "#000000" }}>
        <h3 className={styles.cardHeading}>{flyghtData.title}</h3>
        <p className={styles.cardInfo}>{flyghtData.info}</p>
      </div>

    </div>
  </Card>
}

const useStyles = makeStyles({
  card: {
    width: "100%",
    maxWidth: "100%",
    ...shorthands.border("none"),
    position: "relative",
    boxShadow: "none"
  },
  text: {
    ...shorthands.margin(0),
  },
  cardImg: {
    width: "20px"
  },
  cardHeading: {
    fontSize: "1.3vw",
    fontWeight: 700,
    marginBottom: ".5em",
  },
  cardInfo: {
    marginTop: "0",
    color: "#000000",
    width: "70%",
    fontSize: "1.3vw",
    lineHeight: "1.5vw"
  }
})

export default FlyghtCloudCard