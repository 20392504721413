import * as React from "react";
import { Button, Input, tokens } from "@fluentui/react-components";
import { PersonRegular, PasswordRegular, Eye16Regular, EyeOff16Regular } from "@fluentui/react-icons";
import { UserInfoResponse, UsersService } from "../services/openapi";
import { Link, useNavigate } from "react-router-dom";
import { Header } from "./Header";


export const AddUser = () => {
    const navigate = useNavigate();
    const [error, setError] = React.useState('');

    const [passwordVisible, setPasswordVisible] = React.useState(false);
    const [data, setData] = React.useState({ email: '', password: '' });
    const [userId, setUserId] = React.useState("");

    const submit = () => {
        UsersService.addUsers(data)
            .then((uInfo: UserInfoResponse) => {
                setUserId(uInfo.id);
            })
            .catch((error) => setError(`Could not fetch user ${error}`))
    }

    return <>
        <Header ButtonLink="/admin/manageUsers" ButtonText="ManageUsers"/>
         
        <div style={{
            margin: 'auto',
            height: '50%',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: tokens.colorNeutralBackground1,
            borderRadius: '2em', width: '50%', padding: '3em',
            minWidth: '30em',
            maxWidth: '35em',
            justifyContent: 'center',
            boxShadow: '5px 1px 20px 0px rgba(0, 0, 0, 0.05)',
        }}>
            <div
                style={{
                    fontSize: 32,
                    fontWeight: '500',
                    paddingTop: '1em',
                    paddingBottom: '1em'
                }}
            >
                Add User
            </div>
            <div style={{ paddingBottom: '2em' }}>
                <Input
                    style={{
                        width: '100%',
                    }}
                    appearance="outline"
                    size="large"
                    type="email"
                    placeholder="Email Address"
                    contentBefore={<PersonRegular />}
                    value={data.email}
                    onChange={(e) => {
                        setData({ ...data, email: e.target.value })
                    }}
                />
            </div>
            <div style={{ paddingBottom: '2em' }}>
                <Input
                    style={{
                        width: '100%',
                    }}
                    appearance="outline"
                    size="large"
                    placeholder="Password"
                    type={passwordVisible ? "text" : "password"}
                    contentBefore={<PasswordRegular />}
                    contentAfter={passwordVisible ?
                        <EyeOff16Regular onClick={() => setPasswordVisible(false)} />
                        : <Eye16Regular onClick={() => setPasswordVisible(true)} />}
                    value={data.password}
                    onChange={(e) => {
                        setData({ ...data, password: e.target.value })
                    }}
                />
            </div>
            <div>
                <Button
                    style={{
                        width: '100%',
                    }}
                    appearance="primary"
                    onClick={() => {
                        submit();
                        if (userId) {
                            navigate(`/admin/manageUsers/${userId}`);
                        }
                    }}
                    disabled={!data.email || !data.password}
                >
                    Add
                </Button>
            </div>
            <div style={{ color: 'red', fontWeight: 600, fontSize: '1em', marginLeft: '2em', marginTop: '2em' }}>
                {!error ? "" : error}
            </div>
        </div>
    </>
}