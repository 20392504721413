import { Map } from 'ol';
import { EventsKey } from 'ol/events';
import Draw from 'ol/interaction/Draw';
import { Pixel } from 'ol/pixel';
import { toLonLat } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style } from 'ol/style';
import {
  AreaRequest,
  DistanceRequest,
  ElevationRequest,
  MeasurementResponse,
  MeasurementsService,
  Point,
  VolumeRequest,
} from '../../../services/openapi';

const handleDefaultTool = (
  olMap: Map,
  callbacks: {
    hideAnnotation: () => void;
    showAnnotation: (id: string) => void;
    listenersSetupCallback: (singleEventKey: EventsKey) => void;
  }
) => {
  callbacks.hideAnnotation();
  olMap.getViewport().style.cursor = 'default';
  const getFeatureAtPixel = (pixel: Pixel) => {
    const feature = olMap.getFeaturesAtPixel(pixel);
    return feature;
  };

  const handleMapClick = () => {
    const singleEventKey = olMap.on('click', (event) => {
      const pixel = olMap.getEventPixel(event.originalEvent);
      const features = getFeatureAtPixel(pixel);
      const featuresWithIDs =
        features.length > 0 &&
        features.filter((feature) => feature.get('id').length > 0);
      if (featuresWithIDs) {
        const id = features[0].get('id');
        callbacks.showAnnotation(id);
      } else {
        callbacks.hideAnnotation();
      }
    });
    callbacks.listenersSetupCallback(singleEventKey);
  };
  handleMapClick();
  return;
};

const addMapInteration = (
  olMap: Map,
  shape: 'LineString' | 'Polygon' | 'Point',
  callback: (draw: Draw) => void
) => {
  const drawStyle = new Style({
    fill: new Fill({
      color: 'rgba(4, 0, 255, 0.1)',
    }),
    stroke: new Stroke({
      color: '#006096',
      width: 2,
    }),
  });

  const vectorSource = new VectorSource({ wrapX: false });
  const drawLayer = new Draw({
    source: vectorSource,
    type: shape,
    style: drawStyle,
  });

  olMap.addInteraction(drawLayer);
  callback(drawLayer);
  return drawLayer;
};

const handleDistanceTool = (
  olMap: Map,
  layerID: string,
  callbacks: {
    addInteration: (draw: Draw) => void;
    onDrawEnd: (measurement: MeasurementResponse) => void;
    onError: () => void;
  }
) => {
  olMap.getViewport().style.cursor = 'crosshair';
  const draw = addMapInteration(olMap, 'LineString', callbacks.addInteration);

  const sendDistanceRequest = async (
    coordinates: any[],
    measurementID: string,
    end = false
  ) => {
    const transformedCoordinates = coordinates.map((coord) => toLonLat(coord));
    const formattedCoordinates = transformedCoordinates.map((coord) => ({
      latitude: coord[1],
      longitude: coord[0],
    }));

    const getDistanceRequest: DistanceRequest = {
      measurement_id: measurementID,
      coordinates: formattedCoordinates,
    };

    await MeasurementsService.getDistance(layerID, getDistanceRequest)
      .then((measurement) => {
        if (end) {
          callbacks.onDrawEnd(measurement);
        }
      })
      .catch((err) => {
        console.error('ERROR GETTING DISTANCE', err);
        callbacks.onError();
      });
  };

  draw.on('drawend', async (event: any) => {
    const coordinates = event.feature.getGeometry().getCoordinates();
    let mID = '';
    await MeasurementsService.createDistanceObject(layerID).then((distance) => {
      mID = distance.id;
    });

    for (let i = 0; i < coordinates.length - 1; i++) {
      const coordPair = [coordinates[i], coordinates[i + 1]];
      await sendDistanceRequest(coordPair, mID, i === coordinates.length - 2);
    }
  });
};

const handleVolumeTool = (
  olMap: Map,
  layerID: string,
  callbacks: {
    addInteration: (draw: Draw) => void;
    onDrawEnd: (measurement: MeasurementResponse) => void;
    onError: () => void;
  }
) => {
  olMap.getViewport().style.cursor = 'crosshair';
  const drawLayer = addMapInteration(olMap, 'Polygon', callbacks.addInteration);

  drawLayer.on('drawend', async (event) => {
    const feature = event.feature;
    const geometry = feature.getGeometry();
    const coordinates = (geometry as any).getCoordinates()[0];

    const transformedCoordinates = coordinates.map((coord: any) =>
      toLonLat(coord)
    );

    const formattedCoordinates: Point[] =
      transformedCoordinates.length > 0
        ? transformedCoordinates.map((coord: any) => ({
            latitude: coord[1],
            longitude: coord[0],
          }))
        : [];

    const getVolumeRequest: VolumeRequest = {
      coordinates: formattedCoordinates,
    };

    await MeasurementsService.getVolume(layerID, getVolumeRequest)
      .then((measurement) => {
        callbacks.onDrawEnd(measurement);
      })
      .catch((err) => {
        console.error('Something went wrong', err);
        callbacks.onError();
      });
  });
};

const handleAreaTool = (
  olMap: Map,
  layerID: string,
  callbacks: {
    addInteration: (draw: Draw) => void;
    onDrawEnd: (measurement: MeasurementResponse) => void;
    onError: () => void;
  }
) => {
  olMap.getViewport().style.cursor = 'crosshair';
  const drawLayer = addMapInteration(olMap, 'Polygon', callbacks.addInteration);

  drawLayer.on('drawend', async (event) => {
    const feature = event.feature;
    const geometry = feature.getGeometry();
    const coordinates = (geometry as any).getCoordinates()[0];

    const transformedCoordinates = coordinates.map((coord: any) =>
      toLonLat(coord)
    );

    const formattedCoordinates: Point[] =
      transformedCoordinates.length > 0
        ? transformedCoordinates.map((coord: any) => ({
            latitude: coord[1],
            longitude: coord[0],
          }))
        : [];

    const getAreaRequest: AreaRequest = { coordinates: formattedCoordinates };

    await MeasurementsService.getArea(layerID, getAreaRequest)
      .then((measurement) => {
        callbacks.onDrawEnd(measurement);
      })
      .catch((err) => {
        console.error('Something went wrong', err);
        callbacks.onError();
      });
  });
};

const handleElevationTool = (
  olMap: Map,
  layerID: string,
  callbacks: {
    onDrawEnd: (measurement: MeasurementResponse) => void;
    onError: () => void;
    addInteration: (draw: Draw) => void;
  }
) => {
  olMap.getViewport().style.cursor = 'crosshair';
  const draw = addMapInteration(olMap, 'Point', callbacks.addInteration);

  draw.on('drawend', async (event: any) => {
    const [lon, lat] = toLonLat(event.feature.getGeometry().getCoordinates());

    const pointData: ElevationRequest = {
      point: {
        latitude: lat,
        longitude: lon,
      },
    };

    try {
      const measurement = await MeasurementsService.getElevation(
        layerID,
        pointData
      );
      callbacks.onDrawEnd(measurement);
    } catch (err) {
      console.error('Error getting elevation');
      callbacks.onError();
    }
  });
};

export {
  handleAreaTool,
  handleDefaultTool,
  handleDistanceTool,
  handleElevationTool,
  handleVolumeTool,
};
