import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogBody, DialogContent, DialogSurface, makeStyles } from '@fluentui/react-components';
import modalClose from "../../../assets/icons/modalClose.svg"
import { ProjectFileResponse, ProjectResponse, ProjectsService } from '../../../services/openapi';
import ExistFile from './ExistFile';
import EmptyFiles from './EmptyFiles';
import AddNewFile from './AddNewFile';
import DeleteFile from './DeleteFile';
import { Loading } from '../../Loading';

interface FileManageWrapperProps {
    fmWrapperVisible: boolean;
    toggleFmWrapperModal: (flag: boolean) => void;
    projectId: string
    notifySucess: (msg: string) => void;
}
interface FileEditModel {
    file: ProjectFileResponse,
    editorData: { file?: File, forceUpload?: boolean, }
}
function FileManageWrapper({ fmWrapperVisible, toggleFmWrapperModal, projectId, notifySucess }: FileManageWrapperProps) {

    const classes = useStyles()
    const [files, setFiles] = React.useState<FileEditModel[] | null>(null);
    const [project, setProject] = React.useState<ProjectResponse | null>(null);
    const [isFilesEmpty, setIsFilesEmpty] = useState(false)
    const [error, setError] = React.useState("");
    const [addNewFiles, setAddNewFiles] = useState(false);
    const [cmnBtnVisible, setCmnBtnVisible] = useState(true);
    const [deleteFile, setDeleteFile] = useState(false);
    const [selectedFile, setSeletedFile] = useState("")
    const [isAddNewSeleted, setIsAddNewSeleted] = useState(false);
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        loadFiles();
    }, []);

    const loadFiles = () => {
        if (!projectId) return;
        setLoading(true)
        ProjectsService.getProjectSummary(projectId)
            .then((ps) => setProject(ps))
            .catch((err) => console.error(err));
        ProjectsService.getProjectFiles(projectId)
            .then((ps) => {
                setLoading(false)
                if (ps.files.length >= 1) {
                    setIsFilesEmpty(true)
                }
                setFiles(ps.files.map((p) => {
                    return {
                        file: p,
                        editorData: {}
                    }
                }));
            })
            .catch((err) => {
                console.error(err);
                setLoading(false)
                setError('Could not get file list')
            });

    };

    const toggleAddNewFilesModal = (flag: boolean) => {
        setAddNewFiles(flag);
    };
    const toggleDeleteModal = (flag: boolean, file_id: string) => {
        setSeletedFile(file_id)
        setDeleteFile(flag);
    };
    const handleWarpperCancel = () => {
        if (addNewFiles) {
            setAddNewFiles(false)
        }
        else if (fmWrapperVisible) {
            toggleFmWrapperModal(false)
        }
    }
    if (loading) {
        return <Loading />;
    }
    return (
        <div>
            <Dialog open={fmWrapperVisible}>
                <DialogSurface
                    style={{ boxShadow: "-1px 1px 10px 0px #0000001A" }}>
                    <style>
                        {`
                .r19ug08i {
                    backdrop-filter: blur(1px);
                    background-color: #ffffff36 !important;
                    }
                `}
                    </style>
                    <DialogBody>
                        <DialogContent>

                            <div>

                                <div style={{ display: "flex", justifyContent: "end", gap: ".5em", alignItems: "center" }}>
                                    <img onClick={() => {
                                        toggleFmWrapperModal(false)
                                    }} style={{ cursor: "pointer" }} src={modalClose} />

                                </div>
                                <h1 style={{ textAlign: files?.length === 0 ? "center" : "start", fontSize: "1.5rem", marginBottom: ".5em", fontWeight: "600", color: "#3E3E3E" }}>Manage Project Files</h1>
                                <div>
                                    <div>
                                        {
                                            (files?.length === 0 && !isAddNewSeleted) && <div>
                                                <EmptyFiles />
                                            </div>
                                        }
                                        {
                                            files && isFilesEmpty && !addNewFiles && files?.length >= 1 && <div>
                                                <div style={{ marginTop: "2.5em", marginBottom: "1em" }}>
                                                    {
                                                        files.map((row) => {
                                                            return <div key={row.file.id}>
                                                                <ExistFile
                                                                    load={loadFiles}
                                                                    existFile={row}
                                                                    projectId={projectId}
                                                                    toggleFmWrapperModal={toggleFmWrapperModal}
                                                                    toggleAddNewFilesModal={toggleAddNewFilesModal}
                                                                    toggleDeleteModal={toggleDeleteModal}
                                                                    notifySucess={notifySucess}
                                                                />
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {
                                            addNewFiles && (
                                                <AddNewFile projectId={projectId}
                                                    toggleCmnBtns={setCmnBtnVisible}
                                                    toggleAddNewFile={toggleAddNewFilesModal}
                                                    load={loadFiles}
                                                    notifySucess={notifySucess}
                                                    toggleAddNewSelected={setIsAddNewSeleted}
                                                />
                                            )
                                        }
                                    </div>
                                </div>
                                <div>
                                    {
                                        cmnBtnVisible && (
                                            <div style={{ display: "flex", justifyContent: files?.length === 0 ? "center" : "end", margin: "1em 0" }}>
                                                <Button className={classes.cmbBtns}
                                                    onClick={handleWarpperCancel}
                                                >Cancel</Button>
                                                <Button className={classes.cmbBtns}
                                                    style={{ backgroundColor: "#007AFF", color: "white", marginLeft: "1em" }}
                                                    onClick={() => {
                                                        loadFiles();
                                                        setAddNewFiles(true)
                                                        setCmnBtnVisible(false)
                                                        setIsAddNewSeleted(true)
                                                    }}
                                                >
                                                    Add New File
                                                </Button>
                                            </div>
                                        )
                                    }
                                </div>
                                {
                                    deleteFile && <DeleteFile visible={deleteFile} toggleModal={toggleDeleteModal}
                                        load={loadFiles} project_id={projectId} file_id={selectedFile}
                                        notifySucess={notifySucess}
                                    />
                                }
                            </div>
                        </DialogContent>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </div>
    )
}

export default FileManageWrapper
const useStyles = makeStyles({
    cmbBtns: {
        width: "25%",
        padding: "6px 12px",
        borderRadius: "6px",
        color: "#586A84",
        fontWeight: "600"
    },
})