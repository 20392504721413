import { Checkbox, makeStyles, mergeClasses, shorthands } from "@fluentui/react-components";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import DownloadFileIcon from "../../assets/icons/download_all_report.svg";
import DroneIcon from "../../assets/icons/drone.svg";
import ProjectIcon from "../../assets/icons/project.svg";
import ViewMapIcon from "../../assets/icons/view_map.svg";
import { FlightResponse, MapLayerResponse, ReportResponse, ReportsService, ViewsService } from "../../services/openapi";
import { getTimestampTime } from "../../utils/datetime";
import { prettydate } from "../../utils/prettyDate";
import { IconButton } from "../IconButton";
import { MapModal } from "../MapModal";
import { DownloadReportModal } from "./modals/DownloadReportModal";

interface Props {
    flights: FlightResponse[];
    handleFlightRowClick: (f: FlightResponse) => () => void
    selectedFlightIDs: string[]
}

export default function RecentFlightsColumnHeader({ flights, handleFlightRowClick, selectedFlightIDs }: Props) {
    const classes = useTableStyles();
    const [showMapDialog, setShowMapDialog] = useState<{
        flight?: FlightResponse,
        visible: boolean,
    }>({
        visible: false
    });
    const [showDownloadReportDialog, setShowDownloadReportDialog] = useState<{
        flight?: FlightResponse,
        visible: boolean,
    }>({
        visible: false
    });
    const [layers, setLayers] = useState<MapLayerResponse[] | null>();
    const [reports, setReports] = useState<ReportResponse[] | null>();


    const openMaps = (f: FlightResponse) => {
        ViewsService.getProjectViews(f.projectId)
            .then((ls) => {
                setLayers(ls.layers);
                setShowMapDialog({ flight: f, visible: true });
            })
            .catch((err) => console.error(err));
    };

    const getReports = (f: FlightResponse) => {
        ReportsService.getFlightReports(f.id)
            .then((reportsResponse) => {
                setReports(reportsResponse.reports);
                setShowDownloadReportDialog({
                    flight: f, visible: true
                })
            })
            .catch((err) => console.error(err));
    };

    const toggleMapModal = (flag: boolean) => {
        setShowMapDialog({ visible: flag })
    };

    const toggleReportModal = (flag: boolean) => {
        setShowDownloadReportDialog({ visible: flag })
    };

    return <>
        <div className={mergeClasses(classes.table, classes.rowItem)}>
            {flights.map(flight => {
                const showMapsCta = Boolean(flight.numMaps)
                const showReportsCta = Boolean(flight.numReports)

                return <React.Fragment key={flight.id}>
                    <Checkbox className={classes.checkbox} size='large' onClick={handleFlightRowClick(flight)} checked={selectedFlightIDs.includes(flight.id)} />

                    <Link className={classes.rowItemContainer} to={`/projects/${flight.projectId}/flights/${flight.id}`}>
                        <img src={DroneIcon} alt="Drone Icon" />
                        <p className={mergeClasses(classes.rowItemText, classes.flightRowText)}>{flight.name}</p>
                    </Link>

                    <Link className={classes.rowItemContainer} to={`/projects/${flight.projectId}`}>
                        <img src={ProjectIcon} alt="projectIcon" />
                        <p className={classes.rowItemText}>{flight.projectName}</p>
                    </Link>

                    <p className={classes.rowItemText}>{prettydate.format(getTimestampTime(flight.createdAt))}</p>

                    <div style={{ display: "flex", gap: "1rem", justifyContent: "flex-end" }}>
                        {showMapsCta &&
                            <IconButton borderColor='#5C74E6'
                                icon={<img src={ViewMapIcon}
                                    alt="icon" width="24px" height="24px" style={{ minWidth: "18px", minHeight: "18px" }} />}
                                onClick={() => openMaps(flight)} label="Maps" />}

                        {showReportsCta && <IconButton borderColor='#5C74E6'
                            icon={<img src={DownloadFileIcon}
                                alt="icon" width="24px" height="24px" style={{ minWidth: "18px", minHeight: "18px" }} />}
                            onClick={() => getReports(flight)} label="Reports" />}
                    </div>
                </React.Fragment>
            })}
        </div>

        <MapModal visible={showMapDialog.visible} flight={showMapDialog.flight} layers={layers} toggleModal={toggleMapModal} />
        <DownloadReportModal visible={showDownloadReportDialog.visible} reports={reports} toggleModal={toggleReportModal} />
    </>
}


const useTableStyles = makeStyles({
    table: {
        display: "grid",
        gridTemplateColumns: "5% 32% 34% 10% 19%",
        alignItems: "center",
        rowGap: '1rem',
    },
    rowItem: {
        ...shorthands.padding("0", "0.5rem"),
        paddingRight: "32px",
    },
    checkbox: {
        '> div': {
            ...shorthands.borderRadius("3.5px"),
            ...shorthands.borderColor("#7D8FA9"),
            ...shorthands.margin(0),
        },
    },
    rowItemText: {
        color: "#586A84",
        fontSize: "18px",
        lineHeight: "24px",
    },
    rowItemContainer: {
        display: "flex",
        alignItems: "center",
        ...shorthands.gap("0.5rem"),
        cursor: "pointer",
        ...shorthands.textDecoration("none"),
    },
    flightRowText: {
        fontWeight: "600"
    },
});