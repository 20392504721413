import refWorkFlow_icon from "../../../assets/icons/discover-apps/refWorkflow.webp";
export const allWorkFlowData = [
    {
        id: 1,
        icon: refWorkFlow_icon,
        title: 'Workflow name workflow name',
        info: "Detailed overview of the quantities and qualities of materials stored at a particular site.Created by DeepMatrix FLY",
        tagNames: ['Integration Name', 'Report Type', 'Industry', 'Orthomosaic', 'DEM', 'Custom Report', 'Domain', 'Data Visualization']
    },
    {
        id: 2,
        icon: refWorkFlow_icon,
        title: 'Workflow name workflow name',
        info: "Detailed overview of the quantities and qualities of materials stored at a particular site.Created by DeepMatrix FLY",
        tagNames: ['Integration Name', 'Report Type', 'Industry', 'Orthomosaic', 'DEM', 'Custom Report', 'Domain', 'Data Visualization']
    }, {
        id: 3,
        icon: refWorkFlow_icon,
        title: 'Workflow name workflow name',
        info: "Detailed overview of the quantities and qualities of materials stored at a particular site.Created by DeepMatrix FLY",
        tagNames: ['Integration Name', 'Report Type', 'Industry', 'Orthomosaic', 'DEM', 'Custom Report', 'Domain', 'Data Visualization']
    }, {
        id: 4,
        icon: refWorkFlow_icon,
        title: 'Workflow name workflow name',
        info: "Detailed overview of the quantities and qualities of materials stored at a particular site.Created by DeepMatrix FLY",
        tagNames: ['Integration Name', 'Report Type', 'Industry', 'Orthomosaic', 'DEM', 'Custom Report', 'Domain', 'Data Visualization']
    }, {
        id: 5,
        icon: refWorkFlow_icon,
        title: 'Workflow name workflow name',
        info: "Detailed overview of the quantities and qualities of materials stored at a particular site.Created by DeepMatrix FLY",
        tagNames: ['Integration Name', 'Report Type', 'Industry', 'Orthomosaic', 'DEM', 'Custom Report', 'Domain', 'Data Visualization']
    }, {
        id: 6,
        icon: refWorkFlow_icon,
        title: 'Workflow name workflow name',
        info: "Detailed overview of the quantities and qualities of materials stored at a particular site.Created by DeepMatrix FLY",
        tagNames: ['Integration Name', 'Report Type', 'Industry', 'Orthomosaic', 'DEM', 'Custom Report', 'Domain', 'Data Visualization']
    }, {
        id: 7,
        icon: refWorkFlow_icon,
        title: 'Workflow name workflow name',
        info: "Detailed overview of the quantities and qualities of materials stored at a particular site.Created by DeepMatrix FLY",
        tagNames: ['Integration Name', 'Report Type', 'Industry', 'Orthomosaic', 'DEM', 'Custom Report', 'Domain', 'Data Visualization']
    }, {
        id: 8,
        icon: refWorkFlow_icon,
        title: 'Workflow name workflow name',
        info: "Detailed overview of the quantities and qualities of materials stored at a particular site.Created by DeepMatrix FLY",
        tagNames: ['Integration Name', 'Report Type', 'Industry', 'Orthomosaic', 'DEM', 'Custom Report', 'Domain', 'Data Visualization']
    }, {
        id: 9,
        icon: refWorkFlow_icon,
        title: 'Workflow name workflow name',
        info: "Detailed overview of the quantities and qualities of materials stored at a particular site.Created by DeepMatrix FLY",
        tagNames: ['Integration Name', 'Report Type', 'Industry', 'Orthomosaic', 'DEM', 'Custom Report', 'Domain', 'Data Visualization']
    }, {
        id: 10,
        icon: refWorkFlow_icon,
        title: 'Workflow name workflow name',
        info: "Detailed overview of the quantities and qualities of materials stored at a particular site.Created by DeepMatrix FLY",
        tagNames: ['Integration Name', 'Report Type', 'Industry', 'Orthomosaic', 'DEM', 'Custom Report', 'Domain', 'Data Visualization']
    }, {
        id: 11,
        icon: refWorkFlow_icon,
        title: 'Workflow name workflow name',
        info: "Detailed overview of the quantities and qualities of materials stored at a particular site.Created by DeepMatrix FLY",
        tagNames: ['Integration Name', 'Report Type', 'Industry', 'Orthomosaic', 'DEM', 'Custom Report', 'Domain', 'Data Visualization']
    }, {
        id: 12,
        icon: refWorkFlow_icon,
        title: 'Workflow name workflow name',
        info: "Detailed overview of the quantities and qualities of materials stored at a particular site.Created by DeepMatrix FLY",
        tagNames: ['Integration Name', 'Report Type', 'Industry', 'Orthomosaic', 'DEM', 'Custom Report', 'Domain', 'Data Visualization']
    }, {
        id: 13,
        icon: refWorkFlow_icon,
        title: 'Workflow name workflow name',
        info: "Detailed overview of the quantities and qualities of materials stored at a particular site.Created by DeepMatrix FLY",
        tagNames: ['Integration Name', 'Report Type', 'Industry', 'Orthomosaic', 'DEM', 'Custom Report', 'Domain', 'Data Visualization']
    }, {
        id: 14,
        icon: refWorkFlow_icon,
        title: 'Workflow name workflow name',
        info: "Detailed overview of the quantities and qualities of materials stored at a particular site.Created by DeepMatrix FLY",
        tagNames: ['Integration Name', 'Report Type', 'Industry', 'Orthomosaic', 'DEM', 'Custom Report', 'Domain', 'Data Visualization']
    }, {
        id: 15,
        icon: refWorkFlow_icon,
        title: 'Workflow name workflow name',
        info: "Detailed overview of the quantities and qualities of materials stored at a particular site.Created by DeepMatrix FLY",
        tagNames: ['Integration Name', 'Report Type', 'Industry', 'Orthomosaic', 'DEM', 'Custom Report', 'Domain', 'Data Visualization']
    },
]