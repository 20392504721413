import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, Input, makeStyles, shorthands, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, Textarea, Toast, Toaster, ToastTitle, useId, useToastController } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import ToastErrorIcon from "../assets/icons/toast_error.svg";
import ToastSuccessIcon from "../assets/icons/toast_success.svg";
import { FeatureDemosResponse, FeatureDemosService } from "../services/openapi";
import { PrimaryButton2 } from "./common/PrimaryButton2";

const defaultFormValues = {
    name: "",
    description: ""
}
export function AddFeatureDemo() {
    const classes = useStyles();
    const [features, setFeatures] = useState<FeatureDemosResponse | null>(null);
    const [error, setError] = useState(null);
    const [isFormOpen, setIsFormOpen] = useState(false)
    const [formValues, setFormValues] = useState(defaultFormValues);
    const successToastID = useId("success");
    const failureToastID = useId("failed");
    const { dispatchToast: dispatchSuccessToast } = useToastController(successToastID);
    const { dispatchToast: dispatchFailedToast } = useToastController(failureToastID)

    const successToast = () => dispatchSuccessToast(
        <Toast
            style={{ background: "rgba(223, 246, 221, 1)", width: "100%" }}>
            <ToastTitle style={{ fontSize: "14px", fontWeight: 400 }} media={<img src={ToastSuccessIcon} alt="success icon" style={{ marginRight: "0.25rem" }} />}>Feature added successfully</ToastTitle>
        </Toast>,
        { intent: "success" }
    );

    const failureToast = () => dispatchFailedToast(
        <Toast
            style={{ background: "rgba(253, 231, 233, 1)", width: "100%" }}>
            <ToastTitle style={{ fontSize: "14px", fontWeight: 400 }} media={<img src={ToastErrorIcon} alt="error icon" style={{ marginRight: "0.25rem" }} />}>Something went wrong</ToastTitle>
        </Toast>,
        { intent: "error" }
    );

    const getFeatures = () => {
        FeatureDemosService.getAllFeatureDemos().then(data => {
            setFeatures(data)
        }).catch(err => setError(err))
    }

    const postFeature = async () => {
        await FeatureDemosService.createFeatureDemo(formValues)
            .then(() => { successToast(); })
            .catch((err) => { console.error("Error adding feature", err); failureToast(); })
        setIsFormOpen(false);
    }
    useEffect(() => {
        getFeatures();
    }, [isFormOpen]);

    const columns = [
        { columnKey: "Name", label: "Name" },
        { columnKey: "Description", label: "Description" },
    ];

    return (
        <>
            <div className={classes.container}>
                <Table arial-label="Features table" className={classes.table} noNativeElements>
                    <div className={classes.headerContainer}>
                        <div className={classes.header}>Feature Demos</div>
                        <PrimaryButton2 label="Add a Feature" onClick={() => { setIsFormOpen(true) }} />
                    </div>
                    <TableHeader>
                        <TableRow>
                            {columns.map((column) => (
                                <TableHeaderCell key={column.columnKey} className={classes.column}>
                                    {column.label}
                                </TableHeaderCell>
                            ))}
                        </TableRow>
                    </TableHeader>

                    {features ? <TableBody>
                        {features.featureDemos.map((f) => (
                            <TableRow key={f.id} className={classes.row}>
                                <TableCell>
                                    {f.name}
                                </TableCell>
                                <TableCell>
                                    {f.description}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody> : <p className={classes.noData}>No data to show</p>}
                </Table>
            </div>

            <Dialog open={isFormOpen}>
                <DialogSurface className={classes["dialog-surface"]} style={{ width: "900px" }}>
                    <DialogBody className={classes.body}>
                        <DialogTitle action={null} className={classes.title}>Add a feature</DialogTitle>
                        <DialogContent>
                            <div style={{ marginTop: '2em' }}>
                                <div className={classes.inputName}>
                                    Name
                                </div>
                                <div>
                                    <Input className={classes.customInput}
                                        style={{ width: '100%' }}
                                        value={formValues.name}
                                        onChange={(e) => {
                                            setFormValues({ ...formValues, name: e.target.value });
                                        }}
                                    />
                                </div>
                            </div>

                            <div style={{ marginTop: '2em' }}>
                                <div className={classes.inputName}>
                                    Description
                                </div>
                                <div>
                                    <Textarea className={classes.customInput}
                                        style={{ width: '100%' }}
                                        value={formValues.description}
                                        onChange={(e) => {
                                            setFormValues({ ...formValues, description: e.target.value });
                                        }}
                                    />
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions style={{ marginTop: "12px" }}>
                            <Button appearance="secondary" onClick={() => { setFormValues(defaultFormValues); setIsFormOpen(false); }}>Cancel</Button>
                            <PrimaryButton2 label="Submit" disabled={!formValues.name || !formValues.description} onClick={postFeature} />
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog >

            <Toaster toasterId={successToastID} position="bottom" limit={1} />
            <Toaster toasterId={failureToastID} position="bottom" limit={1} />
        </>
    );
}

const useStyles = makeStyles({
    container: {
        ...shorthands.padding("24px")
    },
    header: {
        color: "#043957",
        fontSize: "28px",
        fontWeight: 600,
        lineHeight: "36px",
    },
    headerContainer: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    table: {
        ...shorthands.borderRadius("1rem"),
        ...shorthands.border("1px", "solid", "#E3E9F2"),
        boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.07)",
        paddingBottom: "18px",
        paddingRight: "32px",
        paddingTop: "32px",
        paddingLeft: "32px",
    },
    column: {
        color: "#043957",
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "36px",
        paddingBottom: "18px",
        paddingTop: "32px",
    },
    row: {
        color: "#586A84",
        fontSize: "18px",
        lineHeight: "24px",
        paddingBottom: "12px",
        paddingTop: "12px",
    },
    noData: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#586A84",
        fontSize: "18px",
        paddingBottom: "12px",
        paddingTop: "24px",
    },
    "dialog-surface": {
        padding: "0px",
    },
    "body": {
        paddingLeft: "40px",
        paddingRight: "40px",
        paddingTop: "32px",
        paddingBottom: "32px",
    },
    "title": {
        color: "#3E3E3E",
        fontSize: "28px",
        fontWeight: 600,
        lineHeight: "36px",
    },
    inputName: {
        marginTop: "1em",
        marginBottom: "1em",
        fontWeight: "500",
        fontSize: "1.1rem"
    },
    customInput: {
        padding: ".6em",
        background: "#ECF3FF",
        borderRadius: "4px",
    },
})