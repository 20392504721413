import React from 'react'
import MobileHeader from '../../common/MobileHeader';
import { Button, makeStyles, shorthands } from '@fluentui/react-components';
import { whatsNewDataNotes } from './whatsNewNotes';
import WhatsNewCard from './WhatsNewCard';
import BenefitsSection from './BenefitsSection';
import { API_BASE } from '../../../../constants';

function MobileWhatsNew() {
    const classes = useStyles();

    const handleSignIn = () => {
        window.location.href = `${API_BASE}/api/sso/login?client_id=FLYGHTCLOUD`
    }

    return (
        <div>
            <div className={classes.container} style={{
                background: "linear-gradient(283deg, #1482DA 0%, #91C84F 100%) no-repeat padding-box",
                backgroundColor: "transparent",
            }}>
                <MobileHeader />
                <div className={classes['mob-container']} style={{ marginBottom: 0 }}>
                    <div style={{ color: "white" }}>
                        <h3 className={classes.initialHeading}>What's New?</h3>
                        <h3 className={classes.barndingInfo} style={{ margin: "1em 0", }}>We are excited to announce a newer update to FlyghtCloud. With the new Measurement Tools, DeepMatrix integration, custom workflows, and LiDAR data conversion, managing your projects has never been easier. These features provide improved precision, and flexibility to streamline your operations.</h3>
                    </div>
                </div>
            </div>
            <div style={{ background: "#F7F7F7", padding: ".2em 0" }}>
                <div className={classes['mob-container']}>
                    {
                        whatsNewDataNotes.map((item, index) => (
                            <div key={index}>
                                <WhatsNewCard
                                    icon={item.icon} title={item.title} info={item.info}
                                />
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className={classes['secondary-container']} style={{ padding: ".3em 0" }}>
                <BenefitsSection />
            </div>
            <div style={{ background: "#1482DA", color: "white", padding: ".2em 0" }}>
                <div className={classes['secondary-container']}>
                    <h2 className="wnfooterHeading">Sign up for a free trial today</h2>
                    <p className="wnfooterInfo">Ready to experience these improvements</p>
                    <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
                        <Button
                            style={{ padding: "10px 20px", lineHeight: "1.5rem", fontSize: "1.3rem", fontWeight: "bold", marginTop: "1em", border: "1px solid #1482DA", color: "#1482DA" }} shape="circular"
                            onClick={handleSignIn}
                        >
                            Sign Up Now
                        </Button>
                    </div>
                </div>
            </div>
            <div className={classes.commonRow} style={{ fontSize: ".7rem", textAlign: "center" }}>
                <p style={{ marginTop: "1em", color: "#586A84" }}>Copyright © 2024 ideaForge Technology Ltd. All rights reserved.</p>
            </div>
        </div>
    )
}

export default MobileWhatsNew;

const useStyles = makeStyles({
    "container": {
        minHeight: "40vh",
        maxHeight: "100vh",
        minWidth: "100vw",
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
    },
    "mob-container": {
        width: "93%",
        margin: "2em auto 1em auto"
    },
    barndingLabel: {
        fontSize: "3rem",
        lineHeight: "3rem",
        fontWeight: "700"
    },
    barndingInfo: {
        fontSize: ".85rem",
        lineHeight: "1.1rem",
        color: "#ffff",
        marginTop: ".5em"
    },
    initialHeading: {
        fontSize: "1.8rem",
        fontWeight: "500",
        lineHeight: "2rem",
    },
    gradBtn: {
        ...shorthands.padding("10px"),
        marginTop: "1em",
        marginBottom: "1em",
        fontWeight: "1rem"
    },
    "secondary-container": {
        width: "93%",
        margin: "1em auto"
    },
    ssinitialHeading: {
        fontSize: "1.3rem",
        fontWeight: "500",
        lineHeight: "1.5rem",
    },
    grid: {
        display: "grid",
        gridTemplateColumns: 'repeat(2, 3fr)',
        marginTop: "1em",
        marginBottom: "2em",
        gridGap: '1em',
    },
    ssbarndingInfo: {
        fontSize: ".85rem",
        lineHeight: "1.1rem",
        color: "#08201D",
        margin: ".5em 0"
    },
    commonRow: {
        marginTop: "2em",
        marginBottom: "2em",
    },

})