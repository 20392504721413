
import indeustry_icon from "../../../assets/icons/landing/industry.svg";
import effortLess_icon from "../../../assets/icons/landing/effortlessItegration.svg";
import customisable_icon from "../../../assets/icons/landing/customise.svg";
import realTimeData_icon from "../../../assets/icons/landing/realtimeData.svg";
import automated_icon from "../../../assets/icons/landing/automated_report.svg";
import aiPowered_icon from "../../../assets/icons/landing/aiPowered.svg";
import secure_icon from "../../../assets/icons/landing/secureData.svg";
import expensive_icon from "../../../assets/icons/landing/expensive.svg";
import scalableArc_icon from "../../../assets/icons/landing/scalableArc.svg";

export const FeaturesData = [
    {
        id: 1,
        title: 'Industry-centric workflows',
        icon: indeustry_icon,
    },
    {
        id: 2,
        title: 'Effortless integration',
        icon: effortLess_icon,
    },
    {
        id: 3,
        title: 'Customizable  solutions',
        icon: customisable_icon,
    },
    {
        id: 4,
        title: 'Real-time data processing',
        icon: realTimeData_icon,
    },
    {
        id: 5,
        title: 'Automated reporting',
        icon: automated_icon,
    },
    {
        id: 6,
        title: 'AI-Powered Insights',
        icon: aiPowered_icon,
    }, {
        id: 7,
        title: 'Secure Data Management',
        icon: secure_icon,
    },
    {
        id: 8,
        title: 'Extensive App Marketplace',
        icon: expensive_icon,
    },
    {
        id: 9,
        title: 'Scalable Architecture',
        icon: scalableArc_icon,
    },

]