import { makeStyles, shorthands } from "@fluentui/react-components";

interface Props {
    text: string
    icon: string
}

export const PageHeader = ({ text, icon }: Props) => {
    const styles = useStyles()

    return <div className={styles.container}>
        <img src={icon} alt={text} />
        <p>{text}</p>
    </div>
}

const useStyles = makeStyles({
    container: {
        height: "3rem",
        fontSize: "1rem",
        display: "flex",
        alignItems: "center",
        paddingLeft: "1rem",
        ...shorthands.gap("0.5rem"),
        ...shorthands.borderBottom("1px", "solid", '#E3E9F2'),
        flexShrink: 0,
    }
})
