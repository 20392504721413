import * as React from 'react'
import flyghtcloud_logo from "../../../../assets/icons/landing/logo_green.svg"
import HeaderNavBar from '../../common/HeaderNavBar'
import { Button, makeStyles, shorthands } from '@fluentui/react-components'
import about_bg from "../../../../assets/icons/landing/solutionBG.webp"
import { AnalyticsData } from '../../common/DataAnalytics'
import AnalyticsCard from './AnalyticsCard'
import { FeaturesData } from '../../common/FeaturesData'
import FeaturesCard from './FeaturesCard'
import Footer from '../../common/Footer'
import BookDemoModal from '../../common/BookDemoModal'
import useIsMobile from '../../../hooks/useIsMobile'
import MobileSolutions from './MobileSolutions'
import { useNavigate } from 'react-router-dom'
interface analyticsDataProps {
    id: number,
    title: string,
    icon: string
}

interface featuresDataProps {
    id: number,
    title: string,
    icon: string
}
function Solutions() {
    const classes = useStyles();
    const isMobile = useIsMobile();
    const navigate = useNavigate();
    const [analyticsDatas, setAnalyticsDatasProps] = React.useState<analyticsDataProps[] | null>(null);
    const [featuresDatas, setFeaturesDatasProps] = React.useState<featuresDataProps[] | null>(null);
    const [bookDemoModalVisible, setBookDemoModalVisible] = React.useState(false);
    const topRef = React.useRef<null | HTMLDivElement>(null);

    const scrollToTop = () => {
        topRef.current?.scrollIntoView({ behavior: "auto", block: "start" });
    };

    React.useEffect(() => {
        scrollToTop();
    }, []);



    React.useEffect(() => {
        setAnalyticsDatasProps(AnalyticsData);
        setFeaturesDatasProps(FeaturesData)
    }, []);

    const toggleBookDemoModal = (flag: boolean) => {
        setBookDemoModalVisible(flag)
    }

    const handleMiningClick = () => {
        navigate("/solutions/mining")
    };

    return isMobile ? <MobileSolutions /> : <div ref={topRef} >
        <div className={classes.headerNavBar}>
            <HeaderNavBar icon={flyghtcloud_logo} signInBorder={true} color='black' btnBg="#E5E8EB" />
        </div>
        <div>
            <div style={{ maxWidth: "90%", margin: "2em auto" }}>
                <div className={classes.bgContainer} style={{ backgroundPosition: "center" }}>
                    <div className={classes.bgTextContainer}>
                        <h1 className={classes.bgHeader}>Explore Tailored Solutions for Your Industry</h1>
                        <h3 className={classes.bgSubHeader}>Revolutionize data analytics with innovative workflows designed for your industry. Our industry-centric solutions are easy to integrate, customizable, and deliver insights that drive business growth.</h3>
                        <Button style={{ border: "none", padding: "8px" }} className={classes.bgBtns} onClick={() => { toggleBookDemoModal(true) }}>Connect to Expert</Button>
                    </div>
                </div>
                <div className={classes.commonConatainer}>
                    <h2 className={classes.primaryHeading}>How our tailored industry solutions transform data analytics</h2>
                    {analyticsDatas && (
                        <div className={classes.grid}>
                            {analyticsDatas.map((data, index) => (
                                <div key={index} >
                                    <AnalyticsCard key={index} analyticsData={data}
                                        onMiningClick={handleMiningClick} />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className={classes.commonConatainer}>
                    <h2 className={classes.primaryHeading}>Features</h2>
                    {featuresDatas && (
                        <div className={classes.fegrid}>
                            {featuresDatas.map((data, index) => (
                                <div key={index} >
                                    <FeaturesCard key={index} featuresData={data} />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className={classes.footerConatainer} style={{ margin: "0 auto" }}>
                    <h2 className={classes.footerPrimaryHeading}>Ready to see the future of data analytics?</h2>
                    <p className={classes.footerInfo}>Our industry-centric solutions are designed to meet the unique needs of your business. Explore our workflows to learn more.</p>
                    <div className={classes.footerBtns}>
                        <Button className={classes.footerBtn}
                            onClick={() => { toggleBookDemoModal(true) }}
                            style={{
                                background: "#617AFA", padding: "10px", width: "20%", borderRadius: "4px", color: "#ffff", border: "none"
                            }}>Connect to Expert</Button>

                    </div>
                    <div style={{ color: "#586A84" }}>
                        <Footer />
                    </div>
                </div>
            </div>
            <BookDemoModal visible={bookDemoModalVisible} toggleModal={toggleBookDemoModal} />
        </div>
    </div>
}
const useStyles = makeStyles({
    headerNavBar: {
        position: "sticky",
        top: 0,
        width: "100%",
        backgroundColor: "white",
        zIndex: 9999
    },
    bgContainer: {
        backgroundImage: `url(${about_bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        ...shorthands.borderRadius("8px"),
        position: "relative",
        height: 'calc(55vh - 4rem)',
        color: "#ffff",
        display: "flex",
        alignItems: "center"
    },
    bgTextContainer: {
        marginLeft: "4em",
        marginTop: "4em"
    },
    bgHeader: {
        fontSize: "2.4rem",
        maxWidth: "100%",
        fontWeight: 600,
    },
    commonConatainer: {
        marginTop: "4em"
    },
    bgSubHeader: {
        fontSize: "1rem",
        maxWidth: "60%",
        fontWeight: 300,
        marginTop: "2rem",
        marginBottom: "2rem"
    },
    bgBtns: {
        color: "#ffff",
        backgroundColor: "#617AFA",
        ':hover': {
            color: "#617AFA",
            backgroundColor: "#EDF0F2 !important"
        },
    },
    primaryHeading: {
        maxWidth: "70%",
        lineHeight: '2rem',
        marginTop: "1.5rem",
        marginBottom: "1rem",
        fontSize: "1.5rem",
        fontWeight: 600,
    },
    footerPrimaryHeading: {
        fontSize: "2rem",
        maxWidth: "100%",
        fontWeight: 600,
        marginTop: "1.5rem",
        marginBottom: "1rem"
    },
    grid: {
        display: "grid",
        gridTemplateColumns: 'repeat(5, 3fr)',
        marginTop: "1em",
        marginBottom: "2em",
        gridGap: '1em',
    },
    fegrid: {
        display: "grid",
        gridTemplateColumns: 'repeat(9, 3fr)',
        marginTop: "1em",
        marginBottom: "2em",
        gridGap: '1em',
    },
    footerConatainer: {
        display: "flex",
        justifyContent: "center",
        marginTop: "3em !important",
        alignItems: "center",
        flexDirection: "column",
        maxWidth: "90%",
        width: "62%",
        textAlign: "center",
        paddingBottom: "3em"
    },
    footerInfo: {
        fontSize: "1rem",
        fontWeight: 400,
        maxWidth: "70%"
    },
    footerBtns: {
        marginTop: "3em",
        marginBottom: "3em",
        width: "100%"
    },
    footerBtn: {
        ...shorthands.borderRadius("0"),
    },
})
export default Solutions