import * as React from 'react'
import HomeBG from "../../../../assets/icons/landing/homeBGMobile.webp";
import { makeStyles, shorthands } from '@fluentui/react-components';
import BrandName from '../../../common/BrandName';
import PersonWorkingIcon from "../../../../assets/icons/landing/person_working.svg";
import MobileHeader from '../../common/MobileHeader';


function MobileDesclimer() {
    const classes = useStyles();
    return (
        <div>
            <div className={classes.container}>
                <MobileHeader />
                <div className={classes["content-container"]}>
                    <div className={classes["content-inner-container"]}>
                        <img src={PersonWorkingIcon} alt='PersonWorkingIcon' className={classes["content-image"]} />
                        <p><BrandName /> is currently optimised for web access on desktops and laptops.
                            Mobile and tablet versions are not yet supported but will be launching soon!</p>
                    </div>
                </div>
            </div>
        </div >

    )
}

export default MobileDesclimer;

const useStyles = makeStyles({
    "container": {
        minHeight: "100vh",
        minWidth: "100vw",
        backgroundImage: `url(${HomeBG})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPositionX: "40%",
        backgroundPositionY: "40%",
        position: "relative",
    },
    "navbar-parent-container": {
        width: "100%",
        height: "40px",
        ...shorthands.padding("8px", "16px"),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.50)"
    },
    "navbar-logo-container": {
        display: "flex",
        ...shorthands.gap("4px"),
        alignItems: "center"
    },
    "navbar-logo-text": {
        color: "#FFF",
        fontSize: "16px",
        "> span": {
            fontWeight: 700
        }
    },
    "content-container": {
        height: "90vh",
        width: "100%",
        display: "flex",
        alignItems: 'center',
        justifyContent: "center",
        paddingLeft: "32px",
        paddingRight: "32px",
    },
    "content-image": {
        width: "64px",
        height: "64px",
    },
    "content-inner-container": {
        ...shorthands.borderRadius("16px"),
        backgroundColor: "rgba(0, 0, 0, 0.50)",
        lineHeight: "normal",
        color: "#FFF",
        leadingTrim: "both",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 600,
        ...shorthands.padding("74px", "24px"),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        ...shorthands.gap("24px"),
    }
})