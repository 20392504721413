import { makeStyles } from '@fluentui/react-components'
import { useNavigate } from 'react-router-dom';

function Footer() {
    const classes = useStyles()
    const navigate = useNavigate();


    return (
        <div className={classes.footerContainer}>
            <div style={{ display: "flex" }}>
                <p className={classes.eula} onClick={() => { navigate(`/privacy-policy`) }}>Privacy Policy</p>
                <p className={classes.eula} onClick={() => { navigate(`/eula`) }} style={{ marginLeft: "5em", marginRight: "5em" }}>End User License Agreement</p>
                <p className={classes.eula} onClick={() => { window.open('https://ideaforgetech.com/ ', '_blank') }}>ideaforgetech.com</p>
            </div>
            <p style={{ marginTop: "1em" }}>Copyright © 2024 ideaForge Technology Ltd. All rights reserved.</p>
        </div>)
}
const useStyles = makeStyles({
    footerContainer: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        color: "#586A84"
    },
    eula: {
        cursor: "pointer"
    }

})
export default Footer