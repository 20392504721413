/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateFlightFileRequest } from '../models/CreateFlightFileRequest';
import type { FlightFileResponse } from '../models/FlightFileResponse';
import type { FlightFilesResponse } from '../models/FlightFilesResponse';
import type { FlightRequest } from '../models/FlightRequest';
import type { FlightResponse } from '../models/FlightResponse';
import type { FlightsResponse } from '../models/FlightsResponse';
import type { FlightSummaryResponse } from '../models/FlightSummaryResponse';
import type { ImageResponse } from '../models/ImageResponse';
import type { Multipart } from '../models/Multipart';
import type { PreSignedUrlType } from '../models/PreSignedUrlType';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FlightsService {

    /**
     * @param flightId
     * @returns any Archive flight: delete images, outputs etc. Everything except maps will be deleted
     * @throws ApiError
     */
    public static archiveFlight(
        flightId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/flights/{flight_id}/archive',
            path: {
                'flight_id': flightId,
            },
        });
    }

    /**
     * @param flightId
     * @returns FlightFilesResponse Get Flight Files
     * @throws ApiError
     */
    public static getFlightFiles(
        flightId: string,
    ): CancelablePromise<FlightFilesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/flights/{flight_id}/files',
            path: {
                'flight_id': flightId,
            },
        });
    }

    /**
     * @param flightId
     * @param requestBody
     * @returns FlightFileResponse Get Flight Files
     * @throws ApiError
     */
    public static createFlightFiles(
        flightId: string,
        requestBody: CreateFlightFileRequest,
    ): CancelablePromise<FlightFileResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/flights/{flight_id}/files',
            path: {
                'flight_id': flightId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param urlType
     * @param flightId
     * @param fileId
     * @returns string Get Flight Files
     * @throws ApiError
     */
    public static getFlightFilePresignedUrl(
        urlType: PreSignedUrlType,
        flightId: string,
        fileId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/flights/{flight_id}/files/{file_id}/presigned_url',
            path: {
                'flight_id': flightId,
                'file_id': fileId,
            },
            query: {
                'url_type': urlType,
            },
        });
    }

    /**
     * @param flightId
     * @param fileId
     * @returns any Sync Flight File
     * @throws ApiError
     */
    public static syncFlightFile(
        flightId: string,
        fileId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/flights/{flight_id}/files/{file_id}/sync',
            path: {
                'flight_id': flightId,
                'file_id': fileId,
            },
        });
    }

    /**
     * @param flightId
     * @returns ImageResponse Uoload image
     * @throws ApiError
     */
    public static getImages(
        flightId: string,
    ): CancelablePromise<Array<ImageResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/flights/{flight_id}/images',
            path: {
                'flight_id': flightId,
            },
        });
    }

    /**
     * @param flightId
     * @param formData
     * @returns ImageResponse Upload image
     * @throws ApiError
     */
    public static uploadImage(
        flightId: string,
        formData: Multipart,
    ): CancelablePromise<ImageResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/flights/{flight_id}/images',
            path: {
                'flight_id': flightId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                409: `Image already uploaded (safe to ignore)`,
            },
        });
    }

    /**
     * @param flightId
     * @returns FlightSummaryResponse Flight summary
     * @throws ApiError
     */
    public static getFlightSummary(
        flightId: string,
    ): CancelablePromise<FlightSummaryResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/flights/{flight_id}/summary',
            path: {
                'flight_id': flightId,
            },
        });
    }

    /**
     * @param projectId
     * @returns FlightsResponse Get Flights
     * @throws ApiError
     */
    public static getFlights(
        projectId: string,
    ): CancelablePromise<FlightsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/projects/{project_id}/flights',
            path: {
                'project_id': projectId,
            },
        });
    }

    /**
     * @param projectId
     * @param requestBody
     * @returns FlightResponse Create Flight
     * @throws ApiError
     */
    public static createFlight(
        projectId: string,
        requestBody: FlightRequest,
    ): CancelablePromise<FlightResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/projects/{project_id}/flights',
            path: {
                'project_id': projectId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
