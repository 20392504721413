import * as React from "react";
import { Tab, TabList, makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { Link } from 'react-router-dom'
import discoverApp_icon from "../../assets/icons/nav_icons/discover-apps.svg";
import { allWorkFlowData } from "./common/AllWorkFlowData";
import AllWorkFlowCard from "./AllWorkFlowCard";

type AllWorkFlowDataType = {
    id: number,
    icon: string,
    title: string,
    info: string
    tagNames: string[]
}[]

const allTabs = ["All Workflows", "Mineral Extraction", "Energy Grid", "Defence", "Forestry", "Construction", "Agriculture", "Land Surveying", "Oil and Gas"]
const AllWorkFlows = () => {
    const classes = useStyles();
    const [allsworkFlowsDatas, setAllsworkFlowsDatas] = React.useState<AllWorkFlowDataType | null>(null)

    React.useEffect(() => {
        setAllsworkFlowsDatas(allWorkFlowData)
    }, [])

    return <>
        <div className={classes.projectHeaderText}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <Link style={{ textDecoration: "none", fontSize: '1rem', color: "black", fontWeight: "normal" }} className={classes.flexContent} to="/discover-apps"><img alt="projectIcon" style={{ paddingRight: ".5em", }} src={discoverApp_icon} /> Discover Apps</Link></div> <p style={{ margin: "0 .2em " }}>{'>'}</p> <div style={{ fontSize: '1rem' }}>All Workflows</div>
        </div>
        <div className={classes.bgContainer}
            style={{
                background: tokens.colorNeutralBackground1,
                overflow: "auto",

            }}
        >
            <div className={classes.subHeader}>
                <div style={{ display: "flex", paddingLeft: ".5em", alignItems: "center" }}>
                    All Workflows
                </div>
                <div className={classes.tabRoot}>
                    <TabList defaultSelectedValue="All Workflows">
                        {
                            allTabs.map((ele, index) => <Tab key={index} value={ele}>{ele}</Tab>
                            )
                        }
                    </TabList>
                </div>
                <div style={{ height: "calc(76vh - 4.2rem)", overflowY: "auto", scrollbarWidth: "thin" }}>
                    {allsworkFlowsDatas && (
                        <div className={classes.wrapper}>
                            {allsworkFlowsDatas.map((data, index) => (
                                <div key={index} >
                                    <AllWorkFlowCard key={index} allWorkFlowCardData={data} />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>

    </>
}
const useStyles = makeStyles({
    projectHeaderText: {
        height: "3rem",
        paddingLeft: "1.25rem",
        display: "flex",
        alignItems: "center",
        color: "#2B2B2B",
        ...shorthands.borderBottom("1px", "solid", "#E3E9F2"),
        "> span": {
            fontWeight: "700",
        },
    },
    flexContent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    bgContainer: {
        width: "98%",
        ...shorthands.borderRadius("1em"),
        ...shorthands.padding("1em"),
        ...shorthands.margin("1em"),
        height: "calc(92vh - 4.2rem)",
        scrollbarWidth: "thin",
        boxShadow: "5px 1px 20px 0px rgba(0, 0, 0, 0.05)",
    },
    subHeader: {
        fontWeight: "600",
        fontSize: "1.5em",
    },
    tabRoot: {
        alignItems: "flex-start",
        display: "flex",
        marginTop: "1em",
        flexDirection: "column",
        justifyContent: "flex-start",
        rowGap: "20px",
    },
    wrapper: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(320px, 1fr))",
        marginTop: "1em",
        gridGap: '1em',
    }
})

export default AllWorkFlows