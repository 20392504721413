import export_icon from "../../../assets/icons/discover-apps/expertDesign_icon.svg";
import plugPlay_icon from "../../../assets/icons/discover-apps/plug_play.svg"
import aiDriven_icon from "../../../assets/icons/discover-apps/aiDriven.svg"
import customisable_icon from "../../../assets/icons/discover-apps/customizable.svg"
import seamless_icon from "../../../assets/icons/discover-apps/seamless.svg"


export const workflowsData = [
    {
        id: 1,
        icon: export_icon,
        title: 'Expert-Designed',
        info: "Curated by industry specialists for optimal performance."
    },{
        id: 2,
        icon: plugPlay_icon,
        title: 'Plug-and-Play',
        info: "Streamlined solutions for hassle-free integration."
    },
    {
        id: 3,
        icon: aiDriven_icon,
        title: 'AI-Driven Insights',
        info: "Leverage advanced analytics for actionable data."
    },
    {
        id: 4,
        icon: customisable_icon,
        title: 'Customizable',
        info: "Tailor workflows to suit your unique requirements."
    }, {
        id: 5,
        icon: seamless_icon,
        title: 'Seamless Integration',
        info: "Effortlessly incorporate workflows into your existing processes."
    },
]
