import explore_icon from "../../../../../assets/icons/landing/explore.svg";
import deploy_icon from "../../../../../assets/icons/landing/dep1.png";
import optimize_icon from "../../../../../assets/icons/landing/optimize.svg";
import iterate_icon from "../../../../../assets/icons/landing/iterat1.svg";

export const HowWorksData = [
    {
        id: 1,
        title: 'Explore',
        icon: explore_icon,
        info: "Dive into our diverse range of workflow categories, carefully curated to address specific industry challenges and use cases."
    },
    {
        id: 2,
        title: 'Deploy',
        icon: deploy_icon,
        info: " Invest in workflow tailored to your needs, whether you're looking to monitor stockpiles, analyze vegetation health, or inspect infrastructure, Flyght Cloud has you covered."
    },
    {
        id: 3,
        title: 'Optimize',
        icon: optimize_icon,
        info: "Our user-friendly platform empowers users of all skill levels to implement the power of aerial data analytics effectively."
    },
    {
        id: 4,
        title: 'Iterate',
        icon: iterate_icon,
        info: "With Flyght Cloud, you can continuously refine your workflows, iterate on your analysis techniques, and stay ahead of the curve in your industry."
    }
]