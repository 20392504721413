import { Fill, Icon, Stroke, Style, Text } from 'ol/style';

const imageSelectedStyle = new Style({
  image: new Icon({
    crossOrigin: 'anonymous',
    src: '/assets/map_image_selected.svg',
  }),
});

const imageNormalStyle = new Style({
  image: new Icon({
    src: '/assets/map_image_normal.svg',
  }),
});

const imageSuccessStyle = new Style({
  image: new Icon({
    src: '/assets/map_image_success.svg',
  }),
});

const getFlagStyle = (flagName: string, state: 'normal' | 'selected') => {
  const svg =
    state === 'normal'
      ? '/assets/map_flag_normal.svg'
      : '/assets/map_flag_selected.svg';
  const labelBG = state === 'normal' ? 'white' : '#F1EA42';
  return new Style({
    image: new Icon({
      crossOrigin: 'anonymous',
      src: svg,
    }),
    text: new Text({
      text: flagName,
      font: 'bold 14px Segoe-UI-Variable,sans-serif',
      offsetX: 0,
      offsetY: 24,
      backgroundFill: new Fill({
        color: labelBG,
      }),
      textAlign: 'center',
      padding: [2, 2, 0, 4],
      backgroundStroke: new Stroke({
        color: 'black',
      }),
    }),
  });
};

export {
  getFlagStyle,
  imageNormalStyle,
  imageSelectedStyle,
  imageSuccessStyle,
};
