import { makeStyles, mergeClasses } from "@fluentui/react-components";

interface Props {
    line?: string | JSX.Element | JSX.Element[];
    children: string | JSX.Element | JSX.Element[];
}

const useStyles = makeStyles({
    infoItem: {
        marginBottom: '1em'
    },
    infoTitle: {
        paddingBottom: "0.5em",
        fontSize: "1.5em",
        fontWeight: 600,
        display: 'flex',
        justifyContent: 'space-between',
    },
    infoDetail: {
        color: '#8E8E8E',
    },
});

export const HeadingCard = ({ line, children }: Props) => {
    const classes = useStyles();
    return <div className={mergeClasses(classes.infoItem)}>
        <div className={mergeClasses(classes.infoTitle)}>
            {children}
        </div>
        <div className={mergeClasses(classes.infoDetail)}>{line}</div>

    </div>
}
