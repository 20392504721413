import * as React from 'react'
import { makeStyles } from '@fluentui/react-components'
import customizationIcon from "../../../../../assets/icons/landing/customization.svg";
import cbsIcon from "../../../../../assets/icons/landing/cbSolution.svg";
import miningSolutionIcon from "../../../../../assets/icons/landing/miningAutomation.svg"
import { SendRegular } from '@fluentui/react-icons';
import "./styles.css"

function KeyFeatures() {
    const classes = useStyles();

    return (<>
        <div className="kfContainer">
            <h1 className='heading'>Key Features and Benefits</h1>
            <div className='boxContainer'>
                <div className='kfAllBox'>
                    <div className="kfBox">
                        <img src={customizationIcon} alt='customizationIcon' />
                        <h2 className={classes.boxLabel}>Customization</h2>
                        <p className={classes.textHighLight}></p>
                        <div>
                            <p className={classes.boxinfo}><span className={classes.hightlight}><SendRegular /> Tailored features:</span> Unlike generic GIS software, pay for only what you use.</p>
                            <p className={classes.boxinfo}> <span className={classes.hightlight}><SendRegular /> API:</span> API for analytics from a wide range of software partners.</p>
                            <p className={classes.boxinfo}> <span className={classes.hightlight}><SendRegular /> Validation:</span> Tested end-to-end solution.</p>
                        </div>
                    </div>
                    <div className="kfBox">
                        <img src={cbsIcon} alt='cbsIcon' />
                        <h2 className={classes.boxLabel}>Cloud based solution</h2>
                        <p className={classes.textHighLight}></p>
                        <div>
                            <p className={classes.boxinfo}><SendRegular /> Low upfront investment</p>
                            <p className={classes.boxinfo}><SendRegular /> Enterprise data security</p>
                            <p className={classes.boxinfo}><SendRegular /> Scalability</p>
                            <p className={classes.boxinfo}><SendRegular /> Ease of collaboration and data dissemination</p>
                        </div>
                    </div>
                    <div className="kfBox">
                        <img src={miningSolutionIcon} alt='miningSolutionIcon' />
                        <h2 className={classes.boxLabel}>Automation</h2>
                        <p className={classes.textHighLight}></p>
                        <div>
                            <p className={classes.boxinfo}><SendRegular /> Error free image capture and data analytics</p>
                            <p className={classes.boxinfo}><SendRegular /> Quick turnaround time </p>
                            <p className={classes.boxinfo}><SendRegular /> Increase in the productivity of data analysts</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>
    )
}

export default KeyFeatures

const useStyles = makeStyles({

    boxLabel: {
        fontSize: "1.2rem",
        lineHeight: "1rem",
        marginTop: ".5em",
        color: "#0A2C3D",
        fontWeight: "600"
    },
    boxinfo: {
        margin: '.3em 0',
        fontWeight: "400",
        color: "#0A2C3D"
    },
    hightlight: {
        fontWeight: "bold"
    },
    textHighLight: {
        width: "15%",
        marginTop: "1em",
        marginBottom: ".5em",
        padding: 0,
        height: "3px",
        background: "#1482DA"

    }

})