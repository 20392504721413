
import React from 'react';
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Label, makeStyles } from '@fluentui/react-components';
import { ProjectsService } from '../../../services/openapi';

interface ModalProps {
    visible: boolean;
    toggleModal: (flag: boolean, file_id: string) => void;
    project_id: string;
    file_id: string;
    load: () => void;
    notifySucess: (msg: string) => void;
}

function DeleteFile(props: ModalProps) {
    const { visible, toggleModal, project_id, file_id, load, notifySucess } = props;
    const classes = useStyles()
    const handleDelete = (ev: React.FormEvent) => {
        ev.preventDefault();
        ProjectsService.deleteProjectFile(project_id, file_id).then(() => {
            toggleModal(false, "");
            load();
            notifySucess("File deleted successfully.")
        }).catch((err) => {
            toggleModal(false, "");
            console.table(err)
        })
    };


    return <Dialog
        open={visible}
    >
        <DialogSurface>
            <DialogBody>
                <DialogTitle style={{ color: "#586A84", marginBottom: ".3em" }}>Deleting the file?</DialogTitle>
                <DialogContent>
                    <Label htmlFor={"name-input"} style={{ marginRight: "0.75rem", color: "#586A84", fontWeight: "400", lineHeight: "24px" }}>
                        This action cannot be reversed. Make sure you have downloaded a copy of this file for future reference.
                    </Label>
                </DialogContent>
                <DialogActions>
                    <DialogTrigger disableButtonEnhancement>
                        <Button className={classes.cmbBtns} style={{ width: "50%" }} appearance="secondary" onClick={() => toggleModal(false, '')}>Cancel</Button>
                    </DialogTrigger>
                    <Button className={classes.cmbBtns} appearance="primary" onClick={handleDelete} style={{ width: "50%", background: "#FF5D5D", color: "white", fontWeight: "600" }}>
                        Delete
                    </Button>
                </DialogActions>
            </DialogBody>
        </DialogSurface>
    </Dialog >
}

export default DeleteFile;
const useStyles = makeStyles({
    cmbBtns: {
        padding: "6px 12px",
        borderRadius: "6px",
        color: "#586A84",
        fontWeight: "600"
    },
})