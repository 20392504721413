import { Dialog, DialogBody, DialogContent, DialogSurface, DialogTitle, Divider } from "@fluentui/react-components";
import { FlightResponse, FlightsService } from "../../../services/openapi";

interface ModalProps {
    visible: boolean;
    toggleModal: (flag: boolean) => void;
    selectedFlights: FlightResponse[];
}


const DeleteFlightModal = (props: ModalProps) => {
    const { visible, selectedFlights, toggleModal } = props

    function archiveFlights() {
        selectedFlights.map(flight => flight.id).forEach((flightID) => {
            FlightsService.archiveFlight(flightID)
        })
    }

    return (
        <Dialog
            open={visible}
            modalType='non-modal'
        >
            <DialogSurface style={{ width: "450px", padding: "0px" }}>
                <DialogBody style={{ padding: "24px" }}>
                    <DialogTitle action={null}>Delete Flight Data?</DialogTitle>
                    <DialogContent>
                        The data of the chosen flight will be removed from the Flyght cloud. This action will help in preserving the storage space.
                    </DialogContent>
                </DialogBody>

                <Divider />


                <div style={{ display: "flex", width: "100%", paddingTop: "24px", alignItems: "center", gap: "1rem", justifyContent: "center", padding: "24px" }}>
                    <button style={{ all: "unset", boxSizing: "border-box", padding: "8px 16px", borderRadius: "8px", border: "1px solid #5C74E6", color: "#5E5CE6", cursor: "pointer", textAlign: "center", width: "160px", maxWidth: "160px" }} onClick={() => {
                        toggleModal(false)
                    }}>Cancel</button>
                    <button style={{ all: "unset", boxSizing: "border-box", padding: "8px 16px", borderRadius: "8px", background: "#5E5CE6", color: "white", cursor: "pointer", textAlign: "center", width: "160px", maxWidth: "160px" }} onClick={() => {
                        toggleModal(false)
                        archiveFlights();
                    }}>Yes, Delete</button>
                </div>
            </DialogSurface>
        </Dialog >
    );
};

export default DeleteFlightModal;