import satSure_icon from "../../../assets/icons/partenerLogos/satsure.svg";
import huviair_icon from "../../../assets/icons/partenerLogos/contra.svg";
import deepMatrix_icon from "../../../assets/icons/partenerLogos/deepMatrix.svg";
import esri_icon from "../../../assets/icons/partenerLogos/esri.svg";

export const BookDemoData = [
    {
        id: 1,
        title: deepMatrix_icon,
    },
    {
        id: 2,
        title: satSure_icon,
    }, {
        id: 3,
        title: huviair_icon,
    },
    {
        id: 4,
        title: esri_icon,
    },
]