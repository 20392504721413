/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ControlPointResponse } from '../models/ControlPointResponse';
import type { ControlPointsResponse } from '../models/ControlPointsResponse';
import type { CreateControlPointRequest } from '../models/CreateControlPointRequest';
import type { CreateProjectionRequest } from '../models/CreateProjectionRequest';
import type { EditControlPointRequest } from '../models/EditControlPointRequest';
import type { ProjectionResponse } from '../models/ProjectionResponse';
import type { ProjectionsResponse } from '../models/ProjectionsResponse';
import type { UploadControlPointsRequest } from '../models/UploadControlPointsRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ControlPointsService {

    /**
     * @param projectionId
     * @returns ProjectionResponse Delete control point projection
     * @throws ApiError
     */
    public static deleteControlPointProjection(
        projectionId: string,
    ): CancelablePromise<ProjectionResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/control_point_projections/{projection_id}',
            path: {
                'projection_id': projectionId,
            },
        });
    }

    /**
     * @param controlPointId
     * @returns ControlPointResponse Delete control point
     * @throws ApiError
     */
    public static deleteControlPoint(
        controlPointId: string,
    ): CancelablePromise<ControlPointResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/control_points/{control_point_id}',
            path: {
                'control_point_id': controlPointId,
            },
        });
    }

    /**
     * @param controlPointId
     * @param requestBody
     * @returns ControlPointResponse Edit a control point
     * @throws ApiError
     */
    public static editControlPoint(
        controlPointId: string,
        requestBody: EditControlPointRequest,
    ): CancelablePromise<ControlPointResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/control_points/{control_point_id}',
            path: {
                'control_point_id': controlPointId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param controlPointId
     * @returns ProjectionsResponse Get image projections of a ControlPoint
     * @throws ApiError
     */
    public static getControlPointProjections(
        controlPointId: string,
    ): CancelablePromise<ProjectionsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/control_points/{control_point_id}/projections',
            path: {
                'control_point_id': controlPointId,
            },
        });
    }

    /**
     * @param controlPointId
     * @param requestBody
     * @returns ProjectionResponse Get image projections of a ControlPoint
     * @throws ApiError
     */
    public static createControlPointProjection(
        controlPointId: string,
        requestBody: CreateProjectionRequest,
    ): CancelablePromise<ProjectionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/control_points/{control_point_id}/projections',
            path: {
                'control_point_id': controlPointId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param flightId
     * @returns ControlPointsResponse Get control points for a Flight
     * @throws ApiError
     */
    public static getControlPoints(
        flightId: string,
    ): CancelablePromise<ControlPointsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/flights/{flight_id}/control_points',
            path: {
                'flight_id': flightId,
            },
        });
    }

    /**
     * @param flightId
     * @param requestBody
     * @returns ControlPointResponse Create a control point for a Flight
     * @throws ApiError
     */
    public static createControlPoint(
        flightId: string,
        requestBody: CreateControlPointRequest,
    ): CancelablePromise<ControlPointResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/flights/{flight_id}/control_points',
            path: {
                'flight_id': flightId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param flightId
     * @returns ProjectionsResponse Get all image projections of all ControlPoints in a flight
     * @throws ApiError
     */
    public static getAllProjectionsForFlight(
        flightId: string,
    ): CancelablePromise<ProjectionsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/flights/{flight_id}/projections',
            path: {
                'flight_id': flightId,
            },
        });
    }

    /**
     * @param flightId
     * @param requestBody
     * @returns ControlPointsResponse Uploada control points for a Flight
     * @throws ApiError
     */
    public static uploadControlPoints(
        flightId: string,
        requestBody: UploadControlPointsRequest,
    ): CancelablePromise<ControlPointsResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/flights/{flight_id}/upload_control_points',
            path: {
                'flight_id': flightId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
