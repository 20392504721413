import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, makeStyles, shorthands } from "@fluentui/react-components";
import DemoImage from "../../assets/icons/demo_illustration.svg";
import BrandName from "../common/BrandName";

interface Props {
    hideDemoModal: () => void;
    visible: boolean
}

export function FlyghtCloudDemoModal({ hideDemoModal, visible }: Props) {

    const classes = useModalStyles();

    return <Dialog open={visible}>
        <DialogSurface className={classes["dialog-surface"]} style={{ width: "900px" }}>
            <DialogBody className={classes.body}>
                <DialogTitle action={null} className={classes.title}>Welcome to <BrandName /> Demo Hub</DialogTitle>
                <DialogContent className={classes.content}>
                    <div>
                        <p className={classes.text}>You've been enrolled to <strong><BrandName /> Demo Hub</strong>, which is our Demo Organization to help you explore the platform and its features.</p>

                        <p className={classes.text}> Within this demo environment, you'll find sample projects in view only mode that showcase the capabilities of <BrandName />.</p>

                        <p className={classes.text2}><strong>To unlock full access and features:</strong></p>
                        <ul className={classes.text}>
                            <li className={classes.li}><p>&bull;</p> <p>Contact your company's admin to add you to the correct organization.</p></li>
                            <li className={classes.li}><p>&bull;</p>
                                <p>Solo user or freelancer? Reach out to our <BrandName /> Admin at
                                    <a className={classes.link} href='mailto:flyghtcloud@ideaforgetech.com'>flyghtcloud@ideaforgetech.com</a>
                                    to unlock features.
                                </p></li>
                        </ul>

                        <p>We hope you enjoy exploring <BrandName />!</p>
                    </div>
                    <img src={DemoImage} alt="demo" />
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <Button className={classes.primaryButton} appearance="primary" onClick={hideDemoModal}>Continue</Button>
                </DialogActions>
            </DialogBody>

        </DialogSurface>
    </Dialog >
}


export const useModalStyles = makeStyles({
    "dialog-surface": {
        width: "900px",
        minWidth: "900px",
        padding: "0px",
    },
    "body": {
        paddingLeft: "40px",
        paddingRight: "40px",
        paddingTop: "32px",
        paddingBottom: "32px",
    },
    "title": {
        color: "#3E3E3E",
        fontSize: "28px",
        fontWeight: 600,
        lineHeight: "36px",
        marginBottom: "32px",
    },
    "content": {
        display: "flex",
        ...shorthands.gap("4px"),
        color: "#3E3E3E",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "24px"
    },
    "text-container": {
        ...shorthands.flex("0.75"),
    },
    "text": {
        marginBottom: "24px"
    },
    "text2": {
        marginBottom: "12px"
    },
    "li": {
        paddingLeft: "1rem",
        display: "flex",
        marginBottom: "12px",
        ...shorthands.gap("8px"),
    },
    "link": {
        color: "#0E84E5",
        cursor: "pointer",
        display: "inline-block",
        paddingLeft: "4px",
        paddingRight: "4px",
        ...shorthands.textDecoration("none"),
    },
    "primaryButton": {
        display: "flex",
        height: "36px",
        minWidth: "112px",
        padding: "6px 32px",
        justifyContent: "center",
        alignItems: "center",
        flexShrink: 0,
        ...shorthands.borderRadius("6px"),
        ...shorthands.border("0.75px", "solid", "#FFF"),
        backgroundColor: "#007AFF",
        color: "#FFF",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#0066D4",
            color: "#FFF",
        },
        "&:active": {
            backgroundColor: "#0066D4",
            color: "#FFF",
        }
    },
    "secondaryButton": {
        display: "flex",
        height: "36px",
        minWidth: "112px",
        padding: "6px 32px",
        justifyContent: "center",
        alignItems: "center",
        ...shorthands.borderRadius("6px"),
        ...shorthands.border("0.75px", "solid", "#2E405C"),
        color: "#2E405C",
        textAlign: "center",
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "20px"
    },
    "actions": {
        marginTop: "12px"
    }
})