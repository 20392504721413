import { Button, Toast, ToastTitle, Toaster, makeStyles, mergeClasses, shorthands, tokens, useToastController } from '@fluentui/react-components';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../AuthContext';
import checkIcon from "../../assets/icons/discover-apps/check.svg";
import workExplore_icon from "../../assets/icons/discover-apps/exploreWorkflow.png";
import footerImg from "../../assets/icons/discover-apps/footerImg.svg";
import discoverApp_icon from "../../assets/icons/nav_icons/discover-apps.svg";
import useUTMParams from '../../hooks/useUTMParams';
import { LeadRequest, ZohoService } from '../../services/openapi';
import BrandName from '../common/BrandName';
import Footer from '../landing/common/Footer';
import BecomeProviderModal from './BecomeProviderModal';
import SmallCard from './common/SmallCard';
import { workflowsData } from './common/WorkflowData';
import { worksData } from './common/WroksData';
type CardDataType = {
    id: number,
    icon: string,
    title: string,
    info: string
}[]
const DiscoverApps = () => {
    const classes = useStyles()
    const navigate = useNavigate();
    const [workFlowsDatas, setWorkFlowsDatas] = React.useState<CardDataType | null>(null)
    const [worksDatas, setWorksDatas] = React.useState<CardDataType | null>(null);
    const [becomeProviderModalVisible, setBecomeProviderModalVisible] = React.useState(false);
    const user = React.useContext(AuthContext);
    const { dispatchToast } = useToastController("success");
    const utmParams = useUTMParams();

    const notifySucess = (msg: string) => dispatchToast(
        <Toast
            style={{ background: "#DFF6DD", }}>
            <ToastTitle style={{ fontSize: "14px", fontWeight: 400 }}>{msg}</ToastTitle>
        </Toast>,
        { intent: "success" }
    );

    React.useEffect(() => {
        setWorkFlowsDatas(workflowsData)
        setWorksDatas(worksData)
    }, [])

    const toggleModal = (flag: boolean) => {
        setBecomeProviderModalVisible(flag);
    };
    const submitMessege = (formValues: any) => {
        console.log('messege form', formValues, user);

        const data: LeadRequest = {
            data: [
                {
                    "Lead_Source": "FlyghtCloud Form",
                    "Last_Name": formValues.name,
                    "Email": user.me?.email,
                    "Description": formValues.message,
                    "Form_Page_Url": "https://flyghtcloud.ideaforgetech.com/discover-apps",
                    "Form_Name": "Become a provider - Flyght Cloud",
                    ...utmParams,
                }
            ]
        }

        ZohoService.addLeadToZohoCrm(data).then(() => {
            notifySucess("DeepMatrix Stockpile Report is removed from the Workflow Library");
            console.log("Lead created successfully")
        }).catch(err => console.error("Error creating lead", err))
    }
    return (
        <div className={classes.mainContainer}>
            <div className={classes.projectHeaderText}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <img src={discoverApp_icon} alt="Discover Apps" />
                    <p style={{ marginLeft: ".5em" }}>Discover Apps</p>
                </div>
            </div>
            <div className={classes.bgContainer}
                style={{
                    background: tokens.colorNeutralBackground1,
                    overflow: "auto",
                }}
            >
                <div className={classes.subHeader}>
                    <div className={classes.workFlowContainer}>
                        <div style={{ paddingTop: "1em" }}>
                            <p className={classes.smallHeader}><BrandName /> Discover Apps</p>
                            <h2 className={classes.primaryHeading}>Discover Cutting-Edge<br />
                                Drone Data Analytics Workflows</h2>
                            <p className={classes.subHeadingLabel}>Unlock the full potential of drone data analytics with <BrandName /> ‘Discover Apps’ <br />
                                Elevate your aerial data insights and explore tailored workflows designed for diverse industries.</p>
                            <p className={classes.tagText}><img src={checkIcon} alt='checked' className={classes.tagImg} /> Meticulously Designed Workflows <img src={checkIcon} alt='checked' className={classes.tagImg} /> Seamless Integration <img src={checkIcon} alt='checked' className={classes.tagImg} /> Tailored Insights</p>
                            <Button onClick={() => { navigate(`/discover-apps/recommended-Workflows`) }} className={classes.commonBtn} style={{ marginTop: "1em", padding: "8px 30px" }}>Explore Workflows</Button>
                        </div>
                        <img src={workExplore_icon} style={{ width: "40%" }} alt='workExplore' />
                    </div>
                </div>
                <div className={classes.subHeader}>
                    <div className={classes.commonContainer}>
                        <h2 className={classes.commonHeader}>Why Choose <BrandName /> Workflows?</h2>
                        <p className={classes.subHeading}>Empower your operations with <BrandName /> Workflows, meticulously crafted to optimise efficiency and drive<br /> results. Harness the power of drone data analytics to unlock actionable insights and stay ahead in your industry.</p>
                        {workFlowsDatas && (
                            <div className={classes.grid}>
                                {workFlowsDatas.map((data, index) => (
                                    <div key={index} >
                                        <SmallCard key={index} cardData={data} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div >
                <div className={classes.subHeader}>
                    <div className={classes.commonContainer}>
                        <h2 className={classes.commonHeader}>How It Works:</h2>
                        <p className={classes.subHeading}>Streamline your workflow journey with <BrandName /> - from exploration to optimisation, we're here<br /> to empower your data analytics experience</p>
                        {worksDatas && (
                            <div className={classes.grid}>
                                {worksDatas.map((data, index) => (
                                    <div key={index} >
                                        <SmallCard key={index} cardData={data} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div >
                <div className={classes.subHeader}>
                    <div className={mergeClasses(classes.commonConatainerBg)} style={{ padding: "0 1.5em" }}>
                        <div>
                            <p className={classes.subHeading}>Join our ecosystem as a workflow provider and showcase your expertise to a global audience.<br />
                                Reach new clients and expand your business opportunities with <BrandName />.</p>
                            <Button className={classes.becomeProvider} onClick={() => {
                                toggleModal(true);
                            }} style={{ background: "none", padding: "8px 30px" }}>Become a Provider</Button>
                        </div>
                        <div style={{ paddingRight: "3em", position: "absolute", bottom: 0, right: 0 }}>
                            <img src={footerImg} alt='footerIcon' style={{ width: "70%", }} />
                        </div>
                    </div>
                    <BecomeProviderModal
                        visible={becomeProviderModalVisible}
                        toggleModal={toggleModal}
                        onSubmit={(d: any) => submitMessege(d)}
                    />
                </div>
                <div style={{ color: "#586A84", textAlign: "center" }}>
                    <Footer />
                </div>
            </div>
            <Toaster inline toasterId={"success"} position="bottom" />
        </div>
    )
}

const useStyles = makeStyles({
    projectHeaderText: {
        height: "3rem",
        paddingLeft: "1.25rem",
        display: "flex",
        alignItems: "center",
        color: "#2B2B2B",
        ...shorthands.borderBottom("1px", "solid", "#E3E9F2"),
        "> span": {
            fontWeight: "700",
        },
    },
    subHeader: {
        fontWeight: "600",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        fontSize: "1.5em",
        marginBottom: ".2em",
    },
    bgContainer: {
        width: "100%",
        padding: "1em 2em",
        height: "calc(92vh - 3rem)",
        scrollbarWidth: "thin",
        position: "relative",
    },
    workFlowContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
    },
    commonContainer: {
        width: "100%",
        marginTop: "2em"
    },
    smallHeader: {
        fontSize: "0.9rem",
        fontWeight: 700,
        color: "#8193AC"
    },
    primaryHeading: {
        color: "#1C1C21",
        fontSize: "2.6rem",
        fontWeight: 700,
        lineHeight: "2.8rem",
        marginTop: ".2em",
    },
    commonHeader: {
        color: "#1C1C21",
        fontSize: "1.6rem",
        fontWeight: 700,
        lineHeight: "2.3rem",
        marginTop: ".2em",
        marginBottom: ".2em"
    },
    subHeading: {
        fontSize: "1rem",
        lineHeight: "1.25rem",
        marginTop: "1em",
        fontWeight: 400
    },
    tagText: {
        fontSize: ".8rem",
        color: "#586A84",
        lineHeight: "1rem",
        fontWeight: "500",
        marginTop: "1em",
        marginBottom: "1em",
        display: "flex",
        alignItems: "center"
    },
    tagImg: {
        marginLeft: ".5em",
        marginRight: ".5em"
    },
    commonBtn: {
        backgroundColor: "#5E5CE6",
        color: "#FFFFFF",
        ...shorthands.borderRadius("8px"),
    },
    grid: {
        display: "grid",
        marginTop: "1.5em",
        marginBottom: "2em",
        width: "90%",
        gridTemplateColumns: "repeat(auto-fill, minmax(15%, 1fr))",
        gridGap: '1.3em',
    },
    commonConatainerBg: {
        backgroundColor: "#EEEEFF",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        marginTop: "2em",
        marginBottom: "2em",
        position: "relative",
        alignItems: "center",
        ...shorthands.border("1px", "solid", "#E3E9F2"),
        ...shorthands.borderRadius("16px"),
    },
    becomeProvider: {
        ...shorthands.border("1px", "solid", "#586A84"),
        ...shorthands.borderRadius("8px"),
        color: "#586A84", marginTop: "2em", marginBottom: "3em",
    },
    subHeadingLabel: {
        fontSize: ".92rem",
        marginTop: "1em"
    },
    mainContainer: {
        '& .r1yngb2j': {
            width: "500px !important",
        }
    }
})

export default DiscoverApps