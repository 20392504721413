import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useUTMParams = () => {
    const [utmParams, setUtmParams] = useState<{
        utm_ad_set?: string | null;
        utm_content?: string | null;
        utm_keyword?: string | null;
        utm_medium?: string | null;
        utm_platform?: string | null;
        utm_source?: string | null;
        utm_term?: string | null;
    }>({
        utm_source: null,
        utm_ad_set: null,
        utm_keyword: null,
        utm_platform: null,
        utm_term: null,
        utm_content: null,
        utm_medium: null,
    });

    const location = useLocation();

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const params = {
            utm_source: urlParams.get('utm_source'),
            utm_ad_set: urlParams.get('utm_ad_set'),
            utm_keyword: urlParams.get('utm_keyword'),
            utm_platform: urlParams.get('utm_platform'),
            utm_term: urlParams.get('utm_term'),
            utm_content: urlParams.get('utm_content'),
            utm_medium: urlParams.get('utm_medium'),
        };
        setUtmParams(params);
    }, [location.search]);

    return utmParams;
};

export default useUTMParams;