import { makeStyles } from "@fluentui/react-components"

export function TechnicalDocumentation() {
    const classes = useStyles()
    return <div className={classes.container}>
        <iframe src="/technicalDocumentMarkup.html" style={{ width: '100%', height: '100%', border: 'none' }}></iframe>
    </div>
}

const useStyles = makeStyles({
    container: {
        height: "calc(100vh - 54px)",
    }
})