/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FeatureDemoRequest } from '../models/FeatureDemoRequest';
import type { FeatureDemoResponse } from '../models/FeatureDemoResponse';
import type { FeatureDemosResponse } from '../models/FeatureDemosResponse';
import type { FeatureDemoViewRequest } from '../models/FeatureDemoViewRequest';
import type { FeatureDemoViewsResponse } from '../models/FeatureDemoViewsResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FeatureDemosService {

    /**
     * @returns FeatureDemosResponse Get all feature demos
     * @throws ApiError
     */
    public static getAllFeatureDemos(): CancelablePromise<FeatureDemosResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/feature_demos',
        });
    }

    /**
     * @param requestBody
     * @returns FeatureDemoResponse Creating new feature demo
     * @throws ApiError
     */
    public static createFeatureDemo(
        requestBody: FeatureDemoRequest,
    ): CancelablePromise<FeatureDemoResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/feature_demos',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns FeatureDemoViewsResponse Get all user viewed feature demos
     * @throws ApiError
     */
    public static getAllFeatureDemoViews(): CancelablePromise<FeatureDemoViewsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/user/feature_demos',
        });
    }

    /**
     * @param requestBody
     * @returns any Add user viewed feature demo
     * @throws ApiError
     */
    public static addFeatureDemoView(
        requestBody: FeatureDemoViewRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user/feature_demos',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
