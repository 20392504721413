import { Button, Dialog, DialogSurface, Input, Textarea, makeStyles, shorthands } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface FormProps {
    message: string,
    name: string,
}
interface ModalProps {
    visible: boolean;
    onSubmit: (f: FormProps) => void;
    toggleModal: (flag: boolean) => void;
}


const BecomeProviderModal = (props: ModalProps) => {
    const { visible, toggleModal } = props
    const classes = useStyles()
    const navigate = useNavigate();
    const [formValues, setFormvalues] = useState<FormProps>({
        message: '',
        name: "",
    });

    useEffect(() => {
        setFormvalues({
            ...formValues, message: '', name: "",
        });
    }, [visible])

    const handleSubmit = (ev: React.FormEvent) => {
        ev.preventDefault();
        toggleModal(false);
        props.onSubmit(formValues);
        setFormvalues((prev: any) => {
            return { ...prev, message: '' };
        })
    };

    return (
        <Dialog
            open={visible}
        >
            <DialogSurface>
                <form onSubmit={handleSubmit}>
                    <div style={{ marginBottom: "1rem" }}>
                        <h3 className={classes.labelText}>Your name *</h3>
                        <Input
                            style={{
                                width: '100%',
                            }}
                            appearance="outline"
                            size="large"
                            placeholder="Type your name here"
                            value={formValues.name}
                            onChange={(e) => {
                                setFormvalues({ ...formValues, name: e.target.value })
                            }}
                        />
                    </div>

                    <div >
                        <h3 className={classes.quesText}>Type your message *</h3>
                        <Textarea
                            style={{
                                width: '100%',
                                border: "1px solid #D1DBE8",
                                height: "20vh"

                            }}
                            className={classes.txtBox}
                            appearance="outline"
                            size="large"
                            placeholder="Type here..."
                            value={formValues.message}
                            onChange={(e) =>
                                setFormvalues((prev: any) => {
                                    return { ...prev, message: e.target.value };
                                })
                            }
                        />
                        <p className={classes.info}>Flyght Cloud uses the information you provide us to process your request, and occasionally contact you about relevant content and services. You can unsubscribe any time. For more information, check out our <span style={{ color: "#171586", cursor: "pointer" }} onClick={() => { navigate(`/privacy-policy`) }}>Privacy Policy.</span></p>
                    </div>
                    <div style={{ marginTop: "1rem", display: "flex", justifyContent: "space-between" }}>
                        <Button style={{ width: '45%', color: "#586A84", padding: "8px 16px", borderRadius: "8px" }}
                            onClick={() => { props.toggleModal(false) }}
                        >
                            Cancel
                        </Button>
                        <Button
                            style={{ width: '45%', background: "#5E5CE6", color: "white", padding: "8px 16px", borderRadius: "8px" }}
                            appearance="primary"
                            disabled={!formValues.message || !formValues.name}
                            type="submit"
                        >
                            Submit
                        </Button>
                    </div>
                </form>
            </DialogSurface>
        </Dialog >
    );
};
const useStyles = makeStyles({
    quesText: {
        marginBottom: "1rem",
        fontSize: "1rem !important",
        fontWeight: 600,
    },
    txtBox: {
        ...shorthands.border("1px", "solid", "#E3E9F2"),
        ...shorthands.borderRadius("16px"),
        ...shorthands.padding("12px"),
        backgroundColor: "#F7FAFC"
    },
    info: {
        marginTop: "1em",
        marginBottom: "1em",
        fontWeight: "400"
    },
    labelText: {
        marginBottom: ".5rem",
        fontSize: "1rem !important",
        fontWeight: 600,
    },

})
export default BecomeProviderModal;