/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PasswordRequest } from '../models/PasswordRequest';
import type { StatusRequest } from '../models/StatusRequest';
import type { UserInfoResponse } from '../models/UserInfoResponse';
import type { UserRequest } from '../models/UserRequest';
import type { UsersInfoResponse } from '../models/UsersInfoResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsersService {

    /**
     * @returns UsersInfoResponse Users:
     * @throws ApiError
     */
    public static listUsers(): CancelablePromise<UsersInfoResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users',
        });
    }

    /**
     * @param requestBody
     * @returns UserInfoResponse User added
     * @throws ApiError
     */
    public static addUsers(
        requestBody: UserRequest,
    ): CancelablePromise<UserInfoResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @returns UserInfoResponse User Info
     * @throws ApiError
     */
    public static getUser(
        userId: string,
    ): CancelablePromise<UserInfoResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users/{user_id}',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns UserInfoResponse Password updated successfully
     * @throws ApiError
     */
    public static setPassword(
        userId: string,
        requestBody: PasswordRequest,
    ): CancelablePromise<UserInfoResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/{user_id}/password',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns UserInfoResponse User activated successfully
     * @throws ApiError
     */
    public static setStatus(
        userId: string,
        requestBody: StatusRequest,
    ): CancelablePromise<UserInfoResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/{user_id}/status',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param emailId
     * @returns UserInfoResponse User Info
     * @throws ApiError
     */
    public static getUserByEmail(
        emailId: string,
    ): CancelablePromise<UserInfoResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/usersInfo/{email_id}',
            path: {
                'email_id': emailId,
            },
        });
    }

}
