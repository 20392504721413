
import * as React from 'react';
import { Button, Tag, TagGroup, makeStyles, mergeClasses, shorthands, tokens } from "@fluentui/react-components"
import workFlow_icon from "../../assets/icons/nav_icons/workflows.svg";
import { Link } from "react-router-dom";
import footerImg from "../../assets/icons/discover-apps/talkToExp_footer.svg"
import TalkToExpertModal from '../discover_apps/TalkToExpertModal';
const tagLables = ["DeepMatrix", "FLY", "Stockpile Report", "Mining", "Orthomosaic", "DEM", "Custom Report"];

const MyWorkFlow = () => {
    const classes = useStyles()
    const [talkToExpertModal, setTalkToExpertModal] = React.useState<boolean>(false)

    const toggleTalkToExpertDialog = (flag: boolean) => {
        setTalkToExpertModal(flag)
    }
    type TalkToExpertForm = {
        fullName: string;
        email: string;
        phoneNumber: string;
        customMessage: string;
    }

    const onSubmit = (formValues: TalkToExpertForm) => {
        console.log('formValues recived', formValues)
        setTalkToExpertModal(false)
    }
    return (
        <>
            <div className={classes.projectHeaderText}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Link style={{ textDecoration: "none", fontSize: '1rem', color: "black", fontWeight: "normal" }} className={classes.flexContent} to="/workFlow-library"><img alt="projectIcon" style={{ paddingRight: ".5em", }} src={workFlow_icon} /> Workflows Library</Link></div> <p style={{ margin: "0 .2em " }}>{'>'}</p> <div style={{ fontSize: '1rem' }}>DeepMatrix Stockpile Report</div>
            </div>
            <div className={classes.bgContainer}
                style={{
                    background: tokens.colorNeutralBackground1,
                    overflow: "auto",

                }}
            >
                <div className={classes.subHeader} style={{
                }}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <div>
                            DeepMatrix Stockpile Report <TagGroup style={{ marginLeft: "1em" }} role="list">
                                {
                                    tagLables.map((item, index) => <Tag key={index} role="listitem" className={classes.tagBar} style={{ height: "3.2vh", borderRadius: "4px" }}>{item}</Tag>
                                    )
                                }
                            </TagGroup>
                        </div>
                        <Button className={classes.commonBtn}>Add to Workflow Library</Button>
                    </div>
                </div>
                <div className={classes.subHeader}>
                    <div className={mergeClasses(classes.commonConatainerBg)} style={{ padding: "1em" }}>
                        <div style={{ width: "50%" }}>
                            <h2 className={classes.subHeading1}>Try out this workflow now!!</h2>
                            <p className={classes.subHeading2}>Our experts will assist you in running a sample dataset and guide you through the<br /> process to enhance your workflow experience.</p>
                            <Button className={classes.footerBtn} onClick={() => toggleTalkToExpertDialog(true)}>Talk To Expert</Button>

                        </div>
                        <div style={{ width: "50%" }}>
                            <img src={footerImg} alt='footerIcon' style={{ width: "40%", marginLeft: "3em" }} />
                        </div>
                    </div>
                </div>
            </div>
            <TalkToExpertModal visible={talkToExpertModal} onSubmit={(d: any) => {
                onSubmit(d)
            }} toggleModal={toggleTalkToExpertDialog} />

        </>
    )
}

const useStyles = makeStyles({
    projectHeaderText: {
        height: "3rem",
        paddingLeft: "1.25rem",
        display: "flex",
        alignItems: "center",
        color: "#2B2B2B",
        ...shorthands.borderBottom("1px", "solid", "#E3E9F2"),
        "> span": {
            fontWeight: "700",
        },
    },
    bgContainer: {
        width: "98%",
        ...shorthands.borderRadius("1em"),
        ...shorthands.padding("1em"),
        ...shorthands.margin("1em"),
        height: "calc(92vh - 4.2rem)",
        scrollbarWidth: "thin",
        boxShadow: "5px 1px 20px 0px rgba(0, 0, 0, 0.05)",
    },
    flexContent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    subHeader: {
        fontWeight: "600",
        fontSize: "1.5em",
    },
    cardHeading: {
        fontSize: "1.2rem",
        color: "#586A84",
        fontWeight: 500,
        lineHeight: "1.5rem",
        ...shorthands.margin(0),
    },
    tagBar: {
        backgroundColor: "#E3E6EB",
        color: "#272727",
        fontSize: ".5rem !important",
        ...shorthands.padding("4px", "10px"),
        display: "flex", justifyContent: "center", alignItems: "center",
        '& .f1g03r3y': {
            fontSize: '.7rem',
        },
    },
    wrapper: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(400px, 1fr))",
        marginTop: "1em",
        gridGap: '1.2em',
    },
    commonBtn: {
        ...shorthands.padding("10px", "24px"),
        ...shorthands.border("1px", "solid", "#586A84"),
        ...shorthands.borderRadius("8px"),
        color: "#5E5CE6"
    },
    commonConatainerBg: {
        backgroundColor: "#EEEEFF",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        marginTop: "2em",
        marginBottom: "2em",
        alignItems: "center",
        ...shorthands.border("1px", "solid", "#E3E9F2"),
        ...shorthands.borderRadius("16px"),
    },
    subHeading2: {
        fontSize: ".8rem",
        color: "#586A84",
        lineHeight: "1rem",
        fontWeight: "500",
        marginTop: "1em",
        marginBottom: "1em"
    },
    subHeading1: {
        fontSize: "1.2rem",
        color: "#586A84",
        lineHeight: "1rem",
        fontWeight: "500",
        marginTop: "1em",
        marginBottom: "1em"
    },
    footerBtn: {
        ...shorthands.borderRadius("8px"),
        backgroundColor: "#5E5CE6",
        ...shorthands.padding("4px 16px"),
        color: "#ffff"
    }
}
)

export default MyWorkFlow