import { Button, makeStyles, shorthands } from '@fluentui/react-components';
import * as React from 'react';
import Lottie from 'react-lottie';
import uploadingAnimation from "../../assets/lottie/uploading.json";
import { TaskFileResponse, TaskQueueService, WorkflowsService } from '../../services/openapi';
import { Loading } from '../Loading';
import { PluginProps } from '../PluginProps';


const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: uploadingAnimation,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

export const WorkflowFileUploaderPlugin = ({ workflowId, taskId, onFinish, setBtnStatus }: PluginProps) => {
    const classes = useStyles()
    const [error, setError] = React.useState('');
    const [fileRes, setFileRes] = React.useState<TaskFileResponse | null>(null);
    const [file, setFile] = React.useState<File | null>(null);
    const [isUploading, setIsUploading] = React.useState(false)

    React.useEffect(() => {
        TaskQueueService.getFiles(taskId)
            .then((files) => {
                if (files.outputs.length === 1) {
                    setFileRes(files.outputs[0]);
                }
            })
            .catch((err) => {
                console.error(err);
                setError(`Could not get task's files: ${err}`);
            });
    }, [taskId]);

    const upload = () => {
        setIsUploading(true)
        if (setBtnStatus) {
            setBtnStatus(true)
        }
        if (!fileRes || !file) return;
        TaskQueueService.getFilePresignedUrl(taskId, fileRes?.id)
            .then((url) => {
                return fetch(url, {
                    method: 'PUT',
                    headers: {},
                    body: file
                }).then(() => {
                    return syncFile();
                }).catch((err) => {
                    setError(`Could not upload to presigned URL: ${err}`);
                });
            })
            .catch((err) => {
                setError(`Could not sync file: ${err}`)
            });
    };

    const syncFile = () => {
        return TaskQueueService.syncFile(taskId, fileRes?.id || '')
            .then((fileRes) => {
                if (fileRes) {
                    setFileRes(fileRes);
                    finishTask();
                }
            })
            .catch((err) => setError(`Could not sync file: ${err}`)
            );
    };

    const finishTask = () => {
        WorkflowsService.updateTaskStatus(workflowId, taskId, { status: 'completed' })
            .then(() => {
                if (onFinish) {
                    onFinish();
                }
                if (setBtnStatus) {
                    setBtnStatus(false);
                }
                setIsUploading(false);
            })
            .catch((err) => setError(`Could not finish task: ${err}`));
    };

    const getFileExtension = (filename: string) => {
        const lastDotIndex = filename.lastIndexOf('.');
        if (lastDotIndex !== -1) {
            return filename.substring(lastDotIndex);
        }
        return '';
    }

    if (error) {
        return <div>{error}</div>
    }

    if (!fileRes) {
        return <Loading />
    }

    return <div style={{ padding: '.5em', color: "#586A84" }}>
        <h1 style={{ fontSize: "1.8rem" }}>Upload {fileRes.name}</h1>
        <p style={{ fontSize: "1rem", marginTop: "2em", fontWeight: "400" }}>{`Browse and upload the ${fileRes.name}`}</p>
        <div style={{ display: "flex", justifyContent: "end", marginTop: "2em" }}>
            {
                file !== null ?
                    <div >
                        {file && <p style={{ marginBottom: "0.5rem" }}>{file.name}</p>}
                        <Button className={classes.workflowActionButton} disabled={!file || isUploading} onClick={() => upload()}>{isUploading ?
                            <div style={{ display: "flex", gap: "0.25rem", alignItems: "center" }}>
                                {isUploading && <Lottie
                                    options={defaultOptions}
                                    height={32}
                                    width={32}
                                />}
                                Uploading...
                            </div> : "Upload & Finish Task"}</Button>
                    </div>
                    : <div>
                        <label htmlFor="fileInput" className={classes.workflowActionButton} style={{ padding: "1em 1.5em" }}>
                            Browse File
                        </label>
                        <input
                            type="file"
                            id="fileInput"
                            accept={getFileExtension(fileRes.name ?? "") === "" ? "*" : getFileExtension(fileRes.name ?? "")}
                            onChange={(e) => {
                                if (e.target.files && e.target.files.length > 0) {
                                    const selectedFile = e.target.files[0];
                                    const requiredExtention = getFileExtension(fileRes.name ?? "")
                                    if (requiredExtention) {
                                        if (selectedFile && selectedFile.name.endsWith(requiredExtention)) {
                                            setFile(selectedFile);
                                        } else {
                                            alert(`Please select a valid ${requiredExtention} file.`);
                                        }
                                    } else {
                                        setFile(selectedFile);
                                    }
                                }
                            }}
                            style={{ display: 'none' }}
                        />
                    </div>
            }
        </div>
    </div>
};

const useStyles = makeStyles({
    workflowActionButton: {
        display: "flex",
        height: "40px",
        flexDirection: "row",
        justifyContent: "center",
        cursor: "pointer",
        alignItems: "center",
        ...shorthands.gap("8px"),
        ...shorthands.borderRadius("8px"),
        ...shorthands.border("1px", "solid", "#79747E"),
        color: "#5E5CE",
        textAlign: "center",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0.1px",
        backgroundColor: "transparent",
        whiteSpace: "nowrap",
        '&:hover': {
            backgroundColor: "#F4F4FF",
            color: "#5E5CE6",
        },
        '&:active': {
            backgroundColor: "#F4F4FF",
            color: "#5E5CE6",
            ...shorthands.border("1px", "solid", "#F4F4FF"),
        }
    },

})