import { Checkbox, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, makeStyles, mergeClasses, shorthands } from "@fluentui/react-components";

import ExpandIcon from '../../assets/icons/expand.svg';
import { FlightResponse } from "../../services/openapi";
interface Props {
    setSelectedFlights: React.Dispatch<React.SetStateAction<FlightResponse[]>>;
    flights: FlightResponse[];
    selectedFlights: FlightResponse[];
}

export default function RecentFlightsColumnHeader({ setSelectedFlights, flights, selectedFlights }: Props) {
    const classes = useTableStyles()

    return <div className={mergeClasses(classes.columnHeader, classes.table)}>
        <div className={classes.checkboxContainer}>
            <Checkbox className={classes.checkbox} size='large' onClick={() => {
                if (selectedFlights.length === flights.length) {
                    setSelectedFlights([])
                } else {
                    setSelectedFlights(flights)
                }
            }}
                checked={selectedFlights.length === flights.length && flights.length > 0}
            />
            <Menu>
                <MenuTrigger disableButtonEnhancement>
                    <img src={ExpandIcon} alt="expand icon" style={{ cursor: "pointer" }} />
                </MenuTrigger>

                <MenuPopover>
                    <MenuList>
                        <MenuItem onClick={() => setSelectedFlights(flights)}>
                            <p style={{
                                fontSize: "14px",
                            }}
                            >
                                All
                            </p>
                        </MenuItem>
                        <MenuItem> <p style={{
                            fontSize: "14px",
                        }}
                            onClick={() => {
                                setSelectedFlights(flights.filter(f => {
                                    if (f.numCompletedWorkflows === null || f.numCompletedWorkflows === undefined) {
                                        return false
                                    }
                                    if (f.numCompletedWorkflows > 0 || (f.numCompletedWorkflows === 0 && f.numOngoingWorkflows === 0)) {
                                        return true
                                    }
                                    return false
                                }))
                            }}
                        >
                            Workflow Completed
                        </p></MenuItem>
                        <MenuItem> <p style={{
                            fontSize: "14px",
                        }}
                            onClick={() => {
                                setSelectedFlights(flights.filter(f => f.numOngoingWorkflows !== null && f.numOngoingWorkflows !== undefined && f.numOngoingWorkflows > 0))
                            }}
                        >
                            Workflow Pending
                        </p></MenuItem>
                    </MenuList>
                </MenuPopover>
            </Menu>

        </div>

        <p className={classes.columnHeaderText}>FLIGHT</p>
        <p className={classes.columnHeaderText}>PROJECT</p>
        <p className={classes.columnHeaderText}>CREATED ON</p>
        <p></p>
    </div>
}


const useTableStyles = makeStyles({
    columnHeader: {
        ...shorthands.borderRadius("0.5rem"),
        backgroundColor: "#F2F6FA",
        marginTop: "22px",
        ...shorthands.padding("0.35rem", "0.5rem")
    },
    table: {
        display: "grid",
        gridTemplateColumns: "5% 32% 34% 10% 19%",
        alignItems: "center",
        rowGap: '1rem',
    },
    columnHeaderText: {
        color: "#7D8FA9",
        fontSize: "18px",
    },
    checkboxContainer: {
        display: "flex",
        ...shorthands.gap("0.25rem"),
    },
    checkbox: {
        '> div': {
            ...shorthands.borderRadius("3.5px"),
            ...shorthands.borderColor("#7D8FA9"),
            ...shorthands.margin(0),
        },
        '> input': {
            width: "100%",
        },
    },
})