import measurementTools from "../../../../assets/icons/whatsNew/measurementTools.png"
import dmIntegarion from "../../../../assets/icons/whatsNew/dmIntegarion.png"
import automatedWf from "../../../../assets/icons/whatsNew/changeDetection.png"
import gcpTagging from "../../../../assets/icons/whatsNew/gcpTagging.png"
import lidarData from "../../../../assets/icons/whatsNew/lidarData.png"
import newNetwork from "../../../../assets/icons/whatsNew/newNetwok.png"
import conturMap from "../../../../assets/icons/whatsNew/conturMaps.png"
import tdm from "../../../../assets/icons/whatsNew/dtm.png"
import switchingOrg from "../../../../assets/icons/whatsNew/switchngOg.png"
import clipping from "../../../../assets/icons/whatsNew/clipping.png"


export const whatsNewDataNotes = [
    {
        id: "1",
        icon: measurementTools,
        title: 'Measurement Tools',
        info: "Now you can measure length, area and elevation with precision, ensuring accurate project planning and analysis across all GIS applications."
    },
    {
        id: "2",
        icon: dmIntegarion,
        title: 'DeepMatrix Integration (Haul Road & Stockpile Analysis)',
        info: "Enhance efficiency and reduce manual effort with automatic haul road analysis, stockpile analysis. Simply select the Haul Road Analysis or Stockpile analysis workflow, upload your data, and let FlyghtCloud generate final reports and insights—without any human intervention."
    },
    {
        id: "3",
        icon: automatedWf,
        title: 'Change Detection',
        info: "Effortlessly track changes over time by adjusting the opacity of map layers for a clear comparison. Obtain elevation variations in the altered areas(PDF report), represented with distinct legends and color gradients"
    },
    {
        id: "4",
        icon: newNetwork,
        title: 'Planimetric Map',
        info: "Get precise and detailed representations of both natural and man-made features on Earth's surface, utilizing vector data and colors"
    },
    {
        id: "5",
        icon: conturMap,
        title: 'Contour Maps',
        info: "Create contour maps that depict the Earth's surface, highlighting elevation and terrain shape using contour lines"
    },
    {
        id: "6",
        icon: tdm,
        title: 'Digital Terrain Model (DTM)',
        info: "Effortlessly generate DTMs from Digital Elevation Model, Point Cloud, csv files"
    },
    {
        id: "7",
        icon: gcpTagging,
        title: 'GCP Tagging',
        info: "Achieve improved accuracy in your data outputs by tagging Ground Control Points (GCPs), resulting in more reliable maps and analyses."
    },
    {
        id: "8",
        icon: lidarData,
        title: 'LiDAR Data Conversion to BFT-Compatible DEM',
        info: "Seamlessly convert LiDAR data into BFT-compatible Digital Elevation Model (DEM), expanding your data sources and improving your project analysis capabilities."
    },
    {
        id: "9",
        icon: clipping,
        title: 'Clipping of outputs',
        info: "Create more visually appealing maps with cropped outputs that maintain clear, distortion-free edges"
    },
    {
        id: "10",
        icon: switchingOrg,
        title: 'Switching between multiple organizations',
        info: "Effortlessly toggle between multiple organizations to access their respective “projects”, “credit usage”, “transaction history”, and more"
    },
]
