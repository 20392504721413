import { makeStyles, shorthands } from "@fluentui/react-components";
import { useContext } from "react";
import NoFlightsFoundImage from "../../assets/no_flight_found.png";
import { AuthContext } from "../../AuthContext";
import { PrimaryButton } from "../common/PrimaryButton";


export default function NoFlightsState() {
    const { setIsNewProjectOpen } = useContext(AuthContext);
    const classes = useStyles();

    return <div className={classes.container}>
        <img src={NoFlightsFoundImage} alt="No Flights Found" />

        <div>
            <p className={classes.heading}>No recent flights</p>
            <p className={classes.subheading}>Add a new project to get started</p>

            <div style={{ marginTop: "2rem" }} />
            <PrimaryButton label="Create new project" onClick={() => { setIsNewProjectOpen(true) }} />
        </div>
    </div>
}

const useStyles = makeStyles({
    container: {
        display: "flex",
        ...shorthands.margin("5rem"),
        ...shorthands.gap("3.75rem"),
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        height: "100%",
    },
    heading: {
        color: "#586A84",
        fontSize: "20px",
        fontWeight: 600
    },
    subheading: {
        color: "#586A84",
        fontSize: "14px",
        fontWeight: 600,
        marginTop: "8px",
    },
})
