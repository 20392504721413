import { createDarkTheme, createLightTheme, Theme, makeStyles } from '@fluentui/react-components';
import { BrandVariants } from "@fluentui/react-components";

const flyghtCloudTheme: BrandVariants = {
    10: "#020305",
    20: "#121724",
    30: "#182540",
    40: "#1C3158",
    50: "#1F3D70",
    60: "#214A89",
    70: "#2157A4",
    80: "#1F64BF",
    90: "#1A72DA",
    100: "#0F80F7",
    110: "#438EFF",
    120: "#689CFF",
    130: "#85AAFF",
    140: "#9EB9FF",
    150: "#B5C8FF",
    160: "#CBD7FF",
};

export const lightTheme: Theme = {
    ...createLightTheme(flyghtCloudTheme),
};

export const darkTheme: Theme = {
    ...createDarkTheme(flyghtCloudTheme),
};

lightTheme.colorNeutralBackground2 = flyghtCloudTheme[160];
lightTheme.colorNeutralForeground1 = flyghtCloudTheme[20];
lightTheme.colorNeutralForeground2 = flyghtCloudTheme[10];

lightTheme.colorBrandForeground1 = "#3C3AC7";
// lightTheme.colorBrandBackground = "#ffffff";
lightTheme.colorBrandBackground2 = "#F7F8F9";

export const useGlobalStyles = makeStyles({
    gradient: {
        backgroundImage: 'linear-gradient(to right bottom, var(--colorNeutralBackground1), var(--colorNeutralBackground2))'
    }
});
