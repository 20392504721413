import React from 'react'
import { Body1, Card, CardHeader, CardPreview } from '@fluentui/react-components';
import "./styles.css"

interface Props {
    icon: string;
    title: string;
    info: string
}

function WhatsNewCard({ icon, title, info }: Props) {

    return (
        <Card
            className='wncard'
            focusMode="off"
        >
            <CardPreview>
                <img
                    src={icon}
                    alt={title}
                />
            </CardPreview>

            <CardHeader style={{ padding: "0 1.5em" }}
                header={
                    <Body1>
                        <b className="title">{title}</b>
                        <p className="info">
                            {info}
                        </p>
                    </Body1>
                }
            />
        </Card>
    )
}

export default WhatsNewCard

