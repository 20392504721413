import { Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTrigger, makeStyles, mergeClasses, shorthands } from '@fluentui/react-components';
import closeBtn from "../../../assets/icons/landing/close.svg";

interface ModalProps {
    visible: boolean;
    pdfUrl: string;
    demoType: string;
    toggleModal: (flag: boolean, link: string, type: string) => void;
}

function DemoModal(props: ModalProps) {
    const { visible, toggleModal } = props;
    const classes = useStyles();

    return <Dialog
        open={visible} modalType="non-modal"
    >
        <DialogSurface className={mergeClasses(classes.gradient)} style={{ margin: 0, maxWidth: "100%", width: "100%", position: "fixed", height: "100vh", }}>
            <DialogBody style={{ height: "100vh" }}>
                <DialogActions>
                    <DialogTrigger disableButtonEnhancement>
                        <DialogTrigger disableButtonEnhancement>
                            <img style={{ position: "absolute", top: 0, right: 0, cursor: "pointer" }} src={closeBtn} alt='close' onClick={() => toggleModal(false, "", "")} />
                        </DialogTrigger>
                    </DialogTrigger>
                </DialogActions>
                <DialogContent>
                    <div className={classes.formBox} style={{ width: "100%", color: "white", height: "99%" }}>
                        {
                            props.demoType === 'docs' ? <iframe style={{ scrollbarWidth: "thin" }}
                                src={props.pdfUrl}
                                width="100%"
                                height="100%"
                                title='Demo Pdf'
                                allowFullScreen
                            ></iframe> :
                                <iframe
                                    title={props.pdfUrl.split('v=')[1]}
                                    src={`https://www.youtube.com/embed/${props.pdfUrl.split('v=')[1]}?autoplay=1`}
                                    allowFullScreen
                                    allow="autoplay; encrypted-media"
                                    height={"100%"}
                                    style={{ minHeight: "100%", width: "100%", borderRadius: '16px' }} />
                        }
                    </div>
                </DialogContent>
            </DialogBody>
        </DialogSurface>
    </Dialog >
}


const useStyles = makeStyles({
    gradient: {
        backgroundImage: 'linear-gradient(to left top,rgb(28 7 122), rgb(0, 0, 3) )'
    },
    contentBox: {
        width: "50%",

    },
    formBox: {
        width: "50%",

    },
    bgHeader: {
        color: "#FFF",
        fontSize: "40px",
        fontWeight: 600,
        lineHeight: "52px"
    },
    subHeader: {
        color: "#FFF",
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "28px"
    },
    primaryHeader: {
        fontSize: "1.5rem",
        fontWeight: 600,
        lineHeight: '2rem'
    },
    regularTxt: {
        fontSize: ".8rem",
        textAlign: "center",
        marginTop: "1.5em",
        marginBottom: "1.5em"
    },
    grid: {
        display: "grid",
        gridTemplateColumns: 'repeat(4, 3fr)',
        marginTop: "2em",
        marginBottom: "2em",
        gridGap: '1em',
    },
    card: {
        width: "98%",
        maxWidth: "100%",
        height: 'calc(20vh - 3rem)',
        display: "flex", alignItems: "center", justifyContent: "center",
    },
    resContainer: {
        marginTop: "5em",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        color: "#586A84",
        width: "85%",
    },
    thereContainer: {
        position: "absolute",
        color: "#000000",
        paddingLeft: ".5em",
        paddingTop: ".5em",
        paddingBottom: ".5em",
        paddingRight: "3em",
        backgroundColor: "white",
        ...shorthands.borderRadius("4px"),
        left: "5%",
        fontWeight: "400",
        top: "0",
    }


})
export default DemoModal