export const BecomeProviderCardData = [
    {
        id: 1,
        title: 'Access to thousands of new customers',
        info: "Our community of developers, startups, and enterprises is always looking for the best tools and services. As a Flyght Cloud provider, you'll be featured in our catalog and have the potential to reach thousands of new customers."
    }, {
        id: 2,
        title: 'Opportunities for joint marketing efforts',
        info: "We're committed to helping our providers grow. We'll work with you to design joint marketing campaigns, host webinars, and  create other content that showcases your integration with Flyght Cloud."
    }, {
        id: 3,
        title: 'A badge on your product page',
        info: "Showcase your integration with Flyght Cloud to potential customers. With a badge on your product page, you can let users know that your tool is available on Flyght Cloud."
    }, {
        id: 4,
        title: 'Growth Opportunities',
        info: "Explore new business opportunities and partnerships through collaboration with Flyght Cloud and other providers within the ecosystem, fostering innovation and driving mutual growth in the drone data analytics industry."
    },
]