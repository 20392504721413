import React from 'react'
import projectFilesImg from "../../../assets/icons/manageFiles.svg"

function EmptyFiles() {
    return (
        <div style={{ marginTop: "2em", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <img src={projectFilesImg} style={{ width: "45%" }} />
            <p style={{
                color: "#000000", marginTop: "1em", marginBottom: "4em"
            }}>
                No project files found.< br />
                Add a new file now!
            </p>
        </div>
    )
}

export default EmptyFiles