import { makeStyles } from '@fluentui/react-components';
import React from 'react';
import flyghtcloud_logo from "../../../../assets/icons/landing/logo_green.svg";
import useUTMParams from '../../../../hooks/useUTMParams';
import { LeadRequest, ZohoService } from '../../../../services/openapi';
import { isValidEmail } from '../../../../utils/validators';
import { FASQsData } from '../../common/FaqsData';
import Footer from '../../common/Footer';
import HeaderNavBar from '../../common/HeaderNavBar';
import FaqCategoryList from './FaqCategoryList';
import useIsMobile from '../../../hooks/useIsMobile';
import MobileFaqs from './MobileFaqs';
import FaqForm from './FaqForm';

interface Faq {
    question: string;
    answer: string;
}
interface FaqCategory {
    id: number;
    type: string;
    faqs: Faq[];
}

function FAQs() {
    const classes = useStyles();
    const isMobile = useIsMobile();
    const [faqsDatas, setFaqsDatasProps] = React.useState<FaqCategory[] | null>(null);
    const [data, setData] = React.useState({ question: '', email: '', name: '' });
    const [isformSubmitted, setisFormSubmitted] = React.useState<boolean>(false);
    const [isValidMail, setIsValidMail] = React.useState(false);
    const utmParams = useUTMParams();

    React.useEffect(() => {
        setFaqsDatasProps(FASQsData);
    }, []);

    const createLead = (formValues: any) => {
        const data: LeadRequest = {
            data: [
                {
                    "Lead_Source": "FlyghtCloud Form",
                    "Last_Name": formValues.name,
                    "Email": formValues.email,
                    Description: formValues.question,
                    "Form_Page_Url": "https://flyghtcloud.ideaforgetech.com/faqs",
                    "Form_Name": "FAQ - Flyght Cloud",
                    ...utmParams,
                }
            ]
        }

        ZohoService.addLeadToZohoCrm(data).then(() => {
            console.log("Lead created successfully")
        }).catch(err => console.error("Error creating lead", err))
    }

    const onSubmit = (formValues: any) => {
        const hasValidEmail = isValidEmail(data.email)
        setIsValidMail(!hasValidEmail);
        if (hasValidEmail) {
            setisFormSubmitted(true);
            setData({ ...data, question: "", email: "", name: "" });
            createLead(formValues)
        }
    }

    return isMobile ? <MobileFaqs /> : <> <div className={classes.headerNavBar}>
        <HeaderNavBar icon={flyghtcloud_logo} color='black' signInBorder={true} btnBg="#E5E8EB" />
    </div>
        <div style={{ height: "calc(99vh - 3rem)", background: "white", overflow: "auto" }}>
            <h2 className={classes.primaryHeading} style={{ background: "white", height: "8vh", zIndex: 9999, position: "sticky", top: "0" }} >Frequently Asked Questions</h2>
            <div className={classes.faqContainer} style={{ margin: "0 auto", position: "relative" }} >
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    <div className={classes.quesContainer}>
                        {faqsDatas && (
                            <div >
                                {faqsDatas.map((data, index) => (
                                    <div key={index} >
                                        <FaqCategoryList key={index} faqsData={data} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className={classes.formContainer} style={{
                        width: "30%",
                        position: "fixed",
                        left: "60%",
                        transform: "(translate(-50%, -50%) !important",

                    }}>
                        <h3 className={classes.cardHeading}>Can't find what you're looking for?</h3>
                        <p className={classes.info}>Don't worry, you can submit your question and we'll get back to  you with an answer.</p>
                        <FaqForm
                            data={data}
                            isValidMail={isValidMail}
                            onSubmit={onSubmit}
                            setData={setData}
                            setisFormSubmitted={setisFormSubmitted}
                            setIsValidMail={setIsValidMail}
                        />
                        {
                            isformSubmitted ? <div style={{ fontSize: "1vw" }}> <h3 className={classes.cardHeading} style={{ marginTop: "1em" }}>Thank you for reaching out!</h3>
                                <p>We have received your question and will contact you at the provided email address with a response.</p>
                            </div> : null
                        }
                    </div>
                    <div style={{ width: "100%", marginTop: "2em", marginBottom: "1em", textAlign: "center" }}>
                        <Footer />
                    </div>
                </div>

            </div >
        </div >
    </>
}

const useStyles = makeStyles({
    headerNavBar: {
        position: "sticky",
        top: 0,
        width: "100%",
        backgroundColor: "white",
        zIndex: 999

    },
    faqContainer: {
        maxWidth: "92%",
    },
    primaryHeading: {
        fontSize: "1.5rem",
        fontWeight: 600,
        paddingLeft: "2.5em",
        display: "flex",
        alignItems: "center",
    },
    quesContainer: {
        maxWidth: "60%",
        width: "55%",
    },
    formContainer: {
        maxWidth: "50%",
        width: "42%"
    },
    cardHeading: {
        fontSize: "1.2rem",
        fontWeight: 600,
    },
    info: {
        maxWidth: "65%",
        marginTop: "1em"
    },
    quesText: {
        marginTop: "2rem",
        marginBottom: ".5rem",
        fontSize: "1rem !important",
        fontWeight: 600,
    },
    mailText: {
        marginBottom: ".5rem",
        fontSize: "1rem !important",
        fontWeight: 600,
    },
    cmpTxt: {
        color: "#586A84"
    }
})
export default FAQs