import * as React from 'react'
import { Button, Input, makeStyles, Field } from '@fluentui/react-components'
import BrandName from '../../../common/BrandName';
import "./styles.css"
interface BecomeProviderForm {
    fullName: string, companyName: string, email: string, phoneNumber: string
}
interface FormProps {
    onSubmit: (f: BecomeProviderForm) => void;
}
function BecomeaProviderForm(props: FormProps) {
    const classes = useStyles();
    const [isformSubmitted, setisFormSubmitted] = React.useState<boolean>(false);
    const [isValidMail, setIsValidMail] = React.useState(false);
    const [data, setData] = React.useState({ fullName: '', companyName: '', email: "", phoneNumber: "" });

    const handleSubmit = () => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsValidMail(!(emailPattern.test(data.email)));
        if (emailPattern.test(data.email)) {
            props.onSubmit(data);
            setisFormSubmitted(true);
            setData({ ...data, fullName: '', companyName: '', email: "", phoneNumber: "" });
        }
    }
    return <>
        <form className='form-Container'
            onSubmit={(e) => { e.preventDefault() }}
        >
            <div>
                <div className={classes.inputContainer}>
                    <h3 className="mailText">Full Name *</h3>
                    <Input
                        className="input"
                        appearance="outline"
                        size="large"
                        placeholder="Full Name *"
                        value={data.fullName}
                        onChange={(e) => {
                            setData({ ...data, fullName: e.target.value });
                            setisFormSubmitted(false);
                        }}
                    />
                </div>
                <div className={classes.inputContainer}>
                    <h3 className="mailText">Company Name *</h3>
                    <Input
                        className="input"

                        appearance="outline"
                        size="large"
                        placeholder="Company Name *"
                        value={data.companyName}
                        onChange={(e) => {
                            setData({ ...data, companyName: e.target.value });
                            setisFormSubmitted(false);
                        }}
                    />
                </div>
                <div className={classes.inputContainer}>
                    <h3 className="mailText">Email address *</h3>
                    <Field validationMessageIcon={null} validationMessage={isValidMail ? "Incorrect email address" : null}>
                        <Input
                            style={{
                                width: '100%',
                                border: isValidMail ? "1px solid red" : "1px solid #D1DBE8",
                                backgroundColor: "#F7FAFC"
                            }}
                            className="input"
                            appearance="outline"
                            size="large"
                            placeholder="Email Address *"
                            value={data.email}
                            onChange={(e) => {
                                setIsValidMail(false)
                                setisFormSubmitted(false)
                                setData({ ...data, email: e.target.value })
                            }}
                        />
                    </Field>
                </div>
                <div className={classes.inputContainer}>
                    <h3 className="mailText">Phone Number</h3>
                    <input
                        className="custome-input"
                        placeholder="Phone Number *"
                        type="tel"
                        value={data.phoneNumber}
                        onChange={(e) => {
                            setData({ ...data, phoneNumber: e.target.value });
                            setisFormSubmitted(false);
                        }} />
                </div>

                <div style={{ marginTop: "2em" }}>
                    <Button
                        style={{ width: '100%', background: "#617AFA", color: "white", padding: "8px 16px", borderRadius: "8px" }}
                        appearance="primary"
                        onClick={handleSubmit}
                        disabled={!data.fullName || !data.companyName || !data.email}
                        type="submit"
                    >
                        Submit
                    </Button>
                </div>
                {
                    isformSubmitted ? <div className={classes.inputContainer}>
                        <h2 className="mailText">Thank you for your interest in becoming a provider with <BrandName />!</h2>
                        <p className="mailTextHide" style={{ marginTop: "1em" }}>We have received your details and will contact you at the provided email address with more details.</p>
                    </div> : null
                }
            </div>
        </form>
    </>
}
const useStyles = makeStyles({
    quesText: {
        marginTop: "2rem",
        marginBottom: ".5rem",
        fontSize: "1rem !important",
        fontWeight: 600,
    },
    
    inputContainer: {
        marginTop: "1.5em"
    },
   
})

export default BecomeaProviderForm