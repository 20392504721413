import * as React from 'react';
import { WorkflowResponse, WorkflowsService } from '../services/openapi';
import { Loading } from './Loading';
import { FlightWorkflowsTable } from './FlightWorkflowsTable';

interface Props {
    flightId: string;
}

export const FlightWorkflows = ({ flightId }: Props) => {
    const [workflows, setWorkflows] = React.useState<WorkflowResponse[] | null>();
    const [error, setError] = React.useState('');

    React.useEffect(() => {
        WorkflowsService.getWorkflowsForFlight(flightId)
            .then((ws) => setWorkflows(ws.workflows))
            .catch((err) => setError(`Could not get workflows: ${err}`));
    }, [flightId]);

    if (error) {
        return <div>{error}</div>
    }

    if (!workflows) {
        return <Loading />
    }
    return <>
        <FlightWorkflowsTable workflows={workflows} />
    </>
}
