const workflowData: Record<string, string> = {
  METASHAPE_BFT_OUTPUT: 'BFT COMPATIBLE OUTPUTS',
  METASHAPE_MAPPING: 'Mapping With Metashape',
  UPLOAD_RGBA_MAP: 'Upload Orthomosaic',
  UPLOAD_1C_MAP: 'Digital elevation model upload Model',
  DEFAULT_MAPPING: 'Mapping With OpenDroneMap',
  STOCKPILE_REPORT: 'Stockpile Report Creation for Existing Maps',
  ODM_MAP_STOCKPILE_REPORT: 'Stockpile Report Creation For New Maps',
  VECTOR_EXTRACTION: 'Draw or Upload Vectors',
  METASHAPE_MAPPING_W_GCPS:
    'Mapping With Metashape Using Ground Control Points',
};

const stepsData: Record<string, any> = {
  dataset: {
    name: 'Dataset Preparation',
    states: {
      pending: 'Dataset preparation in progress',
      completed: 'Dataset preparation completed',
      failed: 'Dataset preparation failed',
      default: 'Dataset preparation pending',
    },
  },
  metashape: {
    name: 'Maps Processing',
    states: {
      pending: 'Maps processing in progress',
      completed: 'Maps processing completed',
      failed: 'Maps processing failed',
      default: 'Maps processing pending',
    },
  },
  odm: {
    name: 'Maps Processing',
    states: {
      pending: 'Maps processing in progress',
      completed: 'Maps processing completed',
      failed: 'Maps processing failed',
      default: 'Maps processing pending',
    },
  },
  convert: {
    name: 'BFT Compatible Transformation',
    states: {
      pending: 'BFT compatible maps transformation in progress',
      completed: 'BFT compatible maps transformation completed',
      failed: 'BFT compatible maps transformation failed',
      default: 'BFT compatible maps transformation pending',
    },
  },
  tile_4c: {
    name: 'Preparing for orthomosaic display',
    states: {
      pending: 'Preparing for orthomosaic display in progress',
      completed: 'Preparing for orthomosaic display completed',
      failed: 'Preparing for orthomosaic display failed',
      default: 'Preparing for orthomosaic display pending',
    },
  },
  tile_1c: {
    name: 'Preparing for digital elevation model display',
    states: {
      pending: 'Preparing for digital elevation model display in progress',
      completed: 'Preparing for digital elevation model display completed',
      failed: 'Preparing for digital elevation model display failed',
      default: 'Preparing for digital elevation model display pending',
    },
  },
  tile_point_cloud: {
    name: 'Preparing for point cloud display',
    states: {
      pending: 'Preparing for point cloud display in progress',
      completed: 'Preparing for point cloud display completed',
      failed: 'Preparing for point cloud display failed',
      default: 'Preparing for point cloud display pending',
    },
  },
  upload_workflow_file: {
    name: 'Upload workflow file',
    states: {
      pending: 'Upload workflow file in progress',
      completed: 'Upload workflow file completed',
      failed: 'Upload workflow file failed',
      default: 'Upload workflow file pending',
    },
  },
  upload_workflow_file_dem: {
    name: 'Digital elevation model upload model',
    states: {
      pending: 'Digital elevation model upload in progress',
      completed: 'Digital elevation model upload completed',
      failed: 'Digital elevation model upload failed',
      default: 'Digital elevation model upload pending',
    },
  },
  upload_workflow_file_mosaic: {
    name: 'Upload orthomosaic',
    states: {
      pending: 'Orthomosaic upload in progress',
      completed: 'Orthomosaic upload completed',
      failed: 'Orthomosaic upload failed',
      default: 'Orthomosaic upload pending',
    },
  },
  //   pick_old_dems: {
  //     name: 'Upload orthomosaic',
  //     states: {
  //       pending: 'Orthomosaic upload in progress',
  //       completed: 'Orthomosaic upload completed',
  //       failed: 'Orthomosaic upload failed',
  //       default: 'Orthomosaic upload failed',
  //     },
  //   },
  pick_boundaries: {
    name: 'Volume calculations as per boundaries',
    states: {
      pending: 'Volume calculations for uploaded outputs in progress',
      completed: 'Volume calculations for uploaded outputs completed',
      failed: 'Volume calculations for uploaded outputs failed',
      default: 'Volume calculations for uploaded outputs pending',
    },
  },
  generate_stockpile_report: {
    name: 'Stockpile report creation',
    states: {
      pending: 'Stockpile report creation in progress',
      completed: 'Stockpile report creation completed',
      failed: 'Stockpile report creation failed',
      default: 'Stockpile report creation pending',
    },
  },
  edit_geojson: {
    name: 'Draw a vector on existing map or upload an existing vector file',
    states: {
      pending: 'Vector data upload in progress',
      completed: 'Vector data upload completed',
      failed: 'Vector data upload failed',
      default: 'Vector data upload pending',
    },
  },
  tile_geojson: {
    name: 'Preparing for vector data display',
    states: {
      pending: 'Preparing for vector data display in progress',
      completed: 'Preparing for vector data display completed',
      failed: 'Preparing for vector data display failed',
      default: 'Preparing for vector data display pending',
    },
  },
  gcp_tagging: {
    name: 'GCP Marking',
    states: {
      pending:
        'Mark ground control points in the images. Be sure to annotate each marker in a minimum of four images. Increased projections yield improved accuracy levels.',
      completed: 'GCP Marking completed',
      failed: 'GCP marking failed',
      default:
        'Mark ground control points in the images. Be sure to annotate each marker in a minimum of four images. Increased projections yield improved accuracy levels.',
    },
  },
};

export { stepsData, workflowData };
