export const Filter = () => {
    return <div style={{ marginLeft: '13em', width: '1.5em', height: '1.5em', marginTop: '0.5em', backgroundColor: "#00000000" }}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_1611_3471" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_1611_3471)">
                <path d="M14.4442 11.6192L13.375 10.55L16.95 5.99997H8.825L7.32502 4.5H18.4904C18.8045
                                 4.5 19.0323 4.63846 19.174 4.91537C19.3156 5.19229 19.2903 5.45896 19.098 5.71537L14.4442
                                  11.6192ZM13.5 15.623V18.6153C13.5 18.8666 13.4154 19.0769 13.2461 19.2461C13.0769 19.4153
                                  12.8666 19.4999 12.6153 19.4999H11.3846C11.1334 19.4999 10.9231 19.4153 10.7539 19.2461C10.5846
                                  19.0769 10.5 18.8666 10.5 18.6153V12.623L2.60002 4.72302C2.46157 4.58457 2.39074 4.41311 2.38752
                                   4.20862C2.38432 4.00414 2.45516 3.82434 2.60002 3.66922C2.75516 3.51409 2.93337 3.43652 3.13465
                                    3.43652C3.33592 3.43652 3.51412 3.51409 3.66925 3.66922L20.3462 20.3461C20.4949 20.4948 20.5683
                                     20.6689 20.5663 20.8682C20.5644 21.0676 20.4859 21.2448 20.3307 21.4C20.1756 21.5448 20 21.6198
                                      19.8039 21.625C19.6077 21.6301 19.4321 21.5551 19.277 21.4L13.5 15.623Z" fill="#8E8E8E" />
            </g>
        </svg>
    </div>
}