import * as React from 'react'
import { Pagination } from '../projects/common/Pagination'
import { InsightsService, WorkflowSummaryResponse } from '../../services/openapi';
import { Button, createTableColumn, Dropdown, Field, Input, makeStyles, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, Option, shorthands, Table, TableBody, TableCell, TableColumnDefinition, TableHeaderCell, TableRow, useTableFeatures } from '@fluentui/react-components';
import moment from 'moment';

interface FormDataTypes {
    status: string | null;
    orgId: string | null;
    projectId: string | null;
    workflowId: string | null;
}


function WorkflowTable() {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [hasNext, setHasNext] = React.useState(false);
    const [workflowData, setWorkflowData] = React.useState<WorkflowSummaryResponse[] | []>();
    const [formData, setFormData] = React.useState<FormDataTypes>({
        orgId: null,
        projectId: null,
        status: null,
        workflowId: null
    });
    const [selectedStatus, setSelectedStatus] = React.useState<string | null>(null);
    const [filtersApplied, setFiltersApplied] = React.useState<boolean>(false);

    const prev = () => {
        setPage(page - 1);
        load(formData.status, formData.orgId, formData.projectId, formData.workflowId, page - 1);
    };

    const next = () => {
        setPage(page + 1);
        load(formData.status, formData.orgId, formData.projectId, formData.workflowId, page + 1);
    };

    const load = (status: string | null, orgId: string | null, projectId: string | null, workflowId: string | null, page: number) => {
        InsightsService.getWorkflows(status, orgId, projectId, workflowId, page, 10)
            .then((res) => {
                setWorkflowData(res)
                setHasNext(res.length >= 10);
            })
            .catch((err) => {
                console.table(err)
            });
    };

    React.useEffect(() => {
        load(formData.status, formData.orgId, formData.projectId, formData.workflowId, page);
    }, [page]);

    type Item = {
        flight_name: { label: string };
        project_name: { label: string };
        org_name: { label: string };
        workflow: { label: string };
        workflow_id: { label: string };
        status: { label: string };
        started_at: { label: string };
        end_at: { label: string };
        latest_task_info: { topic: string; status: string };
    };
    const columns: TableColumnDefinition<Item>[] = [
        createTableColumn<Item>({ columnId: "flight_name" }),
        createTableColumn<Item>({ columnId: "org_name" }),
        createTableColumn<Item>({ columnId: "workflow" }),
        createTableColumn<Item>({ columnId: "status" }),
        createTableColumn<Item>({ columnId: "started_at" }),
        createTableColumn<Item>({ columnId: "end_at" }),
        createTableColumn<Item>({ columnId: "project_name" }),
        createTableColumn<Item>({ columnId: "latest_task_info" }),
        createTableColumn<Item>({ columnId: "workflow_id" })

    ];

    const items: Item[] = workflowData?.map((item) => ({
        project_name: { label: item.project_name },
        flight_name: { label: item.flight_name },
        org_name: { label: item.org_name },
        workflow: { label: item?.workflow || "" },
        status: { label: item.status },
        started_at: { label: item.started_at || "" },
        workflow_id: { label: item.workflow_id || "" },
        end_at: { label: item.end_at || "" },
        latest_task_info: {
            topic: item.latest_task_info?.topic || "-",
            status: item.latest_task_info?.status || "-"
        }

    })) || [];

    const { getRows } = useTableFeatures({
        columns,
        items,
    });
    const rows = getRows();

    const clearFilter = () => {
        setFormData({
            ...formData, orgId: null,
            projectId: null,
            status: null,
            workflowId: null
        })
        setSelectedStatus(null);
        load(null, null, null, null, page);
        setFiltersApplied(false);
    }
    const handleSubmit = (e: React.FormEvent) => {
        setFiltersApplied(true);
        const orgId = formData.orgId?.trim() === '' ? null : formData.orgId;
        const projectId = formData.projectId?.trim() === '' ? null : formData.projectId;
        const workflowId = formData.workflowId?.trim() === '' ? null : formData.workflowId;
        load(formData.status, orgId, projectId, workflowId, page);
    }

    const handleStatusSelect = (e: any, selectedData: any) => {
        const status = selectedData.optionText ?? 'Status';
        setFormData({ ...formData, status });
        setSelectedStatus(status);
    };

    return (
        <div>
            <div className={classes.cardHeaderButtonsContainer}>
                <form
                    onSubmit={(e) => { e.preventDefault() }}
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <div className={classes.inputContainer}>
                        <Field>
                            <Input
                                appearance="outline"
                                size="large"
                                placeholder="Org Id"
                                value={formData.orgId || ''}
                                onChange={(e) => {
                                    setFormData({ ...formData, orgId: e.target.value })
                                }}
                            />
                        </Field>
                    </div>
                    <div className={classes.inputContainer}>
                        <Field>
                            <Input
                                appearance="outline"
                                size="large"
                                placeholder="Project Id"
                                value={formData.projectId || ''}
                                onChange={(e) => {
                                    setFormData({ ...formData, projectId: e.target.value })
                                }}
                            />
                        </Field>
                    </div>
                    <div className={classes.inputContainer}>
                        <Field>
                            <Input
                                appearance="outline"
                                size="large"
                                placeholder="Workflow Id"
                                value={formData.workflowId || ''}
                                onChange={(e) => {
                                    setFormData({ ...formData, workflowId: e.target.value })
                                }}
                            />
                        </Field>
                    </div>
                    <Dropdown
                        className={classes.dropDown}
                        placeholder="Status"
                        onOptionSelect={handleStatusSelect}
                        defaultValue={selectedStatus || ''}

                    >
                        <Option value='started'>started</Option>
                        <Option value='failed'>failed</Option>
                        <Option value='completed'>completed</Option>
                    </Dropdown>                    <div>
                        <Button style={{
                            marginLeft: "1em",
                        }}
                            disabled={!formData.orgId && !formData.projectId && !formData.workflowId && !formData.status}
                            onClick={handleSubmit}
                            type="submit"
                        >
                            Apply Filter
                        </Button>
                    </div>
                    <div>
                        {filtersApplied && (
                            <div>
                                <Button
                                    style={{ marginLeft: "1em" }}
                                    onClick={clearFilter}
                                >
                                    Clear filter
                                </Button>
                            </div>
                        )}
                    </div>
                </form>
            </div >
            <div style={{ overflowX: "auto", width: "80vw" }}>
                <Table>
                    <TableRow>
                        <TableHeaderCell className={classes.customTh}>Project Name</TableHeaderCell>
                        <TableHeaderCell className={classes.customTh}>Flight Name</TableHeaderCell>
                        <TableHeaderCell className={classes.customTh}>Organization Name</TableHeaderCell>
                        <TableHeaderCell className={classes.customTh}>Workflow</TableHeaderCell>
                        <TableHeaderCell style={{ minWidth: "300px", width: "300px" }}>Workflow Id</TableHeaderCell>
                        <TableHeaderCell className={classes.customTh}>Status</TableHeaderCell>
                        <TableHeaderCell className={classes.customTh} style={{ minWidth: "300px", width: "300px" }}>Latest Task Topic</TableHeaderCell>
                        <TableHeaderCell className={classes.customTh}>Latest Task Status</TableHeaderCell>
                        <TableHeaderCell className={classes.customTh}>Start Date</TableHeaderCell>
                        <TableHeaderCell className={classes.customTh}>End Date</TableHeaderCell>
                    </TableRow>
                    <TableBody>
                        {
                            rows.map(({ item }) => (
                                <TableRow>
                                    <TableCell className={classes.customTh}>{item.project_name.label}</TableCell>
                                    <TableCell className={classes.customTh}>{item.flight_name.label}</TableCell>
                                    <TableCell className={classes.customTh}>{item.org_name.label}</TableCell>
                                    <TableCell className={classes.customTh}>{item.workflow.label}</TableCell>
                                    <TableCell style={{ minWidth: "300px", width: "300px" }}>{item.workflow_id.label}</TableCell>
                                    <TableCell className={classes.customTh}>{item.status.label}</TableCell>
                                    <TableCell className={classes.customTh} style={{ minWidth: "300px", width: "300px" }}>{item.latest_task_info.topic}</TableCell>
                                    <TableCell className={classes.customTh}>{item.latest_task_info.status}</TableCell>
                                    <TableCell className={classes.customTh}>{item.started_at.label ? moment(item.started_at.label).format('DD/MM/YYYY, h:mm:ss') : "-"}</TableCell>
                                    <TableCell className={classes.customTh}>{item.end_at.label ? moment(item.end_at.label).format('DD/MM/YYYY, h:mm:ss') : "-"}</TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </div>
            <Pagination currentPage={page + 1} hasNext={hasNext} hasPrev={page > 0} onClickPrev={prev} onClickNext={next} />

        </div >
    )
}

export default WorkflowTable

const useStyles = makeStyles({
    cardHeaderButtonsContainer: {
        display: "flex",
        ...shorthands.gap("1rem"),
        alignItems: "center",
        justifyContent: "end",
    },
    roundedButton: {
        ...shorthands.outline("none"),
        ...shorthands.border("none"),
        backgroundColor: "transparent",
        color: "#5E5CE6",
        fontSize: "18px",
        cursor: "pointer",
        paddingTop: "5px",
        paddingBottom: "5px",
        paddingLeft: "20px",
        paddingRight: "20px",
        ...shorthands.borderRadius("1rem"),
        ":hover": {
            backgroundColor: "#D8D8FF",
            ...shorthands.padding("5px 20px"),
        }
    },
    iconImage: {
        minWidth: "18px",
        minHeight: "18px",
    },
    filterButton: {
        ...shorthands.border("1px solid #7D8FA9"),
        ...shorthands.padding("6px 12px"),
        ":hover": {
            backgroundColor: "#E5E5FE",
        }
    },
    filterButtonText: {
        fontSize: "14px",
        color: "#7D8FA9",
        fontWeight: 400,
    },
    menuText: {
        fontSize: "14px",
    },
    inputContainer: {
        marginLeft: "1em",
        padding: 0,
        '& .f13qh94s': {
            display: "block"
        },
        '& .f1w5jphr': {
            minHeight: "30px"
        }
    },
    dropDown: {
        ...shorthands.border('1px', 'solid', '#D1DBE8'),
        minWidth: "100px",
        marginLeft: "1em",
        '& .f1k6fduh': {
            fontSize: "16px",
        }
    },
    customTh: {
        minWidth: "180px", width: "180px"
    },
})