import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, makeStyles, shorthands } from "@fluentui/react-components";
import { Dismiss20Regular } from "@fluentui/react-icons";

const data = [
    { name: 'GCP8', x: 73.04159384, y: 19.11125875, z: -29.69694761 },
    { name: 'GCP9', x: 73.04244503, y: 19.10974517, z: -28.54308622 },
    { name: 'GCP10', x: 73.04276335, y: 19.10948881, z: -26.7711124 },
    { name: 'GCP12', x: 73.03974588, y: 19.10916544, z: -33.97516478 },
    { name: 'GCP17', x: 73.03987236, y: 19.11032493, z: -31.62348216 },
    { name: 'GCP14', x: 73.04068928, y: 19.10922576, z: -32.26626169 },
    { name: 'GCP11', x: 73.04095989, y: 19.1090932, z: -34.79593818 },
    { name: 'GCP13', x: 73.04028157, y: 19.10915048, z: -33.15221039 }
];

interface Props {
    visible: boolean;
    setIsVisible: any;
}
export default function SampleCSVModal(props: Props) {
    const { visible, setIsVisible } = props
    const classes = useStyles()

    return <Dialog
        open={visible}
        onOpenChange={(_, d) => { setIsVisible(d.open); }}
        modalType='non-modal'
    >
        <DialogSurface >
            <DialogBody style={{ gap: "16px" }}>
                <DialogTitle
                    className={classes.header}
                    action={
                        <DialogTrigger action="close">
                            <Button
                                appearance="subtle"
                                aria-label="close"
                                icon={<Dismiss20Regular />}
                            />
                        </DialogTrigger>
                    }>Sample CSV</DialogTitle>
                <DialogContent >
                    <table border={1} className={classes.table}>
                        <thead>
                            <tr>
                                <th className={classes.tableHeader}>name</th>
                                <th className={classes.tableHeader}>x</th>
                                <th className={classes.tableHeader}>y</th>
                                <th className={classes.tableHeader}>z</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => (
                                <tr key={index}>
                                    <td className={classes.tableCell}>{item.name}</td>
                                    <td className={classes.tableCell}>{item.x}</td>
                                    <td className={classes.tableCell}>{item.y}</td>
                                    <td className={classes.tableCell}>{item.z}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </DialogContent>

                <DialogActions className={classes.actionsContainer}>
                    <DialogTrigger disableButtonEnhancement>
                        <Button appearance="secondary" className={classes.closeButton}>Close</Button>
                    </DialogTrigger>
                </DialogActions>
            </DialogBody>
        </DialogSurface>
    </Dialog >
}

const useStyles = makeStyles({
    table: {
        width: "100%",
        fontSize: "16px",
    },
    tableHeader: {
        ...shorthands.padding("4px")
    },
    tableCell: {
        ...shorthands.padding("4px")
    },
    header: {
        color: "#586A84",
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "28px"
    },
    actionsContainer: {
        display: "flex",
        ...shorthands.gap("16px"),
        marginTop: "1rem",
    },
    closeButton: {
        display: "flex",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "24px",
        paddingRight: "24px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        ...shorthands.borderRadius("8px"),
        ...shorthands.border("1px", "solid", "#79747E"),
        color: "#79747E",
        textAlign: "center",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0.1px"
    },
})