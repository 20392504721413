/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AreaRequest } from '../models/AreaRequest';
import type { DistanceRequest } from '../models/DistanceRequest';
import type { EditMeasurementRequest } from '../models/EditMeasurementRequest';
import type { ElevationRequest } from '../models/ElevationRequest';
import type { MeasurementResponse } from '../models/MeasurementResponse';
import type { MeasurementsResponse } from '../models/MeasurementsResponse';
import type { VolumeRequest } from '../models/VolumeRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MeasurementsService {

    /**
     * @param flightId
     * @returns MeasurementsResponse List measurements for a flight
     * @throws ApiError
     */
    public static listMeasurements(
        flightId: string,
    ): CancelablePromise<MeasurementsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/flights/{flight_id}/measurements',
            path: {
                'flight_id': flightId,
            },
        });
    }

    /**
     * @param layerId
     * @param requestBody
     * @param shareId
     * @returns MeasurementResponse Get area of a polygon
     * @throws ApiError
     */
    public static getArea(
        layerId: string,
        requestBody: AreaRequest,
        shareId?: string | null,
    ): CancelablePromise<MeasurementResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/map_layers/{layer_id}/measurements/area',
            path: {
                'layer_id': layerId,
            },
            query: {
                'share_id': shareId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param layerId
     * @returns MeasurementResponse Creates measurement object
     * @throws ApiError
     */
    public static createDistanceObject(
        layerId: string,
    ): CancelablePromise<MeasurementResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/map_layers/{layer_id}/measurements/create_distance',
            path: {
                'layer_id': layerId,
            },
        });
    }

    /**
     * @param layerId
     * @param requestBody
     * @param shareId
     * @returns MeasurementResponse Calculate distance between two points and updates metadata
     * @throws ApiError
     */
    public static getDistance(
        layerId: string,
        requestBody: DistanceRequest,
        shareId?: string | null,
    ): CancelablePromise<MeasurementResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/map_layers/{layer_id}/measurements/distance',
            path: {
                'layer_id': layerId,
            },
            query: {
                'share_id': shareId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param layerId
     * @param requestBody
     * @param shareId
     * @returns MeasurementResponse Get elevation at a point on the DEM
     * @throws ApiError
     */
    public static getElevation(
        layerId: string,
        requestBody: ElevationRequest,
        shareId?: string | null,
    ): CancelablePromise<MeasurementResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/map_layers/{layer_id}/measurements/elevation',
            path: {
                'layer_id': layerId,
            },
            query: {
                'share_id': shareId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param layerId
     * @param requestBody
     * @param shareId
     * @returns MeasurementResponse Get best-fit cut-fill volume within a polygon
     * @throws ApiError
     */
    public static getVolume(
        layerId: string,
        requestBody: VolumeRequest,
        shareId?: string | null,
    ): CancelablePromise<MeasurementResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/map_layers/{layer_id}/measurements/volume',
            path: {
                'layer_id': layerId,
            },
            query: {
                'share_id': shareId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param measurementId
     * @returns MeasurementResponse Delete measurement
     * @throws ApiError
     */
    public static deleteMeasurement(
        measurementId: string,
    ): CancelablePromise<MeasurementResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/measurements/{measurement_id}',
            path: {
                'measurement_id': measurementId,
            },
        });
    }

    /**
     * @param measurementId
     * @param requestBody
     * @returns MeasurementResponse Update measurement
     * @throws ApiError
     */
    public static editMeasurement(
        measurementId: string,
        requestBody: EditMeasurementRequest,
    ): CancelablePromise<MeasurementResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/measurements/{measurement_id}',
            path: {
                'measurement_id': measurementId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
