import * as React from 'react'
import { Card, makeStyles, shorthands, mergeClasses } from '@fluentui/react-components';
import rightArrow_icon from "../../../../assets/icons/landing/rightArrow.svg"

interface CardProps {
    id: number,
    title: string,
    info: string,
}
function BecomeaProviderCard({ cardData }: { cardData: CardProps }) {
    const styles = useStyles();
    return <Card
        className={mergeClasses(styles.card)}
    >
        <img src={rightArrow_icon} style={{ width: "6%" }} alt='rightArrow_icon' />
        <h3 className={styles.cardHeading}>{cardData.title}</h3>
        <p className={styles.cardInfo}>{cardData.info}</p>
    </Card>
}

const useStyles = makeStyles({
    card: {
        width: "100%",
        maxWidth: "100%",
        height: '100%',
        ...shorthands.border('1px', 'solid', '#D1DEE5'),
        ...shorthands.borderRadius('8px'),
        ...shorthands.padding("16px"),
        boxShadow: "none",
        backgroundColor: "#F7FAFC"
    },
    text: {
        ...shorthands.margin(0),
    },
    cardImg: {
        width: "20px"
    },
    cardHeading: {
        fontSize: "1rem",
        fontWeight: 600,
    },
    cardInfo: {
        marginTop: "0",
        fontSize: ".7rem",
        color: "#4A789C"
    }
})

export default BecomeaProviderCard