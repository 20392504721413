export const FASQsData = [
    {
        id: 1,
        type: "General Information",
        faqs: [
            {
                question: "What is Flyght Cloud?",
                answer: "Flyght Cloud is a cutting-edge platform that revolutionizes drone data analytics, offering tailored workflows for industries like construction, mining, agriculture, and more."
            }, {
                question: "How does Flyght Cloud work?",
                answer: "Flyght Cloud seamlessly processes drone-captured data, generating actionable insights through AI-driven analytics, customizable workflows, and intuitive visualization tools."
            },
            {
                question: "How is Flyght Cloud different from other drone data analytics platforms?",
                answer: "Flyght Cloud offers tailored workflows, AI-driven insights, a user-friendly interface, and a collaborative environment, setting it apart from other drone data analytics platforms."
            }
        ],
    }, {
        id: 2,
        type: "Account Management",
        faqs: [
            {
                question: "How do I create a Flyght Cloud account?",
                answer: "To create a Flyght Cloud account, simply visit our website and click the 'Sign Up' button in the top right corner. You'll be prompted to enter your email address, choose a password, and provide some basic information about your organization. Once you've completed the  signup process, you'll receive a verification email to activate your account. If you have any questions or need assistance, our support team is here to help!"
            },
            {
                question: "How do I reset my Flyght Cloud password?",
                answer: "To reset your Flyght Cloud password, navigate to the profile section, select 'Edit Profile',  and follow the prompts to reset it securely."
            }, {
                question: "How can I manage my account settings?",
                answer: "Navigate to your profile settings from the top right corner to update your personal information, change preferences, or modify setting."
            }
        ]
    },
    {
        id: 3,
        type: "Workflow Creation",
        faqs: [
            {
                question: "What is a Workflow?",
                answer: "A workflow in Flyght Cloud is a predefined sequence of tasks designed to automate specific drone data analysis processes. It streamlines data processing, from input to output, optimizing efficiency and accuracy. Users can select, customize, and deploy workflows tailored to their unique project requirements, enhancing their data analytics capabilities effortlessly."
            }, {
                question: "What are the available workflow templates in Flyght Cloud?",
                answer: "Flyght Cloud offers a diverse range of workflow templates tailored to various industries and data analysis needs. From stockpile monitoring to vegetation mapping, users can choose from a comprehensive selection of pre-designed workflows to streamline their data processing tasks efficiently. Schedule a demo today to discover workflows tailored to your needs."
            }, {
                question: "Can I customize the parameters of a workflow in Flyght Cloud?",
                answer: "Certainly! In Flyght Cloud, users have the flexibility to customize workflow parameters to align with their specific needs and preferences. Through our intuitive interface, users can tailor various aspects of the workflow, such as input data specifications, processing methods, and output formats. This customization empowers users to optimize their data analysis processes, ensuring that the insights generated meet their exact standards and objectives. With Flyght Cloud's robust customization capabilities, users can adapt workflows seamlessly to diverse project requirements and achieve superior outcomes in drone data analytics."
            }, {
                question: "Can I create custom workflows?",
                answer: "Yes, Flyght Cloud offers a user-friendly interface for creating custom workflows tailored to your specific project requirements."
            }, {
                question: "What are default workflows?",
                answer: "Default workflows are pre-built templates designed by industry experts, offering a quick start for common data analysis tasks."
            },
        ]
    }, {
        id: 4,
        type: "Data Processing",
        faqs: [
            {
                question: "What file formats does Flyght Cloud support for data processing?",
                answer: "Flyght Cloud supports a wide range of file formats for drone data processing, including: - Image formats: JPEG, PNG, TIFF, GeoTIFF, GeoJSON etc. - Point cloud formats: LAS, LAZ, XYZ, etc. - 3D model formats: OBJ, FBX, PLY, etc. If you have data in a format that's not listed here, please contact our support team, and we'll be happy to assist you with your specific requirements."
            }, {
                question: "How long does it take to process drone data in Flyght Cloud?",
                answer: "Processing times vary based on the complexity of the analysis and the volume of data. Our platform ensures efficient processing to deliver timely results. Reach out to our support team for a personalised demo using your sample data set, and discover the advantages of Flyght Cloud firsthand."
            }, {
                question: "Can I monitor the progress of my data processing jobs in Flyght Cloud?",
                answer: "Yes, you can monitor the progress of your data processing jobs in Flyght Cloud. Our platform provides real-time updates and notifications, allowing you to track the status of your jobs as they progress through various stages of processing. This feature ensures transparency and enables you to stay informed about the status of your data processing tasks at all times, enhancing efficiency and productivity in your workflow."
            },
        ]
    },
    {
        id: 5,
        type: "Data Privacy",
        faqs: [{
            question: "What measures does Flyght Cloud take to protect my data privacy?",
            answer: "Flyght Cloud prioritizes data privacy and employs robust security measures to safeguard user information. These measures include encryption protocols, secure authentication mechanisms, and regular security audits."
        }, {
            question: "Can I control who has access to my data on Flyght Cloud?",
            answer: "Flyght Cloud offers comprehensive privacy settings that allow users to control access to their data. Users can manage permissions, define sharing settings, and revoke access as needed to ensure the security and privacy of their information."
        }, {
            question: "Can I export my data from Flyght Cloud?",
            answer: "Yes, Flyght Cloud provides users with the ability to export their data in a variety of formats for backup, archival, or portability purposes. This ensures that users maintain control over their information and can transfer it as needed."
        }]
    }
]
