import * as React from 'react';
import { PluginResponse, PluginsService, WorkflowTemplateResponse, WorkflowTemplatesService } from '../../services/openapi';
import { Loading } from '../Loading';
import { Button, Text, tokens, } from '@fluentui/react-components';
import { Link, useSearchParams } from 'react-router-dom';
import { TemplateEditor } from './TemplateEditor';
import { AuthContext } from '../../AuthContext';
import drag_handle from './drag_handle.svg'

export const TemplatesList = () => {
    const [plugins, setPlugins] = React.useState<PluginResponse[] | null>();
    const [templates, setTemplates] = React.useState<WorkflowTemplateResponse[] | null>();
    const [selected, setSelected] = React.useState('');
    const [search] = useSearchParams();
    const { me: auth } = React.useContext(AuthContext);
    let template: WorkflowTemplateResponse | undefined;

    const selectTemplate = (id: string) => {
        setSelected(id)
        setLeftWidth(15)
        leftRef.current!.style.width = `${leftWidth}%`;
    }

    React.useEffect(() => {
        PluginsService.getPlugins()
            .then((res) => setPlugins(res.plugins))
            .catch((err) => console.error(err));
        WorkflowTemplatesService.getTemplates()
            .then((res) => setTemplates(res.templates))
            .catch((err) => console.error(err));
    }, [template]);

    React.useEffect(() => {
        if (search && search.get('templateId')) {
            setSelected(search.get('templateId') || '');
            setLeftWidth(15)
        }
    }, [search]);


    const [leftWidth, setLeftWidth] = React.useState<undefined | number>(undefined);
    const leftRef = React.createRef<HTMLDivElement>();

    React.useEffect(() => {
        if (leftRef.current) {
            if (!leftWidth) {
                setLeftWidth(template ? 15 : 95);
                return;
            }
            leftRef.current.style.width = `${leftWidth}%`;
        }
    }, [leftRef, leftWidth, setLeftWidth, template]);



    if (!templates || !plugins) {
        return <Loading />;
    }

    template = templates.find((t) => t.id === selected);

    return <>
        {/* <Header ButtonLink='/workflows/new-workflow' ButtonText='Add a new workflow'>
            <div
                style={{
                    height: '100%',
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <div>
                    <Link to="/workflows">Workflows</Link>
                </div>
            </div>
        </Header> */}
        {/* outer pane to be split */}
        <div
            style={{
                width: '100%',
                height: 'calc(100vh - 5em)',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start'
            }}
        >
            {/* left pane */}
            <div ref={leftRef}
                style={{
                    minWidth: '15em',
                    maxWidth: '60%',
                    flexGrow: 0,
                    height: '100%',
                    overflow: 'auto',
                }}
            >
                {/* my templates */}
                <div
                    style={{
                        paddingTop: '0.5em',
                        marginBottom: '1em',
                        backgroundColor: tokens.colorNeutralBackground1,
                        borderRadius: '0.5em',
                        height: '38%',
                        overflowY: 'scroll',
                        boxShadow: '5px 1px 20px 0px rgba(0, 0, 0, 0.05)'
                    }}>
                    <div style={{ fontWeight: '600', fontSize: '1.2em', marginTop: '0.5em', marginLeft: '0.5em' }}> Created by me</div>
                    <div style={{ columns: template ? '' : '3 auto', columnFill: 'balance-all', height: '95%' }}>
                        {templates.filter((t) => t.author === auth?.userId).map((t) => {
                            return <div
                                key={t.id}
                                style={{
                                    margin: '1em',
                                    marginBottom: '1em',
                                    borderRadius: '0.5em',
                                    padding: '0.5em',
                                    cursor: 'pointer',
                                    backgroundColor: selected === t.id ? '#0A84FF30' : '#F2F6FA',
                                    color: "#606060",
                                    breakInside: 'avoid',
                                }}
                                onClick={() => selectTemplate(t.id)}>
                                <div><Text weight='semibold'>{t.name}</Text></div>
                                <div><Text size={200}>{t.description}</Text></div>
                            </div>;
                        })}
                    </div>
                </div>

                {/* System templates */}
                <div
                    style={{
                        paddingTop: '0.5em',
                        marginBottom: '1em',
                        backgroundColor: tokens.colorNeutralBackground1,
                        borderRadius: '0.5em',
                        height: '56%',
                        overflowY: 'scroll',
                        boxShadow: '5px 1px 20px 0px rgba(0, 0, 0, 0.05)'
                    }}>
                    <div style={{ fontWeight: '600', fontSize: '1.2em', margin: '0.5em' }}>
                        System provided workflows
                    </div>
                    <div style={{ columns: template ? '' : '3 auto', columnFill: 'balance-all', height: '95%' }}>
                        {templates.filter((t) => t.author !== auth?.userId).map((t) => {
                            return <div
                                key={t.id}
                                style={{
                                    margin: '1em',
                                    borderRadius: '0.5em',
                                    padding: '0.5em',
                                    cursor: 'pointer',
                                    marginBottom: '1em',
                                    backgroundColor: selected === t.id ? '#0A84FF30' : '#F2F6FA',
                                    color: "#606060",
                                    breakInside: 'avoid',
                                }}
                                onClick={() => { selectTemplate(t.id) }}
                            >
                                <div>
                                    <Text weight='semibold'>{t.name}</Text>
                                </div>
                                <div><Text>{t.description}</Text></div>
                            </div>;
                        })}
                    </div>
                </div>
            </div>
            {/* divider handle */}
            <img
                // onMouseDown={onMouseDown} 
                src={drag_handle}
                alt='drag'
                style={{
                    height: '2em',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    // cursor: 'col-resize',
                }}
            />
            {/* right pane */}
            <div
                style={{
                    marginBottom: '1em',
                    marginRight: '2em',
                    flexGrow: 1,
                    height: '98%',
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'auto',
                }}
            >

                {!template && (<div style={{ height: '5em', marginTop: '40%', display: 'flex', padding: '2%', fontSize: '1.5em', justifyContent: 'center', textAlign: 'center' }}>Select a template from left panel to view details</div>)}
                {template && (<div style={{
                    backgroundColor: tokens.colorNeutralBackground1,
                    padding: '1em',
                    borderRadius: '0.5em',
                    boxShadow: '5px 1px 20px 0px rgba(0, 0, 0, 0.05)'
                }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', }}>
                            <Text style={{ marginBottom: '0.2em' }} size={400} weight='semibold'>{template.name}</Text>
                            <Text size={300}>{template.description}</Text>
                        </div>
                        <div>
                            {(template.author === auth?.userId) && (
                                <Button appearance='outline'> <Link to={`/workflows/edit-workflow?from=${template.id}`}>Edit</Link></Button>
                            )}
                            <Button appearance='outline' style={{ marginLeft: '0.5em' }}> <Link to={`/workflows/new-workflow?from=${template.id}`}>Duplicate</Link> </Button>
                        </div>
                    </div>
                </div>
                )}
                {template && (<TemplateEditor readonly base={template} onChange={() => { }} />)}
            </div>
        </div>
    </>
};

