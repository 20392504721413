import { Button, makeStyles, shorthands, tokens } from '@fluentui/react-components';
import Lottie from "react-lottie";
import { Link } from "react-router-dom";
import techicalDocumenation_icon from "../../assets/icons/support/Technical Documentation.json";
import faqs_icon from "../../assets/icons/support/faq.json";
import raiseQuery_icon from "../../assets/icons/support/raise a query.json";

const supportData = [
    {
        id: 1,
        icon: techicalDocumenation_icon,
        title: "Technical Documentation",
        info: "Solutions to configuration issues and general troubleshooting",
        btnName: "Get Document",
        href: "/support/docs"
    },
    {
        id: 2,
        icon: faqs_icon,
        title: "Frequently Asked Questions",
        info: "Find solutions to common issues or browse tropics to find what you are looking for. ",
        btnName: "FAQ",
        href: "/support/faqs"
    },
    {
        id: 3,
        icon: raiseQuery_icon,
        title: "Raise a Query",
        info: "Do you have any specific queries? Connect with our expert for assistance.",
        btnName: "Talk to Expert",
        href: "/support/raiseaquery"
    }
]
const Support = () => {
    const classes = useStyles();

    return (
        <div className={classes.bgContainer} style={{ background: tokens.colorNeutralBackground1, overflow: "auto" }}>
            <div style={{ width: "90%" }}>
                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "2em" }}>
                    {
                        supportData.map((ele, index) => (
                            <>
                                {
                                    (index === 1 || index === 2) ? <div className={classes.verticalLinneContainer}>
                                        <div className={classes.verticalLine}></div>
                                    </div> : null
                                }
                                <div className={classes.columnContainer}>
                                    <Lottie
                                        options={{
                                            loop: true,
                                            autoplay: true,
                                            animationData: ele.icon,
                                            rendererSettings: {
                                                preserveAspectRatio: "xMidYMid slice"
                                            }
                                        }}
                                        width={'50%'}
                                    />
                                    <h2 className={classes.primaryHeading}>{ele.title}</h2>
                                    <p className={classes.secondaryHeading}>{ele.info}</p>
                                    <Link style={{ textDecoration: "none" }} to={ele.href}>
                                        <Button style={{ padding: "15px 24px" }} className={classes.commonBtn}>
                                            {ele.btnName}
                                        </Button>
                                    </Link>
                                </div >
                            </>
                        ))
                    }
                </div>
            </div>
        </div >
    );
};

const useStyles = makeStyles({
    bgContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "1em 2em",
        height: "calc(92vh - 3rem)",
        scrollbarWidth: "thin",
        position: "relative",
    },
    columnContainer: {
        width: "30%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        ...shorthands.padding("1em"),
        height: 'calc(50vh - 3rem)',
    },
    verticalLine: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: "50%",
        backgroundColor: "#D1DBE8",
        width: "100%"
    },
    verticalLinneContainer: {
        position: "relative",
        width: "1px",
        marginLeft: '1em',
        marginRight: "1em"
    },
    primaryHeading: {
        fontSize: "1.4rem",
        textAlign: "center",
        color: "#4E4C4C",
        fontWeight: "500"
    },
    secondaryHeading: {
        fontSize: ".8rem",
        color: "#4E4C4C",
        textAlign: "center",
        fontWeight: "400",
        marginTop: "2em"
    },
    commonBtn: {
        marginTop: "2em",
        ...shorthands.border("1px", "solid", "#79747E"),
    }
});

export default Support;
