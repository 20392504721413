import { Accordion, AccordionHeader, AccordionItem, AccordionPanel, Button, CounterBadge, Input, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import * as React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import projectHeaderIcon from "../assets/icons/project_header.svg";
import { FlightsService, ProjectResponse, ProjectsService } from '../services/openapi';
import { Loading } from './Loading';


export const NewFlightForm = () => {
    const classes = useStyles()
    const [project, setProject] = React.useState<ProjectResponse | null>();
    const [form, setForm] = React.useState({ name: '', flightDate: '', groupId: null as string | null });
    const [error, setError] = React.useState('');
    const { projectId } = useParams();
    const navigate = useNavigate();

    React.useEffect(() => {
        if (!projectId) return;
        ProjectsService.getProjectSummary(projectId)
            .then((ps) => setProject(ps))
            .catch((err) => setError(`Could not get parent project info: ${err}`));
    }, [projectId])

    const create = () => {
        if (!projectId) return;
        FlightsService.createFlight(projectId, form)
            .then((f) => navigate(`/projects/${f.projectId}/flights/${f.id}`))
            .catch((err) => setError(`Could not create flight: ${err}`));
    };

    if (!project) {
        return <Loading />
    }

    return <>
        <div className={classes.projectHeaderText}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <Link style={{ textDecoration: "none" }} className={classes.flexContent} to="/projects"><img src={projectHeaderIcon} alt="arrowRight" /> Projects</Link></div> <p style={{ margin: "0 .2em " }}>{'>'}</p> <Link style={{ textDecoration: "none" }} className={classes.flexContent} to={`/projects/${project.id}`}>{project.name}</Link><p style={{ margin: "0 .2em " }}>{'>'}</p> <div style={{ fontSize: '1rem' }}>Add a new flight</div>
        </div>
        <div
            style={{
                width: '98%',
                background: tokens.colorNeutralBackground1,
                borderRadius: '1em',
                padding: '1em',
                paddingRight: '3em',
                margin: '1em',
                boxShadow: '5px 1px 20px 0px rgba(0, 0, 0, 0.05)'
            }}
        >
            <div style={{ fontWeight: 600, fontSize: '1.5em', marginTop: '1em', marginBottom: '1em' }}>
                Add New Flight
            </div>
            <div>
                <div>
                    <CounterBadge count={1} color="informative" style={{ marginRight: '0.75em' }} />
                    Flight Title
                </div>
                <div style={{ paddingLeft: '2em' }}>
                    <Input
                        style={{ width: '100%' }}
                        value={form.name}
                        onChange={(e) => {
                            setForm({ ...form, name: e.target.value });
                        }}
                    />
                </div>
            </div>
            <div style={{ marginTop: '2em' }}>
                <div>
                    <CounterBadge count={2} color="informative" style={{ marginRight: '0.75em' }} />
                    Flight Date
                </div>
                <div style={{ paddingLeft: '2em' }}>
                    <Input
                        type='date'
                        style={{ width: '100%' }}
                        value={form.flightDate}
                        onChange={(e) => {
                            setForm({ ...form, flightDate: e.target.value });
                        }}
                    />
                </div>
            </div>
            <Accordion collapsible defaultOpenItems={false}>
                <AccordionItem value={1}>
                    <AccordionHeader>Advanced Configuration</AccordionHeader>
                    <AccordionPanel>
                        <div style={{ marginTop: '2em' }}>
                            <div>
                                <CounterBadge count={3} color="informative" style={{ marginRight: '0.75em' }} />
                                Group ID
                            </div>
                            <div style={{ paddingLeft: '2em' }}>
                                <Input
                                    style={{ width: '100%' }}
                                    value={form.groupId || ''}
                                    onChange={(e) => {
                                        if (e) {
                                            setForm({ ...form, groupId: e.target.value });
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
            <div style={{ marginTop: '2em', display: 'flex', justifyContent: 'end' }}>
                {error && <div>{error}</div>}
                <Button onClick={() => { navigate(-1) }}>Cancel</Button>
                <Button appearance="primary" disabled={!form.name || !form.flightDate} onClick={() => create()} style={{ marginLeft: '1em' }}>Continue</Button>
            </div>
        </div>
    </>
};

const useStyles = makeStyles({
    projectHeaderText: {
        height: "3rem",
        paddingLeft: "1.25rem",
        display: "flex",
        alignItems: "center",
        color: "#2B2B2B",
        ...shorthands.borderBottom('1px', 'solid', '#E3E9F2'),
    },
    flexContent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "black", fontWeight: "normal"
    },
})