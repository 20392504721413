import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle } from "@fluentui/react-components";
import Illustration from "../../assets/icons/tour_completed.png";
import { useModalStyles } from "./FlyghtCloudDemoModal";
import BrandName from "../common/BrandName";

interface Props {
    hideModal: () => void;
    visible: boolean
}

export function TourGuideCompletedModal({ hideModal, visible }: Props) {
    const classes = useModalStyles();
    return <Dialog open={visible}>
        <DialogSurface className={classes["dialog-surface"]} style={{ width: "900px" }}>
            <DialogBody className={classes.body}>
                <DialogTitle action={null} className={classes.title}>Congratulations!!</DialogTitle>
                <DialogContent className={classes.content}>
                    <div>
                        <p className={classes.text}>You've completed the <BrandName /> onboarding tour. We hope you found it helpful.</p>

                        <p className={classes.text}>Feel free to explore more features and start utilizing the platform to its fullest potential. If you have any questions, our support team is here to assist you.</p>

                        <p className={classes.text2}>Happy exploring!</p>
                    </div>
                    <img src={Illustration} alt="demo" />
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <Button className={classes.primaryButton} appearance="primary" onClick={hideModal}>Ok</Button>
                </DialogActions>
            </DialogBody>
        </DialogSurface>
    </Dialog >
}


