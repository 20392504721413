import explore_icon from "../../../assets/icons/discover-apps/explore.svg";
import discover_icon from "../../../assets/icons/discover-apps/discover.svg"
import optimise_icon from "../../../assets/icons/discover-apps/optimize.svg"
import deploy_icon from "../../../assets/icons/discover-apps/deploy.svg"
import achieve_icon from "../../../assets/icons/discover-apps/achieve.svg"


export const worksData = [
    {
        id: 1,
        icon: explore_icon,
        title: 'Explore',
        info: "Browse through our carefully crafted workflow categories."
    }, {
        id: 2,
        icon: discover_icon,
        title: 'Discover',
        info: "Uncover workflows tailored to your industry needs."
    }, {
        id: 3,
        icon: deploy_icon,
        title: 'Deploy',
        info: "Implement seamlessly with Flyght Cloud's user-friendly interface."
    },
    {
        id: 4,
        icon: optimise_icon,
        title: 'Optimize',
        info: "Enhance your data analytics capabilities effortlessly."
    },
    {
        id: 5,
        icon: achieve_icon,
        title: 'Achieve',
        info: "Drive success with actionable insights and optimised workflows."
    },
]
