import * as React from 'react'
import pdfIcon from "../../assets/icons/discover-apps/demo_pdf.svg";
import youtubeIcon from "../../assets/icons/discover-apps/demo_youtube.svg"
import { makeStyles } from '@fluentui/react-components';
import DemoModal from '../discover_apps/cards/DemoModal';

interface DemoLink {
    name: string;
    path: string;
    type: string;
}

type DemoCardProps = {
    demoLinks: DemoLink[];
}

function Demo({ demoLinks }: DemoCardProps) {
    const classes = useStyles()
    const [pdfModalVisible, setPdfModalVisible] = React.useState(false);
    const [selectedPdf, setSelectedPdf] = React.useState('');
    const [selectedType, setSelectedType] = React.useState('');


    const togglePdfModal = (flag: boolean, link: string, type: string) => {
        setPdfModalVisible(flag);
        setSelectedPdf(link);
        setSelectedType(type)
    }

    return (
        <div>
            {
                demoLinks.map((ele, index) => (
                    <div>{
                        ele.type !== 'docs' ? <div onClick={() => { togglePdfModal(true, ele.path, ele.type) }} key={index} style={{ display: "flex", cursor: "pointer", margin: "1em 0", alignItems: "center" }}>
                            <img src={youtubeIcon} style={{ width: "22%" }} />
                            <p className={classes.info}>{ele.name}</p>
                        </div> :
                            <div>
                                <div key={index} style={{ display: "flex", cursor: "pointer", margin: "2em 0", alignItems: "center" }}>
                                    <img src={pdfIcon} style={{ width: "22%" }} />
                                    <p className={classes.info}>
                                        <a
                                            href={ele.path}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ color: '#000000', textDecoration: 'none' }}
                                        >
                                            {ele.name}
                                        </a>

                                    </p>

                                </div>
                            </div>
                    }

                    </div>
                ))
            }
            <DemoModal visible={pdfModalVisible} toggleModal={togglePdfModal} pdfUrl={selectedPdf} demoType={selectedType} />
        </div>
    )
}

const useStyles = makeStyles({
    info: {
        fontSize: ".9rem",
        color: "#000000",
        fontWeight: 400,
        paddingBottom: ".5em",
        marginLeft: ".5em",
        lineBreak: "anywhere"
    },
})
export default Demo