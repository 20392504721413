import React from 'react'
import { FlightsService } from '../services/openapi';
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Label } from '@fluentui/react-components';

interface ModalProps {
    visible: boolean;
    toggleModal: (flag: boolean) => void;
    flightId: string;
    flightName: string;
    projectId: string;
    getFlightData: (projectId: string) => void;
}

function DeleteProjectFlightModal(props: ModalProps) {
    const { visible, flightId, projectId, flightName, getFlightData, toggleModal } = props;

    const handleDelete = (ev: React.FormEvent) => {
        ev.preventDefault();
        FlightsService.archiveFlight(flightId).then(() => {
            toggleModal(false);
            getFlightData(projectId)
        })
    };

    return <Dialog
        open={visible}
    >
        <DialogSurface>
            <DialogBody>
                <DialogTitle>Delete Project</DialogTitle>
                <DialogContent>
                    <Label htmlFor={"name-input"} style={{ marginRight: "0.75rem" }}>
                        Are you sure you want to delete <b>{flightName}</b> ?
                    </Label>
                </DialogContent>
                <DialogActions>
                    <DialogTrigger disableButtonEnhancement>
                        <Button appearance="secondary" onClick={() => toggleModal(false)}>Close</Button>
                    </DialogTrigger>
                    <Button onClick={handleDelete} appearance="primary">
                        Delete
                    </Button>
                </DialogActions>
            </DialogBody>
        </DialogSurface>
    </Dialog >
}

export default DeleteProjectFlightModal