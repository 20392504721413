import * as React from "react";
import { GridRegular } from "@fluentui/react-icons";
import listIcon from "../../../assets/icons/format_list.svg";

interface Props {
  handleGridListView: (view: "grid" | "list") => void;
  viewIcon: string;
}
function ViewOptions({ handleGridListView, viewIcon }: Props) {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {viewIcon === "grid" ? (
        <img
          style={{ margin: "0 .5em", cursor: "pointer" }}
          onClick={() => handleGridListView("list")}
          src={listIcon}
          alt="view_icon"
        />
      ) : (
        <GridRegular
          onClick={() => handleGridListView("grid")}
          style={{ margin: "0 .5em", cursor: "pointer" }}
        />
      )}
    </div>
  );
}

export default ViewOptions;
