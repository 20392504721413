import satSure_icon from "../../../assets/icons/landing/satsure.svg";
import huviair_icon from "../../../assets/icons/landing/costra.svg";
import deepMatrix_icon from "../../../assets/icons/landing/deepmetrix.svg";
import esri_icon from "../../../assets/icons/landing/esri.svg";

export const DataStrategy = [
    {
        id: 1,
        title: 'DeepMatrix',
        tags: ["Mining", "Inspection"],
        icon: deepMatrix_icon,
        info: "DeepMatrix is a cloud-based SaaS platform that manage all geospatial data needs from collection to visualization at a single place."
    },
    {
        id: 2,
        title: 'SatSure',
        icon: satSure_icon,
        tags: ["Utilities", "Infrastructure"],
        info: "SatSure is a deep tech, decision intelligence company.SatSure leverages advances in satellite remote sensing, machine learning, big data analytics and cloud computing to create products and solutions which help enterprises, and their people make smart decisions"
    },
    {
        id: 3,
        title: 'HUVIAiR',
        icon: huviair_icon,
        tags: ["Construction", "Real Estate"],
        info: "HUVIAiR Technologies’ flagship product CONSTRA unlocks the power of visual intelligence for land scouting, surveying, planning, building, and inspection."
    },
    {
        id: 4,
        title: 'ESRI India',
        icon: esri_icon,
        tags: ["Forest", "Mining", "Utilities", "Construction"],
        info: "ESRI is the global market leader in Geographic Information System(GIS) software, location intelligence and mapping.ArcGIS offers unique capabilities and flexible licensing for applying location- based analytics to your business practices."
    },
]