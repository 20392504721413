import { Dialog, DialogBody, DialogContent, DialogSurface, makeStyles } from '@fluentui/react-components';
import { useContext } from 'react';
import modalClose from "../../../assets/icons/modalClose.svg";
import { AuthContext } from '../../../AuthContext';
import { NewProjectForm } from '../../projects/NewProjectForm';

function NewProject() {
    const classes = useStyles()
    const { isNewProjectOpen, setIsNewProjectOpen } = useContext(AuthContext);

    return (
        <>
            <Dialog
                open={isNewProjectOpen}
            >
                <DialogSurface>
                    <DialogBody>
                        <DialogContent >
                            <div>
                                <div style={{ display: "flex", justifyContent: "end", gap: ".5em", alignItems: "center" }}>
                                    {/* <Popover withArrow>
                                        <PopoverTrigger disableButtonEnhancement>
                                            <img style={{ cursor: "pointer" }} src={helpIcon} />
                                        </PopoverTrigger>
                                        <PopoverSurface className={classes.popoverSurface}>
                                            <div>
                                                <h3 style={{ fontWeight: "500" }}>Creating a project for the first time? </h3>
                                                <p>Check out these demo to learn more:</p>
                                                <p>youtube link1</p>
                                                <p>youtube link2</p>
                                            </div>
                                        </PopoverSurface>
                                    </Popover> */}
                                    <img onClick={() => {
                                        setIsNewProjectOpen(false)
                                    }} style={{ cursor: "pointer" }} src={modalClose} />

                                </div>
                                <h1 style={{ textAlign: "center", fontSize: "1.5rem", fontWeight: "600", color: "#3E3E3E" }}>Create New Project</h1>
                            </div>
                            <div>
                                <NewProjectForm />
                            </div>
                        </DialogContent>
                    </DialogBody>
                </DialogSurface>
            </Dialog >
        </>
    )
}
const useStyles = makeStyles({
    modalSurface: {
        '& .r1h3qql9': {
            gap: '0px !important',
        },
        '& .fp67ikv': {
            width: '20% !important',
        },
        '& .f1euv43f': {
            right: '0px !important',
        }
    },
    popoverSurface: {
        transform: "translate3d(50vw, 17vh, 0) !important",
        boxShadow: " -1px 1px 10px 0px #00000040",
        "& .f1euv43f": {
            right: '20px !important',
            left: "auto !important"
        }
    }
})

export default NewProject;

