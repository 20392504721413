import React from 'react'
import { Button, makeStyles, shorthands } from '@fluentui/react-components';
import MobileHeader from '../../../common/MobileHeader';
import miningLand from "../../../../../assets/icons/landing/miningLand.svg"
import fcmdIcon from "../../../../../assets/icons/landing/FCdeepMatrix.png"
import { useNavigate } from 'react-router-dom';
import KeyFeatures from './KeyFeatures';
import Limitations from './Limitations';
import Lottie from 'react-lottie';
import miningWorksData from "./miningWorkFlowData.json"
import Usecases from './Usecases';
import ellipsIcon from "../../../../../assets/icons/landing/ellipse.svg"

function MobileMiningSolution() {
    const classes = useStyles();
    const navigate = useNavigate();

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: miningWorksData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <div>
            <MobileHeader />
            <div style={{ textAlign: "center", margin: "1em 0", display: "flex", justifyContent: "end", alignItems: "center" }}>
                <img src={fcmdIcon} className={classes.fcmdIcon} alt='fcmdIcon' />
            </div>
            <div className={classes.container}>
                <div className={classes['mob-container']}>
                    <h3 className={classes.mediumTxt}>Next-Generation</h3>
                    <h3 className={classes.barndingLabel}>Mining</h3>
                    <h3 className={classes.mediumTxt}>Flyght Cloud and DeepMatrix</h3>
                    <h4 className={classes.barndingInfo}>Streamline Your Drone Data Management with <br /> Our Custom Cloud-Based Automated Solution</h4>
                    <Button shape="circular" className={classes.gradBtn}
                        onClick={() => {
                            navigate(`/book-demo`);
                        }}
                        style={{ background: "linear-gradient(90deg, #AAD824 0%, #2ABCD0 100%)", color: "white", border: "none" }}
                    >Connect to Expert</Button>
                </div>
            </div>
            <div className={classes.elipsContainer} style={{ margin: "1em 0" }}>
                <img src={miningLand} alt='miningLand' />
            </div>
            <div className={classes['secondary-container']}>
                <h3 className={classes.ssinitialHeading}>Key Features and Benefits</h3>
                <KeyFeatures />
            </div>
            <div style={{ width: "100%", background: "rgba(43, 47, 71, 0.1)", padding: ".1em 0" }}>
                <div className={classes['secondary-container']}>
                    <h3 className={classes.ssinitialHeading}>Limitations of current methods</h3>
                    <Limitations />
                </div>
            </div>
            <div className={classes['secondary-container']}>
                <h3 className={classes.ssinitialHeading} style={{ color: "rgb(20, 130, 218)" }}>How it Works</h3>
                <div style={{ width: "100%", margin: "0 auto" }}>
                    <Lottie
                        options={defaultOptions}
                        height={"100%"}
                        width={"100%"}
                    />
                </div>
            </div>
            <div style={{ width: "100%", background: "rgb(20, 130, 218)", color: "white", padding: ".1em 0" }}>
                <div className={classes['secondary-container']} style={{ textAlign: "center" }}>
                    <h3 className={classes.ssinitialHeading}>Ready to take flight?</h3>
                    <h4 className={classes.ssbarndingInfo} style={{ color: "white" }}>Sign in to your FlyghtCloud account or book a demo to experience the
                        future of drone data analytics firsthand.</h4>
                    <Button shape="circular"
                        onClick={() => {
                            navigate(`/book-demo`);
                        }}
                        style={{ color: "black", margin: "1.5em 0", padding: ".7em 2.5em" }}
                    >Connect to Expert</Button>
                </div>
            </div>
            <div className={classes['secondary-container']} style={{ textAlign: "center" }}>
                <h3 className={classes.ssinitialHeading}>Use Cases</h3>
                <Usecases />
            </div>
            <div style={{ width: "100%", background: "rgb(10, 44, 61)", color: "white", padding: ".1em 0" }}>
                <div className={classes['secondary-container']} style={{ textAlign: "center" }}>
                    <h3 className={classes.ssinitialHeading}>Mining Workflow Deepdive</h3>
                    <h4 className={classes.ssbarndingInfo} style={{ color: "white" }}>Unlock the full potential of your mining operations with FlyghtCloud and DeepMatrix. Collaborate with us to achieve more efficiency and accuracy for your mining initiatives</h4>
                    <Button shape='circular' style={{ color: "black", margin: "1.5em 0", padding: ".7em 2.5em" }}
                        onClick={() => { window.open('https://ideaforgetech.com/contactus', '_blank') }}
                    >Register now</Button>
                </div>
            </div>
            <div style={{ width: "100%", marginTop: "1em", background: "rgb(20, 130, 218)", color: "white", padding: ".1em 0" }}>
                <div className={classes['secondary-container']} style={{ textAlign: "center" }}>
                    <h3 className={classes.ssinitialHeading}>Ready to take flight?</h3>
                    <h4 className={classes.ssbarndingInfo} style={{ color: "white" }}>Sign in to your FlyghtCloud account or book a demo to experience the
                        future of drone data analytics firsthand.</h4>
                    <Button shape="circular"
                        onClick={() => {
                            navigate(`/book-demo`);
                        }}
                        style={{ color: "black", margin: "1.5em 0", padding: ".7em 2.5em" }}
                    >Connect to Expert</Button>
                </div>
            </div>
            <div className={classes.commonRow} style={{ fontSize: ".7rem", textAlign: "center" }}>
                <p style={{ marginTop: "1em", color: "#586A84" }}>Copyright © 2024 ideaForge Technology Ltd. All rights reserved.</p>
            </div>
        </div>
    )
}

export default MobileMiningSolution;

const useStyles = makeStyles({
    "container": {
        minHeight: "45vh",
        maxHeight: "100vh",
        minWidth: "100vw",
        backgroundColor: "rgb(245, 248, 255)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
        backdropFilter: "blur(10px) contrast(1.2)"
    },
    fcmdIcon: {
        width: "93%"
    },
    "elipsContainer": {
        maxHeight: "100vh",
        minWidth: "100vw",
        backgroundImage: `url(${ellipsIcon})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom",
    },
    "mob-container": {
        width: "93%",
        margin: "2em auto",
        paddingTop: "2em",
    },
    barndingLabel: {
        fontSize: "3rem",
        lineHeight: "3rem",
        fontWeight: "700",
        margin: ".3em 0",
    },
    mediumTxt: {
        fontSize: "1.5rem",
        fontWeight: "500"
    },
    barndingInfo: {
        fontSize: "1rem",
        fontWeight: "500",
        lineHeight: "1.1rem",
        marginTop: "1em"
    },
    initialHeading: {
        fontSize: "1.8rem",
        fontWeight: "500",
        lineHeight: "2rem",
    },
    gradBtn: {
        ...shorthands.padding("10px"),
        marginTop: "1em",
        marginBottom: "1em",
        fontWeight: "1rem"
    },
    "secondary-container": {
        width: "93%",
        margin: "2em auto"
    },
    ssinitialHeading: {
        fontSize: "1.3rem",
        fontWeight: "500",
        lineHeight: "1.5rem",
        margin: "1em 0",
        textAlign: "center"
    },
    grid: {
        display: "grid",
        gridTemplateColumns: 'repeat(2, 3fr)',
        marginTop: "1em",
        marginBottom: "2em",
        gridGap: '1em',
    },
    centerItem: {
        gridColumn: '1 / span 2',
        justifySelf: 'center',
    },
    ssbarndingInfo: {
        fontSize: ".85rem",
        lineHeight: "1.1rem",
        color: "#08201D",
        marginTop: ".5em"
    },
    commonRow: {
        marginTop: "2em",
        marginBottom: "2em",
    },
})