import { Checkbox, makeStyles, MenuItem, shorthands } from "@fluentui/react-components";
import ChevronDownIcon from "../../assets/icons/chevron_down.svg";
import ChevronRightIcon from "../../assets/icons/chevron_right.svg";
import { MeasurementResponse } from "../../services/openapi";

interface AnnotationMenuProps {
    measurementType: "area" | "distance" | "elevation" | "volume"
    measurements: MeasurementResponse[]
    collapsed: boolean
    onCollapseToggle: () => void
    onParentCheck: () => void,
    onParentUncheck: () => void,
    onChildCheck: (mID: string) => void,
    onChildUncheck: (mID: string) => void,
    onClickLabel: (m: MeasurementResponse) => void,
    hiddenMeasurements: MeasurementResponse[],
    disabled: boolean,
}

export function AnnotationMenu(props: AnnotationMenuProps) {
    const { measurementType, measurements, collapsed, onCollapseToggle, onParentCheck: onCheck, onParentUncheck: onUncheck, hiddenMeasurements, onChildUncheck, onChildCheck, onClickLabel, disabled } = props
    const classes = useStyles();
    if (!measurements) {
        return null
    }
    if (!measurements.find(m => m.measurement_type === measurementType)) {
        return null
    }

    return <>
        <MenuItem style={{ marginLeft: "24px" }}>
            <div className={classes.layerHeaderItem}>
                <img src={collapsed ? ChevronRightIcon : ChevronDownIcon} height="24px" width="24px" onClick={onCollapseToggle} />
                <Checkbox size='large'
                    className={classes.checkbox}
                    onChange={(e) => {
                        if (e.target.checked) {
                            onCheck();
                        } else {
                            onUncheck();
                        }
                    }}
                    defaultChecked={true}
                    disabled={disabled}
                />
                <p className={classes.layerHeaderTitle}>{measurementType[0].toUpperCase() + measurementType.slice(1)}</p>
            </div>
        </MenuItem>

        {measurements.filter(measurement => measurement.measurement_type === measurementType).map(measurement => <MenuItem style={{ marginLeft: "78px", display: collapsed ? "none" : "flex" }} >
            <div className={classes.layerHeaderItem} >
                <Checkbox size='large'
                    className={classes.checkbox}
                    checked={!(hiddenMeasurements.find(m => m.id === measurement.id))}
                    onChange={((e) => {
                        const isHidden = hiddenMeasurements.find(m => m.id === measurement.id)
                        if (isHidden) {
                            onChildCheck(measurement.id)
                        } else {
                            onChildUncheck(measurement.id)
                        }
                    })}
                    disabled={disabled}
                />
                <p className={classes.layerHeaderTitle} onClick={() => { if (!disabled) { onClickLabel(measurement) } }}>{measurement.name}</p>
            </div>
        </MenuItem>)}
    </>
}

const useStyles = makeStyles({
    layerHeaderItem: {
        display: 'flex',
        flexDirection: "row",
    },
    checkbox: {
        '> div': {
            ...shorthands.borderRadius("3.5px"),
            ...shorthands.margin(0),
        },
        '> input': {
            width: "100%",
        },
    },
    layerHeaderTitle: {
        marginLeft: "8px",
    },
})