import { Card, makeStyles, shorthands, Tag, CardHeader, Text, Caption1 } from "@fluentui/react-components"
import { useNavigate } from "react-router-dom"

type AllWorkFlowCardProps = {
    allWorkFlowCardData: {
        id: number,
        icon: string,
        title: string,
        info: string
        tagNames: string[]
    }
}
const AllWorkFlowCard = ({ allWorkFlowCardData }: AllWorkFlowCardProps) => {
    const styles = useStyles();
    const navigate = useNavigate();


    return <Card className={styles.card} onClick={() => { navigate(`/discover-apps/workflow-details`) }}>
        <CardHeader
            image={
                <img style={{ boxShadow: "-1px 1px 10px 0px #0000001A" }}
                    className={styles.headerImage}
                    src={allWorkFlowCardData.icon}
                    alt={allWorkFlowCardData.title}
                />
            }
            header={<Text className={styles.cardHeading}>{allWorkFlowCardData.title}</Text>}
            description={
                <Caption1 className={styles.caption}>{allWorkFlowCardData.info}</Caption1>
            }
        />
        <div style={{ display: "flex", flexWrap: "wrap", gap: "2px" }}>
            {
                allWorkFlowCardData.tagNames.map((item, index) => <Tag key={index} role="listitem" className={styles.tagBar} style={{ height: "3.2vh", borderRadius: "4px" }}>{item}</Tag>
                )
            }
        </div>
    </Card>
}
const useStyles = makeStyles({
    card: {
        maxWidth: "100%",
        height: 'calc(34.5vh - 3rem)',
        ...shorthands.border('1px', 'solid', '#E3E9F2'),
        ...shorthands.borderRadius("16px"),
        ...shorthands.padding('10px'),
        cursor: "pointer"
    },
    cardHeading: {
        fontSize: "1.5rem",
        color: "#272727",
        fontWeight: 400,
        lineHeight: "1.5rem",
        ...shorthands.margin(0),
    },
    tagBar: {
        backgroundColor: "#E3E6EB",
        color: "#272727",
        fontSize: ".5rem !important",
        ...shorthands.padding("4px", "10px"),
        display: "flex", justifyContent: "center", alignItems: "center",
        '& .f1g03r3y': {
            fontSize: '.7rem',
        },
    },
    headerImage: {
        ...shorthands.borderRadius("4px"),
        maxWidth: "150px",
        maxHeight: "120px",
    },
    caption: {
        color: '#272727',
        marginTop: ".5em",
        marginBottom: ".5em",
        maxWidth: "90%"
    },
})

export default AllWorkFlowCard