/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FilesResponse } from '../models/FilesResponse';
import type { Task } from '../models/Task';
import type { TaskStatusRequest } from '../models/TaskStatusRequest';
import type { WorkflowDetailsResponse } from '../models/WorkflowDetailsResponse';
import type { WorkflowRequest } from '../models/WorkflowRequest';
import type { WorkflowResponse } from '../models/WorkflowResponse';
import type { WorkflowsResponse } from '../models/WorkflowsResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WorkflowsService {

    /**
     * @param flightId
     * @param requestBody
     * @returns WorkflowResponse Create Workflow for a particular flight
     * @throws ApiError
     */
    public static createFlightWorkflow(
        flightId: string,
        requestBody: WorkflowRequest,
    ): CancelablePromise<WorkflowResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/flights/{flight_id}/process',
            path: {
                'flight_id': flightId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param flightId
     * @returns WorkflowResponse Create Workflow for a particular flight
     * @throws ApiError
     */
    public static createDefaultFlightWorkflow(
        flightId: string,
    ): CancelablePromise<WorkflowResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/flights/{flight_id}/process_default',
            path: {
                'flight_id': flightId,
            },
        });
    }

    /**
     * @param flightId
     * @returns WorkflowsResponse Get workflows for flight
     * @throws ApiError
     */
    public static getWorkflowsForFlight(
        flightId: string,
    ): CancelablePromise<WorkflowsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/flights/{flight_id}/workflows',
            path: {
                'flight_id': flightId,
            },
        });
    }

    /**
     * @param projectId
     * @param groupId
     * @param requestBody
     * @returns WorkflowResponse Create Workflow for a particular group of flights
     * @throws ApiError
     */
    public static createGroupWorkflow(
        projectId: string,
        groupId: string,
        requestBody: WorkflowRequest,
    ): CancelablePromise<WorkflowResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/projects/{project_id}/groups/{group_id}/process',
            path: {
                'project_id': projectId,
                'group_id': groupId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param projectId
     * @returns WorkflowsResponse Get workflows
     * @throws ApiError
     */
    public static getWorkflows(
        projectId: string,
    ): CancelablePromise<WorkflowsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/projects/{project_id}/workflows',
            path: {
                'project_id': projectId,
            },
        });
    }

    /**
     * @param workflowId
     * @returns WorkflowDetailsResponse Get workflow details
     * @throws ApiError
     */
    public static getWorkflowDetails(
        workflowId: string,
    ): CancelablePromise<WorkflowDetailsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/workflows/{workflow_id}',
            path: {
                'workflow_id': workflowId,
            },
        });
    }

    /**
     * @param workflowId
     * @returns FilesResponse Get associated input/output files
     * @throws ApiError
     */
    public static getFiles(
        workflowId: string,
    ): CancelablePromise<FilesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/workflows/{workflow_id}/files',
            path: {
                'workflow_id': workflowId,
            },
        });
    }

    /**
     * @param workflowId
     * @param fileId
     * @returns string Get pre-signed GET url for associated input/output files
     * @throws ApiError
     */
    public static getFilePresignedUrl(
        workflowId: string,
        fileId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/workflows/{workflow_id}/files/{file_id}/presigned_url',
            path: {
                'workflow_id': workflowId,
                'file_id': fileId,
            },
        });
    }

    /**
     * @param workflowId
     * @param taskId
     * @returns Task [admin] Reset task
     * @throws ApiError
     */
    public static resetTaskStatus(
        workflowId: string,
        taskId: string,
    ): CancelablePromise<Task> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/workflows/{workflow_id}/tasks/{task_id}/reset',
            path: {
                'workflow_id': workflowId,
                'task_id': taskId,
            },
        });
    }

    /**
     * @param workflowId
     * @param taskId
     * @param requestBody
     * @returns Task Updated task
     * @throws ApiError
     */
    public static updateTaskStatus(
        workflowId: string,
        taskId: string,
        requestBody: TaskStatusRequest,
    ): CancelablePromise<Task> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/workflows/{workflow_id}/tasks/{task_id}/status',
            path: {
                'workflow_id': workflowId,
                'task_id': taskId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param workflowId
     * @param taskId
     * @returns void
     * @throws ApiError
     */
    public static getPluginUi(
        workflowId: string,
        taskId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/workflows/{workflow_id}/tasks/{task_id}/ui',
            path: {
                'workflow_id': workflowId,
                'task_id': taskId,
            },
            errors: {
                301: `Redirect to plugin ui`,
            },
        });
    }

}
