import { Card, makeStyles, shorthands, mergeClasses } from "@fluentui/react-components"

type SmallCardProps = {
    cardData: {
        id: number,
        icon: string,
        title: string,
        info: string
    }
}
const SmallCard = ({ cardData }: SmallCardProps) => {
    const styles = useStyles();

    return <Card
        className={mergeClasses(styles.card)}
    >
        {
            cardData.title === "Customizable" ? <p className={styles.launch} style={{ borderRadius: "0px 0px 0px 16px", padding: "4px 15px" }}>Launching Soon</p> : null
        }
        <img className={styles.cardImg} src={cardData.icon} alt="cardData.title" />
        <h3 className={styles.cardHeading}>{cardData.title}</h3>
        <p className={styles.cardInfo}>{cardData.info}</p>
    </Card>
}

const useStyles = makeStyles({
    card: {
        width: "98%",
        maxWidth: "100%",
        height: 'calc(100% - 0rem)',
        ...shorthands.border("1px", "solid", "#D6D6DE"),
        ...shorthands.borderRadius("4px"),
        boxShadow: "none",
        position: "relative"
    },
    cardImg: {
        width: "20px"
    },
    cardHeading: {
        color: "#3D404A",
        fontSize: "1rem",
        fontWeight: 500,
        lineHeight: "1rem",
        ...shorthands.margin(0),
    },
    cardInfo: {
        color: "#3D404A",
        fontSize: ".8rem",
        fontWeight: 400,
        lineHeight: "1rem",
        marginTop: ".5em"
    },
    launch: {
        position: "absolute",
        right: 0,
        backgroundColor: "#D9DFFF",
        fontSize: "12px",
        lineHeight: "20px",
        color: "#6F82DC",
        top: 0,
        fontWeight: "600"
    }
})
export default SmallCard