import * as React from 'react';
import { AuthContext } from "../AuthContext";
import { Link, useParams } from 'react-router-dom';
import { OrganisationsService, Project } from '../services/openapi';
import { Header } from './Header';
import { Button, tokens, Toolbar } from '@fluentui/react-components';

export const OrganisationProjects = () => {
    const { me: user } = React.useContext(AuthContext);
    const { orgId } = useParams();
    const [projects, setProjects] = React.useState<Project[] | null>();
    const [error, setError] = React.useState('');
    const [search, setSearch] = React.useState('');

    React.useEffect(() => {
        OrganisationsService.getAllMemberProjects(orgId!)
            .then((ps) => setProjects(ps || []))
            .catch((err) => setError(`Could not fetch projects ${err}`))
    }, [orgId])

    if (!user?.admin) {
        return <div>You are not authorized to use this page.</div>
    }

    return <div style={{ height: '96%' }}>
        <Header>
            <div
                style={{
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <div></div>
                <Button appearance="primary" style={{ paddingLeft: '2em', paddingRight: '2em', marginRight: '1em' }}>
                    <Link to="/admin/manageOrganisations" style={{ textDecoration: 'none', color: 'unset' }}>ManageOrganisations</Link>
                </Button>
            </div>
        </Header>
        <div style={{
            display: 'flex',
            paddingRight: '3em',
            flexDirection: 'column',
            height: "95%",
            margin: '1em',
            backgroundColor: tokens.colorNeutralBackground1,
            borderRadius: '0.5em',
            boxShadow: '5px 1px 20px 0px rgba(0, 0, 0, 0.05)'
        }}>
            <div style={{
                fontWeight: 600, fontSize: '2.5em',
                margin: '1em'
            }}>
                Organisation Projects
            </div>

            {error && <div>{error}</div>}

            <Toolbar>
                <input
                    type="input"
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Search projects"
                    style={{
                        width: '80%',
                        borderRadius: '1.5em',
                        padding: '1em 2em',
                        fontSize: '1.1em',
                        fontWeight: 'bold',
                        border: '0.01em solid black',
                        marginLeft: '0.5em',
                    }}
                />
            </Toolbar>

            <br />
            <div className='scrollablelist' style={{
                overflowY: 'scroll',
                height: '100%',
            }}>
                {projects && projects.filter((r) =>
                    r.name.toLowerCase().includes(search) || (r.notes || '').includes(search)
                ).map((p) => {
                    return <div
                        key={p.id}
                        style={{
                            padding: '0.8em',
                            margin: '0.8em',
                            backgroundColor: tokens.colorNeutralBackground1Hover,
                            borderRadius: '1em',
                            fontSize: '1.2em',
                        }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'end' }}>
                            <div>
                                <p>{p.name} ({p.id})</p>
                                <p><Link to={`/projects/${p.id}`}>Go to project</Link></p>
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </div >
    </div>
}