import { makeStyles, shorthands } from "@fluentui/react-components";

interface PrimaryButtonProps {
    label: string;
    onClick?: () => void;
    disabled?: boolean;
    type?: "submit" | "button";
}

export function PrimaryButton2(props: PrimaryButtonProps) {
    const { type, label, onClick = () => null, disabled = false } = props;
    const classes = usePrimaryStyles();

    return <button type={type} className={classes.button} onClick={onClick} disabled={disabled}>{label}</button>
}

const usePrimaryStyles = makeStyles({
    button: {
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        ...shorthands.outline("none"),
        color: "#FFF",
        textAlign: "center",
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "20px",
        letterSpacing: "0.1px",
        ...shorthands.padding("10px", "24px"),
        ...shorthands.border("none"),
        height: "36px",
        padding: "6px 32px",
        flexShrink: 0,
        ...shorthands.borderRadius("6px"),
        ...shorthands.border("0.75px", "solid", "#FFF"),
        backgroundColor: "#007AFF",
        // ":hover": {
        //     backgroundColor: "#7E7CFF",
        //     boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
        // },
        // ":active": {
        //     backgroundColor: "#3C3AC7",
        // },
        ":disabled": {
            backgroundColor: "rgba(29, 27, 32, 0.12)",
            color: "rgba(29, 27, 32, 0.38)",
            ":active": {
                backgroundColor: "black",
            },
            ":hover": {
                backgroundColor: "rgba(29, 27, 32, 0.12)",
                color: "rgba(29, 27, 32, 0.38)",
                boxShadow: "none",
            },
        }
    }
});