import seamless from "../../../assets/icons/landing/seamless.svg";
import unMatch from "../../../assets/icons/landing/unMatch.svg";
import constEffective from "../../../assets/icons/landing/costEffective.svg";
import effortLess from "../../../assets/icons/landing/effortless.svg";
import unAware from "../../../assets/icons/landing/unAware.svg";
import unParallel from "../../../assets/icons/landing/unParallel.svg";


export const flyghtCloudData = [
    {
        id: 1,
        title: 'Seamless End-to-End Solution',
        icon: seamless,
        info: "Eliminate finger-pointing with a fully integrated platform "
    },
    {
        id: 2,
        title: 'Unmatched Flexiblity',
        icon: unMatch,
        info: "Choose the processing software that best suits your needs"
    },
    {
        id: 3,
        title: 'Effortless Collaboration',
        icon: effortLess,
        info: "Share data and insights with ease across your team"
    },
    {
        id: 4,
        title: 'Cost-Effective',
        icon: constEffective,
        info: "Get started with minimal upfront investment"
    },
    {
        id: 5,
        title: 'Unwavering Security',
        icon: unAware,
        info: "Your data is always protected with robust security measures"
    },
    {
        id: 6,
        title: 'Unparalleled Speed',
        icon: unParallel,
        info: "Gain insights from your data faster than ever before"
    }
]