import React from 'react'
import { Button, makeStyles, shorthands } from '@fluentui/react-components';
import about_bg from "../../../../assets/icons/landing/about.webp"
import MobileHeader from '../../common/MobileHeader';
import { aboutUsData } from '../../common/AboutUsData';
import OfferCard from './OfferCard';
import offerImg from "../../../../assets/icons/landing/offerImg.webp"
import { useNavigate } from 'react-router-dom';

function MobileAboutUs() {
    const classes = useStyles();
    const navigate = useNavigate();
    return (
        <div>
            <div className={classes.container}>
                <MobileHeader />
                <div className={classes['mob-container']}>
                    <div style={{ color: "white" }}>
                        <h3 className={classes.initialHeading}> About Flyght Cloud </h3>
                        <h3 className={classes.barndingInfo} style={{ margin: "1em 0", }}>Flyght Cloud is a SaaS platform developed by ideaForge Technology Limited, a pioneer and pre-eminent market leader in the Indian unmanned aircraft systems (“UAS”) market. With the largest operational deployment of indigenous UAVs across India, an ideaForge manufactured drone takes off every five minutes for surveillance and mapping on average. Our customers have completed over 450,000 flights using our UAVs, solidifying our position as a global leader in the dual-use category (civil and defense) drone manufacturers.</h3>
                    </div>
                </div>
            </div>
            <div className={classes['mob-container']}>
                <h3 className={classes.ssinitialHeading}> Our Mission </h3>
                <h3 className={classes.ssbarndingInfo}>At Flyght Cloud, our mission is to empower businesses and organizations with the most advanced and innovative drone data analytics solutions. We believe that the power of drone data lies in its ability to provide valuable insights and intelligence. By providing easy-to-use, scalable, and powerful software tools, we aim to help our users unlock the full potential of their drone data.</h3>
            </div>
            <div className={classes['mob-container']}>
                <h3 className={classes.ssinitialHeading}> What We Offer </h3>
                <div>
                    {aboutUsData && (
                        <div className={classes.grid}>
                            {aboutUsData.map((data, index) => (
                                <div key={index} >
                                    <OfferCard key={index} offerData={data} />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            <img style={{ width: "100%" }} src={offerImg} alt='offer_icon' />
            <div className={classes['mob-container']} style={{ marginBottom: 0 }}>
                <h2 className={classes.ssinitialHeading}>Ready to see the future of data analytics?</h2>
                <h3 className={classes.ssbarndingInfo} style={{ fontWeight: "500" }}>Sign in to your Flyght Cloud account or book a demo to experience the future of drone data analytics firsthand. Whether you're a seasoned professional or just starting your journey with drone data, Flyght Cloud has the tools and expertise to support your success.</h3>
                <Button shape="circular" className={classes.gradBtn}
                    onClick={() => {
                        navigate(`/book-demo`);
                    }}
                    style={{ background: "#617AFA", color: "white", border: "none" }}
                >Connect to Expert</Button>
            </div>
            <div style={{ marginBottom: "1em", fontSize: ".7rem", textAlign: "center" }}>
                <p style={{ color: "#586A84" }}>Copyright © 2024 ideaForge Technology Ltd. All rights reserved.</p>
            </div>
        </div>
    )
}

export default MobileAboutUs

const useStyles = makeStyles({
    "container": {
        minHeight: "50vh",
        maxHeight: "100vh",
        minWidth: "100vw",
        backgroundImage: `url(${about_bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
    },
    "mob-container": {
        width: "93%",
        margin: "2em auto 1em auto"
    },
    barndingLabel: {
        fontSize: "3rem",
        lineHeight: "3rem",
        fontWeight: "700"
    },
    barndingInfo: {
        fontSize: ".85rem",
        lineHeight: "1.1rem",
        color: "#ffff",
        marginTop: ".5em"
    },
    initialHeading: {
        fontSize: "1.8rem",
        fontWeight: "500",
        lineHeight: "2rem",
    },
    gradBtn: {
        ...shorthands.padding("10px"),
        marginTop: "1em",
        marginBottom: "1em",
        fontWeight: "1rem"
    },
    "secondary-container": {
        width: "93%",
        margin: "1em auto"
    },
    ssinitialHeading: {
        fontSize: "1.3rem",
        fontWeight: "500",
        lineHeight: "1.5rem",
    },
    grid: {
        display: "grid",
        gridTemplateColumns: 'repeat(2, 3fr)',
        marginTop: "1em",
        marginBottom: "2em",
        gridGap: '1em',
    },
    ssbarndingInfo: {
        fontSize: ".85rem",
        lineHeight: "1.1rem",
        color: "#08201D",
        margin: ".5em 0"
    },
    commonRow: {
        marginTop: "2em",
        marginBottom: "2em",
    },
})