/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ReportsResponse } from '../models/ReportsResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReportsService {

    /**
     * @param flightId
     * @returns ReportsResponse Get reports for flight
     * @throws ApiError
     */
    public static getFlightReports(
        flightId: string,
    ): CancelablePromise<ReportsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/flights/{flight_id}/reports',
            path: {
                'flight_id': flightId,
            },
        });
    }

    /**
     * @param projectId
     * @returns ReportsResponse Get reports for project
     * @throws ApiError
     */
    public static getProjectReports(
        projectId: string,
    ): CancelablePromise<ReportsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/projects/{project_id}/reports',
            path: {
                'project_id': projectId,
            },
        });
    }

}
