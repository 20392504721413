import reduceTime_icon from "../../../assets/icons/landing/reduceTime.svg";
import preTested_icon from "../../../assets/icons/landing/preTested.svg";
import highPerformance_icon from "../../../assets/icons/landing/highPerformance.svg";
import commitments_icon from "../../../assets/icons/landing/commitments.svg";

export const WorksData = [
    {
        id: 1,
        title: 'Reduced time from plan to insight',
        icon: reduceTime_icon,
        info: "Flyght Cloud minimises the time it takes to capture data, process it and gain valuable insights."
    },
    {
        id: 2,
        title: 'Access pre-tested domain expert workflows',
        icon: preTested_icon,
        info: "Leverage the expertise built into Flyght Cloud’s workflows for your specific industry."
    },
    {
        id: 3,
        title: 'Designed for high-performance GIS teams',
        icon: highPerformance_icon,
        info: "Flyght Cloud can handle even the most demanding workloads with ease."
    },
    {
        id: 4,
        title: 'No upfront commitments',
        icon: commitments_icon,
        info: "Get started with Flyght Cloud without any financial burden."
    }
]