import * as React from 'react'
import { Pagination } from '../projects/common/Pagination'
import { InsightsService, QueueItemResponse } from '../../services/openapi';
import { Button, createTableColumn, makeStyles, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, shorthands, Table, TableBody, TableCell, TableColumnDefinition, TableHeaderCell, TableRow, useTableFeatures } from '@fluentui/react-components';
import moment from 'moment';
import FilterIcon from "../../assets/icons/filter.svg";

interface FormDataTypes {
  status: string | null;
}
function QueueItems() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [hasNext, setHasNext] = React.useState(false);
  const [currentFilter, setCurrentFilter] = React.useState('');
  const [statusVal, setStatusVal] = React.useState<FormDataTypes>({ status: null });
  const [queueItemsData, setQueueItemsData] = React.useState<QueueItemResponse[] | []>();


  const prev = () => {
    setPage(page - 1);
    load(statusVal.status, page - 1);
  };

  const next = () => {
    setPage(page + 1);
    load(statusVal.status, page + 1);
  };

  const load = (status: string | null, page: number) => {
    InsightsService.getQueueItems(page, 10, status)
      .then((res) => {
        const queueItems = res.map((item) => ({
          ...item,
        })) as QueueItemResponse[];

        setQueueItemsData(queueItems);
        setHasNext(res.length >= 10);
      })
      .catch((err) => {
        console.table(err);
      });
  };


  React.useEffect(() => {
    load(statusVal.status, page)
  }, [page]);

  type Item = {
    topic: { label: string };
    workflow_id: { label: string };
    status: { label: string };
    created_at: { label: string };
    updated_at: { label: string };
  };
  const columns: TableColumnDefinition<Item>[] = [
    createTableColumn<Item>({
      columnId: "topic",
    }),
    createTableColumn<Item>({
      columnId: "workflow_id",
    }),
    createTableColumn<Item>({
      columnId: "status",
    }),
    createTableColumn<Item>({
      columnId: "created_at",
    }), createTableColumn<Item>({
      columnId: "updated_at",
    }),
  ];

  const items: Item[] = queueItemsData?.map((item) => ({
    topic: { label: item.topic || "" },
    workflow_id: { label: item.workflow_id || "" },
    status: { label: item.status || "" },
    created_at: { label: item.created_at || "" },
    updated_at: { label: item.updated_at || "" }
  })) || [];

  const { getRows } = useTableFeatures({
    columns,
    items,
  });
  const rows = getRows();

  const handleFilter = (status: string) => {
    load(status, page)
    setStatusVal({ ...statusVal, status: status })
  }

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "end" }}>
        {
          statusVal.status && (
            <Button style={{ marginRight: "1em" }} onClick={() => {
              load(null, page);
              setStatusVal({ ...statusVal, status: null })
            }}>Clear Filter</Button>
          )
        }
        <Menu>
          <MenuTrigger disableButtonEnhancement>
            <Button
              icon={<img src={FilterIcon} alt="icon" className={classes.iconImage} />}
              size="large"
              className={classes.filterButton}>
              <p className={classes.filterButtonText}>{statusVal.status !== null ? statusVal.status : "Filter"}</p>
            </Button>
          </MenuTrigger>

          <MenuPopover>
            <MenuList>
              <MenuItem onClick={() => {
                handleFilter("failed")
              }}>
                <p className={classes.menuText}>
                  failed
                </p>
              </MenuItem>
              <MenuItem onClick={() => {
                handleFilter("completed")
              }}>
                <p className={classes.menuText}>
                  completed
                </p></MenuItem>
              <MenuItem onClick={() => {
                handleFilter("processing")
              }}>
                <p className={classes.menuText}>
                  processing
                </p>
              </MenuItem>
              <MenuItem onClick={() => {
                handleFilter("queued")
              }}>
                <p className={classes.menuText}>
                  queued
                </p>
              </MenuItem>
            </MenuList>
          </MenuPopover>
        </Menu>
      </div>
      <Table>
        <TableRow>
          <TableHeaderCell>Topic</TableHeaderCell>
          <TableHeaderCell>Workflow Id</TableHeaderCell>
          <TableHeaderCell>Status</TableHeaderCell>
          <TableHeaderCell>Created Date</TableHeaderCell>
          <TableHeaderCell>Updated Date</TableHeaderCell>
        </TableRow>
        <TableBody>
          {
            rows.map(({ item }) => (
              <TableRow>
                <TableCell>{item.topic.label}</TableCell>
                <TableCell>{item.workflow_id.label}</TableCell>
                <TableCell>{item.status.label}</TableCell>
                <TableCell>{moment(item.created_at.label).format('DD/MM/YYYY, h:mm:ss')}</TableCell>
                <TableCell>{moment(item.updated_at.label).format('DD/MM/YYYY, h:mm:ss')}</TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
      <Pagination currentPage={page + 1} hasNext={hasNext} hasPrev={page > 0} onClickPrev={prev} onClickNext={next} />
    </div >
  )
}

export default QueueItems;
const useStyles = makeStyles({
  iconImage: {
    minWidth: "18px",
    minHeight: "18px",
  },
  filterButton: {
    ...shorthands.border("1px solid #7D8FA9"),
    ...shorthands.padding("6px 12px"),
    ":hover": {
      backgroundColor: "#E5E5FE",
    }
  },
  filterButtonText: {
    fontSize: "14px",
    color: "#7D8FA9",
    fontWeight: 400,
  },
  menuText: {
    fontSize: "14px",
  },
})