import React from 'react';
import enhanceIcon from "../../../../assets/icons/whatsNew/enahancement.png";
import improveIcon from "../../../../assets/icons/whatsNew/improvement.png";
import scalabilityIcon from "../../../../assets/icons/whatsNew/scalability.png";
import "./styles.css"

const BenefitsSection = () => {
    return <>
        <h1 className="bnHeading">Benefits</h1>
        <div className="benifitsContainer">
            <div className='bnBoxCotainer'>
                <div className="box">
                    <img alt='Enhanced' className="boxIcon" src={enhanceIcon} />
                    <h3 className="boxheading">Enhanced Precision</h3>
                    <p className="boxInfo">Take advantage of advanced measurement tools and GCP tagging for more accurate data outputs.</p>
                </div>
                <div className="box">
                    <img alt='Improved' className="boxIcon" src={improveIcon} />
                    <h3 className="boxheading">Improved Efficiency</h3>
                    <p className="boxInfo">Automate time-consuming tasks and integrate multiple data sources seamlessly.</p>
                </div>
                <div className="box">
                    <img alt='Scalability' className="boxIcon" src={scalabilityIcon} />
                    <h3 className="boxheading">Scalability</h3>
                    <p className="boxInfo">Manage large, complex projects with ease using flexible workflows and expanded data capabilities.</p>
                </div>
            </div>
        </div>
    </>
};

export default BenefitsSection;
