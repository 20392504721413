import { makeStyles, shorthands } from "@fluentui/react-components";
import previousIcon from "../../../assets/icons/back.svg";
import nextIcon from "../../../assets/icons/next.svg";

interface PaginationProps {
    currentPage: number;
    hasNext: boolean;
    hasPrev: boolean;
    onClickNext: () => void;
    onClickPrev: () => void;
}


export function Pagination(props: PaginationProps) {
    const { currentPage, hasNext, hasPrev, onClickNext, onClickPrev } = props
    const classes = useStyles()

    return <div className={classes.container}>
        {hasPrev && <button className={classes.actionButton} onClick={onClickPrev}>
            <img src={previousIcon} alt="previous page" />
            <p>Previous</p>
        </button>}

        <div className={classes.pageNumberContainer}>
            <p className={classes.pageNumber}>{currentPage}</p>
        </div>

        {hasNext && <button className={classes.actionButton} onClick={onClickNext}>
            <p>Next</p>
            <img src={nextIcon} alt="next page" />
        </button>}
    </div>
}

const useStyles = makeStyles({
    container: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        ...shorthands.gap("24px"),
        paddingTop: "12px",
        paddingBottom: "12px",
    },
    pageNumberContainer: {
        position: "relative",
        ...shorthands.borderRadius("50%"),
        ...shorthands.padding("20px"),
        backgroundColor: "#F9F5FF",
    },
    pageNumber: {
        position: "absolute",
        top: "50%",
        right: "50%",
        transform: "translate(50%,-50%)",
        color: "#7F56D9",
        textAlign: "center",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "20px",
        cursor: "pointer"
    },
    actionButton: {
        ...shorthands.borderRadius("8px"),
        ...shorthands.border("1px", "solid", "#D0D5DD"),
        backgroundClip: "#FFF",
        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        color: "#344054",
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "20px",
        display: "flex",
        ...shorthands.padding("8px", "14px"),
        justifyContent: "center",
        alignItems: "center",
        ...shorthands.gap("8px"),
        cursor: "pointer",
        backgroundColor: "#FFF",
    }
})