/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { QueueInsightsResponse } from '../models/QueueInsightsResponse';
import type { TaskQueueItemResponse } from '../models/TaskQueueItemResponse';
import type { WorkflowSummaryResponse } from '../models/WorkflowSummaryResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InsightsService {

    /**
     * @returns QueueInsightsResponse queue insights
     * @throws ApiError
     */
    public static getQueueInsights(): CancelablePromise<Array<QueueInsightsResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/queue/insights',
        });
    }

    /**
     * @param page
     * @param perPage
     * @param status
     * @returns TaskQueueItemResponse Queue items
     * @throws ApiError
     */
    public static getQueueItems(
        page?: number | null,
        perPage?: number | null,
        status?: string | null,
    ): CancelablePromise<Array<TaskQueueItemResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/queue/items',
            query: {
                'page': page,
                'per_page': perPage,
                'status': status,
            },
        });
    }

    /**
     * @param status
     * @param orgId
     * @param projectId
     * @param workflowId
     * @param page
     * @param perPage
     * @returns WorkflowSummaryResponse workflows
     * @throws ApiError
     */
    public static getWorkflows(
        status?: string | null,
        orgId?: string | null,
        projectId?: string | null,
        workflowId?: string | null,
        page?: number | null,
        perPage?: number | null,
    ): CancelablePromise<Array<WorkflowSummaryResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/workflows',
            query: {
                'status': status,
                'org_id': orgId,
                'project_id': projectId,
                'workflow_id': workflowId,
                'page': page,
                'per_page': perPage,
            },
        });
    }

}
