/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddPluginRequest } from '../models/AddPluginRequest';
import type { EditPluginRequest } from '../models/EditPluginRequest';
import type { PluginResponse } from '../models/PluginResponse';
import type { PluginsResponse } from '../models/PluginsResponse';
import type { PublishRequest } from '../models/PublishRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PluginsService {

    /**
     * @returns PluginsResponse Get plugins you have access to process
     * @throws ApiError
     */
    public static getOwnPlugins(): CancelablePromise<PluginsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/my_plugins',
        });
    }

    /**
     * @returns PluginsResponse Get available plugins
     * @throws ApiError
     */
    public static getPlugins(): CancelablePromise<PluginsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/plugins',
        });
    }

    /**
     * @param requestBody
     * @returns PluginResponse Add new plugin
     * @throws ApiError
     */
    public static addPlugin(
        requestBody: AddPluginRequest,
    ): CancelablePromise<PluginResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/plugins',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param pluginId
     * @param requestBody
     * @returns PluginResponse Edit plugin info
     * @throws ApiError
     */
    public static editPlugin(
        pluginId: string,
        requestBody: EditPluginRequest,
    ): CancelablePromise<PluginResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/plugins/{plugin_id}/edit',
            path: {
                'plugin_id': pluginId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param pluginId
     * @param requestBody
     * @returns PluginResponse Publish/Unpublish plugin
     * @throws ApiError
     */
    public static publishPlugin(
        pluginId: string,
        requestBody: PublishRequest,
    ): CancelablePromise<PluginResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/plugins/{plugin_id}/publish',
            path: {
                'plugin_id': pluginId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
