import { Button, Dialog, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Divider, Toast, ToastIntent, ToastTitle, Toaster, useId, useToastController } from "@fluentui/react-components";
import { Dismiss20Regular } from "@fluentui/react-icons";
import React, { useState } from "react";
import { ApiError, ReportResponse, WorkflowsService } from "../../../services/openapi";
import DownloadReportIcon from "../../../assets/icons/download_report.svg";
import ToastErrorIcon from "../../../assets/icons/toast_error.svg";
import ToastSuccessIcon from "../../../assets/icons/toast_success.svg";



interface Props {
    visible: boolean;
    reports?: ReportResponse[] | null;
    toggleModal: (flag: boolean) => void
}

export function DownloadReportModal(props: Props) {
    const { visible, toggleModal, reports } = props;

    const [url, setUrl] = React.useState('');
    const aRef = React.createRef<HTMLAnchorElement>();
    const toasterId = useId("toaster");
    const { dispatchToast } = useToastController(toasterId);

    const notify = () => dispatchToast(
        <Toast style={{ background: "#DFF6DD" }}>
            <ToastTitle style={{ fontSize: "14px", fontWeight: 400 }} media={<img src={ToastSuccessIcon} alt="error icon" style={{ marginRight: "0.25rem" }} />}>File Downloaded Successfully</ToastTitle>
        </Toast>,
        { intent: "success" }
    );

    const notifyError = (err: string) => dispatchToast(
        <Toast style={{ background: "#FDE7E9" }}>
            <ToastTitle style={{ fontSize: "14px", fontWeight: 400 }} media={<img src={ToastErrorIcon} alt="error icon" style={{ marginRight: "0.25rem" }} />}>{err}</ToastTitle>
        </Toast>,
        { intent: "error" }
    );

    const downloadReport = (r: ReportResponse) => {
        setUrl('');
        WorkflowsService.getFilePresignedUrl(r.workflowId, r.id)
            .then((url) => {
                setUrl(url);
                notify()
            })
            .catch((err: ApiError) => {
                notifyError(err.body)
            })
            .finally(() => { });
    };

    React.useEffect(() => {
        if (aRef.current) {
            aRef.current.click();
            setUrl('');
        }
    }, [aRef])

    return <>
        <Dialog
            open={visible}
            onOpenChange={(_, d) => { toggleModal(d.open) }}
        >
            <DialogSurface >
                <DialogBody >
                    <DialogTitle
                        action={
                            <DialogTrigger action="close">
                                <Button
                                    appearance="subtle"
                                    aria-label="close"
                                    icon={<Dismiss20Regular />}
                                />
                            </DialogTrigger>
                        }>Download reports</DialogTitle>
                    <DialogContent>
                        {(reports || []).map((r, i) => {
                            return <React.Fragment key={r.id}>
                                <div style={{ display: "flex", cursor: "pointer", alignItems: "center", justifyContent: "space-between", padding: "12px 0" }} onClick={() => {
                                    downloadReport(r)
                                }}>
                                    {r.name}
                                    <img src={DownloadReportIcon} alt="download icon" />
                                </div>
                                {i + 1 !== reports?.length && < Divider />}
                            </React.Fragment>
                        })}
                    </DialogContent>
                </DialogBody>
            </DialogSurface>
        </Dialog >

        {url && <a ref={aRef} rel="noreferrer" style={{ display: 'none' }} href={url} target='_blank'></a>}

        <Toaster toasterId={toasterId} position="top" />
    </>
}