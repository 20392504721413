import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Label } from '@fluentui/react-components';
import React, { useContext } from 'react';
import { AuthContext } from '../../AuthContext';
import { ProjectResponse, ProjectsService } from '../../services/openapi';

interface ModalProps {
    visible: boolean;
    toggleModal: (flag: boolean) => void;
    project: ProjectResponse;
    load: (page: number, orgId: string) => void;
    currentPage: number;
}

function DeleteProjectModal(props: ModalProps) {
    const { visible, project, toggleModal, load, currentPage } = props;
    const { orgId } = useContext(AuthContext);

    const handleDelete = (ev: React.FormEvent) => {
        ev.preventDefault();
        ProjectsService.archiveProject(project.id).then(() => {
            toggleModal(false);
            if (orgId?.orgId) {
                load(currentPage, orgId?.orgId)
            }
        })
    };

    return <Dialog
        open={visible}
    >
        <DialogSurface>
            <DialogBody>
                <DialogTitle>Delete Project</DialogTitle>
                <DialogContent>
                    <Label htmlFor={"name-input"} style={{ marginRight: "0.75rem" }}>
                        Are you sure you want to delete <b>{project.name}</b> ?
                    </Label>
                </DialogContent>
                <DialogActions>
                    <DialogTrigger disableButtonEnhancement>
                        <Button appearance="secondary" onClick={() => toggleModal(false)}>Close</Button>
                    </DialogTrigger>
                    <Button onClick={handleDelete} appearance="primary">
                        Delete
                    </Button>
                </DialogActions>
            </DialogBody>
        </DialogSurface>
    </Dialog >
}

export default DeleteProjectModal