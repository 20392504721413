import React     from 'react'
import { Button, makeStyles } from '@fluentui/react-components';
import HeaderNavBar from '../../common/HeaderNavBar';
import flyghtcloud_logo from "../../../../assets/icons/landing/logo_green.svg"
import { API_BASE } from '../../../../constants';
import { whatsNewDataNotes } from './whatsNewNotes';
import WhatsNewCard from './WhatsNewCard';
import useIsMobile from '../../../hooks/useIsMobile';
import MobileWhatsNew from './MobileWhatsNew';
import BenefitsSection from './BenefitsSection';

function WhatsNew() {
    const classes = useStyles();
    const isMobile = useIsMobile();

    const handleSignIn = () => {
        window.location.href = `${API_BASE}/api/sso/login?client_id=FLYGHTCLOUD`
    }
    return isMobile ? <MobileWhatsNew /> : <>
        <div className={classes.headerNavBar}>
            <HeaderNavBar icon={flyghtcloud_logo} signInBorder={true} color='black' btnBg="#E5E8EB" />
        </div>
        <div>
            <div className={classes.headerContainer} style={{
                background: "linear-gradient(283deg, #1482DA 0%, #91C84F 100%) no-repeat padding-box",
                backgroundColor: "transparent",
                color: "white",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <h1 className={classes.heading}>What's New?</h1>
                <p className={classes.headerInfo}>We are excited to announce a newer update to FlyghtCloud. With the new Measurement Tools, DeepMatrix integration, custom workflows, and LiDAR data conversion, managing your projects has never been easier. These features provide improved precision, and flexibility to streamline your operations.</p>
            </div>
            <div className={classes.headerContainer} style={{ background: "#F7F7F7", display: "flex", justifyContent: "center", alignItems: "center", gap: "3em", flexDirection: "column" }}>
                {
                    whatsNewDataNotes.map((item, index) => (
                        <div>
                            <WhatsNewCard key={index}
                                icon={item.icon} title={item.title} info={item.info}
                            />
                        </div>
                    ))
                }
            </div>
            <div className={classes.headerContainer}>
                <BenefitsSection />
            </div>
            <div className={classes.headerContainer} style={{ background: "#1482DA", color: "white" }}>
                <h2 className={classes.footerHeading}>Sign up for a free trial today</h2>
                <p className={classes.footerInfo}>Ready to experience these improvements</p>
                <Button
                    style={{ padding: "15px 30px", lineHeight: "1.5rem", fontSize: "2rem", fontWeight: "bold", marginTop: "1em", border: "1px solid #1482DA", color: "#1482DA" }} shape="circular"
                    onClick={handleSignIn}
                >
                    Sign Up Now
                </Button>
            </div>
        </div >
    </>
}

export default WhatsNew;
const useStyles = makeStyles({
    headerNavBar: {
        position: "sticky",
        top: 0,
        width: "100%",
        backgroundColor: "white",
        zIndex: 999
    },
    headerContainer: {
        textAlign: "center",
        padding: "3em 0"
    },
    heading: {
        fontSize: "4rem",
        fontWeight: "bold",
        lineHeight: "5rem",
    },
    headerInfo: {
        fontSize: "1rem",
        fontWeight: "500",
        lineHeight: "1.5rem",
        width: "70%",
        marginTop: "1em"
    },
    footerHeading: {
        fontSize: "2.2rem",
        fontWeight: "bold",
        lineHeight: "3.5rem"
    },
    footerInfo: {
        fontSize: "1.3rem",
        fontWeight: "500",
        lineHeight: "1rem"
    },
    benifitsContainer: {
        display: "flex",
        justifyContent: "center ",
        alignItems: "center",
        marginTop: "1em"
    },
    box: {
        padding: "1em",
        height: "30vh"
    },
    boxIcon: {
        width: "30%",
        height: "10vh"
    },
    boxheading: {
        fontSize: "1.5rem",
        fontWeight: "bold",
        lineHeight: "2rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#051F1E"
    },
    boxInfo: {
        fontSize: "1rem",
        color: "#051F1E",
        lineHeight: "1.2rem",
        fontWeight: "500",
        marginTop: ".5em"
    }
})