import { Body1, Card, shorthands, Tag, TagGroup, makeStyles, CardPreview, CardHeader } from '@fluentui/react-components'

interface dataStrategyProps {
    id: number,
    title: string,
    icon: string,
    info: string,
    tags: string[];
}
function DataStrategyCard({ dataStrategy }: { dataStrategy: dataStrategyProps }) {
    const classes = useStyles()
    return <Card className={classes.card}>
        <CardPreview style={{ padding: "1.5em", display: "flex", justifyContent: "center", alignItems: "center", height: "20vh" }}>
            <img className={classes.gridImg}
                src={dataStrategy.icon}
                alt="work logo"
                style={{ width: "80%" }}
            />
        </CardPreview>
        <CardHeader
            header={
                <Body1 style={{ width: "100%" }}>
                    <TagGroup role="list" className={classes.tagContainer} >
                        {
                            dataStrategy.tags.map((item, index) => <Tag key={index} role="listitem" className={classes.tagBar} style={{ height: "3.5vh", borderRadius: "4px" }}>{item}</Tag>
                            )
                        }
                    </TagGroup>
                    <p className={classes.cardInfo}>{dataStrategy.info}</p>
                </Body1>
            }
        />
    </Card >
}

const useStyles = makeStyles({
    card: {
        maxWidth: "100%",
        height: "calc(0rem + 100%)",
        ...shorthands.padding("0px"),
        ...shorthands.border('1px', 'solid', '#E3E9F2'),
        boxShadow: "none"
    },
    cardHeading: {
        fontSize: "1.5rem",
        fontWeight: 600,
        textAlign: "center"
    },
    cardInfo: {
        marginTop: "0",
        ...shorthands.padding("1.5em")
    },
    tagContainer: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        marginTop: "2em",
        marginBottom: "1em",
        ...shorthands.gap("1em")
    },
    tagBar: {
        backgroundColor: "#E3E6EB",
        color: "#272727",
        fontSize: ".5rem !important",
        ...shorthands.padding("4px", "10px"),
        display: "flex", justifyContent: "center", alignItems: "center",
        '& .f1g03r3y': {
            fontSize: '.7rem',
        },
    },
    gridImg: {
        width: "100%",
    },

})

export default DataStrategyCard