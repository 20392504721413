import {
    Button,
    CheckboxOnChangeData, Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Table,
    TableBody, TableCell,
    TableCellLayout,
    TableColumnDefinition,
    TableHeader, TableHeaderCell,
    TableRow,
    createTableColumn,
    makeStyles,
    useTableFeatures
} from "@fluentui/react-components";
import moment from "moment";
import * as React from "react";
import { Link } from "react-router-dom";
import verticalMode from "../assets/icons/more_actions.svg";
import reportIcon from "../assets/icons/reportIcon.svg";
import sampleImg from "../components/ODM_Stockpile_sample.png";
import { WorkflowResponse } from '../services/openapi';
import { getTimestampTime } from "../utils/datetime";
import { workflowData } from "./WorkflowData";


interface WrokflowProps {
    workflows: WorkflowResponse[];
}

export const FlightWorkflowsTable: React.FC<WrokflowProps> = ({ workflows }) => {
    const classes = useStyles()
    type Template_idCell = {
        label: string;
        icon: JSX.Element;
    };

    type LastUpdatedCell = {
        label: string;
    };

    type WorkflowNameCell = {
        label: string;
    };
    type WorkflowIdCell = {
        label: string;
    }

    type ReportsCell = {
        label: number;
    };

    type Item = {
        template_id: Template_idCell;
        reports: ReportsCell;
        lastUpdated: LastUpdatedCell;
        workflowName: WorkflowNameCell;
        workflowId: WorkflowIdCell;
    };

    const columns: TableColumnDefinition<Item>[] = [
        createTableColumn<Item>({
            columnId: "workflowName",
        }),
        createTableColumn<Item>({
            columnId: "lastUpdated",
        }),
    ];

    const items: Item[] = workflows.map((project) => ({
        template_id: {
            label: project.template_id ? project.template_id : " ", icon: <img style={{ width: "45px", borderRadius: "5px", height: "30px" }}
                src={sampleImg}
                alt="sampleImg"
            />
        },
        lastUpdated: { label: moment(getTimestampTime(project.updated_at)).fromNow() },
        reports: { label: project.numReports ? project.numReports : 0 },
        workflowName: { label: project.workflowName },
        workflowId: { label: project.id },
    }));

    const {
        getRows,
    } = useTableFeatures(
        {
            columns,
            items,
        },
    );


    const rows = getRows();

    return (
        <div style={{
            overflowY: 'auto',
            height: 'calc(35vh - 1rem)',
            scrollbarWidth: 'thin',
        }}
        >
            <Table sortable aria-label="Table with sort"
                id="flight-details-workflowrows"
            >
                <TableHeader className={classes.tableHeader} style={{ background: "white" }}>
                    <TableRow>
                        <TableHeaderCell>
                            Workflow Name
                        </TableHeaderCell>
                        <TableHeaderCell>
                            Last updated
                        </TableHeaderCell>
                        <TableHeaderCell>
                            Reports
                        </TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {rows.map(({ item, }, index) => {
                        const id = index === 0 ? "flight-details-workflowrow-title" : undefined
                        return <TableRow key={item.template_id.label} >
                            <TableCell id="flight-details-workflowrow-title">
                                <TableCellLayout media={item.template_id.icon}>
                                    <Link style={{ textDecoration: "none", color: "black" }} to={`/workflows/${item.workflowId.label}`}>{item.workflowName.label}</Link>
                                </TableCellLayout>
                            </TableCell>
                            <TableCell>{item.lastUpdated.label}</TableCell>
                            <TableCell style={{ display: "flex", alignItems: "center" }}><img src={reportIcon} style={{ marginRight: ".5em" }} />  {item.reports.label}</TableCell>
                        </TableRow>
                    })}
                </TableBody>
            </Table>
        </div>
    );
};



const WorkflowMoreFeature = () => {
    const [checked, setChecked] = React.useState(false);
    const handleChange = (
        ev: React.ChangeEvent<HTMLInputElement>,
        data: CheckboxOnChangeData
    ) => {
        setChecked(Boolean(data.checked));
    };
    return <Dialog modalType="non-modal">
        <DialogTrigger disableButtonEnhancement>
            <img style={{ cursor: "pointer", width: "8%" }} src={verticalMode} />
        </DialogTrigger>
        <DialogSurface aria-describedby={undefined}>
            <DialogBody>
                <DialogTitle>Download
                    <p style={{ fontSize: "1rem", fontWeight: "normal" }}>Generated on: 14-12-2023</p>
                </DialogTitle>
                <DialogContent>
                    <p>
                        Coming soon
                    </p>
                </DialogContent>
                <DialogActions>
                    <DialogTrigger disableButtonEnhancement>
                        <Button disabled={!checked} appearance="primary">
                            Download All
                        </Button>
                    </DialogTrigger>
                    <DialogTrigger disableButtonEnhancement>
                        <Button appearance="secondary">Download Selected</Button>
                    </DialogTrigger>
                </DialogActions>
            </DialogBody>
        </DialogSurface>
    </Dialog>
}

const useStyles = makeStyles({
    tableHeader: {
        position: "sticky",
        top: "0",
        zIndex: "1",
    }

})
