import * as React from 'react'
import FaqCategoryList from '../landing/pages/faqs/FaqCategoryList';
import { makeStyles, tokens, shorthands } from '@fluentui/react-components';
import { FASQsData } from '../landing/common/FaqsData';
import { Link } from 'react-router-dom';
import support_icon from "../../assets/icons/nav_icons/support.svg"

interface Faq {
    question: string;
    answer: string;
}
interface FaqCategory {
    id: number;
    type: string;
    faqs: Faq[];
}
function SupportFaqs() {
    const classes = useStyles();
    const [faqsDatas, setFaqsDatasProps] = React.useState<FaqCategory[] | null>(null);

    React.useEffect(() => {
        setFaqsDatasProps(FASQsData);
    }, []);

    return <>
        <div className={classes.projectHeaderText}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <Link style={{ textDecoration: "none", fontSize: '1rem', color: "black", fontWeight: "normal" }} className={classes.flexContent} to="/support"><img alt="support_icon" style={{ paddingRight: ".5em", }} src={support_icon} /> Support</Link></div> <p style={{ margin: "0 .2em " }}>{'>'}</p> <div style={{ fontSize: '1rem' }}>FAQ</div>
        </div>
        <div className={classes.bgContainer} style={{ background: tokens.colorNeutralBackground1, overflow: "auto" }}>
            <h2 className={classes.primaryHeading} style={{ background: "white", paddingTop: ".3em", paddingLeft: ".3em", height: "6vh", zIndex: 9999, position: "sticky", top: "0" }} >Frequently Asked Questions</h2>
            <div className={classes.quesContainer}>
                {faqsDatas && (
                    <div >
                        {faqsDatas.map((data, index) => (
                            <div key={index} >
                                <FaqCategoryList key={index} faqsData={data} />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    </>
}

const useStyles = makeStyles({
    bgContainer: {
        width: "98%",
        ...shorthands.borderRadius("1em"),
        paddingLeft: "1em",
        ...shorthands.margin("1em"),
        height: "calc(92vh - 4.5rem)",
        scrollbarWidth: "thin",
        boxShadow: "5px 1px 20px 0px rgba(0, 0, 0, 0.05)",
    },
    quesContainer: {
        maxWidth: "60%",
        width: "55%",
    },
    primaryHeading: {
        fontSize: "1.5rem",
        fontWeight: 600,
    },
    projectHeaderText: {
        height: "3rem",
        paddingLeft: "1.25rem",
        display: "flex",
        alignItems: "center",
        color: "#2B2B2B",
        ...shorthands.borderBottom("1px", "solid", "#E3E9F2"),
        "> span": {
            fontWeight: "700",
        },
    },
    flexContent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

})

export default SupportFaqs