import { Button, Dialog, DialogBody, DialogContent, DialogSurface, DialogTitle, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, Switch, Text, tokens } from '@fluentui/react-components';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { WorkflowTemplateResponse, WorkflowTemplatesService, WorkflowsService } from '../services/openapi';
import { WorkflowCard } from './WorkflowCard';

interface ModalProps {
    visible: boolean;
    toggleModal: (flag: boolean) => void;
    label?: string;
    style?: React.CSSProperties,
    className?: string,
    projectId: string;
    projectName?: string;
    flightId: string;
    flightName?: string;
    groupId?: string,
    appearance?: 'secondary' | 'primary' | 'outline' | 'subtle' | 'transparent';
}

function StartWorkflowModal(props: ModalProps) {
    const [templates, setTemplates] = React.useState<WorkflowTemplateResponse[]>([]);
    const [selectedTemplateId, setSelectedTemplateId] = React.useState('');
    const [selectedGroupId, setSelectedGroupId] = React.useState(undefined as string | undefined);
    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState('');
    const navigate = useNavigate();

    const buttonText = (templates || []).find((t) => t.id === selectedTemplateId)?.name || '-- Select a workflow template --';
    const description = (templates || []).find((t) => t.id === selectedTemplateId)?.description || '';


    React.useEffect(() => {
        showDialog()
    }, [props.projectId, props.flightId])


    const showDialog = () => {
        setSelectedTemplateId('');
        if (templates && templates.length) {
            setOpen(true);
            return;
        }
        WorkflowTemplatesService.getTemplates()
            .then((ts) => {
                setTemplates(ts.templates);
                setOpen(true);
            })
            .catch((err) => setError(`Could not get workflow templates: ${err}`));
    }

    const startWorflow = () => {
        if (!selectedTemplateId) return;
        if (selectedGroupId) {
            WorkflowsService.createGroupWorkflow(props.projectId, selectedGroupId, { templateId: selectedTemplateId, config: {} })
                .then((wf) => {
                    setOpen(false);
                    navigate(`/workflows/${wf.id}`);
                })
                .catch((err) => setError(`Could not start workflow: ${err}`));
        } else {
            WorkflowsService.createFlightWorkflow(props.flightId, { templateId: selectedTemplateId, config: {} })
                .then((wf) => {
                    setOpen(false);
                    navigate(`/workflows/${wf.id}`);
                })
                .catch((err) => setError(`Could not start workflow: ${err}`));
        }
    };

    return <Dialog
        open={props.visible}
    >
        <DialogSurface>
            <DialogBody>
                <DialogTitle style={{ fontSize: 'unset' }}>
                    {(props.projectName && props.flightName) ?
                        <>Initiate workflow for flight {props.flightName} in project {props.projectName}</>
                        : <>Initiate workflow</>
                    }
                    {props.groupId && (<div>
                        <Switch
                            value={selectedGroupId}
                            onChange={(v) => {
                                if (v.target.value) {
                                    setSelectedGroupId(props.groupId);
                                } else {
                                    setSelectedGroupId(undefined);
                                }
                            }}
                        /> Initiate workflow for Group Id {props.groupId}
                    </div>)}
                </DialogTitle>
                <DialogContent style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                    <Menu positioning={'below-start'}>
                        <MenuTrigger>
                            <div >
                                <Button size='large' style={{ width: "100%" }}>
                                    {buttonText}
                                </Button>
                                <div><Text size={200}>{description}</Text></div>
                            </div>
                        </MenuTrigger>
                        <MenuPopover style={{ width: "550px", maxWidth: "550px" }}>
                            <MenuList style={{ overflowY: 'auto', maxHeight: '20em' }}>
                                {templates.map((t) => {
                                    return <MenuItem
                                        key={t.id}
                                        style={{
                                            backgroundColor: tokens.colorNeutralBackground1,
                                            minWidth: "100%"
                                        }}
                                        onClick={() => setSelectedTemplateId(t.id)}
                                    >
                                        <WorkflowCard template={t} />
                                    </MenuItem>
                                })}
                            </MenuList>
                        </MenuPopover>
                    </Menu>
                    <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end', marginTop: '1em' }}>
                        <div style={{ flexGrow: 1 }}>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Button onClick={() => props.toggleModal(false)}>Cancel</Button>
                            <Button appearance='primary' disabled={!selectedTemplateId} onClick={() => startWorflow()}>Start</Button>
                        </div>
                    </div>
                </DialogContent>
            </DialogBody>
        </DialogSurface>
    </Dialog >
}

export default StartWorkflowModal