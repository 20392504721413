import React from 'react'
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTrigger, Input, makeStyles, Select } from '@fluentui/react-components';
import { ProjectsService } from '../../../services/openapi';
import modalClose from "../../../assets/icons/modalClose.svg"


interface AddNewFileProps {
    toggleCmnBtns: (flag: boolean) => void;
    toggleAddNewFile: (flag: boolean) => void;
    toggleAddNewSelected: (flag: boolean) => void;
    projectId: string;
    load: () => void;
    notifySucess: (msg: string) => void;
}


function AddNewFile({ toggleCmnBtns, projectId, load, toggleAddNewSelected, toggleAddNewFile, notifySucess }: AddNewFileProps) {
    const classes = useStyles();
    const [fileType, setFileType] = React.useState('');
    const [name, setName] = React.useState('');

    const createFile = () => {
        if (!projectId || !fileType || !name) return;
        ProjectsService.createProjectFile(projectId, { fileType, name })
            .then(() => {
                notifySucess("New project file is created. Please browse and upload the required file now.");
                addNewCancelBtn();
                setFileType('');
                setName('');
                load();
            })
            .catch((e) => console.error(e))
    };

    const addNewCancelBtn = () => {
        toggleCmnBtns(true);
        toggleAddNewFile(false);
        toggleAddNewSelected(false);
    }
    const handleSubmit = (ev: React.FormEvent) => {
        ev.preventDefault();
    };
    return (
        <div>
            <Dialog open={true} modalType="non-modal">
                <DialogSurface aria-describedby={undefined}>
                    <form onSubmit={handleSubmit}>
                        <DialogBody>
                            <DialogContent>
                                <div style={{ display: "flex", justifyContent: "end", gap: ".5em", alignItems: "center" }}>
                                    <img onClick={addNewCancelBtn}
                                        style={{ cursor: "pointer" }} src={modalClose} />
                                </div>
                                <h1 style={{ textAlign: "start", fontSize: "1.5rem", marginBottom: "1.5em", fontWeight: "600", color: "#3E3E3E" }}>Manage Project Files</h1>

                                <div className={classes.customSelect}>
                                    <h2 className={classes.title}>Choose file type *</h2>
                                    <Select size='large' style={{
                                        border: "none", background: "#E6F0FF", borderRadius: "4px"
                                    }}
                                        value={fileType}
                                        onChange={(v) => setFileType(v.target.value)}
                                    >
                                        <option style={{ padding: "1em" }} value={''}>-- Select one --</option>
                                        <option value={'stockpile_boundary'}>Stockpile Boundary</option>
                                        <option value={'road_center_line'}>Road Center Line</option>
                                    </Select>
                                </div>
                                <div style={{ marginTop: "1.5em" }}>
                                    <h2 className={classes.title}>File name *</h2>
                                    <Input className={classes.inputField} value={name} onChange={(e) => setName(e.target.value)} />
                                </div>
                            </DialogContent>
                            <DialogActions style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "2em" }}>
                                <DialogTrigger disableButtonEnhancement>
                                    <Button className={classes.cmbBtns} appearance="secondary" onClick={addNewCancelBtn} style={{ width: "60%" }}>Cancel</Button>
                                </DialogTrigger>
                                <Button type="submit" disabled={!fileType || !name} className={classes.cmbBtns} style={{ width: "60%", backgroundColor: "#007AFF", color: "white", marginLeft: "1em" }} appearance="primary" onClick={createFile}>
                                    Create
                                </Button>
                            </DialogActions>
                        </DialogBody>
                    </form>
                </DialogSurface>
            </Dialog>
        </div>
    )
}

export default AddNewFile

const useStyles = makeStyles({
    title: {
        fontSize: "1.1rem",
        fontWeight: "500",
        marginBottom: ".5em"
    },
    inputField: {
        background: "#E6F0FF",
        border: "none",
        width: "100%",
        padding: ".6em",
        borderRadius: "4px"
    },
    customSelect: {
        "& .f1c1zstj": {
            background: "#E6F0FF",
            border: "none",
            width: "100%",
            borderRadius: "4px !important"
        }
    },
    fileInputLabel: {
        border: "1px solid #586A84",
        padding: ".5em",
        width: "45%",
        textAlign: "center",
        color: "#586A84",
        fontWeight: 600,
        borderRadius: "6px"
    },
    fileInput: {
        display: 'none',
    },
    actionBtns: {
        fontSize: "1.3rem",
        color: "#727272",
        cursor: "pointer",
        '&:hover': {
            color: "#000000"
        },
    },
    cmbBtns: {
        width: "100%",
        padding: "6px 12px",
        borderRadius: "6px",
        color: "#586A84",
        fontWeight: "600"
    },

})