import * as React from 'react';
import { Avatar, Button, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, makeStyles, mergeClasses, shorthands } from '@fluentui/react-components';
import { Link, NavLink } from 'react-router-dom';
import { AuthContext } from '../../../AuthContext';
import { API_BASE } from '../../../constants';
import { AuthService } from '../../../services/openapi';
import BookDemoModal from './BookDemoModal';

const navItems = [
    {
        href: "/",
        name: "Home",
    },
    {
        href: "/solutions",
        name: "Solutions",
    },
    {
        href: "/faqs",
        name: "FAQs",
    },
    {
        href: "/about-us",
        name: "About Us",
    },
    {
        href: "/whats-new",
        name: "What's New?",
    },

]

const profileItems = [
    {
        href: "/projects",
        name: "Projects",
    },
    {
        href: "/workFlow-library",
        name: "Workflows Library",
    },
    {
        href: "/discover-apps",
        name: "Discover Apps",
    },
    {
        href: "/",
        name: "Sign Out",
    },
]
interface headerNavProps {
    icon: string,
    color: string,
    btnBg?: string,
    signInBorder?: boolean,
}

function HeaderNavBar({ icon, color, btnBg, signInBorder }: headerNavProps) {
    const { me, setMe } = React.useContext(AuthContext);
    const classes = useStyles();
    const [bookDemoModalVisible, setBookDemoModalVisible] = React.useState(false)

    const logout = () => {
        setMe(null)
        AuthService.logout()
            .catch((err) => console.error(err))
    };

    const toggleBookDemoModal = (flag: boolean) => {
        setBookDemoModalVisible(flag)
    }

    const handleSignIn = () => {
        window.location.href = `${API_BASE}/api/sso/login?client_id=FLYGHTCLOUD`
    }

    return <div className={classes.navContainer} >
        <div className={classes.branding}>
            <img src={icon} alt="FLYGHT CLOUD Logo" />
            <p style={{ color: color, fontWeight: "400" }}>FLYGHT<span style={{ fontWeight: "700" }}>CLOUD</span></p>
        </div>
        <div style={{ display: "flex", position: "relative", flexWrap: "wrap", alignItems: "center" }}>
            {
                navItems.map(item =>
                    <NavLink
                        key={item.href}
                        style={{ color: color }}
                        to={item.href}
                        className={({ isActive }) =>
                            mergeClasses(
                                classes.navLinkContainer,
                                isActive && (item.href === '/' ? classes.activeNavLink : classes.isActives)
                            )
                        }
                    >
                        {item.name}
                    </NavLink>
                )
            }
            <div>
                <Button style={{ background: "#0A84FF", marginRight: "1.5rem", padding: "8px 15px", border: "none", color: "#FAFAFA" }} shape="circular" onClick={() => { toggleBookDemoModal(true) }}>Connect to Expert</Button>
                {
                    !me ?
                        <>
                            <Button
                                style={{ padding: "8px 15px", border: signInBorder ? "1px solid #242B35" : "none", color: signInBorder ? "#242B35" : "#617AFA", }} shape="circular"
                                onClick={handleSignIn}
                            >
                                Sign Up
                            </Button>
                        </>
                        : <Button
                            style={{ padding: "8px 15px", border: "none", color: "#586A84", background: btnBg ? btnBg : "" }} shape="circular"
                        >
                            <Link to='/dashboard'>Dashboard</Link>
                        </Button>
                }
                {
                    me ? <Menu>
                        <MenuTrigger disableButtonEnhancement>
                            <Avatar
                                name={me?.email}
                                style={{ cursor: "pointer", marginLeft: "1rem" }}
                            />
                        </MenuTrigger>
                        <MenuPopover className={classes.layersPopover}>
                            <MenuList style={{ width: "150px" }}>
                                {profileItems.map((item, index) => <MenuItem key={index} style={{ padding: 0 }}>
                                    <Link className={classes.menulist} to={item.href} onClick={item.href === '/' ? logout : undefined}>
                                        <p>
                                            {item.name}
                                        </p>
                                    </Link>
                                </MenuItem>)}
                            </MenuList>
                        </MenuPopover>
                    </Menu> : null
                }
            </div>
        </div>
        <BookDemoModal visible={bookDemoModalVisible} toggleModal={toggleBookDemoModal} />

    </div >
}


const useStyles = makeStyles({
    navContainer: {
        paddingLeft: "1rem",
        paddingRight: "1rem",
        paddingTop: ".5rem",
        paddingBottom: ".5rem",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        ...shorthands.gap("0.5rem"),
        ...shorthands.borderBottom("1px", "solid", "#E5E8EB"),
    },
    navLinkContainer: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "center",
        ...shorthands.gap("0.5rem"),
        ...shorthands.textDecoration("none"),
        fontSize: "1rem",
        marginRight: "1.5rem",
        textAlign: "center",
        ':hover': {
            ...shorthands.borderBottom("1px", "solid", "#E5E8EB"),
        },
    },
    activeNavLink: {
        ...shorthands.borderBottom("2px", "solid", "#E5E8EB"),
        fontWeight: "bold"
    },
    isActives: {
        ...shorthands.borderBottom("2px", "solid", "#242B35"),
        fontWeight: "blue"

    },
    expButton: {
        ...shorthands.padding("6px 12px"),
        ":hover": {
            backgroundColor: "#E5E5FE",
        }
    },
    menulist: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        ...shorthands.gap('0.5rem'),
        "> p": {
            color: "black",
            fontSize: "14px"
        },
        ...shorthands.textDecoration("none"),
    },
    layersPopover: {
        top: "4px !important",
    },
    branding: {
        display: "flex",
        ...shorthands.gap('.5rem'),
        fontSize: "21px",
        fontWeight: "700",
        lineHeight: "18px",
        height: "100%",
        alignItems: "center",
    },
})

export default HeaderNavBar