
import * as React from 'react'
import { Pagination } from '../projects/common/Pagination'
import { InsightsService, QueueInsightsResponse, QueueItemResponse, WorkflowSummaryResponse } from '../../services/openapi';
import { createTableColumn, Table, TableBody, TableCell, TableColumnDefinition, TableHeaderCell, TableRow, useTableFeatures } from '@fluentui/react-components';
import moment from 'moment';

function QueueInsights() {
  const [page, setPage] = React.useState(0);
  const [hasNext, setHasNext] = React.useState(false);
  const [queueInsightsData, setQueueInsightsData] = React.useState<QueueInsightsResponse[] | []>();


  const prev = () => {
    setPage(page - 1);
    load(page - 1);
  };

  const next = () => {
    setPage(page + 1);
    load(page + 1);
  };

  const load = (page: number) => {
    InsightsService.getQueueInsights()
      .then((res) => {
        setQueueInsightsData(res)
        setHasNext(res.length >= 10);
      })
      .catch((err) => {
        console.table(err)
      });
  };

  React.useEffect(() => {
    load(page)
  }, [page]);

  type Item = {
    topic: { label: string };
    queueSize: { label: number };
  };
  const columns: TableColumnDefinition<Item>[] = [
    createTableColumn<Item>({
      columnId: "topic",
    }),
    createTableColumn<Item>({
      columnId: "queueSize",
    }),
  ];

  const items: Item[] = queueInsightsData?.map((item) => ({
    topic: { label: item.topic || "" },
    queueSize: { label: item.queueSize || 0 },
  })) || [];

  const { getRows } = useTableFeatures({
    columns,
    items,
  });
  const rows = getRows();

  return (
    <div>
      <Table>
        <TableRow>
          <TableHeaderCell>Topic</TableHeaderCell>
          <TableHeaderCell>QueueSize</TableHeaderCell>
        </TableRow>
        <TableBody>
          {
            rows.map(({ item }) => (
              <TableRow>
                <TableCell>{item.topic.label}</TableCell>
                <TableCell>{item.queueSize.label}</TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
      <Pagination currentPage={page + 1} hasNext={hasNext} hasPrev={page > 0} onClickPrev={prev} onClickNext={next} />
    </div >
  )
}

export default QueueInsights