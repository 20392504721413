import * as React from 'react';
import { Button, Dialog, DialogBody, DialogContent, DialogSurface, DialogTitle, makeStyles, mergeClasses, shorthands } from "@fluentui/react-components";
import { WorkflowTemplateResponse } from '../services/openapi';
import { Loading } from './Loading';
import sample_image from './ODM_Stockpile_sample.png';
import sample_image_explain from './sample_image_explain.png';

const useStyles = makeStyles({
    parentContainer: {
        ...shorthands.borderRadius("0.5em"),
        ...shorthands.padding("1em"),
        backgroundColor: "#F5F6FF",
    },
    templateTitle: {
        display: "flex",
        alignItems: "initial",
        fontSize: "1.25em",
        fontWeight: "400",
    },
    textPadding: {
        ...shorthands.padding("0.25em")
    },
    container: {
        display: "flex",
        justifyContent: "space-between",
    },
    pluginsContainer: {
        display: "flex",
        justifyContent: 'space-between',
        maxHeight: "2em",
        marginTop: "0.5em",
        ...shorthands.gap("0.25em")
    },
    icon: {
        marginTop: "1em",
        marginLeft: "1em",
        justifyContent: "space-between",
        alignItems: "center",
        color: "#606060",
    },
});

interface Props {
    template?: WorkflowTemplateResponse;
    showDemoButton?: boolean;
}

export const WorkflowCard = ({ showDemoButton = false, template }: Props) => {
    const [showDemo, setShowDemo] = React.useState(false);
    const classes = useStyles()

    if (!template) {
        return <Loading />;
    }

    return <>
        <div className={classes.parentContainer}>
            <div className={mergeClasses(classes.templateTitle, classes.textPadding)}>
                {template.name}
            </div>
            <div className={classes.textPadding}>{template.description}</div>
            <div className={classes.container}>
                {showDemoButton && <>
                    <Button onClick={() => setShowDemo(true)}
                        style={{
                            fontWeight: "400", color: "2C2C2C", borderRadius: "0.25rem",
                            border: "1px solid #606060", backgroundColor: "#F6FAFF"
                        }}>View Demo</Button>
                    {showDemo ?
                        <Dialog modalType='non-modal' open={showDemo} onOpenChange={(_, d) => setShowDemo(d.open)}>
                            <DialogSurface style={{ width: '60%', height: '70%' }}>
                                <DialogBody style={{ width: '100%', height: '100%' }}>
                                    <DialogTitle style={{ fontSize: 'unset' }}>
                                        {template.name}
                                    </DialogTitle>
                                    <DialogContent style={{ width: '100%', height: '100%', justifyContent: 'space-between' }}>
                                        <img src={sample_image} alt="sample data" />
                                        <img src={sample_image_explain} alt="sample data" />
                                    </DialogContent>
                                </DialogBody>
                            </DialogSurface>
                        </Dialog>
                        : <></>
                    }
                </>}
            </div>
        </div>
    </>
}