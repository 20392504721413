const getBase = () => {
  let base = '';
  if (process.env.REACT_APP_API_BASE) {
    base = process.env.REACT_APP_API_BASE;
  } else if (window.location.href.indexOf('localhost:3000') > -1) {
    base = 'http://localhost:8000';
  }
  console.log('API BASE: ', base);
  return base;
};
export const API_BASE: string = getBase();
export const GTM_ID: string = process.env.REACT_APP_GTM_ID || '';
