import { makeStyles, shorthands } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import IdeaforgeIcon from "../assets/logo/ideaforge_small.svg";
import { MapLayerResponse, ViewsService } from "../services/openapi";
import { Loading } from "./Loading";
import OpenLayers from "./OpenLayers";
import { PageNotFound } from "./PageNotFound";
import BrandName from "./common/BrandName";

export function SharedMapViewer() {
    const location = useLocation();
    const [error, setError] = useState(null);
    const [layers, setLayers] = useState<MapLayerResponse | null>(null);
    const [loading, setLoading] = useState(false);
    const [shareID, setShareID] = useState("");
    const classes = useStyles();

    useEffect(() => {
        const shareID = location.pathname.split("/share/")[1];
        setShareID(shareID);
        setLoading(true);
        ViewsService.viewSharedMap(shareID).then(data => {
            setLoading(false);
            setLayers(data[0]);
        }).catch(err => {
            setLoading(false);
            setError(err);
        })
    }, [location.pathname]);

    if (loading) {
        return <div><Loading /></div>
    }

    if (!layers || error) {
        return <PageNotFound actionButton={false} />
    }

    return <div style={{ width: "100vw", height: "100vh", display: "flex", flexDirection: 'column' }}>
        <div className={classes.topBar}>
            <div className={classes.logoContainer}>
                <img src={IdeaforgeIcon} alt="logo" />
                <p>FLYGHT<strong>CLOUD</strong></p>
            </div>
        </div>

        <div className={classes.contact}>
            <p>This is a view-only map generated by Flyght Cloud. Contact our experts to learn more about <BrandName />’s advanced features.</p>
            {/* <Button appearance="secondary">Contact us</Button> */}
        </div>
        <OpenLayers layers={[layers]} showFullScreenIcon={false} showLayers={false} showIdeaforgeButton={true} shareID={shareID} showMeasurements={false} />
    </div>
}

const useStyles = makeStyles({
    topBar: {
        backgroundColor: "#281D6B",
        display: "flex",
        height: "48px",
        paddingTop: "8px",
        paddingBottom: "8px",
        paddingLeft: "16px",
        paddingRight: "16px",
        alignItems: "center",
    },
    logoContainer: {
        display: "flex",
        ...shorthands.gap("6px"),
        color: "#FFF",
        fontSize: "21px",
        fontWeight: 400,
        lineHeight: "18px"
    },
    contact: {
        height: "48px",
        color: "#2C2C2C",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "24px",
        backgroundImage: "linear-gradient(90deg, #D8BCFF 0.62%, #9ECBFF 100%)",
        boxShadow: "-1px 1px 10px 0px rgba(0, 0, 0, 0.10)",
        display: 'flex',
        alignItems: "center",
        paddingTop: "12px",
        paddingBottom: "12px",
        paddingLeft: "24px",
        paddingRight: "24px",
        ...shorthands.gap("32px"),
        '@media(max-width: 414px)': {
            display: "none"
        },
    }
});