import { Button, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, Persona } from "@fluentui/react-components"
import { Link } from "react-router-dom";
import { AlertBadge24Regular } from '@fluentui/react-icons';
import { AuthService } from "../services/openapi";
import { useContext } from "react";
import { AuthContext } from "../AuthContext";

interface Props {
    children?: string | JSX.Element | JSX.Element[];
    ButtonText?: string
    ButtonLink?: string
}

export const Header = ({ children, ButtonText, ButtonLink }: Props) => {
    const {me} = useContext(AuthContext);

    const logout = () => {
        AuthService.logout()
            .catch((err) => console.error(err))
            .finally(() => window.location.reload());
    };

    return <div
        style={{
            width: '100%',
            height: '4em',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0.5em',
            paddingTop: '1em',
            paddingBottom: '1em',
        }}
    >
        {children}
        {ButtonText && ButtonLink && <Button style={{
            background: "#5E5CE6", color: "white"
        }}>
            <Link to={ButtonLink} style={{ textDecoration: 'none', color: 'unset' }}>{ButtonText}</Link>
        </Button >}
    </div >
}
