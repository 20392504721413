import { Button, Dropdown, Field, Input, Option, makeStyles, shorthands, useId } from '@fluentui/react-components';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { isValidEmail } from '../../../utils/validators';
import { countries } from './CountryList';
import "./styles.css";

interface BookDemoFormProps {
    onSubmit: (data: any) => void;
}


function BookDemoForm({ onSubmit }: BookDemoFormProps) {
    const classes = useStyles()
    const defaultValue = '';
    const navigate = useNavigate();
    const comboId = useId("combo-multi");
    const options = ["Mining", "Construction", "Agriculture", 'Utilities', 'Railways', "Solar", "Surveillance", "Others"];
    const [data, setData] = React.useState({ fullname: '', city: '', email: "", phoneNumber: "", companyName: '', designation: "", country: "", industry: [] });
    const [isValidMail, setIsValidMail] = React.useState(false);


    const handleCountrySelect = (e: any, selectedData: any) => {
        setData({ ...data, country: selectedData.optionText ?? 'Country' })
    };
    const handleIndustrySelect = (e: any, selectedData: any) => {
        setData(prevState => ({
            ...prevState,
            industry: selectedData.selectedOptions
        }));
    }
    const handleSubmit = () => {
        const hasValidEmail = isValidEmail(data.email)
        setIsValidMail(!hasValidEmail);
        if (hasValidEmail) {
            onSubmit(data);
        }
    }
    return (
        <div >
            <form className='formContainer'
                onSubmit={(e) => { e.preventDefault() }}
            >
                <div
                >
                    <div style={{ paddingBottom: '.5em' }}>
                        <Input
                            className={classes.input}
                            appearance="outline"
                            size="large"
                            placeholder="Full Name *"
                            value={data.fullname}
                            onChange={(e) => {
                                setData({ ...data, fullname: e.target.value })
                            }}
                        />
                    </div>
                    <div style={{ paddingBottom: '.5em' }}>
                        <Field validationMessageIcon={null} validationMessage={isValidMail ? "Incorrect email address" : null}>
                            <Input
                                style={{
                                    width: '100%',
                                    border: isValidMail ? "1px solid red" : "1px solid #D1DBE8",
                                    padding: "8px",
                                    marginTop: ".1em",
                                    marginBottom: ".1em"
                                }}
                                appearance="outline"
                                size="large"
                                placeholder="Work Email *"
                                value={data.email}
                                onChange={(e) => {
                                    setIsValidMail(false)
                                    setData({ ...data, email: e.target.value })
                                }}
                            />
                        </Field>
                    </div>
                    <div style={{ paddingBottom: '.5em' }}>
                        <input
                            className={classes.input}
                            style={{ fontSize: "16px", padding: "10px 25px", borderRadius: "4px" }}
                            type="tel"
                            placeholder="Phone Number *"
                            value={data.phoneNumber}
                            onChange={(e) => {
                                setData({ ...data, phoneNumber: e.target.value })
                            }} />
                    </div>
                    <div style={{ paddingBottom: '.5em' }}>
                        <Input
                            className={classes.input}
                            appearance="outline"
                            size="large"
                            placeholder="Company Name *"
                            value={data.companyName}
                            onChange={(e) => {
                                setData({ ...data, companyName: e.target.value })
                            }}
                        />
                    </div>
                    <div style={{ paddingBottom: '.5em' }}>
                        <Input
                            className={classes.input}
                            appearance="outline"
                            size="large"
                            placeholder="Designation"
                            value={data.designation}
                            onChange={(e) => {
                                setData({ ...data, designation: e.target.value })
                            }}
                        />
                    </div>
                    <div style={{ paddingBottom: '.5em' }}>
                        <Dropdown
                            className={classes.dropDown}
                            placeholder='Country'
                            onOptionSelect={(e, data) => handleCountrySelect(e, data)}
                            defaultSelectedOptions={[defaultValue]}
                            defaultValue={defaultValue}
                            style={{ padding: "4px 14px" }}
                            listbox={{ className: classes.listbox }}
                        >

                            {countries.map((ele) => (
                                <Option key={ele} value={ele}>{ele}</Option>
                            ))}
                        </Dropdown>
                    </div>
                    <div style={{ paddingBottom: '.5em' }}>
                        <Input
                            className={classes.input}
                            appearance="outline"
                            size="large"
                            placeholder="City"
                            value={data.city}
                            onChange={(e) => {
                                setData({ ...data, city: e.target.value })
                            }}
                        />
                    </div>
                    <div style={{ paddingBottom: '.5em' }}>
                        <Dropdown
                            aria-labelledby={comboId}
                            className={classes.dropDown}
                            multiselect={true}
                            placeholder="Industry"
                            onOptionSelect={(e, data) => handleIndustrySelect(e, data)}
                            style={{ padding: "4px 14px" }}
                        >
                            {options.map((option) => (
                                <Option key={option}>
                                    {option}
                                </Option>
                            ))}
                        </Dropdown>
                    </div>
                    <p className='footerInfo'>Flyght Cloud uses the information you provide us to process your request, and occasionally contact you about relevant content and services. You can unsubscribe any time.
                        <span className='hiddenTxt'> For more information, check out our<span onClick={() => { navigate(`/eula`) }} style={{ color: "#1570EF", cursor: "pointer" }}> Terms of Service </span> & <span style={{ color: "#1570EF", cursor: "pointer" }} onClick={() => { navigate(`/privacy-policy`) }}> Privacy Policy.</span></span>
                    </p>

                    <div style={{ marginTop: "1rem" }}>
                        <Button onClick={handleSubmit}
                            disabled={!data.fullname || !data.companyName || !data.email || !data.phoneNumber}
                            type="submit" style={{ width: '100%', background: "#5E5CE6", color: "white", padding: "8px 16px", borderRadius: "8px" }}>
                            Submit
                        </Button>
                    </div>
                </div>
            </form >
        </div >
    )
}

const useStyles = makeStyles({
    input: {
        width: "100%",
        ...shorthands.border('1px', 'solid', '#D1DBE8'),
        ...shorthands.padding("8px"),
        marginTop: ".1em",
        marginBottom: ".1em"
    },
    dropDown: {
        minWidth: '100%',
        ...shorthands.border('1px', 'solid', '#D1DBE8'),
        marginTop: ".2em",
        marginBottom: ".2em",
        '& .f1k6fduh': {
            fontSize: "16px",
        }
    },
    listbox: {
        maxHeight: "200px",
    },
})

export default BookDemoForm