import { Button, makeStyles, shorthands } from '@fluentui/react-components';
import React, { createContext, useContext, useEffect, useState } from 'react';
import Joyride, { CallBackProps, Step } from 'react-joyride';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import { FeatureDemosService, FlightsService, ProjectsService, WorkflowsService } from '../services/openapi';
import Portal from '../utils/Portal';
import { getTourSteps } from './TourSteps';

export enum sourceTypes {
    startOnboarding = "start-onboarding",
}
export const TourContext = createContext<{
    startTour: ({ run, stepIndex }: {
        run: boolean;
        stepIndex: number;
    }) => void;
    tourCompletedModalVisible: boolean | undefined;
    hideCompletedModal: () => void;
    stepIndex: number;
    source?: sourceTypes;
    setTourSource: (source: sourceTypes) => void;
    isPrevious?: boolean;
}>({ startTour: ({ run, stepIndex, }: { run: boolean, stepIndex: number }) => { }, tourCompletedModalVisible: false, hideCompletedModal: () => { }, source: undefined, setTourSource: (source: sourceTypes) => { }, isPrevious: false, stepIndex: 0 });

interface ITourConfig {
    run: boolean,
    stepIndex: number,
    tourCompletedModalVisible?: boolean,
    source?: sourceTypes,
    isPrevious?: boolean,

}

export const TourProvider = ({ children }: any) => {
    const { orgId } = useContext(AuthContext);

    const [tourConfig, setTourConfig] = useState<ITourConfig>({ run: false, stepIndex: 0, tourCompletedModalVisible: false, source: undefined, isPrevious: false });
    const [additionalData, setAddiontionalData] = useState<Record<string, string | null>>({ projectID: null })
    const [steps, setSteps] = useState<Step[]>([])
    const navigate = useNavigate();
    const startTour = ({ run, stepIndex, tourCompletedModalVisible, source, }: ITourConfig) => setTourConfig({ run, stepIndex, tourCompletedModalVisible: Boolean(tourCompletedModalVisible), source });
    const hideCompletedModal = () => setTourConfig(prev => ({ ...prev, tourCompletedModalVisible: false }))
    const resetTourConfig = () => {
        setTourConfig({ run: false, stepIndex: 0, tourCompletedModalVisible: false, source: undefined });
    }
    const setTourSource = (source: sourceTypes) => {
        setTourConfig(prev => ({ ...prev, source }))
    }
    const loggedMe = React.useContext(AuthContext);
    const classes = useStyles();

    const closeGuideHandler = (reset: boolean = true) => {
        const hasTourSouce = tourConfig.source && tourConfig.source === sourceTypes.startOnboarding
        if (!hasTourSouce) {
            const mapTourIndex = [10, 11]
            if (mapTourIndex.includes(tourConfig.stepIndex)) {
                FeatureDemosService.addFeatureDemoView({ name: "map" }).catch(err => console.error("Error marking feature view", err))
            } else {
                FeatureDemosService.addFeatureDemoView({ name: "projects" }).catch((err) => { console.error("Error marking feature view", err) });
            }
        } else {
            localStorage.removeItem("tour");
        }
        if (reset) {
            resetTourConfig();
        }
    };

    const handleJoyrideCallback = async (data: CallBackProps) => {
        const {
            action,
            index,
            step,
            type,
        } = data;
        const hasTourSource = tourConfig.source && tourConfig.source === sourceTypes.startOnboarding
        const isPreviousAction = action === 'prev';
        if (tourConfig.run && isPreviousAction && step?.data?.previous && type === "step:after") {
            const currentIndex = index
            setTourConfig(prev => ({ run: false, stepIndex: 0, tourCompletedModalVisible: false, source: prev.source, isPrevious: true }));
            navigate(step.data.previous);
            setTimeout(() => {
                setTourConfig(prev => ({ ...prev, stepIndex: currentIndex - 1, run: true, isPrevious: false }));
            }, 200)
            return;
        }
        if (type === "step:after" || type === "error:target_not_found") {
            setTourConfig(prev => ({ ...prev, stepIndex: index + (isPreviousAction ? -1 : +1) }))
        }
        if (tourConfig.stepIndex === steps.length) {
            closeGuideHandler(false);
            setTourConfig({ run: false, stepIndex: 0, tourCompletedModalVisible: true });
            navigate("projects");
            return;
        }
        const nextIDs = [
            "#project-card-title",
            "#flight-actions-viewmaps",
            "#map-breadcrumb",
            "#flight-row",
            "#flight-details-workflowrow-title",
            "#workflow-details-detailedOutput"
        ]
        if (nextIDs.includes(step.target as string) && type === "step:after") {
            if (step.target === "#workflow-details-detailedOutput" && !isPreviousAction) {
                setTourConfig({ run: false, stepIndex: 0, tourCompletedModalVisible: true });
                if (!hasTourSource) {
                    await FeatureDemosService.addFeatureDemoView({ name: "projects" }).catch((err) => { console.error("Error marking feature view", err) });
                } else {
                    localStorage.removeItem("tour");
                }
                navigate(step.data.next);
                return;
            }
            if (!isPreviousAction) {

                if (hasTourSource) {
                    setTourConfig({ run: false, stepIndex: 0, tourCompletedModalVisible: false, source: sourceTypes.startOnboarding });
                } else {
                    resetTourConfig();
                }
                if (step.target === "#map-breadcrumb") {
                    if (!hasTourSource) {
                        await FeatureDemosService.addFeatureDemoView({ name: "map" }).catch(err => console.error("Error marking feature view", err))
                    } else {
                        localStorage.setItem("tour", JSON.stringify({ mapviewed: true }))
                    }
                }
                navigate(step.data.next);
            }
        }
    };


    const getAdditionalData = async () => {
        let projectID = ""
        let flightID = ""
        await ProjectsService.listProjects(0, 10, orgId?.orgId)
            .then((projects) => {
                projectID = projects.projects[0].id
                setAddiontionalData(prev => {
                    return {
                        ...prev,
                        projectID,
                    }
                })
            })
            .catch((err) => console.error(`Could not fetch projects for the tour guide: ${err}`));

        await FlightsService.getFlights(projectID)
            .then((fs) => {
                if (fs.flights.length === 0) return;
                flightID = fs.flights[0].id;
                setAddiontionalData(prev => {
                    return {
                        ...prev,
                        flightID,
                        noOfFlights: String(fs.flights.length),
                    }
                })
            })
            .catch((err) => console.error(`Could not fetch flights for the tour guide: ${err}`));
        WorkflowsService.getWorkflowsForFlight(flightID)
            .then((ws) => {
                if (ws.workflows.length === 0) return;
                setAddiontionalData(prev => {
                    return {
                        ...prev,
                        workflowID: ws.workflows[0].id,
                    }
                })
            })
            .catch((err) => console.error(`Could not fetch workflows for the tour guide: ${err}`));
    }

    useEffect(() => {
        if (loggedMe?.me && tourConfig.run) {
            getAdditionalData();
        }
    }, [tourConfig.source, loggedMe, tourConfig.run]);

    useEffect(() => {
        if (additionalData) {
            setSteps(getTourSteps(additionalData))
        }
    }, [additionalData])

    return (
        <TourContext.Provider value={{ startTour, tourCompletedModalVisible: tourConfig.tourCompletedModalVisible, hideCompletedModal, source: tourConfig.source, setTourSource, isPrevious: tourConfig.isPrevious, stepIndex: tourConfig.stepIndex }}>
            {children}
            <Joyride
                locale={{ next: "Next", back: "Previous", last: "Next" }}
                steps={steps}
                run={tourConfig.run}
                continuous
                callback={handleJoyrideCallback}
                stepIndex={tourConfig.stepIndex}
                styles={{ overlay: { height: '100%', maxHeight: '100%' } }}
                disableScrolling={true}
                floaterProps={{
                    options: {
                        computeStyle: {
                            gpuAcceleration: false
                        }
                    }
                }}
            />
            {tourConfig.run && <Portal>
                <div className={classes.buttonContainer}>
                    <Button className={classes.button} onClick={() => { closeGuideHandler(true) }} appearance='secondary'>Close guide</Button>
                </div>
            </Portal>}
        </TourContext.Provider>
    );
};

const useStyles = makeStyles({
    buttonContainer: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        position: "absolute",
        bottom: "40px",
        justifyContent: "center",
    },
    button: {
        color: "#0E84E5",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "24px",
        paddingLeft: "40px",
        paddingRight: "40px",
        height: "40px",
        flexShrink: 0,
        ...shorthands.borderRadius("8px"),
        ...shorthands.border("1px", "solid", "#0E84E5"),
        backgroundColor: "#FFF",
        zIndex: 300,
        "&: hover": {
            backgroundColor: "#FFF",
        }
    }
})