
import Lottie from 'react-lottie';
import animationData from './comingSoon.json'
export const ComingSoon = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <div style={{
            backgroundColor: "#FDFDFD",
            margin: "auto",
            boxShadow: '5px 1px 20px 0px rgba(0, 0, 0, 0.05)',
            borderRadius: '0.5em'
        }}>
            <div style={{
                marginTop: '10%',
                marginLeft: "10%",
                height: '40%',
                width: '60%',
                minHeight: '35em',
                minWidth: "65em",
                display: "flex",
                flexDirection: "row",
            }}>
                <div style={{ margin: 'auto', height: "60%", fontWeight: "600", maxWidth: "35em" }}>
                    <div style={{ marginBottom: "1em", color: "#3887B4", fontSize: "3.25em" }}>We are coming soon</div>
                    <div style={{ color: "#4EABE0", fontSize: "1.5em", lineHeight: "1.75em" }}>This section is under construction. We will be here soon with new features.</div>
                </div>
                <div>
                    <Lottie
                        options={defaultOptions}
                        height={400}
                        width={400}
                    />
                </div>
            </div>
        </div>
    );
};