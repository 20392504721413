import * as React from 'react';
import { Button, CounterBadge, Tag, TagGroup, Toast, ToastTitle, Toaster, makeStyles, shorthands, tokens, useToastController } from "@fluentui/react-components"
import discoverApp_icon from "../../assets/icons/nav_icons/discover-apps.svg";
import { Link, useParams } from "react-router-dom";
import TalkToExpertModal from './TalkToExpertModal';
import { recommendedWorkflowsData } from './common/RcmdWorkflowsData';
import Footer from '../landing/common/Footer';
import HighlightText from '../workflow_library/common/HighlightText';
import Demo from '../workflow_library/Demo';

interface DemoLink {
    name: string;
    path: string;
    type: string;
}
interface HowItWorks {
    title: string;
    info: string;
    icon: string;
}

interface MoreInfos {
    title: string;
    info: string;
}
interface ReportItem {
    id: string;
    icon: string;
    title: string;
    info: string;
    info1?: string;
    info2?: string;
    createdBy: string;
    domain: string[];
    workFlowStages: MoreInfos[];
    input: MoreInfos[];
    output: MoreInfos[];
    demoLink: DemoLink[];
    howItWorks: HowItWorks[];
    tagNames: string[];
}


const DMSReport = () => {
    const classes = useStyles()
    const { dsrID } = useParams();
    const timeout = 2000;
    const { dispatchToast } = useToastController('addNewWorkFlow');
    const [rcmsworkFlowsDatas, setrcmsworkFlowsDatas] = React.useState<ReportItem | null>(null);
    const [talkToExpertModal, setTalkToExpertModal] = React.useState<boolean>(false)
    const [isBtnDisabled, setIsBtnDisabled] = React.useState(false);


    React.useEffect(() => {
        const SelectedDMSReport = recommendedWorkflowsData.find((ele: any) => ele.id === dsrID);
        if (SelectedDMSReport) {
            console.log("SelectedDMSReport", SelectedDMSReport)
            setrcmsworkFlowsDatas(SelectedDMSReport);
        }
    }, [dsrID]);


    const notifyToast = (msg: string) => dispatchToast(
        <Toast
            style={{ background: "rgb(222, 225, 242)" }}>
            <ToastTitle style={{ fontSize: "14px", fontWeight: 700 }}>{msg}</ToastTitle>
        </Toast>,
        { intent: "info" }
    );

    const toggleTalkToExpertDialog = (flag: boolean) => {
        setTalkToExpertModal(flag)
    }
    type TalkToExpertForm = {
        fullName: string;
        email: string;
        phoneNumber: string;
        customMessage: string;
    }

    const onSubmit = (formValues: TalkToExpertForm) => {
        console.log('formValues recived', formValues)
        setTalkToExpertModal(false)
    }
    const handleToaster = () => {
        setIsBtnDisabled(true);
        notifyToast("Coming soon")
        setTimeout(() => {
            setIsBtnDisabled(false)
        }, timeout);
    }
    return (
        <>
            <div className={classes.projectHeaderText}>
                <div style={{ display: "flex", alignItems: "center", }}>
                    <Link className={classes.linkTxt} to="/discover-apps"><img alt="projectIcon" style={{ paddingRight: ".5em", }} src={discoverApp_icon} /> Discover Apps</Link><p className={classes.linkTxt} style={{ margin: "0 .2em " }}>{'>'}</p>
                    <Link className={classes.linkTxt} to="/discover-apps/recommended-Workflows">Recommended Workflows</Link><p className={classes.linkTxt} style={{ margin: "0 .2em " }}>{'>'}</p> <p className={classes.linkTxt}>{rcmsworkFlowsDatas?.title}</p>
                </div>
            </div>
            <div className={classes.bgContainer}
                style={{
                    background: tokens.colorNeutralBackground1,
                    overflow: "auto",

                }}

            >
                <div className={classes.subHeader} >
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <h2 style={{ color: "#043957", fontWeight: "700" }}>{rcmsworkFlowsDatas?.title}</h2>
                            <div style={{ marginLeft: "1em" }}>
                                {
                                    rcmsworkFlowsDatas && <TagGroup role="list" className={classes.tagContainer} >
                                        {
                                            rcmsworkFlowsDatas.tagNames.map((item, index) => <Tag key={index} role="listitem" className={classes.tagBar} style={{ height: "3.5vh", borderRadius: "4px" }}>{item}</Tag>
                                            )
                                        }
                                    </TagGroup>
                                }
                            </div>
                        </div>
                        <Toaster toasterId={'addNewWorkFlow'} position="top-end" />
                        <Button disabled={isBtnDisabled} style={{ padding: "8px 16px" }} onClick={handleToaster}>Add to workflow library</Button>
                    </div>
                </div>
                <div style={{ marginTop: "2em", width: "70%", display: "flex", justifyContent: "space-between" }}>
                    <div style={{ width: "70%" }}>
                        <div>{
                            rcmsworkFlowsDatas && (<div>
                                <h2 className={classes.heading}>About</h2>
                                <p className={classes.info}>
                                    <HighlightText text={rcmsworkFlowsDatas?.info} />
                                </p>
                                {
                                    rcmsworkFlowsDatas.info1 && (
                                        <p className={classes.info}>
                                            <HighlightText text={rcmsworkFlowsDatas?.info1} />
                                        </p>
                                    )
                                }
                                {
                                    rcmsworkFlowsDatas.info2 && (
                                        <p className={classes.info}>
                                            <HighlightText text={rcmsworkFlowsDatas?.info2} />                                            </p>
                                    )
                                }
                            </div>
                            )}
                        </div>
                        <div style={{ marginTop: "2em" }}>{
                            rcmsworkFlowsDatas && (<>
                                <h2 className={classes.heading}>Workflow Stages</h2>
                                {
                                    rcmsworkFlowsDatas.workFlowStages.map((ele, index) => (
                                        <div key={index} style={{ margin: "2em 0", display: "flex" }}>
                                            <CounterBadge style={{ padding: "1.1em", background: "#DFEDDB", color: "#000000", fontWeight: "500", fontSize: "1.2rem" }} size='extra-large' count={index + 1} shape="rounded" />
                                            <div style={{ margin: "0 0.5em", padding: 0 }}>
                                                <p className={classes.subHeading2} style={{ margin: 0, padding: 0 }}>{ele.title}</p>
                                                <p className={classes.info} style={{ margin: 0, padding: ".2em 0", color: "#696969" }}>{ele.info}</p>
                                            </div>
                                        </div>
                                    ))
                                }
                            </>
                            )}
                        </div>
                        <div style={{ marginTop: "2em" }}>{
                            rcmsworkFlowsDatas && (<>
                                <h2 className={classes.heading}>Data Requisites and Results</h2>
                                <div style={{ margin: ".8em 0", display: "flex", alignItems: "baseline" }}>
                                    <div style={{ width: "30%" }}>
                                        <h2 className={classes.subHeading2}>Input Parameters</h2>
                                    </div>
                                    <div style={{ width: "70%" }}>
                                        {
                                            rcmsworkFlowsDatas.input.map((ele, index) => (
                                                <div key={index} style={{ padding: 0, marginBottom: "1em" }}>
                                                    <p className={classes.subHeading2} style={{ margin: 0, padding: 0 }}>{ele.title}</p>
                                                    <p className={classes.info} style={{ margin: 0, padding: ".2em 0", color: "#696969" }}>{ele.info}</p>
                                                </div>
                                            ))
                                        }

                                    </div>
                                </div>
                                <div style={{ display: "flex", justifyContent: "end", margin: "0.5em 0", padding: 0 }}>
                                    <p style={{ border: "1px solid #D0DCEE", width: "70%" }}></p>
                                </div>
                                <div style={{ margin: ".8em 0", display: "flex", alignItems: "baseline" }}>
                                    <div style={{ width: "30%" }}>
                                        <h2 className={classes.subHeading2}>Workflow Results</h2>
                                    </div>
                                    <div style={{ width: "70%" }}>
                                        {
                                            rcmsworkFlowsDatas.output.map((ele, index) => (
                                                <div key={index} style={{ padding: 0, marginBottom: "1em" }}>
                                                    <p className={classes.subHeading2} style={{ margin: 0, padding: 0 }}>{ele.title}</p>
                                                    <p className={classes.info} style={{ margin: 0, padding: ".2em 0", color: "#696969" }}>{ele.info}</p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>

                            </>
                            )}
                        </div>
                    </div>
                    <div style={{ width: "25%" }}>
                        <div style={{ border: "1px solid #E3E9F2", background: "linear-gradient(144.88deg, #FBCB83 2.71%, #FFC3C3 92.61%)", borderRadius: "16px", padding: "1em", textAlign: "center" }}>
                            <h2 className={classes.heading}>Try Out Now!!</h2>
                            <div style={{ margin: "1em 0" }}>
                                <h2 className={classes.info}>Our experts will assist you in running a sample dataset and guide you through the process to enhance your experience.</h2>
                            </div>
                            <Button onClick={() => toggleTalkToExpertDialog(true)} style={{ width: "90%", color: "#FF553B", borderRadius: "6px", border: "0.75px solid #FFFFFF" }}>Talk To Expert</Button>
                        </div>

                        <div style={{ border: "1px solid #E3E9F2", margin: "1.5em 0", background: "#FBFBFB", borderRadius: "16px", padding: ".8em" }}>
                            <h2 className={classes.heading} style={{ textAlign: "center" }}>Demo</h2>
                            <div style={{ margin: "1em 0" }}>
                                {
                                    rcmsworkFlowsDatas && (
                                        <Demo demoLinks={rcmsworkFlowsDatas.demoLink} />
                                    )
                                }
                            </div>
                        </div>

                        <div style={{ border: "1px solid #E3E9F2", margin: "1.5em 0", background: "#FBFBFB", borderRadius: "16px", padding: ".8em" }}>
                            <h2 className={classes.heading} style={{ textAlign: "center" }}>How It Works</h2>
                            <div style={{ margin: "1em 0" }}>
                                {
                                    rcmsworkFlowsDatas && (
                                        <div>
                                            {
                                                rcmsworkFlowsDatas.howItWorks.map((ele, index) => (
                                                    <div key={index} style={{ display: "flex", margin: "1em 0" }}>
                                                        <img src={ele.icon} style={{ width: "16%" }} />
                                                        <div style={{ marginLeft: "1em", marginTop: "1em" }}>
                                                            <p className={classes.subHeading2} style={{ margin: 0, padding: 0 }}>{ele.title}</p>
                                                            <p className={classes.info} style={{ margin: 0, fontSize: ".9rem", color: "#696969", padding: 0 }}>{ele.info}</p>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: "2em" }}>
                    <Footer />
                </div>
            </div>
            <TalkToExpertModal visible={talkToExpertModal} onSubmit={(d: any) => {
                onSubmit(d)
            }} toggleModal={toggleTalkToExpertDialog} />

        </>
    )
}

const useStyles = makeStyles({
    projectHeaderText: {
        height: "3rem",
        paddingLeft: "1.25rem",
        display: "flex",
        alignItems: "center",
        color: "#2B2B2B",
        ...shorthands.borderBottom("1px", "solid", "#E3E9F2"),
        "> span": {
            fontWeight: "700",
        },
    },
    bgContainer: {
        width: "100%",
        ...shorthands.padding("1.5em"),
        height: "calc(91vh - 3rem)",
        scrollbarWidth: "thin",
    },
    subHeader: {
        fontWeight: "600",
        fontSize: "1.5em",
    },
    cardHeading: {
        fontSize: "1.2rem",
        color: "#586A84",
        fontWeight: 500,
        lineHeight: "1.5rem",
        ...shorthands.margin(0),
    },
    tagBar: {
        backgroundColor: "#E3E6EB",
        color: "#272727",
        fontSize: ".5rem !important",
        ...shorthands.padding("4px", "10px"),
        display: "flex", justifyContent: "center", alignItems: "center",
        '& .f1g03r3y': {
            fontSize: '.7rem',
        },
    },
    wrapper: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(40%, 1fr))",
        marginTop: "1em",
        gridGap: '1.2em',
    },
    commonBtn: {
        ...shorthands.padding("10px", "24px"),
        ...shorthands.border("1px", "solid", "#586A84"),
        ...shorthands.borderRadius("8px"),
        color: "#5E5CE6"
    },
    commonConatainerBg: {
        backgroundColor: "#EEEEFF",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        marginTop: "2em",
        marginBottom: "2em",
        alignItems: "center",
        ...shorthands.border("1px", "solid", "#E3E9F2"),
        ...shorthands.borderRadius("16px"),
    },
    subHeading2: {
        fontSize: "1rem",
        color: "#000000",
        lineHeight: "1rem",
        fontWeight: "500",
        marginTop: "1em",
        marginBottom: "1em"
    },
    subHeading1: {
        fontSize: "1.2rem",
        color: "#586A84",
        lineHeight: "1rem",
        fontWeight: "500",
        marginTop: "1em",
        marginBottom: "1em"
    },
    footerBtn: {
        ...shorthands.borderRadius("8px"),
        backgroundColor: "#5E5CE6",
        ...shorthands.padding("4px 16px"),
        color: "#ffff"
    },
    linkTxt: {
        ...shorthands.textDecoration("none"),
        fontSize: '1rem',
        display: "flex",
        alignItems: "center", justifyContent: "center",
        color: "black",
        fontWeight: "normal"
    },
    tagContainer: {
        display: "flex",
        flexWrap: "wrap",
        ...shorthands.gap(".2em")
    },
    heading: {
        fontSize: "1.2rem",
        color: "#272727",
        fontWeight: 600,
        paddingBottom: ".3em",
        ...shorthands.margin(0),
    },
    info: {
        fontSize: ".9rem",
        color: "#000000",
        fontWeight: 400,
        paddingBottom: ".5em",
        marginTop: ".8em"
    },

}
)

export default DMSReport