import React from 'react'
import "./styles.css"
import { makeStyles } from '@fluentui/react-components';
import haradousIcon from "../../../../../assets/icons/landing/hazaradous.svg"
import costIcon from "../../../../../assets/icons/landing/cost.svg"
import labourIcon from "../../../../../assets/icons/landing/labour.svg"
import respiratoryIcon from "../../../../../assets/icons/landing/respiratory.svg"
import timeConsumeIcon from "../../../../../assets/icons/landing/timeConsume.svg"
import explosiveIcon from "../../../../../assets/icons/landing/expensive.svg"

function Limitations() {
    const classes = useStyles();

    return (
        <div>
            <h1 className="heading">Limitations of current methods</h1>
            <div className="lmContainer">
                <div className='lmBoxContainer lmRight'>
                    <div className={classes.lmBox}>
                        <div className="imgOuter">
                            <div className="imgInner"   >
                                <img src={haradousIcon} alt='haradousIcon' />
                            </div>

                        </div>
                        <h2 className='lmLabel'>Hazardous Working Condition</h2>
                    </div>
                    <div className={classes.lmBox}>
                        <div className="imgOuter">
                            <div className="imgInner"   >
                                <img src={respiratoryIcon} alt='respiratoryIcon' />
                            </div>

                        </div>
                        <h2 className='lmLabel'>Respiratory hazards</h2>
                    </div>
                    <div className={classes.lmBox}>
                        <div className="imgOuter">
                            <div className="imgInner"   >
                                <img src={explosiveIcon} alt='explosiveIcon' />
                            </div>

                        </div>
                        <h2 className='lmLabel'>Explosive hazards</h2>
                    </div>
                </div>
                <div className='lmBoxContainer lmLeft'>
                    <div className={classes.lmBox}>
                        <div className="imgOuter">
                            <div className="imgInner"   >
                                <img src={labourIcon} alt='labourIcon' />
                            </div>

                        </div>
                        <h2 className='lmLabel'>Labour Intensive</h2>
                    </div>
                    <div className={classes.lmBox}>
                        <div className="imgOuter">
                            <div className="imgInner"   >
                                <img src={timeConsumeIcon} alt='timeConsumeIcon' />
                            </div>

                        </div>
                        <h2 className='lmLabel'>Time consuming</h2>
                    </div>
                    <div className={classes.lmBox}>
                        <div className="imgOuter">
                            <div className="imgInner"   >
                                <img src={costIcon} alt='costIcon' />
                            </div>

                        </div>
                        <h2 className='lmLabel'>Cost burden</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Limitations;

const useStyles = makeStyles({
    heading: {
        textAlign: "center",
        fontSize: "2.5rem",
        padding: "1.5em 0",
        fontWeight: "bold"
    },

    lmBox: {
        display: "flex",
        alignItems: "center",
        marginBottom: "1em",
        ">h2": {
            fontSize: "1.2rem",
            marginLeft: '.5em',
            fontWeight: "500"
        }
    },

})