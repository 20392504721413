import {
    Menu,
    MenuItem,
    MenuList,
    MenuPopover, MenuTrigger, Table, TableBody, TableCell, TableCellLayout, TableColumnDefinition, TableHeader,
    TableHeaderCell, TableRow, createTableColumn, makeStyles,
    shorthands,
    useTableFeatures,
} from "@fluentui/react-components";
import moment from "moment";
import * as React from 'react';
import { useNavigate } from "react-router-dom";
import verticalMode from "../../assets/icons/more_actions.svg";
import workflowsIcon from "../../assets/icons/nav_icons/workflows.svg";
import { API_BASE } from "../../constants";
import { FlightResponse } from "../../services/openapi";
import DeleteProjectFlightModal from '../DeleteProjectFlightModal';
import StartWorkflowModal from '../StartWorkflowModal';
import EmptyState from './common/EmptyState';

interface Props {
    flightData: FlightResponse[];
    showWorkflowButton?: boolean;
    getFlightData: (projectId: string) => void;
    canArchive: boolean;
    canUploadData: boolean;
}

export const FlightCard = ({ flightData: f, getFlightData, canArchive, canUploadData }: Props) => {
    const navigate = useNavigate();
    const classes = useStyles()

    type nameCell = {
        label: string;
        icon: JSX.Element;
    };

    type LastUpdatedCell = {
        label: string;
    };

    type IdCell = {
        label: string;
    };
    type ProjectIdCell = {
        label: string;
    };

    type projectNameCell = {
        label: string;
    };
    type groupIdCell = {
        label: string;
    };
    type NumWorkflowsCell = {
        label: number;
    };
    type NumReportsCell = {
        label: number;
    };

    type numCompletedWorkflowsCell = {
        label: number;
    };

    type archivedCell = {
        label: boolean;
    };

    type NumMapsCell = {
        label: number;
    };

    type NumOngoingWorkflowsCell = {
        label: number;
    }
    type CreatedAtCell = {
        label: string;
    };
    type UpdatedAtCell = {
        label: string;
    };
    type Item = {
        name: nameCell;
        flightDate: LastUpdatedCell;
        numWorkflows: NumWorkflowsCell;
        numReports: NumReportsCell;
        id: IdCell,
        projectId: ProjectIdCell,
        numMaps: NumMapsCell,
        projectName: projectNameCell,
        groupId: groupIdCell,
        archived: archivedCell,
        numCompletedWorkflows: numCompletedWorkflowsCell,
        numOngoingWorkflows: NumOngoingWorkflowsCell,
        createdAt: CreatedAtCell,
        updatedAt: UpdatedAtCell
    };

    const items: Item[] = f.map((flight) => ({
        name: {
            label: flight.name, icon: <img style={{ width: "45px", borderRadius: "5px", height: "30px" }}
                src={`${API_BASE}/api/flights/${flight.id}/thumbnail`}
                alt="Flight Thumbnail"
            />
        },
        flightDate: { label: moment(flight.flightDate).format("DD-MM-YYYY") },
        numWorkflows: { label: flight.numWorkflows || 0 },
        numReports: { label: flight.numReports || 0 },
        id: { label: flight.id },
        projectId: { label: flight.projectId },
        numMaps: { label: flight.numMaps || 0 },
        projectName: { label: flight.projectName },
        groupId: { label: flight.groupId ? flight.groupId : 'N/A' },
        archived: { label: flight.archived ? flight.archived : false },
        numCompletedWorkflows: { label: flight.numCompletedWorkflows || 0 },
        numOngoingWorkflows: { label: flight.numOngoingWorkflows || 0 },
        createdAt: { label: flight.createdAt },
        updatedAt: { label: flight.createdAt }
    }));

    const columns: TableColumnDefinition<Item>[] = [
        createTableColumn<Item>({
            columnId: "name",
        }),
        createTableColumn<Item>({
            columnId: "flightDate",
        }),
        createTableColumn<Item>({
            columnId: "numWorkflows",
        }),
        createTableColumn<Item>({
            columnId: "numReports",
        }),

    ];

    const {
        getRows,
    } = useTableFeatures(
        {
            columns,
            items,
        },
    );
    const rows = getRows();

    return (
        <div id="flights-table">
            <Table sortable aria-label="Table with controlled sort">
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell>
                            Flight Name
                        </TableHeaderCell>
                        <TableHeaderCell>
                            <div style={{ width: "100%", display: "flex", justifyContent: "center" }} >
                                Flight Date
                            </div>
                        </TableHeaderCell>
                        <TableHeaderCell>
                            <div style={{ width: "100%", display: "flex", justifyContent: "center" }} >
                                Workflows
                            </div>
                        </TableHeaderCell>
                    </TableRow>
                </TableHeader>

                <TableBody>
                    {rows.length === 0 ? (
                        <TableRow className={classes.emptyRow}>
                            <TableCell colSpan={3}>
                                <EmptyState stateMessage='flight' />
                            </TableCell>
                        </TableRow>
                    ) : (
                        rows.map(({ item }) => (
                            <TableRow key={item.name.label} className={`${item.archived.label ? classes.disabledCard : ''}`} id="flight-row">
                                <TableCell
                                    onClick={item.archived.label ? undefined : () => navigate(`/projects/${item.projectId.label}/flights/${item.id.label}`)}
                                >
                                    <TableCellLayout media={item.name.icon}>
                                        <p style={{ cursor: item.archived.label ? "default" : "pointer", color: "#606060" }}>
                                            {item.name.label}
                                        </p>
                                    </TableCellLayout>
                                </TableCell>
                                <TableCell style={{ textAlign: "center" }}>
                                    {item.flightDate.label}
                                </TableCell>
                                <TableCell style={{ textAlign: "center" }}>
                                    <div style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }} id="flights-table-workflows">
                                        <img alt="WorkFlow" src={workflowsIcon} style={{ width: "17px", marginRight: ".3em" }} /><span>{item.numWorkflows.label}</span>
                                    </div>
                                </TableCell>
                                <TableCell className={classes.flexContent} style={{ textAlign: "end", position: "absolute", right: '2%' }}>
                                    <MoreFeature archived={item.archived.label} projectName={item.projectName.label} getFlightData={getFlightData} flightName={item.name.label} projectId={item.projectId.label} flightId={item.id.label} canArchive={canArchive} canUploadData={canUploadData} />
                                </TableCell>
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>
        </div>
    );
}

interface ProjectMoreProps {
    projectId: string;
    flightId: string;
    flightName: string;
    projectName: string;
    archived: boolean;
    getFlightData: (projectId: string) => void;
    canArchive: boolean;
    canUploadData: boolean
}
const MoreFeature = ({ projectId, archived, getFlightData, flightId, flightName, projectName, canArchive, canUploadData }: ProjectMoreProps) => {
    const classes = useStyles();
    const [startWorkFlowModalVisible, setStartWorkFlowModalVisible] = React.useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = React.useState(false);


    const startWorkFlowModal = (flag: boolean) => {
        setStartWorkFlowModalVisible(flag)
    }
    const toggleDeleteModal = (flag: boolean) => {
        setDeleteModalVisible(flag)
    }
    return <div>
        <Menu>
            {
                archived ? <div className={classes.new}>
                    <img alt='More' style={{ width: "18px" }} className={archived ? classes.disabledOverlay : classes.nonDisabledOverlay} src={verticalMode} />
                </div> : <MenuTrigger disableButtonEnhancement>
                    <div className={classes.new}>
                        <img alt='More' style={{ width: "18px" }} className={archived ? classes.disabledOverlay : classes.nonDisabledOverlay} src={verticalMode} />
                    </div>
                </MenuTrigger>
            }
            <MenuPopover className={classes.menuPopoverContainer}>
                <MenuList>
                    <MenuItem className={classes.flexContent} style={{ padding: 0, opacity: canUploadData ? "100%" : "40%" }} onClick={() => { canUploadData && startWorkFlowModal(true) }}>
                        Start New Workflow
                    </MenuItem>
                    <MenuItem className={classes.flexContent} style={{ padding: 0, opacity: canArchive ? "100%" : "40%" }} onClick={() => { canArchive && toggleDeleteModal(true) }} >
                        Delete
                    </MenuItem>
                </MenuList>
            </MenuPopover>
        </Menu>
        <StartWorkflowModal flightId={flightId} flightName={flightName} projectName={projectName} projectId={projectId} visible={startWorkFlowModalVisible} toggleModal={startWorkFlowModal} />
        <DeleteProjectFlightModal visible={deleteModalVisible} toggleModal={toggleDeleteModal} flightId={flightId} projectId={projectId} flightName={flightName} getFlightData={getFlightData} />
    </div>
}

const useStyles = makeStyles({
    footerIcons: {
        width: "18px"
    },
    flexContent: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    new: {
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
        ...shorthands.gap('0.5rem'),
        fontSize: "18px",
        lineHeight: "22px",
        cursor: "pointer",
    },
    menuPopoverContainer: {
        width: "15%",
        color: "red",
    },
    disabledOverlay: {
        cursor: "default"
    },
    nonDisabledOverlay: {
        cursor: "pointer",
    },
    disabledCard: {
        opacity: "0.6",
    },
    emptyRow: {
        ':hover': {
            backgroundColor: "none"
        },
    }
})
