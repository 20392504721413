import { Button, Tooltip, makeStyles, mergeClasses, shorthands } from "@fluentui/react-components";

interface IconButtonProps {
    icon: any;
    disabled?: boolean,
    onClick: () => void,
    borderColor: string,
    label: string,
}

export const IconButton = (props: IconButtonProps) => {
    const { icon, disabled = false, onClick, borderColor, label } = props
    const classes = useStyles()

    return <Tooltip content={label} relationship="label" hideDelay={0} appearance="inverted" positioning={"below"} showDelay={0} >
        <Button size="large" icon={icon} className={disabled ? mergeClasses(classes.buttonDisabled, classes.button) : classes.button} disabled={disabled} onClick={onClick} style={{ borderColor }} />
    </Tooltip>
};


const useStyles = makeStyles({
    button: {
        ...shorthands.border("1px solid"),
        ...shorthands.padding("6px 12px"),
        ":hover": {
            backgroundColor: "#E5E5FE",
        }
    },
    buttonDisabled: {
        opacity: '30%'
    },
    buttonText: {
        fontSize: "14px",
        color: "#7D8FA9",
        fontWeight: 400,
    }
})