import dataProcessing_icon from "../../../assets/icons/landing/dataProcessing.svg";
import geoHospital_icon from "../../../assets/icons/landing/geohospital.svg";
import imgRecognization_icon from "../../../assets/icons/landing/imgRecognization.svg"
import aiItegration_icon from "../../../assets/icons/landing/aiItegration.svg";
import signalProcessing_icon from "../../../assets/icons/landing/signalProcessing.svg";
import complienceReport_icon from "../../../assets/icons/landing/complienceReport.svg";
import automation_icon from "../../../assets/icons/landing/automation.svg";
import collabration_icon from "../../../assets/icons/landing/collabration.svg";


export const aboutUsData = [
    {
        id: 1,
        icon: dataProcessing_icon,
        title: 'Data Processing',
        info: "Efficiently process and clean large volumes of drone data  with our advanced data processing tools."
    },
    {
        id: 2,
        icon: geoHospital_icon,
        title: 'Geospatial Analysis',
        info: "Leverage geospatial analysis to extract valuable location-based insights from your drone data."
    },
    {
        id: 3,
        icon: imgRecognization_icon,
        title: 'Image Recognition',
        info: "Use our image recognition technology to identify objects, patterns, and anomalies in your drone images."
    },
    {
        id: 4,
        icon: aiItegration_icon,
        title: 'AI/ML Integration',
        info: "Integrate AI/ML models with your drone data to unlock powerful predictive and prescriptive analytics capabilities."
    }, {
        id: 5,
        icon: signalProcessing_icon,
        title: 'Signal Processing',
        info: "Leverage our signal processing tools to extract valuable information from your drone's sensor data."
    }, {
        id: 6,
        icon: complienceReport_icon,
        title: 'Compliance Reporting',
        info: "Generate compliance reports for regulatory requirements, safety standards, and industry guidelines."
    }, {
        id: 7,
        icon: automation_icon,
        title: 'Automation',
        info: "Automate repetitive tasks, workflows, and processes with our drone data analytics platform."
    }, {
        id: 8,
        icon: collabration_icon,
        title: 'Collaboration Tools',
        info: "Collaborate with your team in real-time with  our built-in collaboration tools, including chat, comments, and more."
    },
]