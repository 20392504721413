import React from 'react';

const HighlightText: React.FC<{ text: string }> = ({ text }) => {
    const highlights: { [key: string]: string } = {
        "DeepMatrix": "https://deepmatrix.io/",
        "FLY": "https://deepmatrix.io/#/products/fly",
        "HUVIAiR": "http://huviair.com/",
        "Constra Scouter": "https://www.constra.world/scouter/",
        "Constra Survey": "https://www.constra.world/survey/",
        "Constra Build": "https://www.constra.world/build/",
        "SatSure": "https://www.satsure.co/",
        "Skies": "https://www.satsure.co/product-skies.html",
        "ESRI": "https://www.esri.in/en-in/home",
        "OpenDroneMap": "https://www.opendronemap.org/",
        "Agisoft Metashape": "https://www.agisoft.com/"
    };

    if (!text) return null;

    const regex = new RegExp(`(${Object.keys(highlights).join('|')})`, 'gi');
    const parts = text.split(regex);
    const highlightedTerms = new Set<string>();

    return (
        <>
            {parts.map((part, index) => {
                const lowerCasePart = part.toLowerCase();
                const highlightKey = Object.keys(highlights).find(
                    key => key.toLowerCase() === lowerCasePart
                );

                if (highlightKey && !highlightedTerms.has(lowerCasePart)) {
                    highlightedTerms.add(lowerCasePart);
                    return (
                        <a
                            href={highlights[highlightKey]}
                            style={{ color: "#4891FF", fontWeight: "600", textDecoration: "none" }}
                            key={index}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {part}
                        </a>
                    );
                } else {
                    return part;
                }
            })}
        </>
    );
};

export default HighlightText;
