
import { makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
    primaryHeading: {
        fontSize: "1.2rem",
        fontWeight: 600,
        paddingTop: "1em",
        paddingBottom: "1em",
        textAlign: "center"
    },
    info: {
        fontSize: ".8rem",
        fontWeight: 600,
        textAlign: "center"

    }
});

export const ApprovalPending = () => {
    const classes = useStyles();


    return <div style={{ width: "80%", padding: "3em", background: "rgb(252 251 251 / 40%)", borderRadius: "16px", backdropFilter: "blur(10px)", boxShadow: "-1px 1px 10px 0px #00000040" }}>
        <h3 className={classes.primaryHeading}>Account Approval Pending</h3>
        <p className={classes.info}>Your account approval is pending from your company administrator. You will be notified once it's approved. Thank you for your patience.</p>
    </div>

};
