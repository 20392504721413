import React from 'react'
import MobileHeader from '../../common/MobileHeader';
import { Button, makeStyles, shorthands } from '@fluentui/react-components';
import about_bg from "../../../../assets/icons/landing/solutionBG.webp"
import { useNavigate } from 'react-router-dom';
import { AnalyticsData } from '../../common/DataAnalytics';
import AnalyticsCard from './AnalyticsCard';
import { FeaturesData } from '../../common/FeaturesData';
import FeaturesCard from './FeaturesCard';

function MobileSolutions() {
    const classes = useStyles();
    const navigate = useNavigate();

    return <div>
        <div className={classes.container}>
            <MobileHeader />
            <div className={classes['mob-container']}>
                <div style={{ color: "white" }}>
                    <h3 className={classes.initialHeading}> Explore Tailored Solutions for Your Industry </h3>
                    <h3 className={classes.barndingInfo} style={{ margin: "1em 0", }}>Revolutionize data analytics with innovative workflows designed for your industry. Our industry-centric solutions are easy to integrate, customizable, and deliver insights that drive business growth.</h3>
                    <Button shape="circular" className={classes.gradBtn}
                        onClick={() => {
                            navigate(`/book-demo`);
                        }}
                        style={{ background: "linear-gradient(90deg, #AAD824 0%, #2ABCD0 100%)", color: "white", border: "none" }}
                    >Connect to Expert</Button>
                </div>
            </div>
        </div>
        <div className={classes['secondary-container']}>
            <h3 className={classes.ssinitialHeading}>How our tailored industry solutions transform data analytics </h3>
            {AnalyticsData && (
                <div className={classes.grid}>
                    {AnalyticsData.map((data, index) => (
                        <div key={index}>
                            <AnalyticsCard key={index} analyticsData={data}
                                onMiningClick={() => { navigate("/solutions/mining") }} />
                        </div>
                    ))}
                </div>
            )}
        </div>
        <div className={classes['secondary-container']}>
            <h3 className={classes.ssinitialHeading}>Features</h3>
            {FeaturesData && (
                <div className={classes.grid}>
                    {FeaturesData.map((data, index) => (
                        <div key={index}>
                            <FeaturesCard key={index} featuresData={data} />
                        </div>
                    ))}
                </div>
            )}
        </div>
        <div className={classes['secondary-container']} style={{ textAlign: "center" }}>
            <h3 className={classes.ssinitialHeading}>Ready to see the future of data analytics?</h3>
            <h3 className={classes.ssbarndingInfo} style={{ margin: "1em 0" }}>Our industry-centric solutions are designed to meet the unique needs of your business. Explore our workflows to learn more.</h3>
            <Button shape="circular" className={classes.gradBtn}
                onClick={() => {
                    navigate(`/book-demo`);
                }}
                style={{ background: "linear-gradient(90deg, #AAD824 0%, #2ABCD0 100%)", color: "white", border: "none" }}
            >Connect to Expert</Button>
        </div>
        <div className={classes.commonRow} style={{ fontSize: ".7rem", textAlign: "center" }}>
            <p style={{ marginTop: "1em", color: "#586A84" }}>Copyright © 2024 ideaForge Technology Ltd. All rights reserved.</p>
        </div>
    </div>
}

export default MobileSolutions;

const useStyles = makeStyles({
    "container": {
        minHeight: "45vh",
        maxHeight: "100vh",
        minWidth: "100vw",
        backgroundImage: `url(${about_bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
    },
    "mob-container": {
        width: "93%",
        margin: "2em auto 1em auto"
    },
    barndingLabel: {
        fontSize: "3rem",
        lineHeight: "3rem",
        fontWeight: "700"
    },
    barndingInfo: {
        fontSize: ".85rem",
        lineHeight: "1.1rem",
        color: "#ffff",
        marginTop: ".5em"
    },
    initialHeading: {
        fontSize: "1.8rem",
        fontWeight: "500",
        lineHeight: "2rem",
    },
    gradBtn: {
        ...shorthands.padding("10px"),
        marginTop: "1em",
        marginBottom: "1em",
        fontWeight: "1rem"
    },
    "secondary-container": {
        width: "93%",
        margin: "1em auto"
    },
    ssinitialHeading: {
        fontSize: "1.3rem",
        fontWeight: "500",
        lineHeight: "1.5rem",
    },
    grid: {
        display: "grid",
        gridTemplateColumns: 'repeat(2, 3fr)',
        marginTop: "1em",
        marginBottom: "2em",
        gridGap: '1em',
    },
    ssbarndingInfo: {
        fontSize: ".85rem",
        lineHeight: "1.1rem",
        color: "#08201D",
    },
    commonRow: {
        marginTop: "2em",
        marginBottom: "2em",
    },
})