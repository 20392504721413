import { Button, Checkbox, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, makeStyles, mergeClasses, shorthands } from "@fluentui/react-components"
import { useState } from "react";

import { DashboardResponse, DashboardService, FlightResponse, TimeRange } from "../../services/openapi"
import { IconButton } from "../IconButton";
import DeleteIcon from "../assets/icons/delete.svg"
import FilterIcon from "../assets/icons/filter.svg"
import ExpandIcon from '../assets/icons/expand.svg'

export enum SeperatorTypes {
    success = "SUCCESS",
    warning = "WARNING"
}

interface Props {
    text: string;
    type: SeperatorTypes
}

export default function RowSeperator({ type, text }: Props) {
    const styles = useStyles()

    return <div style={{
        marginTop: "1.5rem",
        padding: "0 0.5rem",
        marginBottom: "1rem",
        position: 'relative',
    }}>
        <p style={{
            position: 'absolute',
            transform: "translateY(-50%)",
            color: type === SeperatorTypes.warning ? "#AA8500" : "#00973C",
            fontSize: "10px",
            fontWeight: 600,
            lineHeight: "16px",
            padding: "2px 8px",
            borderRadius: '4px',
            background: type === SeperatorTypes.warning ? "#FBF3D2" : "rgba(220, 247, 239, 1)"
        }}>{text}</p>
        <div style={{ borderBottom: "1px solid #E3E9F2" }}></div>

    </div>
}

const useStyles = makeStyles({

})
