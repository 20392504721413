import { makeStyles, Tab, TabList } from '@fluentui/react-components';
import React from 'react'
import { Workflow } from '../Workflow';
import WorkflowTable from './WorkflowTable';
import QueueItems from './QueueItems';
import QueueInsights from './QueueInsights';

function InsightsDashboard() {
    const classes = useStyles();
    const allTabs = ["Workflow", "Queue Items", 'Queue Insights']
    const [selectedTab, setSelectedTab] = React.useState<string | null>(allTabs[0]);

    return (
        <div style={{ padding: "1em" }}>
            <div className={classes.tabRoot}>
                <TabList defaultSelectedValue={selectedTab} onTabSelect={(e: any) => { setSelectedTab(e.target.innerText) }}>
                    {
                        allTabs.map((ele, index) => <Tab key={index} value={ele}>{ele}</Tab>
                        )
                    }
                </TabList>
            </div>
            {
                selectedTab === "Workflow" && (
                    <WorkflowTable />
                )
            }
            {
                selectedTab === "Queue Items" && (
                    <QueueItems />
                )
            } {
                selectedTab === "Queue Insights" && (
                    <QueueInsights />
                )
            }
        </div>
    )
}

export default InsightsDashboard;
const useStyles = makeStyles({
    tabRoot: {
        alignItems: "flex-start",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        rowGap: "20px",
    },
})