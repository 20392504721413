import React from 'react'
import "./styles.css"
import stockpileVolume from "../../../../../assets/icons/landing/stockpileVolume.jpg"
import countourMapping from "../../../../../assets/icons/landing/countourMap.jpg"
import ssm from "../../../../../assets/icons/landing/ssm.jpg"
import topoMap from "../../../../../assets/icons/landing/topoMap.jpg"
import { SendRegular } from '@fluentui/react-icons'

function Usecases() {
    return (
        <div className="useCaseContainer">
            <h1 className="heading">Use Cases</h1>
            <div className="row oddRow">
                <img src={stockpileVolume} alt='stockpileVolume' />
                <div className="more">
                    <h2>Stockpile Volume Estimation</h2>
                    <p>Optimize resources, maintain accurate inventories, enhance operational efficiency, and ensure material quality.</p>
                    <p><SendRegular /> Resource Management</p>
                    <p><SendRegular /> Inventory Control</p>
                    <p><SendRegular /> Operational Efficiency</p>
                    <p><SendRegular /> Quality Control</p>
                </div>
            </div>
            <div className="row evenRow">
                <img src={countourMapping} alt='countourMapping' />
                <div>
                    <h2>Contour Mapping</h2>
                    <p>Ensure effective planning and improvement for the operations with reliable and detailed contour maps. Our solution supports a range of applications from site planning to resource management.</p>
                    <p><SendRegular /> Customizable contour intervals</p>
                    <p><SendRegular />  Inventory Control</p>
                    <p><SendRegular />  Integrated GIS Data</p>
                    <p><SendRegular />  Cloud-based access:</p>
                </div>
            </div>
            <div className="row oddRow">
                <img src={ssm} alt='ssm' />
                <div className="more">
                    <h2>Stockpile Spotlevel Map</h2>
                    <p> Keep a close watch on your stockpile inventory ensuring optimal resource management and operational efficiency.</p>
                    <p><SendRegular /> High Accuracy</p>
                    <p><SendRegular /> Automated information processing</p>
                    <p><SendRegular /> Real-time tracking</p>
                    <p><SendRegular /> Advanced reporting</p>
                </div>
            </div>
            <div className="row evenRow">
                <img src={topoMap} alt='topoMap' />
                <div>
                    <h2>Topo Map</h2>
                    <p>Plan efficient drilling and blasting, identify potential hazards, and optimize machinery placement and transport routes.</p>
                    <p><SendRegular /> Excavation Planning</p>
                    <p><SendRegular /> Safety</p>
                    <p><SendRegular /> Logistics</p>
                </div>
            </div>
        </div>
    )
}

export default Usecases
