
import { Card, makeStyles, shorthands, CardHeader, Text, Caption1, TagGroup, Tag } from "@fluentui/react-components"
import { useNavigate } from "react-router-dom";

type RcmdWorkFlowCardProps = {
    rcmdWorkFlowcardData: {
        id: string,
        icon: string,
        title: string,
        info: string,
        partner: string,
        tagNames: string[],
        createdBy: string
    }
}
const RcmdWorkFlowCard = ({ rcmdWorkFlowcardData }: RcmdWorkFlowCardProps) => {
    const styles = useStyles();
    const navigate = useNavigate();
    const truncateText = (text: string, maxLength: number) => {
        const words = text.split(' ');
        if (words.length > maxLength) {
            return words.slice(0, maxLength).join(' ') + '...';
        } else {
            return text;
        }
    };
    return <Card className={styles.card} onClick={() => { navigate(`/discover-apps/workflow-details/${rcmdWorkFlowcardData.id}`) }}>
        <div>
            <div className={styles.imgConatiner} style={{ display: "flex", height: "100%", justifyContent: "space-between" }}>
                <div style={{ width: "40%", height: "100%" }}>
                    <img className={styles.headerImage}
                        style={{ boxShadow: "-1px 1px 10px 0px #0000001A" }}
                        src={rcmdWorkFlowcardData.icon}
                        alt={rcmdWorkFlowcardData.title} />
                </div>
                <div style={{ marginLeft: ".5em", width: "60%" }}>
                    <CardHeader
                        header={<Text className={styles.cardHeading}>{rcmdWorkFlowcardData.title}</Text>}
                        description={
                            <Caption1 className={styles.caption}>  {truncateText(rcmdWorkFlowcardData.info, 7)}</Caption1>
                        }
                    />
                </div>
            </div>
        </div>
        <TagGroup role="list" className={styles.tagContainer} >
            {
                rcmdWorkFlowcardData.tagNames.map((item, index) => <Tag key={index} role="listitem" className={styles.tagBar} style={{ height: "3.5vh", borderRadius: "4px" }}>{item}</Tag>
                )
            }
        </TagGroup>
        <img alt={rcmdWorkFlowcardData.partner} src={rcmdWorkFlowcardData.partner} style={{ position: "absolute", bottom: '10px', width: rcmdWorkFlowcardData.createdBy === 'ESRI India' ? "15%" : "", right: '10px' }} />
    </Card >
}
const useStyles = makeStyles({
    card: {
        ...shorthands.border('1px', 'solid', '#E3E9F2'),
        ...shorthands.borderRadius("16px"),
        ...shorthands.padding('15px'),
        cursor: "pointer",
        backgroundColor: "#FBFBFB",
        boxShadow: "none",
        width: "100%",
        position: "relative",
        maxWidth: "100%",
        height: "calc(0rem + 100%)",
    },

    cardHeading: {
        fontSize: ".8em",
        color: "#272727",
        fontWeight: 500,
        lineHeight: "1.5rem",
        ...shorthands.margin(0),
    },
    headerImage: {
        ...shorthands.borderRadius("4px"),
        width: "100%",
        height: "100%",
        objectFit: "cover"
    },
    caption: {
        color: '#272727',
        marginTop: "1em",
        fontWeight: 400,
        marginBottom: "1em",
    },
    tagBar: {
        backgroundColor: "#E3E6EB",
        color: "#272727",
        fontSize: ".5rem !important",
        ...shorthands.padding("4px", "10px"),
        display: "flex", justifyContent: "center", alignItems: "center",
        '& .f1g03r3y': {
            fontSize: '.7rem',
        },
    },
    imgConatiner: {
        height: 'calc(85% - 4rem)',
    },
    tagContainer: {
        display: "flex",
        flexWrap: "wrap",
        paddingBottom: ".5em",
        ...shorthands.gap(".2em")
    }
})

export default RcmdWorkFlowCard;