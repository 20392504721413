import { EdgeConfig, PluginFile, PluginResponse, WorkflowPluginConfig, WorkflowTemplateResponse } from "../../services/openapi";

export type AddPluginResult = {
  added: 'yes' | 'none' | 'choice',
  fileTypeId: string,
  choices?: { plugin: PluginResponse, config: any }[],
};

export type ViewGenConfig = {
  plugin: PluginResponse,
  srcNodeId: string,
  outputId: string,
  inputId: string,
}

export class Editmodel {
  // outputs: string[];
  plugins: EditPluginModel[];
  edges: EdgeModel[];
  allPlugins: PluginResponse[];

  constructor(allPlugins: PluginResponse[] = [], base: WorkflowTemplateResponse | null = null) {
    // this.outputs = [];
    this.plugins = [];
    this.edges = [];
    this.allPlugins = allPlugins;
    if (base) {
      this.plugins = (base.json['nodes'] as WorkflowPluginConfig[]).map((wp) => {
        const plugin = allPlugins.find((p) => p.id === wp.plugin_id);
        if (plugin) {
          return new EditPluginModel(wp.id, plugin, wp.config || {});
        }
        return null;
      })
        .filter((p) => !!p)
        .reverse() as EditPluginModel[];

      this.edges = (base.json['edges'] as EdgeConfig[]).map((e) => {
        return { from: e.from_node, to: e.to_node, fromPort: e.from_file_id, toPort: e.to_file_id };
      });
    }
  }

  clone() {
    const model = new Editmodel();
    // model.outputs = this.outputs;
    model.plugins = this.plugins;
    model.edges = this.edges;
    model.allPlugins = this.allPlugins;
    return model;
  }

  renderModel() {
    const viewGenOutputs = ['mosaic', 'dem'];
    const viewGenPluginIds = ['TILE_4C_RGB', 'TILE_1C'];
    const viewGenPluginMap: { [key: string]: string } = { 'mosaic': 'TILE_4C_RGB', 'dem': 'TILE_1C' };
    const plugins = [];
    for (const p of this.plugins) {
      const incoming: EdgeModel[] = this.getIncomingEdges(p.id);
      const inputs = [];
      for (const i of p.plugin.inputs) {
        const from = incoming.find((inc) => inc.toPort === i.id);
        const type = p.getFileType(i);
        inputs.push({ id: i.id, type, from: from?.from });
      }
      const outgoing: EdgeModel[] = this.getOutgoingEdges(p.id);
      const outputs = p.plugin.outputs.map((o) => {
        const type = p.getFileType(o);
        let viewGenConfig: ViewGenConfig | null = null;
        if (viewGenOutputs.indexOf(type || '') > -1) {
          const hasViewGen = !!outgoing.find((e) => {
            const node = this.plugins.find((n) => n.id === e.to);
            return node && viewGenPluginIds.indexOf(node.plugin.id) > -1;
          });
          if (!hasViewGen) {
            const pluginId = viewGenPluginMap[type || ''];
            if (pluginId) {
              const plugin = this.allPlugins.find((p) => p.id === pluginId);
              if (plugin) {
                viewGenConfig = {
                  plugin,
                  srcNodeId: p.id,
                  outputId: o.id,
                  inputId: plugin.inputs[0].id,
                };
              }
            }
          }
        }
        return { id: o.id, type, viewGenConfig };
      });
      const ui = { id: p.id, pluginId: p.plugin.id, outputs, inputs, config: p.config };

      plugins.push(ui);
    }
    return {
      plugins,
    };
  }

  setNodeConfig(nodeId: string, config: any) {
    for (const n of this.plugins) {
      if (n.id === nodeId) {
        n.config = config || {};
        break;
      }
    }
  }

  addNode(cfg: ViewGenConfig) {
    const idx = this.plugins.map((p) => p.id).indexOf(cfg.srcNodeId);
    const id = createId(cfg.plugin, this.plugins);
    this.plugins.splice(
      idx,
      0,
      new EditPluginModel(id, cfg.plugin, {}),
    );
    this.edges.push({
      from: cfg.srcNodeId,
      to: id,
      fromPort: cfg.outputId,
      toPort: cfg.inputId,
    });
  }

  addPluginForFileType(fileTypeId: string): AddPluginResult {
    const providers: { plugin: PluginResponse, config: any }[] = [];
    for (const p of this.allPlugins) {
      for (const o of p.outputs) {
        const t = getFileType(o);
        if (t === fileTypeId) {
          providers.push({ plugin: p, config: {} });
          break;
        }
      }
    }
    // we're special casing these 2 types because they can be frequently uploaded
    if (['dem', 'mosaic'].indexOf(fileTypeId) > -1) {
      const uploader = this.allPlugins.find((p) => p.id === 'WORKFLOW_FILE_UPLOADER');
      if (uploader) {
        providers.push({ plugin: uploader, config: { 'file_type': fileTypeId } });
      }
    }
    if (providers.length === 0) {
      // TODO: add uploader and linker
      return { added: 'none', fileTypeId };
    }
    if (providers.length === 1) {
      // TODO: add to plugins array at appropriate place
      // walk down the plugins array (reverse dependency order)
      // and add plugin after first plugin which takes any output as its input
      const res = this.addPlugin(providers[0].plugin, providers[0].config);
      if (res) {
        return res;
      }
      return { added: 'yes', fileTypeId };
    }
    // if we are here we have multiple options
    return { added: 'choice', fileTypeId, choices: providers };
  }

  addPlugin(plugin: PluginResponse, cfg: any): AddPluginResult | null {
    const id = createId(plugin, this.plugins);
    const pluginModel = new EditPluginModel(id, plugin, cfg);
    const inputTypes = [];
    for (const i of plugin.inputs) {
      const iType = pluginModel.getFileType(i);
      if (iType) {
        inputTypes.push(iType);
      }
    }
    // iterate over the plugins array and add before the first plugin which has any of the 
    // input types as output
    // we should update deps now
    let added = false;
    for (let i = 0; i < this.plugins.length; i++) {
      for (const o of this.plugins[i].plugin.outputs) {
        const oType = this.plugins[i].getFileType(o);
        if (oType && inputTypes.indexOf(oType) > -1) {
          this.plugins.splice(i, 0, pluginModel);
          added = true;
          break;
        }
      }
      if (added) break;
    }
    if (!added) {
      this.plugins.push(pluginModel);
    }
    this.edges = [];
    const outputsMap: { [id: string]: { from: string, fromPort: string } } = {};
    // walk nodes in topological order
    for (const p of [...this.plugins].reverse()) {
      const incoming = this.getIncomingEdges(p.id);
      const inputs = p.plugin.inputs;
      for (const i of inputs) {
        const isSatisfied = !!incoming.find((inc) => inc.toPort === i.id);
        if (!isSatisfied) {
          // see if any unsatisfied input matches any output of added plugin
          const iType = p.getFileType(i);
          if (iType && !!outputsMap[iType]) {
            const output = outputsMap[iType];
            this.edges.push({ from: output.from, to: p.id, fromPort: output.fromPort, toPort: i.id });
          }
        }
      }
      for (const o of p.plugin.outputs) {
        const oType = p.getFileType(o);
        if (oType) {
          outputsMap[oType] = { from: p.id, fromPort: o.id };
        }
      }
    }
    // we may be able to add more plugins if there are no choices
    const incoming = this.getIncomingEdges(id);
    for (const i of plugin.inputs) {
      const isSatisfied = !!incoming.find((inc) => inc.toPort === i.id);
      const t = getFileType(i);
      if (!isSatisfied && t) {
        const res = this.addPluginForFileType(t);
        if (res.added === 'choice') return res;
      }
    }
    return null;
  }

  getIncomingEdges(nodeId: string): EdgeModel[] {
    const es: EdgeModel[] = [];
    for (const e of this.edges) {
      if (e.to === nodeId) {
        es.push(e);
      }
    }
    return es;
  }

  getOutgoingEdges(nodeId: string): EdgeModel[] {
    const es: EdgeModel[] = [];
    for (const e of this.edges) {
      if (e.from === nodeId) {
        es.push(e);
      }
    }
    return es;
  }

  getGeneratedOutputs(): string[] {
    // this is hopefully in dependency order
    const plugins = [...this.plugins].reverse();
    const out: string[] = [];
    for (const p of plugins) {
      for (const o of p.plugin.outputs) {
        const t = p.getOutputType(o.id);
        if (t && out.indexOf(t) === -1) {
          out.push(t);
        }
      }
    }
    return out;
  }

  isComplete() {
    for (const p of this.plugins) {
      const incoming = this.getIncomingEdges(p.id);
      for (const i of p.plugin.inputs) {
        const isSatisfied = !!incoming.find((inc) => inc.toPort === i.id);
        if (!isSatisfied) {
          return false;
        }
      }
    }
    return true;
  }
}

export interface EdgeModel {
  from: string;
  to: string;
  fromPort: string;
  toPort: string;
}

export class EditPluginModel {
  id: string;
  plugin: PluginResponse;
  config: any;
  constructor(id: string, plugin: PluginResponse, config: any = {}) {
    this.id = id;
    this.plugin = plugin;
    this.config = config;
  }

  setConfig(cfg: any) {
    this.config = cfg || {};
  }

  getOutputType(outputId: string) {
    const file = this.plugin.outputs.find((o) => o.id === outputId);
    if (file) {
      return this.getFileType(file);
    }
    return null;
  }

  getInputType(inputId: string) {
    const file = this.plugin.inputs.find((o) => o.id === inputId);
    if (file) {
      return this.getFileType(file);
    }
    return null;
  }

  getFileType(file: PluginFile): string | null {
    const typeId = (file.config as any)['file_type'];
    if (typeId && typeId['file_type_id']) {
      return typeId['file_type_id'];
    }

    const typeRef = (file.config as any)['file_type_ref'];
    if (typeRef && typeRef['ref_field']) {
      // we should look this up from config
      return this.config[typeRef['ref_field']];
    }
    return null;
  }
}

export const getFileType = (file: PluginFile): string | null => {
  const typeId = (file.config as any)['file_type'];
  if (typeId && typeId['file_type_id']) {
    return typeId['file_type_id'];
  }
  return null;
}

export const createId = (plugin: PluginResponse, existing: EditPluginModel[]) => {
  const ids: { [id: string]: boolean } = {};
  for (const e of existing) {
    ids[e.id] = true;
  }
  let id = plugin.id.toLowerCase();
  if (!ids[id]) return id;
  for (let i = 2; i < existing.length + 3; i++) {
    const maybeId = `${id}_${i}`;
    if (!ids[maybeId]) return maybeId;
  }
  return plugin.id + new Date();
}

